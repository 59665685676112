import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from "react-hook-form";
import { saveBddFormDataDirect } from '../../services/BddFormService';

function AddBddForm(props) {

    const { setOpenBddForm, openBddForm, getPatientDataById } = props;
    const { register, handleSubmit, reset, resetField, formState: { errors } } = useForm({
        mode: "onTouched"
    });
    const [questions, setQuestions] = useState({
        q1: "",
        q2: "",
        q3: "",
        q4: "",
        q5: ""
    });
    const [date, setDate] = useState(dayjs(new Date()));
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const selectQuestions = (qNumber, value) => {
        setQuestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");

    useEffect(() => {
        if (openBddForm.action) {
            reset(openBddForm.patientData)
        }
    }, [openBddForm.action === true]);

    const saveBddForm = async () => {
        let obj = {
            patientId: openBddForm.patientData.patientId,
            clinicId: openBddForm.patientData.clinicId,
            userEmail: openBddForm.patientData.email,
            ...questions
        }
        await saveBddFormDataDirect(obj)
            .then((resp) => {
                getPatientDataById()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "BDD form save successfully",
                })
                setQuestions({
                    q1: "",
                    q2: "",
                    q3: "",
                    q4: "",
                    q5: ""
                })
                reset({ patientName: "", dateOfBirth: "", email: "" })
                setOpenBddForm({ action: false, patientData: null })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const close = () => {
        setOpenBddForm({ action: false, patientData: null })
        setQuestions({
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: ""
        })
        reset({ patientName: "", dateOfBirth: "", email: "" })
    };

    return (
        <div>
            <material.Dialog open={openBddForm.action} hideBackdrop fullWidth maxWidth="md">
                <material.DialogTitle>BDD Form</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <material.TextField
                                error={errors.patientName?.type === "required"}
                                {...register("name", { required: true })}
                                label="Patient Name"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                readOnly
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <material.TextField
                                error={errors.dateOfBirth?.type === "required"}
                                {...register("dateOfBirth", { required: true })}
                                label="Date of Birth"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                readOnly
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <material.TextField
                                error={errors.userEmail?.type === "required"}
                                {...register("email", { required: true })}
                                label="Email"
                                id="standard-error"
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                readOnly
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DateTimePicker
                                    label="Time Stamp"
                                    format='DD-MM-YYYY'
                                    readOnly
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue)
                                    }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <material.Typography>
                            The below questions will assess underlying psychological conditions such as (BDD) body dysmorphic disorder.
                            Please speak to your practitioner if you require further information regarding BDD.
                        </material.Typography>
                        <material.Typography sx={{ mt: 2 }}>
                            If there are indications of significant underlying psychological issues which may make
                            you an unsuitable candidate for the cosmetic procedure, you may be referred for evaluation to a
                            psychologist, psychiatrist or general practitioner who works independently of the Healthcare professional who will perform the procedure or the medical practitioner who will prescribe the cosmetic injectable.
                        </material.Typography>
                        <div className='row'>
                            <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                                1- Are you worried about the way you look, and do you think about your appearance a lot and wish you could think about it less
                            </material.Typography>
                            <span className='col-lg-3 col-md-4 col-sm-4'>
                                <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("q1", event.target.value)}
                                        value={questions.q1}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        </div>
                        <div className='row'>
                            <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                                2- How has it, the way you feel about it, affected your daily life activities, such as school, work, or other activities?
                            </material.Typography>
                            <span className='col-lg-3 col-md-4 col-sm-4'>
                                <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("q2", event.target.value)}
                                        value={questions.q2}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        </div>
                        <div className='row'>
                            <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                                3- Are there things you avoid because of how you look, such as gatherings, going out etc
                            </material.Typography>
                            <span className='col-lg-3 col-md-4 col-sm-4'>
                                <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("q3", event.target.value)}
                                        value={questions.q3}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        </div>
                        <div className='row'>
                            <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                                Do you spend more than 2-3 hours a day thinking about how you look
                            </material.Typography>
                            <span className='col-lg-3 col-md-4 col-sm-4'>
                                <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("q4", event.target.value)}
                                        value={questions.q4}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        </div>
                        <div className='row'>
                            <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                                5- If you could not have a cosmetic treatment today, would this affect your life adversely?
                            </material.Typography>
                            <span className='col-lg-3 col-md-4 col-sm-4'>
                                <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("q5", event.target.value)}
                                        value={questions.q5}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <div className='col-12 mt-3'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' onClick={close}>Close</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveBddForm)} disabled={isValid}>Save</material.Button>
                        </span>
                    </div>
                </material.DialogActions>
            </material.Dialog>
        </div>
    );
}

export default AddBddForm;