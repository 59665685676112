import React, { useEffect, useState } from "react";
import { material } from "../../../../library/material";
import { useNavigate } from "react-router-dom";
import { getAssessmentData } from "../../../../services/GRSService";
import moment from "moment";
import Snackbar from "../../../toastrmessage/Snackbar";
import RadarChart from "./RadarChart";
import ViewGRSDetails from "./ViewAssessment";
import './GRS.css';
import GRSImage from '../../../../assets/GRSbackground.svg';
import { getAllPatientImage } from "../../../../services/PatientService";
import { connect } from "react-redux";


function GRS(props) {
  const { patientData, callFrom, getGrsRefNo, userData, clinicData } = props;
  const userDetails = userData.authReducer.data;
  const clinicDetails = clinicData.clinicReducer.data;
  const navigate = useNavigate();
  const [GRSData, setGRSData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });
  const [openAssessmentDetails, setOpenAssessmentDetails] = useState({
    action: false,
    rowData: null,
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [patientAllImages, setPatientAllImages] = useState([]);

  useEffect(() => {
    getGRSData();
    getAllImagePatient();
  }, []);

  useEffect(() => {
    if (selectedImages.length > 1) {
      // getGrsRefNo(selectedImages)
      setOpenSnackBar({
        action: true,
        type: "warning",
        message: "You can chose only one GRS",
      });
    } 
    // else if (selectedImages.length) {
    //   getGrsRefNo(selectedImages)
    // } else if (!selectedImages.length) {
    //   getGrsRefNo(selectedImages)
    // }
  }, [selectedImages]);

  const getAllImagePatient = () => {
    setOpenSnackBar({
      "action": true,
      "type": "info",
      "message": "Please wait fetching data",
    })
    const payload = {
      clinicId: clinicDetails.clinicId,
      orgId: userDetails.orgId,
      patientId: patientData.patientId
    }
    getAllPatientImage(payload)
      .then((resp) => {
        setPatientAllImages(resp.data)
        // let images = resp.data.map((ele) => {
        //     let data = getTreatmentImage(ele);
        //     return data;
        // })
        // console.log(images)
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": "Image list fetch complete",
        })
        setTimeout(() => {
          setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait while images are loading",
          })
        }, 3000);
      })
      .catch((error) => {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": error.response.data,
        })
      })
  };

  const getGRSData = () => {
    setisLoading(true);
    getAssessmentData(patientData.patientId)
      .then((res) => {
        setGRSData(res.data);
        setisLoading(false)
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
  };

  const goToAddAssessment = () => {
    navigate("/patient-list/view-patient-details/add-assessment", {
      state: { patientData, patientAllImages },
    });
  };

  const viewGRSData = (rowData) => {
    setOpenAssessmentDetails({ action: true, rowData: rowData });
  };

  const selectChart = (imageKey) => {
    getGrsRefNo(imageKey)
    if (selectedImages.includes(imageKey)) {
      setSelectedImages(selectedImages.filter(key => key !== imageKey));
    } else {
      setSelectedImages([...selectedImages, imageKey]);
    }
  };

  return (
    <div>
      <div className="row">
        {callFrom === "Link-GRS" ? null : (
          <div className="col-12">
            <span className="float-end">
              <material.Button
                variant="contained"
                size="small"
                sx={{ textTransform: "none", mb: 1 }}
                className="me-2"
                onClick={goToAddAssessment}
              >
                Add Assessment
              </material.Button>
            </span>
          </div>
        )}
      </div>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '10%' }}>
          <material.CircularProgress color='secondary' />
        </div>
      ) : (
        <div className="row">
          {GRSData?.map((row, i) => {
            const chartData = [
              row.static,
              row.dynamic,
              row.volume_loss,
              row.sagging,
              row.asymmetry,
              row.imbalance,
              row.loss_Of_Elasticity,
              row.surface_roughness,
              row.dehydration,
            ];
            return (
              <div key={i} className="col-4 mb-4">
                <material.Paper elevation={7}
                  sx={{ p: 1, mt: 2, cursor: callFrom === "Link-GRS" ? "pointer" : "" }}
                  onClick={callFrom === "Link-GRS" ? () => selectChart(row.grs_ref_no) : null}
                  className={selectedImages.includes(row.grs_ref_no) ? "column-chart" : ""}
                >
                  <div style={{marginTop:'6%'}}>
                  <RadarChart data={chartData} />
                  </div>
                  <h4 style={{ textAlign: "center", marginTop: '8%' }}>
                    {moment(row?.createdAt).format("DD-MM-YYYY")}
                  </h4>
                  {callFrom === "Link-GRS" ? null : (
                    <div className="row" style={{ marginLeft: "2%", marginTop: '2%' }}>
                      <div className="col-6">
                        <material.Button
                          variant="contained"
                          size="small"
                          color="error"
                          sx={{
                            textTransform: "none",
                            mb: 1,
                            alignSelf: "center",
                          }}
                        >
                          Delete Assessment
                        </material.Button>
                      </div>
                      <div className="col-6">
                        <material.Button
                          variant="contained"
                          size="small"
                          sx={{
                            textTransform: "none",
                            mb: 1,
                            alignSelf: "center",
                          }}
                          // className="me-2"
                          onClick={() => viewGRSData(row)}
                        >
                          View Assessment
                        </material.Button>
                      </div>
                    </div>
                  )}
                </material.Paper>
              </div>
            );
          })}
        </div>
      )}

      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <ViewGRSDetails
        setOpenAssessmentDetails={setOpenAssessmentDetails}
        openAssessmentDetails={openAssessmentDetails}
        patientData={patientData}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state
  };
};

export default connect(mapStateToProps)(GRS);
