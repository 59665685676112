import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { getAllClinic, getClinicForInjector } from '../../services/ClinicService';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { connect } from 'react-redux';
import { getHighestPriorityRole } from '../../highest-priority-role/HighestPriorityRole';
import { getClinicAction } from '../../store/action/Action';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function AdminPermissionDialog(props) {

    const { userData, getClinicDetails } = props;
    let userDetails = userData.authReducer.data;
    let role = getHighestPriorityRole(userDetails.role)
    const { openPermissionDialog, setOpenPermissionDialog, getAllPatientListByClinicId, getInjectorList, getAllInjectorByClinic, getAllScheduleData, getAllMachineList, getRoomListByClinicId, getProductTreatmentList } = props;
    const [clinicData, setClinicData] = useState([]);

    useEffect(() => {
        if (role === "ADMIN") {
            getClinics()
        } else if (role === "INJECTOR") {
            clinicDetailsForInjector()
        }
    }, [openPermissionDialog.action === true]);

    const getClinics = async () => {
        await getAllClinic()
            .then((resp) => {
                setClinicData(resp.data)
            })
            .catch((error) => {

            })
    };

    const clinicDetailsForInjector = async () => {
        await getClinicForInjector()
            .then((resp) => {
                setClinicData(resp.data)
            })
            .catch((error) => {

            })
    };

    const selectClinic = (clinicData) => {
        getClinicDetails(clinicData)
        if (openPermissionDialog.callFrom === "patientList") {
            getAllPatientListByClinicId(clinicData.clinicId)
        } else if (openPermissionDialog.callFrom === "injectorList") {
            getInjectorList(clinicData.clinicId)
        } else if (openPermissionDialog.callFrom === "appointmentSchedule") {
            getAllInjectorByClinic(clinicData.clinicId)
            getAllScheduleData(clinicData.clinicId)
        } else if (openPermissionDialog.callFrom === "configure_machine") {
            getAllMachineList()
        } else if (openPermissionDialog.callFrom === "configure_room") {
            getRoomListByClinicId()
        } else if (openPermissionDialog.callFrom === "treatment_mapping") {
            getProductTreatmentList()
        } else if (openPermissionDialog.callFrom === "skin_treatment_invoice") {
            getAllPatientListByClinicId()
        };
        setOpenPermissionDialog({ action: false, "data": clinicData, "callFrom": "" })
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth hideBackdrop open={openPermissionDialog.action}>
                <material.DialogTitle>Clinic List</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <material.TableContainer>
                            <material.Table>
                                <material.TableHead>
                                    <material.TableRow>
                                        <StyledTableCell>Clinic Name</StyledTableCell>
                                        {/* <material.TableCell>Director Name</material.TableCell> */}
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {clinicData.map((clinic, i) => (
                                        <material.TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                            onClick={() => selectClinic(clinic)}
                                        >
                                            <material.TableCell>{clinic.clinicName}</material.TableCell>
                                        </material.TableRow>
                                    ))}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClinicDetails: (data) => {
            dispatch(getClinicAction(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPermissionDialog);