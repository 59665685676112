import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Title
} from "chart.js";

// Register the components
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Title
);
// Function to get dynamic color based on index
const getColorByIndex = (index) => {
  if (index === 0 || index === 1) {
    return "#1E90FF"; // "Static", "Dynamic"
  } else if (index === 2 || index === 3) {
    return "#40E0D0"; // "Volume \nLoss", "Sagging"
  } else if (index === 4 || index === 5) {
    return "#2F4F4F"; // "Asymmetry", "Imbalance"
  } else if (index === 6 || index === 7 || index === 8) {
    return "#008080"; // "Loss of \nElasticity", "Surface\n Roughness", "Dehydration"
  }
  return "black"; // Default color
};

function RadarChart(props) {
  const { data, openFrom } = props;
  const chartData = {
    labels: [
      "Static",
      "Dynamic",
      "Volume \nLoss",
      "Sagging",
      "Asymmetry",
      "Imbalance",
      "Loss of \nElasticity",
      "Surface\n Roughness",
      "Dehydration",
    ],
    datasets: [
      {
        label: "Assessment",
        data: data,
        borderColor: "#33DDFF",
        borderWidth: 2,
        pointBackgroundColor: "#33DDFF",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#33DDFF",
      },
    ],
  };

  const options = {
    scales: {
      r: {
        beginAtZero: true,
        min: 0,
        max: 3,
        ticks: {
          stepSize: 1,
          backdropColor: "transparent",
        },
        angleLines: {
          display: true,
          color: "#33DDFF"
        },
        grid: {
          color: "#33DDFF"
        },
        pointLabels: {
          font: {
            size: 12,
          },
          color: function (context) {
            return getColorByIndex(context.index); // Dynamic color for point labels
          },
          callback: function (value) {
            return value.split("\n");
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        maxWidth: openFrom === "viewAssessment" || "addAssessment" ? "300px" : "700px",
        margin: "auto",
        marginTop:'4%'
      }}
    >
      <Radar data={chartData} options={options} />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "115%",
          height: "115%",
          transform: "translate(-50%, -50%)",
          borderRadius: "50%",
          border: "25px solid rgba(255, 193, 144, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-2%",
            left: "42%",
            transform: "translate(-5%, -80%) rotate(0deg)",
            transformOrigin: "center",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#1E90FF",
          }}
        >
          Wrinkles
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "-2%",
            transform: "translate(60%, -75%) rotate(-90deg)",
            transformOrigin: "center",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#40E0D0",
          }}
        >
          Volume
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "-3%",
            left: "50%",
            transform: "translate(-50%, 50%) rotate(0deg)",
            transformOrigin: "center",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#2F4F4F",
          }}
        >
          Morphology
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-5%",
            transform: "translate(-50%, -50%) rotate(-90deg)",
            transformOrigin: "center",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#008080",
          }}
        >
          Skin
        </div>
      </div>
    </div>
  );
}

export default RadarChart;
