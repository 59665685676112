import React from 'react';
import { styled } from '@mui/material/styles';
import { drawerWidth } from '../../drawer/Swipedrawer';
import Swipedrawer from '../../drawer/Swipedrawer';
import { material } from '../../../library/material';


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

function StockUsed(props) {

    const [openSideNav, setOpenSideNav] = React.useState(true);

    return (
        <div className='body'>
             <Swipedrawer openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
            <Main open={openSideNav}>
            <material.Typography>Stock Used</material.Typography>
            </Main>
        </div>
    );
}

export default StockUsed;