import React, { useState } from 'react';
import "../ConsentForm.css";
import SignaturePad from 'react-signature-canvas';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { material } from '../../../../library/material';
import { saveConsentFormOpen, saveConsentForm } from '../../../../services/ConsentFormService';
import Snackbar from '../../../toastrmessage/Snackbar';
import { socket } from '../../../../socket/Socket';

var sigPad = {};

function ExomideScalp(props) {

    const theme = useTheme();
    const { blankTreatmentData, openConsentForm, setGetConsentFormExomideScalpData, consentData, callForm, clinicDetails } = props;
    const navigate = useNavigate();
    const [refNo, setRefNo] = useState('');
    const [saveConsentData, setSaveConsentData] = useState(false);
    const [questions, setquestions] = useState({
        aw_q1: "",
        aw_q2: "",
        aw_q3: "",
        aw_q4: "",
        aw_q5: "",
        aw_q6: "",
        aw_q7: "",
        aw_q8: "",
        aw_q9: "",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const getPatientSignature = () => {
        // getSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    };

    const selectQuestions = (qNumber, value) => {
        setquestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");

    const saveConsent = async (data) => {
        if (openConsentForm.action === false) {
            let obj = {
                patientId: blankTreatmentData.patientId,
                treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                concent_type: "Exomide Scalp",
                concent_data: { ...questions, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentForm(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Consent Form Submit successfully",
                    })
                    if (callForm === "primary-user") {
                        setTimeout(() => {
                            setGetConsentFormExomideScalpData(resp.data)
                        }, 2000)
                    } else {
                        socket.emit("new-concent-added", "Local Anaesthetic Consent Form Submit successfully");
                        // navigate('/kiosk-menu')
                        setSaveConsentData(true);
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        } else {
            let obj = {
                refNo: refNo,
                concent_type: "Exomide Scalp",
                concent_data: { ...questions, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentFormOpen(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };
    };

    const clear = () => {
        sigPad.clear()
    };

    return (
        <div className={callForm === "secondary-user" ? "consent-form-body" : 'content'} id='form-section' hidden={saveConsentData}>
            <material.Paper elevation={3} sx={{
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '70%',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '70%',
                },
                mt: 2, m: 2, p: 3, pb: 10
            }}>
                <div className='mt-2 fw-bold'>
                    <span className='d-flex justify-content-center'>Consent Form for Exomide Scalp Treatments</span>
                </div>
                <div className='mt-5 ms-5'>
                    <div hidden={openConsentForm.action === false || consentData} className='mb-3'>
                        <material.TextField
                            type='text'
                            variant="standard"
                            sx={{ width: "30%", ml: 1, mr: 1, mt: -1 }}
                            name='refNo'
                            label="Reference No"
                            onChange={e => setRefNo(e.target.value)}
                        />
                    </div>
                    <h5>Procedure Information, Risks, and Contraindications:</h5>
                    <h5>1. Procedure Information:</h5>
                    <div className='ms-5'>
                        <span>
                            Your healthcare practitioner has recommended Exomide Scalp treatment.
                            This treatment involves applying the product topically,
                            directly onto the skin or scalp surface within the intended treatment area.
                            Exomide may be used on its own or in conjunction with another treatment.
                            Exomide is a regenerative exosome therapy derived from adipose tissue (fat tissue) stem cells.
                            Exosomes are small nano-sized vesicles that contain various molecules, including proteins,
                            RNA, lipids, and other bioactive substances. It is important to note that exosomes
                            are not cells themselves; they are small vesicles released by cells, are cell-free,
                            and DNA-free, incapable of reproduction. Their main function is to influence other cells,
                            giving them a favorable safety profile.
                        </span>
                    </div>
                    <h5>2. Risk :</h5>
                    <div className='ms-5'>
                        <span>
                            I acknowledge that I have been informed about the Exomide procedure, its potential benefits, and the associated risks. These include, but are not limited to
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q1}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                        value={questions.aw_q1}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <ul className='ms-3'>
                        <li>Temporary redness, swelling, or discomfort.</li>
                        <li>Bruising or heat at the treatment site.</li>
                        <li>Raised bumps under the skin.</li>
                        <li>Allergic reactions, itching, or a mild rash.</li>
                        <li>Tenderness in the treated area.</li>
                        <li>Incomplete or unsatisfactory results.
                            I understand and acknowledge that multiple treatments may be required and results are not guaranteed.
                            I understand and acknowledge that treatment packages are not transferable to any other person and no
                            refund will be provided for change of mind or for any other reason.</li>
                    </ul>
                    <h5>3. Contraindications :</h5>
                    <div className='ms-5'>
                        <span>
                            Please indicate YES or NO for each:
                        </span>
                        <ul className='ms-3'>
                            <li>Are you pregnant or breastfeeding, undergoing IVF treatment, or trying to conceive?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q2}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q2", event.target.value)}
                                            value={questions.aw_q2}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Are you currently taking medications that make your skin more sensitive?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q3}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q3", event.target.value)}
                                            value={questions.aw_q3}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have a history of photosensitivity or conditions exacerbated by light exposure?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q4}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q4", event.target.value)}
                                            value={questions.aw_q4}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Have you recently undergone facial surgery or other facial treatments?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q5}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q5", event.target.value)}
                                            value={questions.aw_q5}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>DDo you have a history of keloids or hypertrophic scarring?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q6}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q6", event.target.value)}
                                            value={questions.aw_q6}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have active skin infections or wounds?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q7}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q7", event.target.value)}
                                            value={questions.aw_q7}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                        </ul>
                    </div>
                    <h5>4. Pre-Procedure Care:</h5>
                    <div className='ms-5'>
                        <span>
                            I confirm that I have taken the following pre-procedure care:
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q8}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q8", event.target.value)}
                                        value={questions.aw_q8}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </span>
                        <ul className='ms-3'>
                            <li>Avoid sun exposure and use of tanning beds for at least 2 weeks before treatment.</li>
                            <li>Inform the practitioner of all medications and skincare products currently in use.</li>
                            <li>Discontinue use of Retin-A, Retinols, or similar products 5-7 days before treatment.</li>
                        </ul>
                    </div>
                    <h5>5. Post-Procedure Care:</h5>
                    <div className='ms-5'>
                        <span>
                            I understand that I need to follow the provided aftercare advice:
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q9}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q9", event.target.value)}
                                        value={questions.aw_q9}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </span>
                        <ul className='ms-3'>
                            <li>Do not touch the treated area.</li>
                            <li>Keep the treated area clean and dry.</li>
                            <li>Do not apply makeup until the next day.</li>
                            <li>Do not apply Vitamin C or any AHAs or BHAs for at least 24 hours.</li>
                            <li>Follow any additional instructions provided by the practitioner and
                                refer to the Exomide aftercare brochure provided by Xytide.</li>
                        </ul>
                    </div>
                    <h5>6. Acknowledgement and Consent:</h5>
                    <div className='ms-5'>
                        <span>
                            By signing below, I acknowledge that I understand the risks, benefits,
                            and instructions associated with the Exomide treatment. I have been given the opportunity to ask questions,
                            and all of my questions have been answered to my satisfaction.
                            I hereby give my consent to undergo the Exomide treatment at <span style={{ fontWeight: "bold" }}>{clinicDetails.clinicName}</span>.
                        </span>
                    </div>
                    <div className='mt-5 d-flex'>
                        <span>Patient Signature</span>
                        {consentData ? (
                            <span className='ms-3'>
                                <img
                                    src={consentData.patientSignature}
                                />
                            </span>
                        ) : (
                            <>
                                <span className='sigBox ms-3'>
                                    <SignaturePad penColor='black'
                                        canvasProps={{ width: 400, height: 100, className: 'sigPad' }}
                                        ref={(ref) => { sigPad = ref }}
                                        onEnd={getPatientSignature}
                                    />
                                </span>
                                <span className='ms-2'>
                                    <material.Button size='small' sx={{ textTransform: "none" }} onClick={clear}>Clear</material.Button>
                                </span>
                            </>
                        )}
                    </div>
                    <span className='float-end mt-3 pe-3'>
                        <material.Button hidden={consentData} variant="contained" sx={{ textTransform: "none" }} onClick={saveConsent} disabled={isValid}>Save Consent Form</material.Button>
                    </span>
                </div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Paper>
        </div>
    );
}

export default ExomideScalp;