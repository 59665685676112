import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import "./Addorganisation.css";
import { useForm } from "react-hook-form";
import { createOrganisation, updateOrganisation } from '../../services/OrganisationService';
import { useLocation, useNavigate } from 'react-router-dom';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import { createClinic, updateClinic } from '../../services/ClinicService';
import Navbar from '../navbar/Navbar';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function Addorganisation(props) {
    const { userData } = props;
    const userDetails = userData.authReducer.data;
    const location = useLocation();
    let organisatioDetails = location.state ? location.state.organisationData : "";
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
        defaultValues: organisatioDetails ? organisatioDetails : ""
    });
    const navigate = useNavigate();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectCheckbox, setSelectCheckbox] = React.useState(false);

    useEffect(() => {
        setSelectCheckbox(organisatioDetails.defaultConfig);
    }, []);

    if (organisatioDetails.parentComponent === "clinic") {
        localStorage.setItem("menuName", "Add Clinic")
    } else if (organisatioDetails.callFrom === "add") {
        localStorage.setItem("menuName", "Add Organization")
    };

    const addOrganisation = async (formData) => {
        if (organisatioDetails.callFrom === "add") {
            await createOrganisation(formData).then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Organisation added successfully",
                })
                setTimeout(() => {
                    reset()
                    goBack()
                }, 1000)
            }).catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
        } else if (organisatioDetails.parentComponent === "clinic") {
            await createClinic({ ...formData, defaultConfig: selectCheckbox })
                .then((res) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Clinic added successfully",
                    })
                    setTimeout(() => {
                        reset()
                        goBack()
                    }, 1000)
                    setSelectCheckbox(false);
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };
    };

    const updateOrganisationDetails = async (formData) => {
        if (userDetails.role.includes("SUPERADMIN")) {
            await updateOrganisation(formData).then(() => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Organization updated successfully",
                })
                setTimeout(() => {
                    reset()
                    goBack()
                }, 1000)
            }).catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
        } else if (userDetails.role.includes("ADMIN")) {
            await updateClinic({ ...formData, defaultConfig: selectCheckbox })
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Organization updated successfully",
                    })
                    setTimeout(() => {
                        reset()
                        goBack()
                    }, 1000)
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };

    };

    const goBack = () => {
        if (userDetails.role.includes("ADMIN")) {
            navigate("/clinic")
        } else if (userDetails.role.includes("SUPERADMIN")) {
            navigate("/organization")
        }
    };

    const handleChange = (event) => {
        setSelectCheckbox(event.target.checked);
    };


    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className="row">
                    <div className="col-6">
                        {/* {organisatioDetails.parentComponent === "clinic" ? (
                            <span><material.Typography variant="h5">Add Clinic</material.Typography></span>
                        ) : organisatioDetails.callFrom === "add" ? (
                            <span><material.Typography variant="h5">Add Organisation</material.Typography></span>
                        ) : ""} */}
                    </div>
                    <div className="col-6">
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>

                <material.Paper className='p-4 mt-2' elevation={1}>
                    <form >
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                {userDetails.role.includes("ADMIN") ? (
                                    <material.TextField
                                        error={errors.clinicName?.type === "required"}
                                        {...register("clinicName", { required: true })}
                                        label="Clinic Name"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        // defaultValue="Small"
                                        size="small"
                                        fullWidth
                                        inputProps={{ style: { textTransform: 'capitalize' } }}
                                        sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                        InputProps={{ readOnly: organisatioDetails.readOnly }}

                                    />
                                ) : (
                                    <material.TextField
                                        error={errors.orgName?.type === "required"}
                                        {...register("orgName", { required: true })}
                                        label="Organisation Name"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        // defaultValue="Small"
                                        size="small"
                                        fullWidth
                                        inputProps={{ style: { textTransform: 'capitalize' } }}
                                        sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                        InputProps={{ readOnly: organisatioDetails.readOnly }}

                                    />
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.acn?.type === "required"}
                                    {...register("acn", { required: true, minLength: 9, maxLength: 9 })}
                                    label="ACN"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                                {(errors?.acn?.type === "minLength" || errors?.acn?.type === "maxLength") && (
                                    <p className='text-danger'>This field only contain 9 digits</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.abn?.type === "required"}
                                    {...register("abn", { required: true, minLength: 11, maxLength: 11 })}
                                    label="ABN"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                                {(errors?.abn?.type === "minLength" || errors?.abn?.type === "maxLength") && (
                                    <p className='text-danger'>This field only contain 11 digits</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 ">
                                <material.TextField
                                    error={errors.registeredOfficeAddress?.type === "required"}
                                    {...register("registeredOfficeAddress")}
                                    label="Register Office"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type='text'
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director1Id?.type === "required"}
                                    // {...register("director1Id", { required: organisatioDetails.parentComponent === "clinic" ? false : true })}
                                    {...register("director1Id")}
                                    label="Director_1 ID"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type='text'
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director1?.type === "required"}
                                    // {...register("director1", { required: organisatioDetails.parentComponent === "clinic" ? false : true })}
                                    {...register("director1")}
                                    label="Director_1 Name"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="text"
                                    fullWidth
                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director1Phone?.type === "required"}
                                    {...register("director1Phone")}
                                    label="Director_1 Ph."
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{
                                        startAdornment: <material.InputAdornment sx={{ marginBottom: 2.6 }} position="start">+61</material.InputAdornment>,
                                        readOnly: organisatioDetails.readOnly
                                    }}
                                />
                                {(errors?.director1Phone?.type === "minLength" || errors?.director1Phone?.type === "maxLength") && (
                                    <p className='text-danger'>Cannot exceed 10 number</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director1Mail?.type === "required"}
                                    {...register("director1Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    label="Director_1 Email"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="email"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                                {(errors?.director1Mail?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director2Id?.type === "required"}
                                    {...register("director2Id")}
                                    label="Director_2 ID"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="text"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director2?.type === "required"}
                                    {...register("director2")}
                                    label="Director_2 Name"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="text"
                                    fullWidth
                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director2Phone?.type === "required"}
                                    {...register("director2Phone")}
                                    label="Director_2 Ph."
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{
                                        startAdornment: <material.InputAdornment sx={{ marginBottom: 2.6 }} position="start">+61</material.InputAdornment>,
                                        readOnly: organisatioDetails.readOnly
                                    }}
                                />
                                {(errors?.director2Phone?.type === "minLength" || errors?.director2Phone?.type === "maxLength") && (
                                    <p className='text-danger'>Cannot exceed 10 number</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.director2Mail?.type === "required"}
                                    {...register("director2Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    label="Director_2 Email"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="email"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                                {(errors?.director2Mail?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact1Name?.type === "required"}
                                    {...register("contact1Name")}
                                    label="Contact_1 Name"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    fullWidth
                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact1Mail?.type === "required"}
                                    {...register("contact1Mail", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    label="Contact_1 Email"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="email"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                                {(errors?.contact1Mail?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact1Address?.type === "required"}
                                    {...register("contact1Address")}
                                    label="Contact_1 Address"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact1Phone?.type === "required"}
                                    {...register("contact1Phone")}
                                    label="Contact_1 Ph."
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{
                                        startAdornment: <material.InputAdornment sx={{ marginBottom: 2.6 }} position="start">+61</material.InputAdornment>,
                                        readOnly: organisatioDetails.readOnly
                                    }}
                                />
                                {(errors?.contact1Phone?.type === "minLength" || errors?.contact1Phone?.type === "maxLength") && (
                                    <p className='text-danger'>Cannot exceed 10 number</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact2Name?.type === "required"}
                                    {...register("contact2Name")}
                                    label="Contact_2 Name"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    fullWidth
                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact2Mail?.type === "required"}
                                    {...register("contact2Mail", {
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,

                                    })}
                                    label="Contact_2 Email"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type="email"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{ readOnly: organisatioDetails.readOnly }}
                                />
                                {errors?.contact2Mail?.type === "pattern" && (
                                    <p className='text-danger'>This is not a valid email</p>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact2Address?.type === "required"}
                                    {...register("contact2Address")}
                                    label="Contact_2 Address"
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.contact2Phone?.type === "required"}
                                    {...register("contact2Phone")}
                                    label="Contact_2 Ph."
                                    id="standard-size-small"
                                    variant="standard"
                                    // defaultValue="Small"
                                    size="small"
                                    type='number'
                                    fullWidth
                                    sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    InputProps={{
                                        startAdornment: <material.InputAdornment sx={{ marginBottom: 2.6 }} position="start">+61</material.InputAdornment>,
                                        readOnly: organisatioDetails.readOnly
                                    }}
                                />
                                {(errors?.contact2Phone?.type === "minLength" || errors?.contact2Phone?.type === "maxLength") && (
                                    <p className='text-danger'>Cannot exceed 10 number</p>
                                )}
                            </div>
                            {userDetails.role.includes("ADMIN") && !organisatioDetails.clinicName ? (
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-2">
                                    <material.FormControl>
                                        <material.FormGroup>
                                            <material.FormControlLabel
                                                control={
                                                    <material.Checkbox {...label} checked={selectCheckbox} onChange={handleChange} color="secondary" />
                                                }
                                                label="Default Config"
                                            ></material.FormControlLabel>
                                        </material.FormGroup>
                                    </material.FormControl>
                                </div>
                            ) : null}
                            {/* <div className="col-lg-3 col-md-6 col-sm-12">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <material.DateTimePicker
                                    label="Date&Time"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue)
                                    }}
                                    renderInput={(params) => <material.TextField {...params}
                                        {...register("timeStamp", { required: true })}
                                        variant="standard"
                                        fullWidth
                                        sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    />}
                                />
                            </LocalizationProvider>
                        </div> */}
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                {organisatioDetails.callFrom === "add" || organisatioDetails.parentComponent === "clinic" ? (
                                    <span className='float-end'>
                                        <material.Button variant="contained" size="medium" sx={{ textTransform: "none" }} className=" mt-3 " onClick={handleSubmit(addOrganisation)}>
                                            Save
                                        </material.Button>
                                    </span>
                                ) : organisatioDetails.callFrom === "edit" ? (
                                    <span className='float-end'>
                                        <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className=" mt-3 " onClick={handleSubmit(updateOrganisationDetails)} disabled={!isValid}>
                                            Update
                                        </material.Button>
                                    </span>
                                ) : ""}

                            </div>
                        </div>
                    </form>
                </material.Paper>


                {/* <PlacesAutocomplete/> */}
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(Addorganisation)