import React, { useState } from 'react';
import { material } from '../../library/material';
import { sendBddForm } from '../../services/BddFormService';
import Snackbar from '../toastrmessage/Snackbar';
import { useEffect } from 'react';

function SendBddForm(props) {

    const { setOpenSendBddForm, openSendBddForm, patientData, stockUpdated } = props;

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (stockUpdated) {
            setOpenSendBddForm({ action: false, data: {} })
        }
    }, [stockUpdated])

    const sendBdd = async () => {
        let obj = {
            "email": openSendBddForm.data.bdd_form_id === null ? openSendBddForm.data.email : openSendBddForm.data.userEmail,
            "patientId": openSendBddForm.data.patientId,
            "clinicId": openSendBddForm.data.clinicId
        }
        await sendBddForm(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "BDD form send successfully",
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const close = () => {
        setOpenSendBddForm({ action: false, data: {} })
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" open={openSendBddForm.action} hideBackdrop >
                <material.DialogContent>
                    {!openSendBddForm.data.isValid && openSendBddForm.data.daysRemain > 0 ? (
                        <material.Typography>
                            Your BDD form expired on {openSendBddForm.data.daysRemain} days.
                            Send new BDD form ?
                        </material.Typography>
                    ) : !openSendBddForm.data.isValid && openSendBddForm.data.daysRemain < 0 ? (
                        <material.Typography>
                            Your BDD form is expired.
                            Send new BDD form ?
                        </material.Typography>
                    ) : openSendBddForm.data.bdd_form_id === null ? (
                        <material.Typography>
                            Your BDD form is not present.
                            Send BDD form ?
                        </material.Typography>
                    ) : null}
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} hidden={!patientData?.bdd_form_id} onClick={close} startIcon={<material.CloseIcon />}>Close</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={sendBdd} startIcon={<material.ForwardToInboxIcon />}>Send</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default SendBddForm;