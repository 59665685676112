import { combineReducers } from "redux";
import authReducer from "./AuthReducer";
import clinicReducer from "./ClinicDetailsReducer";
import patientReducer from "./PatientReducer";
import sidebarReducer from "./SideBarReducer";
import patientIdReducer from "./PatientIdReducer";


export default combineReducers({
    authReducer, clinicReducer, patientReducer, sidebarReducer,patientIdReducer
})