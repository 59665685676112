import React, { useState } from 'react';
import "../ConsentForm.css";
import SignaturePad from 'react-signature-canvas';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { material } from '../../../../library/material';
import { saveConsentFormOpen, saveConsentForm } from '../../../../services/ConsentFormService';
import Snackbar from '../../../toastrmessage/Snackbar';
import { socket } from '../../../../socket/Socket';

var sigPad = {};

function LocalAnaesthetic(props) {

    const theme = useTheme();
    const { blankTreatmentData, openConsentForm, setGetConsentFormLAData, consentData, callForm, clinicDetails } = props;
    const navigate = useNavigate();
    const [refNo, setRefNo] = useState('');
    const [saveConsentData, setSaveConsentData] = useState(false);
    const [questions, setquestions] = useState({
        aw_q1: "",
        aw_q2: "",
        aw_q3: "",
        aw_q4: "",
        aw_q5: "",
        aw_q6: "",
        aw_q7: "",
        aw_q8: "",
        aw_q9: "",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const getPatientSignature = () => {
        // getSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    };

    const selectQuestions = (qNumber, value) => {
        setquestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");

    const saveConsent = async (data) => {
        if (openConsentForm.action === false) {
            let obj = {
                patientId: blankTreatmentData.patientId,
                treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                concent_type: "Local Anaesthetic",
                concent_data: { ...questions, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentForm(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Consent Form Submit successfully",
                    })
                    if (callForm === "primary-user") {
                        setTimeout(() => {
                            setGetConsentFormLAData(resp.data)
                        }, 2000)
                    } else {
                        socket.emit("new-concent-added", "Local Anaesthetic Consent Form Submit successfully");
                        // navigate('/kiosk-menu')
                        setSaveConsentData(true);
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        } else {
            let obj = {
                refNo: refNo,
                concent_type: "Local Anaesthetic",
                concent_data: { ...questions, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentFormOpen(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };
    };

    const clear = () => {
        sigPad.clear()
    };

    return (
        <div className={callForm === "secondary-user" ? "consent-form-body" : 'content'} id='form-section' hidden={saveConsentData}>
            <material.Paper elevation={3} sx={{
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '70%',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '70%',
                },
                mt: 2, m: 2, p: 3, pb: 10
            }}>
                <div className='mt-2 fw-bold'>
                    <span className='d-flex justify-content-center'>Consent Form for Local Anaesthetic Treatments</span>
                </div>
                <div className='mt-5 ms-5'>
                    <div hidden={openConsentForm.action === false || consentData} className='mb-3'>
                        <material.TextField
                            type='text'
                            variant="standard"
                            sx={{ width: "30%", ml: 1, mr: 1, mt: -1 }}
                            name='refNo'
                            label="Reference No"
                            onChange={e => setRefNo(e.target.value)}
                        />
                    </div>
                    <h5>Procedure Information, Risks, and Contraindications:</h5>
                    <h5>1. Procedure Information:</h5>
                    <div className='ms-5'>
                        <span>
                            I acknowledge that I have been informed about using local anaesthetic during my procedure. The purpose of the local anesthetic is to numb the treatment area to minimise discomfort.
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q1}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                        value={questions.aw_q1}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <h5>2. Risk :</h5>
                    <div className='ms-5'>
                        <span>
                            I understand that there are potential risks and side effects associated with the use of local anesthetic, which include but are not limited to:
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q2}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                        value={questions.aw_q2}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <ul className='ms-3'>
                        <li>Temporary redness, swelling, or discomfort at the injection site.</li>
                        <li>Allergic reactions.</li>
                        <li>Infection.</li>
                        <li>Numbness or tingling that may persist after the procedure.</li>
                        <li>Bruising or minor bleeding at the injection site.</li>
                        <li>Dizziness or lightheadedness.</li>
                    </ul>
                    <h5>3. Contraindications :</h5>
                    <div className='ms-5'>
                        <span>
                            Please indicate YES or NO for each:
                        </span>
                        <ul className='ms-3'>
                            <li>Are you pregnant or breastfeeding?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q3}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q3", event.target.value)}
                                            value={questions.aw_q3}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have any known allergies to local anesthetics (e.g., lidocaine)?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q4}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q4", event.target.value)}
                                            value={questions.aw_q4}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Are you taking any medications that affect blood clotting (e.g., blood thinners)?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q5}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q5", event.target.value)}
                                            value={questions.aw_q5}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have a history of heart conditions or arrhythmias?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q6}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q6", event.target.value)}
                                            value={questions.aw_q6}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have a history of seizures?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q7}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q7", event.target.value)}
                                            value={questions.aw_q7}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have any infections at or near the treatment site?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q8}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q8", event.target.value)}
                                            value={questions.aw_q8}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have any history of hypertension?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q9}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q9", event.target.value)}
                                            value={questions.aw_q9}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                        </ul>
                    </div>
                    <h5>4. Pre-Procedure Care:</h5>
                    <div className='ms-5'>
                        <span>
                            I confirm that I have taken the following pre-procedure care:
                        </span>
                        <ul className='ms-3'>
                            <li>Inform the practitioner of all medications and supplements currently in use.</li>
                            <li>Avoid alcohol and smoking for at least 24 hours before the procedure.</li>
                            <li>Follow any specific instructions provided by the clinic.</li>
                        </ul>
                    </div>
                    <h5>5. Post-Procedure Care:</h5>
                    <div className='ms-5'>
                        <span>
                            I understand that I need to follow the provided aftercare advice:
                        </span>
                        <ul className='ms-3'>
                            <li>Keep the treatment area clean and dry.</li>
                            <li>Avoid strenuous activities for at least 24 hours after the procedure.</li>
                            <li>Monitor the area for signs of infection or unusual reactions and contact the clinic if any occur.</li>
                            <li>Follow any additional instructions provided by the practitioner.</li>
                        </ul>
                    </div>
                    <h5>6. Acknowledgement and Consent:</h5>
                    <div className='ms-5'>
                        <span>
                            By signing below, I acknowledge that I understand the risks, benefits, and instructions associated with using local anaesthetic. I have been allowed to ask questions, and all of my questions have been answered to my satisfaction. I hereby consent to the use of local anaesthetic during my procedure at <span style={{ fontWeight: "bold" }}>{clinicDetails.clinicName}</span>.
                        </span>
                    </div>
                    <div className='mt-5 d-flex'>
                        <span>Patient Signature</span>
                        {consentData ? (
                            <span className='ms-3'>
                                <img
                                    src={consentData.patientSignature}
                                />
                            </span>
                        ) : (
                            <>
                                <span className='sigBox ms-3'>
                                    <SignaturePad penColor='black'
                                        canvasProps={{ width: 400, height: 100, className: 'sigPad' }}
                                        ref={(ref) => { sigPad = ref }}
                                        onEnd={getPatientSignature}
                                    />
                                </span>
                                <span className='ms-2'>
                                    <material.Button size='small' sx={{ textTransform: "none" }} onClick={clear}>Clear</material.Button>
                                </span>
                            </>
                        )}
                    </div>
                    <span className='float-end mt-3 pe-3'>
                        <material.Button hidden={consentData} variant="contained" sx={{ textTransform: "none" }} onClick={saveConsent} disabled={isValid}>Save Consent Form</material.Button>
                    </span>
                </div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Paper>
        </div>
    );
}

export default LocalAnaesthetic;