import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { getAllCustomCampaignList, getTemplateList, startCampaign } from '../../../services/CampaignService';
import Snackbar from '../../toastrmessage/Snackbar';

function StartCampaignDialog(props) {

    const { openStartCampaignDialog, setOpenStartCampaignDialog, userData, clinicData, getCampaignName } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        mode: "onTouched",
    });
    const smsText = watch("smsText"); // This line watches for changes in the smsText input
    const [characterCount, setCharacterCount] = useState(0);

    // Update character count when smsText changes
    useEffect(() => {
        setCharacterCount(smsText?.length || 0);
    }, [smsText]);

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectFolder, setSelectFolder] = useState();
    const [selectTemplate, setSelectTemplate] = useState("");
    const [selectListType, setSelectListType] = useState("");
    const [selectFileName, setSelectFileName] = useState("");
    const [templateList, setTemplateList] = useState([]);
    const [customCampaignList, setCustomCampaignList] = useState([]);

    useEffect(() => {
        if (openStartCampaignDialog) {
            getTemplateListByClinicId();
            getCustomCampaignList();
        }
    }, [openStartCampaignDialog === true]);

    const getTemplateListByClinicId = () => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        getTemplateList(payload)
            .then((resp) => {
                setTemplateList(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            })
    };

    const getCustomCampaignList = () => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        getAllCustomCampaignList(payload)
            .then((resp) => {
                setCustomCampaignList(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            })
    };

    const chooseFile = (e) => {
        let fileName = e.target.files[0];
        setSelectFolder(fileName);
    };

    const cancel = () => {
        reset({ campaignName: "", emailSubject: "", smsText: "" });
        setSelectTemplate("");
        setSelectListType("");
        setSelectFileName("")
        setOpenStartCampaignDialog(false);
    };

    const handleChangeListType = (e) => {
        setSelectListType(e.target.value)
    };

    const handleChangeFileName = (e) => {
        setSelectFileName(e.target.value)
    };

    const save = async (formData) => {
        let data = new FormData();
        if (selectListType === "Excel") {
            data.append('excelFile', selectFolder);
            data.append('orgId', userDetails.orgId);
            data.append('clinicId', clinicDetails.clinicId);
            data.append('campaignName', formData.campaignName);
            data.append('emailSubject', formData.emailSubject);
            data.append('smsText', formData.smsText);
            data.append('emailTemplateId', selectTemplate);
        } else if (selectListType === "Patient List") {
            data.append('listName', formData.listName);
            data.append('orgId', userDetails.orgId);
            data.append('clinicId', clinicDetails.clinicId);
            data.append('campaignName', formData.campaignName);
            data.append('emailSubject', formData.emailSubject);
            data.append('smsText', formData.smsText);
            data.append('emailTemplateId', selectTemplate);
        };
        await startCampaign(data)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Successful",
                });
                reset({ campaignName: "", emailSubject: "", smsText: "" });
                setSelectTemplate("");
                getCampaignName();
                setSelectListType("");
                setSelectFileName("")
                setOpenStartCampaignDialog(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            });
    };

    const handleChangeTemplate = (event) => {
        setSelectTemplate(event.target.value);
    };

    return (
        <div>
            <material.Dialog open={openStartCampaignDialog} fullWidth maxWidth="sm" hideBackdrop>
                <material.DialogTitle>Start Campaign</material.DialogTitle>
                <material.DialogContent>
                    <material.TextField
                        required
                        margin="dense"
                        id="subject"
                        label="Email Subject"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("emailSubject", { required: true })}
                    />
                    <material.TextField
                        margin="dense"
                        id="smsText"
                        label="SMS Body"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={2}
                        {...register("smsText")}
                        helperText={`${characterCount} characters`} // Adjust based on your needs
                    />
                    <material.TextField
                        required
                        margin="dense"
                        id="number"
                        label="Campaign Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("campaignName", { required: true })}
                    />
                    <material.FormControl variant="standard" fullWidth margin="dense">
                        <material.InputLabel id="demo-simple-select-standard-label" required>List Type</material.InputLabel>
                        <material.Select
                            {...register("listType", { required: true })}
                            required
                            margin="dense"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectListType}
                            onChange={handleChangeListType}
                        >
                            <material.MenuItem value={"Patient List"}>Patient List</material.MenuItem>
                            <material.MenuItem value={"Excel"}>Excel</material.MenuItem>
                        </material.Select>
                    </material.FormControl>
                    {selectListType === "Excel" ? (
                        <>
                            <label for="excelFile">Choose Excel File</label><br />
                            <input
                                type="file"
                                accept=".xlsx"
                                margin="dense"
                                onChange={chooseFile}
                            />
                        </>
                    ) : selectListType === "Patient List" ? (
                        <material.FormControl variant="standard" fullWidth margin="dense">
                            <material.InputLabel id="demo-simple-select-standard-label" required>File Name</material.InputLabel>
                            <material.Select
                                {...register("listName", { required: true })}
                                required
                                margin="dense"
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectFileName}
                                onChange={handleChangeFileName}
                            >
                                {customCampaignList.map((ele, i) =>
                                    <material.MenuItem key={i} value={ele.listName}>{ele.listName}</material.MenuItem>
                                )};
                            </material.Select>
                        </material.FormControl>
                    ) : null}
                    <material.FormControl variant="standard" fullWidth margin="dense">
                        <material.InputLabel id="demo-simple-select-standard-label" required>Select Template</material.InputLabel>
                        <material.Select
                            {...register("emailTemplateId", { required: true })}
                            required
                            margin="dense"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectTemplate}
                            onChange={handleChangeTemplate}
                        >
                            {templateList.map((ele, i) =>
                                <material.MenuItem key={i} value={ele.emailTemplateId}>{ele.emailTemplateName}</material.MenuItem>
                            )};
                        </material.Select>
                    </material.FormControl>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='m-2'>
                        <material.Button sx={{ textTransform: "none", mr: 2 }} variant="outlined" color='error' onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        <material.Button sx={{ textTransform: "none" }} variant="outlined" onClick={handleSubmit(save)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(StartCampaignDialog);