import React from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllPAtients } from '../../../services/PatientService';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { getSkinTreatmentPriceListForBookingByClinicId, saveSkinTreatmentPlan } from '../../../services/SkinTreatmentService';
import Snackbar from '../../toastrmessage/Snackbar';
import ViewPatientProfile from '../../dialog/ViewPatientProfile';
import { getUsersList } from '../../../services/UserManagementService';
import Navbar from '../../navbar/Navbar';
import { getPatientCreditList } from '../../../services/PatientService';

const icon = <material.CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <material.CheckBoxIcon fontSize="small" />;

var patientId;
var patientDetails;
var generatedBy;
var patientData;
var areaName;

function SkinTreatmentBooking(props) {
    const { userData, clinicData } = props;
    const location = useLocation();
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [patientsData, setPatientsData] = useState([]);
    const [allTreatment, setAllTreatment] = useState([]);
    const [treatmentDetails, setTreatmentDetails] = useState([]);
    const [sessionData, setSessionData] = useState([]);
    const [userList, setSUserList] = useState([]);
    const [selectTreatmentName, setSelectTreatmentName] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [patientCreditList, setPatientCreditList] = useState(0);
    const [getCreditValue, setCreditValue] = useState(null);
    const [openPatientProfile, setOpenPatientProfile] = useState({ action: false, patientData: null, callFrom: null });
    const { register, handleSubmit, reset, getValues, watch, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [renderField, setRenderField] = useState(true);
    const navigate = useNavigate();
    const patientDataDetails = location.state ? location.state.patientData : "";
    localStorage.setItem("menuName", patientDataDetails.callFrom === "patientSkinTreatmentList" ? "Skin Treatment Session Setup" : "Skin Treatment Purchase")
    useEffect(() => {
        getAllPatientListByClinicId()
        getSkinTreatmentList()
        getAllUserList()
        setValue("patientCredit", 0);
        if (patientDataDetails.callFrom === "patientSkinTreatmentList") {
            setValue("patientName", (patientDataDetails.firstName + " " + patientDataDetails.lastName))
        }
    }, []);

    const onChangeSession = watch('numberOfSessions');
    const onChangeMultiplayer = watch('multiplayer');
    const onChangePrice = watch('price');

    useEffect(() => {
        calculationOfNumberOfSession();
    }, [onChangeSession, onChangeMultiplayer]);

    useEffect(() => {
        calculationOfTotalPrice()
    }, [onChangePrice, onChangeMultiplayer]);

    const calculationOfNumberOfSession = () => {
        let numberOfSessions = getValues('numberOfSessions');
        let multiplayer = getValues('multiplayer');
        if (numberOfSessions && multiplayer) {
            let totalNumberOfSessions = Number(numberOfSessions) * Number(multiplayer);
            setValue("total_session", totalNumberOfSessions);
        };
    };

    const calculationOfTotalPrice = () => {
        let price = getValues('price');
        let multiplayer = getValues('multiplayer');
        if (price && multiplayer) {
            let totalPrice = (Number(price) * Number(multiplayer)).toFixed(2);
            setValue("total_price", totalPrice);
        };
    };

    const onChangeEft = watch('eft');
    const onChangeGiftCard = watch('giftCard');
    const onChangeCash = watch('cash');
    const onChangeTotalAmount = watch('total_price');
    const onChangeDiscount = watch('discount');
    const onChangeRemainingAmount = watch("remainingAmount");
    const onChangeTotalCreditAmount = watch('patientCredit');

    useEffect(() => {
        totalCalculation();
    }, [onChangeEft, onChangeGiftCard, onChangeCash, onChangeTotalAmount, onChangeDiscount, onChangeRemainingAmount, onChangeTotalCreditAmount]);

    const totalCalculation = () => {
        let eftValue = getValues('eft');
        let giftCardValue = getValues('giftCard');
        let cashValue = getValues('cash');
        let totalAmount = getValues('total_price');
        let discountValue = getValues('discount');
        let remainingAmountValue = getValues('remainingAmount');
        let totalCreditAmount = getValues('patientCredit');
        if (eftValue && giftCardValue && cashValue && discountValue) {
            let totalValue = (Number(totalAmount) - (Number(eftValue) + Number(giftCardValue) + Number(cashValue) + Number(totalCreditAmount))).toFixed(2);
            setValue("remainingAmount", (Number(totalValue) - Number(discountValue)).toFixed(2));
            setButtonDisabled(false);
            setCreditValue(totalCreditAmount);
        };
        // if (remainingAmountValue == 0) {
        //     setButtonDisabled(false)
        // } else {
        //     setButtonDisabled(true)
        // }
    };

    const getAllUserList = () => {
        getUsersList()
            .then((resp) => {
                setSUserList(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getAllPatientListByClinicId = async () => {
        await getAllPAtients(clinicDetails.clinicId)
            .then((res) => {
                patientDetails = res.data
                setPatientsData(res.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const getSkinTreatmentList = async () => {
        await getSkinTreatmentPriceListForBookingByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                setAllTreatment(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const selectPatient = (e, value) => {
        setSelectedPatient(false)
        patientId = value.patientId
        patientData = value;
        // if (patientData.bdd_form_id !== null) {
        //     setBddPresent(true)
        // } else {
        //     setBddPresent(false)
        //     setOpenSnackBar({
        //         "action": true,
        //         "type": "error",
        //         "message": " Your BDD form is not present.",
        //     })
        // }
        getPatientCreditListData(patientId)
    };

    const getPatientCreditListData = (patientId) => {
        let payload = {
            patientId: patientId,
            // onlyNotUsed: false
        }
        getPatientCreditList(payload)
            .then((resp) => {
                if (resp.data.length) {
                    if (resp.data[0].balance < 0) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "Customer has an outstanding balance. Please check customer credit.",
                        })
                        setPatientCreditList(0);
                    } else {
                        setPatientCreditList(resp.data[0].balance);
                    }
                } else {
                    setPatientCreditList(0);
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectTreatment = (e, value) => {
        // reset({ treatmentArea: "" });
        setValue("treatmentArea", '')
        setTreatmentDetails([]);
        setSessionData([]);
        setSelectTreatmentName(value.treatmentName);
        setTreatmentDetails(value?.treatmentDetails ? value.treatmentDetails : []);
    };
    const selectUser = (e, value) => {
        generatedBy = value.firstName + value.lastName
    };
    const selectTreatmentArea = (e, value) => {
        if (value?.length < 2) {
            setRenderField(true)
        } else {
            setRenderField(false);
            setValue("numberOfSessions", 1);
        }
        let totalPrice = value?.map((ele) => ele.sessionAndPrice.filter((res) => res.numberOfSessions === 1)[0]?.price).reduce((a, b) => a + b, 0);
        areaName = value?.map((ele) => ele.treatmentArea)?.join(",");
        // reset({ price: (totalPrice).toFixed(2) });
        setValue("price", (totalPrice).toFixed(2))
        setSessionData(value?.length ? value[0].sessionAndPrice : []);
        console.log(value)
    };
    const selectSession = (e, value) => {
        // reset({ price: (value.price).toFixed(2) })
        setValue("price", (value.price).toFixed(2))
    };
    const addPatient = () => {
        navigate("/patient-list/add-patient")
    };
    const submit = async (data) => {
        let obj = {
            generatedBy: generatedBy ? generatedBy : "",
            "treatmentName": data.treatmentName,
            "treatmentArea": areaName,
            "productOrMachineUsed": data.productOrMachineUsed,
            "numberOfSessions": Number(data.numberOfSessions),
            "patientId": patientDataDetails.callFrom === "patientSkinTreatmentList" ? patientDataDetails.patientId : patientId,
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            "discount": data.discount,
            "injectorId": userDetails.userId,
            multiplayer: Number(data.multiplayer),
            cash: Number(data.cash),
            giftCard: Number(data.giftCard),
            eft: Number(data.eft),
            total_session: data.total_session,
            total_price: data.total_price,
            remainingSessions: data.total_session,
            patientCredit: data.patientCredit,
            "patientSignature": "",
            "concent_data": {
                "data": ""
            },
        };
        await saveSkinTreatmentPlan(obj)
            .then((resp) => {
                reset({ treatmentName: "", treatmentArea: "", productOrMachineUsed: "", numberOfSessions: "", patientId: "", discount: "", price: "", total_price: "", total_session: "" })
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Treatment booking successfully",
                })
                if (patientDataDetails.callFrom === "patientSkinTreatmentList") {
                    navigate("/patient-list/view-patient-details/patient_skin_treatment_list/skin-treatment-booking/treatment_details", { state: { patientData: resp.data } })
                } else {
                    navigate("/invoice_management/skin_treatment_invoice", { state: { patientId } })
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const goBack = () => {
        navigate(-1)
    };

    const viewPatientProfile = () => {
        setOpenPatientProfile({ action: true, patientData: patientData, callFrom: "skin_treatment_booking" })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                        {/* <span><material.Typography variant="h5">{patientDataDetails.callFrom === "patientSkinTreatmentList" ? "Skin Treatment Session Setup" : "Skin Treatment Purchase"}</material.Typography></span> */}
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            {patientDataDetails.callFrom === "patientSkinTreatmentList" ? (
                                <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                            ) : (
                                <>
                                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} disabled={selectedPatient} onClick={viewPatientProfile} startIcon={<material.VisibilityIcon />}>View Patient Profile</material.Button>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addPatient} startIcon={<material.AddIcon />}>Add-Patient</material.Button>
                                </>
                            )}
                        </span>
                    </div>
                </div>
                <material.Paper className='p-4 pb-5 mt-3' elevation={3}>
                    <div className="row">
                        <div className="col-4">
                            {patientDataDetails.callFrom === "patientSkinTreatmentList" ? (
                                <material.TextField
                                    required
                                    margin="dense"
                                    label="Patient Name"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    {...register("patientName", { required: true })}
                                />
                            ) : (
                                <material.Autocomplete
                                    error={errors.patientId?.type === "required"}
                                    id="name"
                                    margin="dense"
                                    onChange={selectPatient}
                                    options={patientsData}
                                    getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                    renderInput={(params) => <material.TextField {...params} required variant="standard" label="Patient Name"
                                        {...register("patientId", { required: true })}
                                    />}
                                />
                            )}
                            <p className='form-text text-danger'>{errors.patientId?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-4">
                            <material.Autocomplete
                                error={errors.treatmentName?.type === "required"}
                                id="treatmentName"
                                margin="dense"
                                onChange={selectTreatment}
                                options={allTreatment}
                                getOptionLabel={(option) => option.treatmentName}
                                renderInput={(params) => <material.TextField {...params} required variant="standard" label="Treatment Name"
                                    {...register("treatmentName", { required: true })}
                                />}
                            />
                            <p className='form-text text-danger'>{errors.treatmentName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-4">
                            <material.Autocomplete
                                error={errors.treatmentArea?.type === "required"}
                                id="treatment"
                                margin="dense"
                                onChange={selectTreatmentArea}
                                options={treatmentDetails}
                                disableCloseOnSelect
                                multiple
                                getOptionLabel={(option) => option.treatmentArea}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <material.Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.treatmentArea}
                                    </li>
                                )}
                                renderInput={(params) => <material.TextField {...params} required variant="standard" label="Treatment Area"
                                    {...register("treatmentArea")}
                                />}
                            />
                            {/* <p className='form-text text-danger'>{errors.treatmentArea?.type === "required" && 'This field is required'}</p> */}
                        </div>
                        {patientDataDetails.callFrom === "patientSkinTreatmentList" ? (
                            <div className="col-3">
                                <material.TextField
                                    required
                                    margin="dense"
                                    label="Number of Session"
                                    variant="standard"
                                    type="number"
                                    defaultValue={1}
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    {...register("numberOfSessions", { required: true })}
                                />
                            </div>
                        ) : (
                            <div className="col-3">
                                {renderField ? (
                                    <material.Autocomplete
                                        error={errors.numberOfSessions?.type === "required"}
                                        id="numberOfSessions"
                                        margin="dense"
                                        onChange={selectSession}
                                        options={sessionData}
                                        getOptionLabel={(option) => option.numberOfSessions.toString()}
                                        renderInput={(params) => <material.TextField {...params} required variant="standard" label="Number of Session"
                                            {...register("numberOfSessions", { required: true })}
                                        />}
                                    />
                                ) : (
                                    <material.TextField
                                        required
                                        margin="dense"
                                        label="Number of Session"
                                        variant="standard"
                                        type="number"
                                        defaultValue={1}
                                        size="small"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        {...register("numberOfSessions", { required: true })}
                                    />
                                )}
                                <p className='form-text text-danger'>{errors.numberOfSessions?.type === "required" && 'This field is required'}</p>
                            </div>
                        )}
                        <div className="col-3">
                            <material.TextField
                                required
                                margin="dense"
                                label="Number of Packages"
                                variant="standard"
                                type="number"
                                defaultValue={1}
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: patientDataDetails.callFrom === "patientSkinTreatmentList" ? true : false }}
                                {...register("multiplayer", { required: true })}
                            />
                            <p className='form-text text-danger'>{errors.multiplayer?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-3">
                            <material.TextField
                                required
                                margin="dense"
                                label="Total Session"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("total_session", { required: true })}
                            />
                            <p className='form-text text-danger'>{errors.total_session?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-3">
                            <material.TextField
                                required
                                margin="dense"
                                label="Per Unit Price"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("price", { required: true })}
                            />
                            <p className='form-text text-danger'>{errors.price?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-3">
                            <material.TextField
                                required
                                margin="dense"
                                label="Total Price"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("total_price", { required: true })}
                            />
                            <p className='form-text text-danger'>{errors.total_price?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-3">
                            <material.TextField
                                error={errors.productOrMachineUsed?.type === "required"}
                                margin="dense"
                                label="Product/Machine"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("productOrMachineUsed")}
                            />
                            <p className='form-text text-danger'>{errors.productOrMachineUsed?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-3">
                            <material.TextField
                                error={errors.discount?.type === "required"}
                                required
                                margin="dense"
                                label="Discount"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                defaultValue={0}
                                {...register("discount", { required: true })}
                            />
                            <p className='form-text text-danger'>{errors.discount?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-3">
                            <material.Autocomplete
                                id="generatedBy"
                                margin="dense"
                                onChange={selectUser}
                                options={userList}
                                getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                renderInput={(params) => <material.TextField {...params} required variant="standard" label="Sales Account"
                                    {...register("generatedBy", { required: true })}
                                />}
                            />
                        </div>
                        {patientDataDetails.callFrom === "patientSkinTreatmentList" ? null : (
                            <div className='row'>
                                <div className='col-3'>
                                    <material.TextField
                                        {...register("giftCard", { required: true })}
                                        margin="dense"
                                        required
                                        fullWidth
                                        type='number'
                                        label="Gift Card"
                                        variant="standard"
                                        defaultValue={0}
                                    />
                                </div>
                                <div className='col-3'>
                                    <material.TextField
                                        {...register("eft", { required: true })}
                                        margin="dense"
                                        required
                                        fullWidth
                                        type='number'
                                        label="EFT"
                                        variant="standard"
                                        defaultValue={0}
                                    />
                                </div>
                                <div className='col-3'>
                                    <material.TextField
                                        {...register("cash", { required: true })}
                                        margin="dense"
                                        required
                                        fullWidth
                                        type='number'
                                        label="Cash"
                                        variant="standard"
                                        defaultValue={0}
                                    />
                                </div>
                                <div className='col-3'>
                                    <material.TextField
                                        {...register("remainingAmount", { required: true })}
                                        margin="dense"
                                        fullWidth
                                        type='number'
                                        label="Remaining Amount"
                                        InputProps={{ readOnly: true }}
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                    />
                                </div>
                                <div className='col-3'>
                                    <material.TextField
                                        {...register("patientCredit", { required: true })}
                                        margin="dense"
                                        fullWidth
                                        type='number'
                                        label="Credit"
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        defaultValue={0}
                                        disabled={patientCreditList === 0}
                                    />
                                    {(getCreditValue > patientCreditList) && (
                                        <h6 className="text-danger">Maximum credit available {patientCreditList}</h6>
                                    )}
                                    <h6>Available Balance : {patientCreditList}</h6>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='mt-3'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(submit)}>Submit</material.Button>
                        </span>
                    </div>
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ViewPatientProfile
                openPatientProfile={openPatientProfile}
                setOpenPatientProfile={setOpenPatientProfile}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(SkinTreatmentBooking);