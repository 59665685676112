import axios from "axios";
import { rootApiUrl } from "../../environment/Environment";

export const getDataForRollingOneMonthReportByClinicId = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-data-for-rolling-31-days-report`, { params: { ...payload } });
};

export const getSalesPercentageByTreatment = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-sales-percentage-by-treatment`, { params: { ...payload } });
};

export const getMonthSalesByCategoryAndTreatment = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-month-sales-by-category-and-treatment`, { params: { ...payload } });
};

export const getPatientCountsByPeriodStatus = async (payload) => {
    return await axios.get(`${rootApiUrl}/fetch-patient-counts-by-period-status`, { params: { ...payload } });
};

export const getAverageSalesByCategoryPerPatient = async (payload) => {
    return await axios.get(`${rootApiUrl}/fetch-average-sales-by-category-per-patient-for-two-fys`, { params: { ...payload } });
};