import React, { useEffect, useState } from "react";
import { material } from "../../library/material";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  getUserRosterDetails,
  saveUserRoast,
} from "../../services/InjectorService";
import { getClinicRotationDetailsByOrgIdAndClinicId } from "../../services/ClinicService";
import { connect } from "react-redux";
import Snackbar from "../toastrmessage/Snackbar";
import { changeTimeFormateInDay } from "../../date-and-time-format/DateAndTimeFormat";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const AddUserRotation = (props) => {
  const { setOpenRotationDialog, openRotationDialog, userData, clinicData } =
    props;
  const clinicDetails = clinicData.clinicReducer.data;
  const userDetails = userData.authReducer.data;
  const [clinicStatus, setClinicStatus] = useState(
    days.reduce((acc, day) => ({ ...acc, [day]: false }), {})
  );
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    if (openRotationDialog.action && openRotationDialog.userId) {
      getUserRosterDetailsByOrgIdAndClinicId();
      setClinicStatus(days.reduce((acc, day) => ({ ...acc, [day]: false }), {}))
    }
  }, [openRotationDialog.action === true]);

  const getUserRosterDetailsByOrgIdAndClinicId = () => {
    let params = {
      clinicId: clinicDetails.clinicId,
      orgId: userDetails.orgId,
      userId: openRotationDialog.userId,
    };
    let object = {
      orgId: userDetails.orgId,
      clinicId: clinicDetails.clinicId,
    };
    getUserRosterDetails(params)
      .then((res) => {
        showFormFieldsData(res.data);
        if (res.data.length <= 0) {
          getClinicRotationDetailsByOrgIdAndClinicId(object)
            .then((res) => {
              showFormFieldsData(res.data);
            })
            .catch((error) => {
              setOpenSnackBar({
                action: true,
                type: "error",
                message: error.response.data,
              });
            });
        }
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
  };

  const showFormFieldsData = (data) => {
    data.forEach((item) => {
      const day = days[item.dayOfWeek];
      setValue(`clinicStatus.${day}`, item.open === 1);
      setValue(`openingTime.${day}`, dayjs(item.openingTime, "HH:mm:ss"));
      setValue(`closingTime.${day}`, dayjs(item.closingTime, "HH:mm:ss"));
      setClinicStatus((prev) => ({ ...prev, [day]: item.open === 1 }));
    });
  };

  const handleSwitchChange = (day) => (event) => {
    setClinicStatus((prevStatus) => ({
      ...prevStatus,
      [day]: event.target.checked,
    }));
  };

  const onSubmit = (data) => {
    let requestBody = [
      {
        dayOfWeek: "0",
        open: data.clinicStatus.Sunday,
        openingTime: data.openingTime.Sunday ? changeTimeFormateInDay(data.openingTime.Sunday.$d) : null,
        closingTime: data.closingTime.Sunday ? changeTimeFormateInDay(data.closingTime.Sunday.$d) : null,
        orgId: userDetails.orgId,
        clinicId: clinicDetails.clinicId,
        userId: openRotationDialog.userId,
      },
      {
        dayOfWeek: "1",
        open: data.clinicStatus.Monday,
        openingTime: data.openingTime.Monday ? changeTimeFormateInDay(data.openingTime.Monday.$d) : null,
        closingTime: data.closingTime.Monday ? changeTimeFormateInDay(data.closingTime.Monday.$d) : null,
        orgId: userDetails.orgId,
        clinicId: clinicDetails.clinicId,
        userId: openRotationDialog.userId,
      },
      {
        dayOfWeek: "2",
        open: data.clinicStatus.Tuesday,
        openingTime: data.openingTime.Tuesday ? changeTimeFormateInDay(data.openingTime.Tuesday.$d) : null,
        closingTime: data.closingTime.Tuesday ? changeTimeFormateInDay(data.closingTime.Tuesday.$d) : null,
        orgId: userDetails.orgId,
        clinicId: clinicDetails.clinicId,
        userId: openRotationDialog.userId,
      },
      {
        dayOfWeek: "3",
        open: data.clinicStatus.Wednesday,
        openingTime: data.openingTime.Wednesday ? changeTimeFormateInDay(data.openingTime.Wednesday.$d) : null,
        closingTime: data.closingTime.Wednesday ? changeTimeFormateInDay(data.closingTime.Wednesday.$d) : null,
        orgId: userDetails.orgId,
        clinicId: clinicDetails.clinicId,
        userId: openRotationDialog.userId,
      },
      {
        dayOfWeek: "4",
        open: data.clinicStatus.Thursday,
        openingTime: data.openingTime.Thursday ? changeTimeFormateInDay(data.openingTime.Thursday.$d) : null,
        closingTime: data.closingTime.Thursday ? changeTimeFormateInDay(data.closingTime.Thursday.$d) : null,
        orgId: userDetails.orgId,
        clinicId: clinicDetails.clinicId,
        userId: openRotationDialog.userId,
      },
      {
        dayOfWeek: "5",
        open: data.clinicStatus.Friday,
        openingTime: data.openingTime.Friday ? changeTimeFormateInDay(data.openingTime.Friday.$d) : null,
        closingTime: data.closingTime.Friday ? changeTimeFormateInDay(data.closingTime.Friday.$d) : null,
        orgId: userDetails.orgId,
        clinicId: clinicDetails.clinicId,
        userId: openRotationDialog.userId,
      },
      {
        dayOfWeek: "6",
        open: data.clinicStatus.Saturday,
        openingTime: data.openingTime.Saturday ? changeTimeFormateInDay(data.openingTime.Saturday.$d) : null,
        closingTime: data.closingTime.Saturday ? changeTimeFormateInDay(data.closingTime.Saturday.$d) : null,
        orgId: userDetails.orgId,
        clinicId: clinicDetails.clinicId,
        userId: openRotationDialog.userId,
      },
    ];
    saveUserRoast(requestBody)
      .then((res) => {
        setOpenSnackBar({
          action: true,
          type: "success",
          message: res.data.message,
        });
        setOpenRotationDialog({ action: false, rowData: null });
        return new Promise((resolve) => {
          setTimeout(() => {
            getUserRosterDetailsByOrgIdAndClinicId();
            resolve(true);
          }, 1000);
        });
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
  };

  return (
    <div>
      <material.Dialog
        open={openRotationDialog.action}
        maxWidth="lg"
        fullWidth
        hideBackdrop
      >
        <material.DialogTitle className="fw-bold">Add User Hours</material.DialogTitle>
        <material.DialogContent>
          <div
            style={{
              margin: "2%",
              // alignContent: "center",
              // justifyContent: "center",
            }}
          >
            <form>
              {days.map((day, i) => (
                <material.Box
                  key={i}
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "16px",
                    marginBottom: "8px",
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  }}
                >
                  <div style={{ width: "25%" }}>
                    <material.Typography variant="h6">{day}</material.Typography>
                  </div>
                  <div style={{ width: "25%" }}>
                    <material.Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <span>Close</span>
                      <Controller
                        name={`clinicStatus.${day}`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <material.Switch
                            {...field}
                            color="secondary"
                            checked={clinicStatus[day]}
                            onChange={(event) => {
                              field.onChange(event.target.checked);
                              handleSwitchChange(day)(event);
                            }}
                          />
                        )}
                      />
                      <span>Open</span>
                    </material.Stack>
                  </div>
                  <div style={{ width: "25%" }}>
                    <Controller
                      name={`openingTime.${day}`}
                      control={control}
                      defaultValue={null}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Opening Time"
                            {...field}
                            onChange={(newValue) => field.onChange(newValue)}
                            disabled={!clinicStatus[day]}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </div>
                  <div style={{ width: "25%" }}>
                    <Controller
                      name={`closingTime.${day}`}
                      control={control}
                      defaultValue={null}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Closing Time"
                            {...field}
                            onChange={(newValue) => field.onChange(newValue)}
                            disabled={!clinicStatus[day]}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </div>
                </material.Box>
              ))}
            </form>
          </div>
        </material.DialogContent>
        <material.DialogActions sx={{ m: 2 }}>
          <span>
            <material.Button
              variant="contained"
              sx={{ textTransform: "none", mr: 1 }}
              color="error"
              onClick={() => setOpenRotationDialog({ action: false, rowData: null })}
            >
              Cancel
            </material.Button>
          </span>
          <span>
            <material.Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              sx={{ textTransform: "none" }}
              disabled={!isValid}
            >
              Save
            </material.Button>
          </span>
        </material.DialogActions>
      </material.Dialog>
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state,
  };
};

export default connect(mapStateToProps)(AddUserRotation);
