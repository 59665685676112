import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import "./Appointment.css";
import moment from 'moment';
import { changeScheduleStatus, deleteAppointmentById } from '../../../services/AppointmentService';
import Snackbar from '../../toastrmessage/Snackbar';
import CreateAppointment from './CreateAppointment';
import { useNavigate } from 'react-router-dom';

function ViewAppointment(props) {

    const { openViewAppointment, setOpenViewAppointment, selectedEvent, getAllScheduleData } = props;
    const navigate = useNavigate();
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [showModal, setShowModal] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState({ action: false, data: null });

    useEffect(() => {
        if (!openWarningDialog.action && openWarningDialog.data !== null) {
            changeBookingStatus(openWarningDialog.data)
        }
    }, [openWarningDialog.action]);

    const cancelAppointment = (scheduleData) => {
        setOpenWarningDialog({ action: true, data: scheduleData })
    };

    const changeBookingStatus = (scheduleData) => {
        const payload = {
            Id: scheduleData.resource.Id,
            InjectorId: scheduleData.resource.injectorId,
            ClinicId: scheduleData.resource.clinicId,
            title: scheduleData.resource.title,
            PatientName: scheduleData.resource.PatientName,
            Email: scheduleData.resource.Email,
            PhoneNumber: scheduleData.resource.PhoneNumber,
            start: scheduleData.resource.start,
            end: scheduleData.resource.end,
            MachineName: scheduleData.resource.MachineName,
            MachineNumber: scheduleData.resource.MachineNumber,
            MachineId: scheduleData.resource.machineId,
            Purpose: scheduleData.resource.Purpose,
            RoomName: scheduleData.resource.RoomName,
            RoomNumber: scheduleData.resource.RoomNumber,
            RoomId: scheduleData.resource.roomId,
            startTime: scheduleData.resource.startTime,
            duration: scheduleData.resource.duration,
            patientId: scheduleData.resource.patientId,
            rescheduledCounter: scheduleData.resource.rescheduledCounter,
            bookingStatus: scheduleData.bookingStatus,
        }
        changeScheduleStatus(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data,
                });
                getAllScheduleData();
                setOpenViewAppointment(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            });
    };

    const deleteAppointment = async (appointmentData) => {
        await deleteAppointmentById(appointmentData.id)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data,
                });
                getAllScheduleData();
                setOpenViewAppointment(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data,
                });
            });
    };

    const handleClose = () => {
        setOpenViewAppointment(false);
    };

    const editAppointment = (appointmentData) => {
        setShowModal(true);
    };

    const viewPatientData = (patientData) => {
        navigate("/appointment-schedule/view-patient-details", {
            state: { patientData },
        });
    };

    return (
        <div>
            <material.Dialog open={openViewAppointment} hideBackdrop fullWidth maxWidth="sm">
                <material.DialogContent>
                    <div className={`row ${selectedEvent?.className}`} style={{ height: "100px" }}>
                        <div className="col-6">
                            <material.IconButton title='Close' onClick={handleClose}>
                                <material.CloseIcon />
                            </material.IconButton>
                        </div>
                        <div className="col-6">
                            <span className='float-end'>
                                {selectedEvent?.resource?.patientId === null ? null : (
                                    <material.IconButton title='Edit Appointment' onClick={() => editAppointment(selectedEvent)} disabled={selectedEvent?.resource?.bookingStatus === "CANCELLED"}>
                                        <material.CreateIcon sx={{ color: selectedEvent?.resource?.bookingStatus === "CANCELLED" ? "gray" : "white" }} />
                                    </material.IconButton>
                                )}
                                <material.IconButton title='Delete Appointment' onClick={() => deleteAppointment(selectedEvent)} disabled={selectedEvent?.resource?.bookingStatus === "CANCELLED"}>
                                    <material.DeleteIcon sx={{ color: selectedEvent?.resource?.bookingStatus === "CANCELLED" ? "gray" : "white" }} />
                                </material.IconButton>
                            </span>
                        </div>
                        <span>
                            <material.Typography sx={{ color: "white", fontSize: 18, ml: 2 }}>{selectedEvent?.title}</material.Typography>
                        </span>
                    </div>
                    <div className="row mt-3">
                        <div>
                            <material.Typography
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 15,
                                }}
                                variant="caption"
                                noWrap
                            >
                                <material.AccessTimeIcon sx={{ mr: 1 }} />{moment(selectedEvent?.start).format("DD-MM-YYYY hh:mm A")} - {moment(selectedEvent?.end).format("DD-MM-YYYY hh:mm A")}
                            </material.Typography>
                        </div>
                        <div className="d-flex flex-row">
                            <material.Typography
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 15,
                                }}
                                variant="caption"
                                noWrap
                            >
                                <material.PersonIcon sx={{ mr: 1 }} />{selectedEvent?.resource?.PatientName}
                            </material.Typography>
                            {selectedEvent?.resource?.patientId !== null ? (
                                <span>
                                    <material.Button variant="contained" size="small" sx={{ textTransform: "none", ml: 1 }} onClick={() => viewPatientData(selectedEvent.resource)} startIcon={<material.PersonIcon />}>Open Profile</material.Button>
                                </span>
                            ) : null}
                        </div>
                        <div>
                            <material.Typography
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 15,
                                }}
                                variant="caption"
                                noWrap
                            >
                                <material.CallIcon sx={{ mr: 1 }} />{selectedEvent?.resource?.PhoneNumber}
                            </material.Typography>
                        </div>
                        <div>
                            <material.Typography
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 15,
                                }}
                                variant="caption"
                                noWrap
                            >
                                <material.EmailIcon sx={{ mr: 1 }} />{selectedEvent?.resource?.Email}
                            </material.Typography>
                        </div>
                        <div>
                            <material.Typography
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 15,
                                }}
                                variant="caption"
                                noWrap
                            >
                                <material.InventoryIcon sx={{ mr: 1 }} />{selectedEvent?.resource?.appointmentType}
                            </material.Typography>
                        </div>
                        <div>
                            <material.Typography
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 15,
                                }}
                                variant="caption"
                                noWrap
                            >
                                <material.InfoIcon sx={{ mr: 1 }} />{selectedEvent?.resource?.concerns}
                            </material.Typography>
                        </div>
                        {selectedEvent?.resource?.patientId !== null ? (
                            <div>
                                <material.Button
                                    variant={
                                        selectedEvent?.resource?.bookingStatus === "NOT CONFIRMED"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color={
                                        selectedEvent?.resource?.bookingStatus === "NOT CONFIRMED"
                                            ? "success"
                                            : "info"
                                    }
                                    sx={{ textTransform: "none", m: 1 }}
                                    size="small"
                                    onClick={() =>
                                        changeBookingStatus({
                                            ...selectedEvent,
                                            bookingStatus: "NOT CONFIRMED",
                                        })
                                    }
                                    disabled={selectedEvent?.resource?.bookingStatus === "CANCELLED"}
                                >
                                    Not Confirmed
                                </material.Button>
                                <material.Button
                                    variant={
                                        selectedEvent?.resource?.bookingStatus === "CONFIRMED"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color={
                                        selectedEvent?.resource?.bookingStatus === "CONFIRMED"
                                            ? "success"
                                            : "info"
                                    }
                                    sx={{ textTransform: "none", m: 1 }}
                                    size="small"
                                    onClick={() =>
                                        changeBookingStatus({
                                            ...selectedEvent,
                                            bookingStatus: "CONFIRMED",
                                        })
                                    }
                                    disabled={selectedEvent?.resource?.bookingStatus === "CANCELLED"}
                                >
                                    Confirmed
                                </material.Button>
                                <material.Button
                                    variant={
                                        selectedEvent?.resource?.bookingStatus === "ARRIVED"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color={
                                        selectedEvent?.resource?.bookingStatus === "ARRIVED"
                                            ? "success"
                                            : "info"
                                    }
                                    sx={{ textTransform: "none", m: 1 }}
                                    size="small"
                                    onClick={() =>
                                        changeBookingStatus({
                                            ...selectedEvent,
                                            bookingStatus: "ARRIVED",
                                        })
                                    }
                                    disabled={selectedEvent?.resource?.bookingStatus === "CANCELLED"}
                                >
                                    Arrived
                                </material.Button>
                                <material.Button
                                    variant={
                                        selectedEvent?.resource?.bookingStatus === "CHECKOUT"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color={
                                        selectedEvent?.resource?.bookingStatus === "CHECKOUT"
                                            ? "success"
                                            : "info"
                                    }
                                    sx={{ textTransform: "none", m: 1 }}
                                    size="small"
                                    onClick={() =>
                                        changeBookingStatus({
                                            ...selectedEvent,
                                            bookingStatus: "CHECKOUT",
                                        })
                                    }
                                    disabled={selectedEvent?.resource?.bookingStatus === "CANCELLED"}
                                >
                                    Checkout
                                </material.Button>
                                <material.Button
                                    variant={
                                        selectedEvent?.resource?.bookingStatus === "NO SHOW"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color={
                                        selectedEvent?.resource?.bookingStatus === "NO SHOW"
                                            ? "success"
                                            : "info"
                                    }
                                    sx={{ textTransform: "none", m: 1 }}
                                    size="small"
                                    onClick={() =>
                                        changeBookingStatus({
                                            ...selectedEvent,
                                            bookingStatus: "NO SHOW",
                                        })
                                    }
                                    disabled={selectedEvent?.resource?.bookingStatus === "CANCELLED"}
                                >
                                    No Show
                                </material.Button>
                                <material.Button
                                    variant={
                                        selectedEvent?.resource?.bookingStatus === "CANCELLED"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    color={
                                        selectedEvent?.resource?.bookingStatus === "CANCELLED"
                                            ? "success"
                                            : "info"
                                    }
                                    sx={{ textTransform: "none", m: 1 }}
                                    size="small"
                                    onClick={() =>
                                        cancelAppointment({
                                            ...selectedEvent,
                                            bookingStatus: "CANCELLED",
                                        })
                                    }
                                    disabled={selectedEvent?.resource?.bookingStatus === "CANCELLED"}
                                >
                                    Cancel
                                </material.Button>
                            </div>
                        ) : null}
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <CreateAppointment
                showModal={showModal}
                setShowModal={setShowModal}
                selectedEvent={selectedEvent}
                getAllScheduleData={getAllScheduleData}
                setOpenViewAppointment={setOpenViewAppointment}
            />
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
            <AppointmentCancelWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
            />
        </div>
    );
}

const AppointmentCancelWarning = (props) => {
    const { openWarningDialog, setOpenWarningDialog } = props;

    const permission = (data) => {
        if (data === "Yes") {
            setOpenWarningDialog({ action: false, data: openWarningDialog.data })
        } else {
            setOpenWarningDialog({ action: false, data: null })
        }
    };

    return (
        <div>
            <material.Dialog open={openWarningDialog.action} fullWidth maxWidth="sm">
                <material.DialogContent>
                    <div className='d-flex'>
                        <span style={{ marginTop: - 10 }}>
                            <material.Typography>You want to cancel this appointment ?</material.Typography>
                        </span>
                        <span className='ms-3'>
                            <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} color='error' size="small" startIcon={<material.CloseIcon />} onClick={() => permission("No")}>No</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none" }} size="small" startIcon={<material.DoneIcon />} onClick={() => permission("Yes")}>Yes</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    )
}

export default ViewAppointment;