import React, { useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { getOtpForResetPassword, resetPassword, verifyOtpForResetPassword } from '../../services/LoginService';
import Snackbar from '../toastrmessage/Snackbar';

function ChangePassword(props) {
    const { openChangePasswordDialog, setOpenChangePasswordDialog } = props
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [getEmail, setGetEmail] = useState(false);
    const [getVerifyOtp, setGetVerifyOtp] = useState(false);
    const [getPassword, setGetPassword] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [type, setType] = useState("password");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    function showPassword() {
        setIsVisible(!isVisible)
        setType("text")
    };

    function hidePassword() {
        setIsVisible(!isVisible)
        setType("password")
    };

    const getOtp = async (data) => {
        await getOtpForResetPassword(data)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "info",
                    "message": resp.data,
                })
                setGetEmail(true)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const verifyOtp = async (data) => {
        await verifyOtpForResetPassword(data)
            .then((resp) => {
                setGetVerifyOtp(true)
                setOpenSnackBar({
                    "action": true,
                    "type": "info",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const submitPassword = async (data) => {
        await resetPassword(data)
            .then((resp) => {
                setOpenChangePasswordDialog(false)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const close = () => {
        setOpenChangePasswordDialog(false)
    }

    return (
        <div>
            <material.Dialog open={openChangePasswordDialog} maxWidth="sm" fullWidth hideBackdrop>
                <material.DialogTitle>Reset Password
                    <span className='float-end'>
                        <material.IconButton color='error' title='Close' onClick={close}>
                            <material.CloseIcon />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div>
                            <material.TextField
                                {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                required
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            {(errors?.email?.type === "pattern") && (
                                <p className='text-danger'>This is not a valid Email</p>
                            )}
                        </div>
                        {getEmail ? (
                            <div>
                                <div>
                                    <material.TextField
                                        error={errors.otp?.type === "required"}
                                        {...register("otp", { required: true, minLength: 10, maxLength: 10 })}
                                        required
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Enter OTP"
                                        type="number"
                                        fullWidth
                                        variant="standard"
                                    />
                                    {(errors?.otp?.type === "minLength" || errors?.otp?.type === "maxLength") && (
                                        <p className='text-danger'>Enter 10 digit otp</p>
                                    )}
                                </div>
                                {getVerifyOtp ? (
                                    <div>
                                        <material.TextField
                                            {...register("password", { required: true, })}
                                            required
                                            label="Enter New Password"
                                            id="password"
                                            variant="standard"
                                            type={type}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <material.InputAdornment position="end">
                                                        {isVisible === false ? (<material.VisibilityIcon onClick={showPassword} sx={{ cursor: "pointer" }} />) : (<material.VisibilityOffIcon onClick={hidePassword} sx={{ cursor: "pointer" }} />)}
                                                    </material.InputAdornment>
                                                ),
                                            }}
                                        />
                                        <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {!getEmail ? (
                            <span className='d-flex justify-content-center mt-3'>
                                <material.Button onClick={handleSubmit(getOtp)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Get-OTP</material.Button>
                            </span>
                        ) : null}
                        {getEmail && !getVerifyOtp ? (
                            <span className='d-flex justify-content-center mt-3'>
                                <material.Button onClick={handleSubmit(verifyOtp)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Verify-OTP</material.Button>
                            </span>
                        ) : null}
                        {getEmail && getVerifyOtp && !getPassword ? (
                            <span className='d-flex justify-content-center mt-3'>
                                <material.Button onClick={handleSubmit(submitPassword)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Submit</material.Button>
                            </span>
                        ) : null}
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ChangePassword;