import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { StyledTableCell } from '../TableHeaderStyle';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './Table.css';

function TableWidgets(props) {

    const { tableWidgetsData } = props;
    const monthsPerPage = 1;
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const monthEntries = Object.entries(tableWidgetsData).filter(([key]) => key.startsWith('20'));
    const maxPages = Math.ceil(monthEntries.length / monthsPerPage);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const nextPage = () => {
        setCurrentPage((current) => (current < maxPages ? current + 1 : current));
    };

    const prevPage = () => {
        setCurrentPage((current) => (current > 1 ? current - 1 : current));
    };

    const startIndex = (currentPage - 1) * monthsPerPage;
    const displayedMonths = monthEntries.slice(startIndex, startIndex + monthsPerPage);

    return (
        <div>
            <material.TableContainer sx={{ p: 2 }}>
                <div>
                    <h2>Yearly Sales Summary</h2>
                    <p>Total Yearly Gross Sales: {tableWidgetsData.totalYearlyGrossSales.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</p>
                    <p>Total Yearly Net Sales: {tableWidgetsData.totalYearlyNetSales.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</p>
                </div>
                <material.Table className='wd-table'>
                    <material.TableHead >
                        <material.TableRow>
                            <StyledTableCell>Period</StyledTableCell>
                            <StyledTableCell>Total Gross Sales</StyledTableCell>
                            <StyledTableCell>Total Net Sales</StyledTableCell>
                            <StyledTableCell>Treatment Category</StyledTableCell>
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {isLoading ? (
                            <material.TableRow >
                                <material.TableCell colSpan={10}>
                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                        <Skeleton count={10} />
                                    </SkeletonTheme>
                                </material.TableCell>
                            </material.TableRow>
                        ) : (
                            <>
                                {tableWidgetsData !== null ? displayedMonths.map(([month, details]) => (
                                    <material.TableRow
                                        key={month}
                                    >
                                        <material.TableCell size='small'>{month}</material.TableCell>
                                        <material.TableCell size='small'>{details.totalGrossSalesByMonth.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</material.TableCell>
                                        <material.TableCell size='small'>{details.totalNetSalesByMonth.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</material.TableCell>
                                        <material.TableCell size='small'>
                                            {Object.entries(details.categories).map(([categoryName, categoryDetails]) => (
                                                <div key={categoryName}>
                                                    <strong>{categoryName}</strong>
                                                    <p>Total Gross Sales: {categoryDetails.totalGrossSales.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</p>
                                                    <p>Total Net Sales: {categoryDetails.totalNetSales.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</p>
                                                    <material.Table className='wd-sub-table'>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Treatment Name</StyledTableCell>
                                                                <StyledTableCell>Total Gross Sales</StyledTableCell>
                                                                <StyledTableCell>Total Net Sales</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {categoryDetails.treatments.map(treatment => (
                                                                <material.TableRow key={treatment.treatmentName}>
                                                                    <material.TableCell>{treatment.treatmentName}</material.TableCell>
                                                                    <material.TableCell>{treatment.grossSales.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</material.TableCell>
                                                                    <material.TableCell>{treatment.netSales.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</material.TableCell>
                                                                </material.TableRow>
                                                            ))}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </div>
                                            ))}
                                        </material.TableCell>
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={10}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </>
                        )}
                    </material.TableBody>
                </material.Table>
                <div className='mt-3'>
                    <material.Button variant='outlined' sx={{ mr: 1 }} onClick={prevPage} disabled={currentPage === 1} startIcon={<material.SkipPreviousIcon />}>Previous</material.Button>
                    <material.Button variant='outlined' onClick={nextPage} disabled={currentPage === maxPages} endIcon={<material.SkipNextIcon />}>Next</material.Button>
                </div>
            </material.TableContainer>
        </div>
        // <div style={{ padding: "20px" }}>
        //     <h1>Sales Data Overview</h1>
        //     <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20Px" }}>
        //         <thead>
        //             <tr>
        //                 <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Month</th>
        //                 <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Total Gross Sales</th>
        //                 <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Total Net Sales</th>
        //                 <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Categories</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {Object.entries(tableWidgetsData).filter(([key]) => key.startsWith('2024')).map(([month, details]) => (
        //                 <tr key={month}>
        //                     <td style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{month}</td>
        //                     <td style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{details.totalGrossSalesByMonth}</td>
        //                     <td style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{details.totalNetSalesByMonth}</td>
        //                     <td style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
        //                         {Object.entries(details.categories).map(([categoryName, categoryDetails]) => (
        //                             <div key={categoryName}>
        //                                 <strong>{categoryName}</strong>
        //                                 <p>Total Gross Sales: {categoryDetails.totalGrossSales}</p>
        //                                 <p>Total Net Sales: {categoryDetails.totalNetSales}</p>
        //                                 <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20Px" }}>
        //                                     <thead>
        //                                         <tr>
        //                                             <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Treatment</th>
        //                                             <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Gross Sales</th>
        //                                             <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Net Sales</th>
        //                                         </tr>
        //                                     </thead>
        //                                     <tbody>
        //                                         {categoryDetails.treatments.map(treatment => (
        //                                             <tr key={treatment.treatmentName}>
        //                                                 <td style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{treatment.treatmentName}</td>
        //                                                 <td style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{treatment.grossSales}</td>
        //                                                 <td style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{treatment.netSales}</td>
        //                                             </tr>
        //                                         ))}
        //                                     </tbody>
        //                                 </table>
        //                             </div>
        //                         ))}
        //                     </td>
        //                 </tr>
        //             ))}
        //         </tbody>
        //     </table>
        //     <div>
        //         <h2>Yearly Summary</h2>
        //         <p>Total Yearly Gross Sales: {tableWidgetsData.totalYearlyGrossSales}</p>
        //         <p>Total Yearly Net Sales: {tableWidgetsData.totalYearlyNetSales}</p>
        //     </div>
        // </div>
    );
}

export default TableWidgets;