import React, { useEffect, useState } from 'react';
import Swipedrawer from '../drawer/Swipedrawer';
import { material } from '../../library/material';
import { styled } from '@mui/material/styles';
import { drawerWidth } from '../drawer/Swipedrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBddFormDataByFormId } from '../../services/BddFormService';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

function ViewBddForm(props) {

    const [openSideNav, setOpenSideNav] = React.useState(false);
    const [dbbFormData, setDbbFormData] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    let patientData = location.state ? location.state.patientData : "";

    useEffect(() => {
        getBddFormData()
    }, []);

    const getBddFormData = async () => {
        await getBddFormDataByFormId(patientData.bdd_form_id)
            .then((resp) => {
                setDbbFormData(resp.data)
            })
            .catch((error) => {

            })
    };

    const goBack = () => {
        navigate(-1)
        // navigate("/patient-list/edit-patient", { state: { patientData } })
    };

    return (
        <div className='body'>
            <Swipedrawer openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
            <Main open={openSideNav}>
                <div className='row'>
                    <div className='col-6'>

                    </div>
                    <div className="col-6">
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                <div className='content mt-3 p-4'>
                    <material.Paper elevation={7} sx={{ p: 4, width: "75%" }}>
                        <span><material.Typography variant="h5">BDD Form</material.Typography></span>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='fw-bold'>
                                            <td className='col-4'>Patient Name</td>
                                            <td className='col-8'>{dbbFormData.patientName}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td className='col-4'>Patient Email</td>
                                            <td className='col-8'>{dbbFormData.userEmail}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td className='col-4'>Date of Birth</td>
                                            <td className='col-8'>{dbbFormData.dateOfBirth}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td className='col-4'>Clinic Name</td>
                                            <td className='col-8'>{dbbFormData.clinicName}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td className='col-4'>Exp Date</td>
                                            <td className='col-8'>{dbbFormData.exp_date}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <material.Typography>
                                The below questions will assess underlying psychological conditions such as (BDD) body dysmorphic disorder.
                                Please speak to your practitioner if you require further information regarding BDD.
                            </material.Typography>
                            <material.Typography sx={{ mt: 2 }}>
                                If there are indications of significant underlying psychological issues which may make
                                you an unsuitable candidate for the cosmetic procedure, you may be referred for evaluation to a
                                psychologist, psychiatrist or general practitioner who works independently of the Healthcare professional who will perform the procedure or the medical practitioner who will prescribe the cosmetic injectable.
                            </material.Typography>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='col-11'> 1- Are you worried about the way you look, and do you think about your appearance a lot and wish you could think about it less</td>
                                            <td className='col-1 fw-bold'>{dbbFormData.q1}</td>
                                        </tr>
                                        <tr>
                                            <td className='col-11'>2- How has it, the way you feel about it, affected your daily life activities, such as school, work, or other activities?</td>
                                            <td className='col-1 fw-bold'>{dbbFormData.q2}</td>
                                        </tr>
                                        <tr>
                                            <td className='col-11'>3- Are there things you avoid because of how you look, such as gatherings, going out etc</td>
                                            <td className='col-1 fw-bold'>{dbbFormData.q3}</td>
                                        </tr>
                                        <tr>
                                            <td className='col-11'>Do you spend more than 2-3 hours a day thinking about how you look</td>
                                            <td className='col-1 fw-bold'>{dbbFormData.q4}</td>
                                        </tr>
                                        <tr>
                                            <td className='col-11'>5- If you could not have a cosmetic treatment today, would this affect your life adversely?</td>
                                            <td className='col-1 fw-bold'>{dbbFormData.q5}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </material.Paper>
                </div>
            </Main>
        </div>
    );
}

export default ViewBddForm;