import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getSkinTreatmentPriceListForBookingByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-skin-treatment-price-list-for-booking`, { params: { clinicId: clinicId } });
};

export const saveSkinTreatmentPlan = async (treatmentData) => {
    return await axios.post(`${rootApiUrl}/save-skin-treatment-plan`, treatmentData);
};

export const getSkinTreatmentPlanForPatient = async (clinicId, patientId) => {
    return await axios.get(`${rootApiUrl}/get-skin-treatment-plans-for-patient`, { params: { clinicId: clinicId, patientId: patientId } });
};

export const getSkinTreatmentPlanInvoice = async (invoiceId) => {
    return await axios.get(`${rootApiUrl}/get-st-invoice`, { params: { invoiceId: invoiceId } });
};

export const skinTreatmentDecreaseRemaining = async (skinTreatmentPlanId) => {
    return await axios.post(`${rootApiUrl}/decrease-remaining`, { skinTreatmentPlanId: skinTreatmentPlanId });
};

export const sendSkinTreatmentPlanInvoice = async (invoiceId) => {
    return await axios.post(`${rootApiUrl}/send-st-invoice`, null, { params: { invoiceId: invoiceId } });
};

export const rejectSkinTreatmentPlan = async (payload) => {
    return await axios.post(`${rootApiUrl}/reject-skin-treatment-plan`, payload);
};

export const getSkinTreatmentInvoiceData = async (invoiceId) => {
    return await axios.get(`${rootApiUrl}/get-st-invoice-obj`, { params: { invoiceId: invoiceId } });
};

export const updateSkinTreatmentInvoice = async (invoiceData) => {
    return await axios.post(`${rootApiUrl}/update-skin-treatment-invoice`, invoiceData);
};

export const uploadSkinBeforeImage = async (imageData) => {
    return await axios.post(`${rootApiUrl}/upload-skin-before-images`, imageData);
};

export const uploadSkinBeforeImageFile = async (imageData) => {
    return await axios.post(`${rootApiUrl}/upload-skin-before-images-file`, imageData);
};

export const getSkinTreatmentImageList = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-skin-treatment-image-list`, { params: { ...payload } });
};

// export const getSkinTreatmentImageList = async (payload) => {
//     return await axios.get(`${rootApiUrl}/get-skin-treatment-image-`, { params: { ...payload } });
// };get-skin-treatment-notes

export const getSkinTreatmentNotes = async (sessionId) => {
    return await axios.get(`${rootApiUrl}/get-skin-treatment-notes`, { params: { skinTreatmentSessionId: sessionId } });
};

export const getSingleSkinTreatmentPlanForPatient = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-skin-treatment-plan-for-patient`, { params: { ...payload } });
};