import React, { useState } from 'react';
import { material } from '../../library/material';
import AddPatientNote from './AddPatientNote';
import SelectTreatmentTypeDialog from './SelectTreatmentTypeDialog';

function AssessmentAskDialog(props) {

    const { openAssessmentAskDialog, setOpenAssessmentAskDialog, openPatientNote, setOpenPatientNote, patientData } = props;

    const [openSelectTreatmentDialog, setOpenSelectTreatmentDialog] = useState(false);

    const reAssessment = (data) => {
        if (data === "yes") {
            setOpenPatientNote({ action: true, note: null });
            setOpenAssessmentAskDialog({ action: false, patient: null });
        } else {
            setOpenSelectTreatmentDialog(true);
            setOpenAssessmentAskDialog({ action: false, patient: null });
        };
    };

    return (
        <div>
            <material.Dialog open={openAssessmentAskDialog.action} maxWidth="auto" hideBackdrop>
                {openAssessmentAskDialog.patient === "new_patient" ? (
                    <material.DialogContent className='d-flex flex-colum'>
                        Patient Skin Assessment hasn't been done yet, Please complete initial assessment.
                        <span>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} size="medium" onClick={() => reAssessment("yes")}>Start</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} color='error' size="medium" onClick={() => reAssessment("no")} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        </span>
                    </material.DialogContent>
                ) : (
                    <material.DialogContent className='d-flex flex-colum'>
                        Is it time to review the patient's progress and reassess their needs? <br />
                        Please take a moment to verify the patient's information by asking them to review their details on file.
                        <span>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} size="medium" onClick={() => reAssessment("yes")}>Yes</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} color='error' size="medium" onClick={() => reAssessment("no")}>No</material.Button>
                        </span>
                    </material.DialogContent>
                )}
            </material.Dialog>
            <AddPatientNote
                openPatientNote={openPatientNote}
                setOpenPatientNote={setOpenPatientNote}
                patientData={patientData}
            />
            <SelectTreatmentTypeDialog
                patientData={patientData}
                openSelectTreatmentDialog={openSelectTreatmentDialog}
                setOpenSelectTreatmentDialog={setOpenSelectTreatmentDialog}
            />
        </div>
    );
}

export default AssessmentAskDialog;