import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';
import { getCompareImageList } from '../../../services/TreatmentPlanService';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import { rootApiUrl } from '../../../environment/Environment';
import FilterIcon from '../../../assets/filter.png';
import { PermissionDialog } from '../view-patient-details/ComparedImageDialog';


function Portfolio(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [compareImages, setCompareImages] = useState([]);
    const [selectValue, setSelectValue] = useState("");
    const [openDialog, setOpenDialog] = useState({ action: false, portfolioData: null });

    localStorage.setItem("menuName", "Portfolio");

    useEffect(() => {
        getCompareImgList()
    }, []);

    const handleChange = (event) => {
        setSelectValue(event.target.value);
        getCompareImgList(event.target.value);
    };

    const refreshData = () => {
        getCompareImgList()
    };

    const handleMenu = () => {
        setOpenDialog({ action: true, portfolioData: null });
    };

    const getDialogData = (tagName) => {
        getCompareImgList(tagName)
    };

    const getCompareImgList = (tag) => {
        var payload = {}
        if (userDetails.role.includes("INJECTOR") && tag === undefined) {
            payload = {
                "injectorId": userDetails.userId,
                "clinicId": clinicDetails.clinicId,
                "addToPortfolio": true,
            }
        } else if (userDetails.role.includes("INJECTOR") && tag !== undefined) {
            payload = {
                "injectorId": userDetails.userId,
                "clinicId": clinicDetails.clinicId,
                "addToPortfolio": true,
                tag: tag
            }
        } else if (!userDetails.role.includes("INJECTOR") && tag === undefined) {
            payload = {
                "clinicId": clinicDetails.clinicId,
                "addToPortfolio": true,
            }
        } else if (!userDetails.role.includes("INJECTOR") && tag !== undefined) {
            payload = {
                "clinicId": clinicDetails.clinicId,
                "addToPortfolio": true,
                tag: tag
            }
        };
        getCompareImageList(payload)
            .then((resp) => {
                setCompareImages(resp.data)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                // setComparedImg(true);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <material.Paper elevation={3} sx={{ p: 5 }}>
                    <div className='d-flex flex-row'>
                        <span><material.Typography variant='h6'>Portfolio Images</material.Typography></span>
                        {/* <material.FormControl sx={{ width: "40ch", ml: 2, mt: -2 }}>
                            <material.InputLabel id="demo-simple-select-label">Tag</material.InputLabel>
                            <material.Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectValue}
                                label="Age"
                                variant='standard'
                                onChange={handleChange}
                            >
                                <material.MenuItem value="Cosmetic Injectable">Cosmetic Injectable</material.MenuItem>
                                <material.MenuItem value="Skin Treatment">Skin Treatment</material.MenuItem>
                                <material.MenuItem value="General">General</material.MenuItem>
                            </material.Select>
                        </material.FormControl> */}
                        <span>
                            <material.IconButton
                                aria-label="more"
                                id="long-button"
                                onClick={handleMenu}
                                title='Filter'
                                color='info'
                            >
                                <img
                                    src={FilterIcon}
                                    height={25}
                                    width={25}
                                />
                            </material.IconButton>
                        </span>
                        <span>
                            <material.IconButton title='Refresh' color='success' onClick={refreshData}>
                                <material.RefreshIcon />
                            </material.IconButton>
                        </span>
                    </div>
                    {compareImages.length ? compareImages.map((images, i) => (
                        <div className="row mt-3" key={i}>
                            <div className="col-lg-6">
                                <img
                                    src={`${rootApiUrl}/get-skin-treatment-image?key=${images.before_image_link}`}
                                    height={400} width="100%"
                                    alt=''
                                />
                            </div>
                            <div className="col-lg-6">
                                <img
                                    src={`${rootApiUrl}/get-skin-treatment-image?key=${images.after_image_link}`}
                                    height={400} width="100%"
                                    alt=''
                                />
                            </div>
                        </div>
                    )) : (
                        <div className="row">
                            <div className="col-12">
                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                            </div>
                        </div>
                    )}
                </material.Paper>
            </div>
            <PermissionDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                getDialogData={getDialogData}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                callFrom="Portfolio"
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(Portfolio);