import axios from "axios";
import { rootApiUrl } from "../environment/Environment";



export const userLogin = async (userData) => {
    return await axios.post(`${rootApiUrl}/login`, userData);
};

export const userFrocedLogout = async (email) => {
    return await axios.post(`${rootApiUrl}/forced-logout/${email}`);
};

export const getOtpForResetPassword = async (resetPasswordData) => {
    return await axios.post(`${rootApiUrl}/send-otp`, resetPasswordData);
};

export const verifyOtpForResetPassword = async (resetPasswordData) => {
    return await axios.post(`${rootApiUrl}/verify-otp`, resetPasswordData);
};

export const resetPassword = async (resetPasswordData) => {
    return await axios.post(`${rootApiUrl}/reset-password`, resetPasswordData);
};

export const kioskLogin = async (userData) => {
    return await axios.post(`${rootApiUrl}/kiosk-login`, userData);
};