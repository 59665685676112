import React, { useState } from 'react';
import { material } from '../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { UploadPhotoDialog } from '../dashboard/treatment-plan/TreatmentPlan';
import KioskNavbar from '../navbar/KioskNavbar';
import { useTheme } from '@mui/material';


function KioskAddPhoto(props) {

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const payloadData = location.state?.obj;

    const [openUploadPhoto, setOpenUploadPhoto] = useState({ action: false, uploadType: null });

    const selectAndUploadPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "upload_file" });
    };

    const addPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "click_and_upload" });
    };

    const goBack = () => {
        navigate("/kiosk-menu");
    };


    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <KioskNavbar />
            <div className="row">
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <material.Button className='me-2' variant="contained" sx={{ textTransform: "none" }} startIcon={<material.UploadFileIcon />} onClick={selectAndUploadPhoto}>Upload Photo</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddAPhotoIcon />} onClick={addPhoto} >Add Photo</material.Button>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={goBack}>Back</material.Button>
                    </span>
                </div>
            </div>
            {/* <material.Paper className='p-4 pb-5 mt-2' elevation={3}>
                <div className='py-2' hidden={close}>
                    {image ? (
                        <>
                            <span className='me-5'>
                                {allImages.length ? allImages.map((ele, i) => (
                                    <img
                                        key={i}
                                        src={ele.image}
                                        height={100} width="25%"
                                        onClick={() => showImage(ele.image)}
                                        style={{ cursor: "pointer", margin: "5px" }}
                                    />
                                )) : ""}
                            </span>
                        </>
                    ) : (
                        <span>
                            {isWebcamActive ? (
                                <Webcam
                                    height={300}
                                    width="100%"
                                    screenshotFormat="image/jpeg"
                                    ref={videoRef}
                                    videoConstraints={{
                                        ...videoConstraints,
                                        facingMode
                                    }}
                                />
                            ) : (
                                <div style={{ height: "300px", width: "100%" }}></div>
                            )}
                            <span className='d-flex justify-content-center mt-2'>
                                <material.Button sx={{ textTransform: "none", mr: 1 }} size='small' variant="contained" onClick={switchCamera} startIcon={<material.CameraswitchIcon />} >Switch camera</material.Button>
                                <material.Button sx={{ textTransform: "none" }} size='small' variant="contained" onClick={capturePhoto} startIcon={<material.CameraAltIcon />} >Take photo</material.Button>
                                <material.Button sx={{ textTransform: "none" }} size='small' variant="contained" onClick={toggleCamera} className='ms-2'>
                                    {stream ? 'Close camera' : 'Open camera'}
                                </material.Button>
                            </span>
                        </span>
                    )}
                </div>
            </material.Paper> */}
            <UploadPhotoDialog
                openUploadPhoto={openUploadPhoto}
                setOpenUploadPhoto={setOpenUploadPhoto}
                blankTreatmentData={payloadData.blankTreatmentData}
                userDetails={payloadData.userDetails}
                clinicDetails={payloadData.clinicDetails}
                callFrom="secondary-user"
                typeOfImage={payloadData.imageType}
            />
        </material.Grid>
    );
}

export default KioskAddPhoto;