import React, { useState, useEffect } from 'react';
import { material } from '../../library/material';
import { useForm, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { getHighestPriorityRole } from '../../highest-priority-role/HighestPriorityRole';
import { changePlanStatus } from '../../services/PrescriberService';
import Snackbar from '../toastrmessage/Snackbar';
import PreSelectNotes from './PreSelectNotes';
import { Slide } from '@mui/material';

var array = [];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function FinaliseDialog(props) {

    const { openFinaliseDialog, setOpenFinaliseDialog, userData, getTreatmentPlan } = props;
    const { register, handleSubmit, reset, setValue, watch, getValues, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fieldName',
    });
    const userDetails = userData.authReducer.data;
    const [date, setDate] = useState(dayjs(new Date()));
    const [hideShow, setHideShow] = useState(true);
    const [selectNotes, setSelectNotes] = useState("");
    const [openPreSelectNote, setOpenPreSelectNote] = useState({ action: false, treatmentData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const onChangeNotes = watch('reason');

    useEffect(() => {
        getReasonValue();
    }, [onChangeNotes]);

    const getReasonValue = () => {
        let val1 = getValues('reason');
        setSelectNotes(val1)
    };

    useEffect(() => {
        if (openFinaliseDialog.action) {
            append(openFinaliseDialog.treatmentData?.treatmentPlan)
        }
    }, [openFinaliseDialog.action === true]);

    useEffect(() => {
        array.push(date)
    }, [date]);

    const getNoteData = (data) => {
        let notesString = "";
        data?.map((ele) => {
            notesString = notesString + '\n' + ele.treatmentName + "__" + ele.treatmentArea + " :- " + " " + ele.notes + '\n \n \n';
        })
        if (selectNotes !== "" && data !== null) {
            setSelectNotes(selectNotes + '\n' + notesString + '\n')
            setValue("reason", selectNotes + '\n' + notesString + '\n');
        } else if (selectNotes === "") {
            setSelectNotes(notesString + '\n')
            setValue("reason", notesString + '\n');
        };
    };

    const addPreWrittenNote = () => {
        setOpenPreSelectNote({ action: true, treatmentData: openFinaliseDialog.treatmentData })
    };

    const close = () => {
        reset({ product: "", batchId: "", reason: "" })
        setOpenFinaliseDialog({ action: false, treatmentData: null });
        setHideShow(true);
        append([]);
    };
    const changeStatus = (data) => {
        let obj = {
            "treatmentPlanRequestId": openFinaliseDialog.treatmentData?.treatmentPlanRequestId,
            "status": "USED",
            "reason": selectNotes,
            productRelatedDetails: data.fieldName
        }
        changePlanStatus(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Save successfully",
                })
                getTreatmentPlan()
                reset({ product: "", batchId: "", reason: "" });
                append([]);
                setOpenFinaliseDialog({ action: false, treatmentData: null })
                setHideShow(true)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Something went wrong",
                })
            })
    };

    return (
        <div>
            <material.Dialog fullScreen open={openFinaliseDialog.action} hideBackdrop TransitionComponent={Transition}>
                <material.AppBar sx={{ position: 'relative' }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Patient Care Documentation
                        </material.Typography>
                        <material.Button autoFocus color="inherit" onClick={handleSubmit(changeStatus)}>
                            save
                        </material.Button>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogTitle>

                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={addPreWrittenNote}>Select Treatment Notes</material.Button>
                    {/* <material.Fab sx={{ ml: 2 }} color="primary" size='small' aria-label="add" title='Add Notes' >
                        <material.AddIcon />
                    </material.Fab> */}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        {/* <div>
                            <material.Autocomplete
                                id="machineName"
                                onChange={handleChangeTreatment}
                                options={treatmentData}
                                renderInput={(params) => <material.TextField {...params} variant="standard"
                                    label="Select Treatment"
                                    {...register("treatmentName")}
                                    sx={{ width: "30%" }}
                                />}
                            />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
                            <material.Autocomplete
                                id="machineName"
                                onChange={handleChangeNotes}
                                options={preWrittenNotes}
                                renderInput={(params) => <material.TextField {...params} variant="standard"
                                    label="Select Treatment Notes"
                                    {...register("treatmentNote")}
                                    sx={{ width: "100%" }}
                                    multiline
                                    rows={5}
                                />}
                            />
                        </div> */}
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
                            <material.TextField
                                id="standard-error"
                                label="Enter Treatment Notes Here (Free Text)"
                                variant="outlined"
                                type="text"
                                size="small"
                                multiline
                                fullWidth
                                {...register("reason", { required: true })}
                                error={errors.reason?.type === "required"}
                                rows={10}
                                InputLabelProps={{ shrink: true }}
                            />
                            <p className='form-text text-danger'>{errors.reason?.type === "required" && 'This field is required'}</p>
                        </div>
                    </div>
                    {fields.map((field, index) => (
                        <div className='row' key={field.id}>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-2 mt-2">
                                <material.TextField
                                    id="standard-error"
                                    label="Product Name"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    {...register(`fieldName.${index}.product`)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-2 mt-2">
                                <material.TextField
                                    id="standard-error"
                                    label="Batch Id"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    {...register(`fieldName.${index}.batchId`, { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-2 mt-2">
                                <material.TextField
                                    id="standard-error"
                                    label="Exp Date"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    {...register(`fieldName.${index}.expDate`)}
                                />
                            </div>
                        </div>
                    ))}
                </material.DialogContent>
                {/* <material.DialogActions>
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={close}>
                            Cancel
                        </material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(changeStatus)}>
                            Submit
                        </material.Button>
                    </span>
                </material.DialogActions> */}
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <PreSelectNotes
                openPreSelectNote={openPreSelectNote}
                setOpenPreSelectNote={setOpenPreSelectNote}
                userDetails={userDetails}
                getNoteData={getNoteData}
            />
        </div>
    );
}

// const PreSelectNotes = (props) => {

//     const { openPreSelectNote, setOpenPreSelectNote, userDetails, getNoteData } = props;
//     const { register, handleSubmit, reset, setValue, formState: { errors, isValid } } = useForm({
//         mode: "onTouched",
//     });
//     let role = getHighestPriorityRole(userDetails.role);
//     const [preWrittenNotes, setPreWrittenNotes] = useState([]);
//     const [treatmentData, setTreatmentData] = useState([]);
//     const [treatmentAreaData, setTreatmentAreaData] = useState([]);
//     const [selectTreatmentData, setSelectTreatmentData] = useState("");
//     const [selectTreatmentAreaData, setSelectTreatmentAreaData] = useState("");

//     useEffect(() => {
//         if (openPreSelectNote.action) {
//             let treatmentName = openPreSelectNote.treatmentData?.treatmentPlan?.map((ele) => ele.treatmentName);
//             setTreatmentData(treatmentName);
//             setPreWrittenNotes([]);
//         };
//     }, [openPreSelectNote.action === true]);

//     const handleChangeTreatment = (e, value) => {
//         setSelectTreatmentData(value)
//         let treatmentArea = openPreSelectNote.treatmentData?.treatmentPlan?.filter((ele) => ele.treatmentName === value).map((resp) => resp.treatmentArea);
//         setTreatmentAreaData(treatmentArea);
//     };

//     const handleChangeArea = (e, value) => {
//         setSelectTreatmentAreaData(value)
//         getPreWrittenNotes(value)
//     };

//     const getPreWrittenNotes = async (treatmentArea) => {
//         if (role === "INJECTOR" && userDetails.isDoctor === false) {
//             await getPreWrittenNotesInjector(selectTreatmentData, treatmentArea)
//                 .then(async (resp) => {
//                     await resp.data.map((ele) => {
//                         preWrittenNoteArray.push(
//                             {
//                                 treatmentName: selectTreatmentData,
//                                 treatmentArea: treatmentArea,
//                                 notes: ele
//                             })
//                     })
//                     console.log(preWrittenNoteArray)
//                     setPreWrittenNotes(resp.data)
//                 })
//                 .catch((error) => {

//                 })
//         } else if (role === "INJECTOR" && userDetails.isDoctor === true) {
//             await getPreWrittenNotesPrescriber(selectTreatmentData, treatmentArea)
//                 .then(async (resp) => {
//                     await resp.data.map((ele) => {
//                         preWrittenNoteArray.push(
//                             {
//                                 treatmentName: selectTreatmentData,
//                                 treatmentArea: treatmentArea,
//                                 notes: ele
//                             })
//                     })
//                     console.log(preWrittenNoteArray)
//                     setPreWrittenNotes(resp.data)
//                 })
//                 .catch((error) => {

//                 })
//         };
//     };

//     const saveNote = (data) => {
//         // console.log(notesArray)
//         getNoteData(notesArray);
//         reset({ treatmentName: "", treatmentNote: "" });
//         setOpenPreSelectNote({ action: false, treatmentData: null });
//     };

//     const close = () => {
//         setOpenPreSelectNote({ action: false, treatmentData: null });
//         reset({ treatmentName: "", treatmentNote: "" });
//         setPreWrittenNotes([]);
//     };

//     const handleCheckedNotes = (notes) => {
//         if (notesArray.includes(notes)) {
//             notesArray.pop(notes)
//         } else {
//             notesArray.push(notes)
//         };
//     };

//     return (
//         <div>
//             <material.Dialog fullScreen open={openPreSelectNote.action} hideBackdrop TransitionComponent={TransitionSide}>
//                 <material.AppBar sx={{ position: 'relative' }}>
//                     <material.Toolbar>
//                         <material.IconButton
//                             edge="start"
//                             color="inherit"
//                             onClick={close}
//                             aria-label="close"
//                         >
//                             <material.CloseIcon />
//                         </material.IconButton>
//                         <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
//                             Select Pre Written Notes
//                         </material.Typography>
//                         <material.Button autoFocus color="inherit" onClick={handleSubmit(saveNote)}>
//                             save
//                         </material.Button>
//                     </material.Toolbar>
//                 </material.AppBar>
//                 <material.DialogContent>
//                     <div className='row'>
//                         <div className='col-lg-4'>
//                             <material.Autocomplete
//                                 id="machineName"
//                                 onChange={handleChangeTreatment}
//                                 options={treatmentData}
//                                 renderInput={(params) => <material.TextField {...params} variant="standard"
//                                     label="Treatment Name"
//                                     required
//                                     {...register("treatmentName", { required: true })}
//                                 />}
//                             />
//                         </div>
//                         <div className='col-lg-4'>
//                             <material.Autocomplete
//                                 id="machineName"
//                                 onChange={handleChangeArea}
//                                 options={treatmentAreaData}
//                                 renderInput={(params) => <material.TextField {...params} variant="standard"
//                                     label="Treatment Area"
//                                     required
//                                     {...register("treatmentArea", { required: true })}
//                                 />}
//                             />
//                         </div>
//                         <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
//                             <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
//                                 <material.TableContainer sx={{ maxHeight: 460 }}>
//                                     <material.Table stickyHeader aria-label="sticky table">
//                                         <material.TableHead>
//                                             <material.TableRow>
//                                                 <StyledTableCell style={{ width: "10%" }}>Treatment</StyledTableCell>
//                                                 <StyledTableCell style={{ width: "10%" }}>Area</StyledTableCell>
//                                                 <StyledTableCell style={{ width: "75%" }}>Notes</StyledTableCell>
//                                                 <StyledTableCell style={{ width: "5%" }}></StyledTableCell>
//                                             </material.TableRow>
//                                         </material.TableHead>
//                                         <material.TableBody>
//                                             {preWrittenNoteArray?.map((ele, i) => (
//                                                 <material.TableRow key={i}>
//                                                     <material.TableCell>{ele.treatmentName}</material.TableCell>
//                                                     <material.TableCell>{ele.treatmentArea}</material.TableCell>
//                                                     <material.TableCell>{ele.notes}</material.TableCell>
//                                                     <material.TableCell>
//                                                         <material.Checkbox onChange={() => handleCheckedNotes(ele)} color="primary" />
//                                                     </material.TableCell>
//                                                 </material.TableRow>
//                                             ))}
//                                         </material.TableBody>
//                                     </material.Table>
//                                 </material.TableContainer>
//                             </material.Paper>
//                         </div>
//                     </div>
//                 </material.DialogContent>
//             </material.Dialog>
//         </div>
//     )
// };

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(FinaliseDialog);