import React, { useState, useEffect } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { getProductTreatment } from '../../../services/TreatmentListService';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import moment from 'moment';
import { saveOrUpdateStockPrice } from '../../../services/ProductPriceManagment';
import { dateFormat } from '../../../date-and-time-format/DateAndTimeFormat';


function AddCosmeticInjectable(props) {

    const { openAddProduct, setOpenAddProduct, userData, getProductPriceManagementData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, resetField, formState: { errors, isValid }, reset, setValue } = useForm({
        mode: "onTouched"
    });
    const [date, setDate] = React.useState(dayjs(new Date()));
    const [fromDate, setFromDate] = React.useState(dayjs(new Date()));
    const [toDate, setToDate] = React.useState(dayjs("2999-12-31"));
    const [selectProductType, setSelectProductType] = useState("");
    const [selectProductName, setSelectProductName] = useState("");
    const [productList, setProductList] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getProductTreatmentList()
    }, [openAddProduct.action === true]);

    useEffect(() => {
        if (openAddProduct.action && openAddProduct.productData) {
            reset(openAddProduct.productData);
            setDate(openAddProduct?.productData?.timeStamp ? dayjs(openAddProduct.productData.timeStamp) : dayjs(new Date()))
            setFromDate(openAddProduct?.productData?.formDate ? dayjs(openAddProduct.productData.formDate) : dayjs(new Date()))
            setToDate(openAddProduct?.productData?.toDate ? dayjs(openAddProduct.productData.toDate) : dayjs(new Date()))
        }
    }, [openAddProduct.action === true]);

    const getProductTreatmentList = async () => {
        await getProductTreatment(clinicDetails.clinicId, userDetails.orgId)
            .then((resp) => {
                setProductList(resp.data)
            })
            .catch((error) => {
                console.log(error)
            })
    };
    const handleChangeProductType = (e, value) => {
        setSelectProductType(e.target.value)
    };
    const handleChangeProduct = (event) => {
        setSelectProductName(event.target.value)
    };
    const cancel = () => {
        setOpenAddProduct({ action: false, productData: null })
        reset({ productType: "", product: "", perUnitPrice: "" })
        setSelectProductType("")
        setSelectProductName("")
    };
    const saveProduct = async (fromData) => {
        let obj = {
            "clinicId": clinicDetails.clinicId,
            "product": selectProductName.product,
            "productType": selectProductType.treatmentType,
            "perUnitPrice": fromData.perUnitPrice,
            "formDate": dateFormat(fromDate.$d),
            "toDate": dateFormat(toDate.$d),
            "changedBy": userDetails.userId,
            "timeStamp": moment(date.$d).format("YYYY-MM-DDTHH:mm:ss"),
            "orgId": userDetails.orgId
        }
        await saveOrUpdateStockPrice(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Added successfully",
                })
                setOpenAddProduct({ action: false, productData: null })
                reset({ productType: "", product: "", perUnitPrice: "" })
                getProductPriceManagementData()
                setSelectProductType("")
                setSelectProductName("")
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const updateProduct = async (fromData) => {
        await saveOrUpdateStockPrice(fromData)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Updated successfully",
                })
                getProductPriceManagementData()
                reset({ productType: "", product: "", perUnitPrice: "" });
                setSelectProductType("");
                setSelectProductName("");
                setOpenAddProduct({ action: false, productData: null })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openAddProduct.action} hideBackdrop >
                <material.DialogTitle>{openAddProduct.productData ? "Edit Product" : "Add Product"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            {openAddProduct.productData ? (
                                <material.TextField
                                    label="Treatment Type"
                                    size="small"
                                    variant='standard'
                                    fullWidth
                                    {...register("productType", { required: true })}
                                    disabled
                                />
                            ) : (
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="productType">Treatment Type</material.InputLabel>
                                    <material.Select
                                        {...register("productType", { required: true })}
                                        id="productType"
                                        value={selectProductType}
                                        onChange={handleChangeProductType}
                                    >
                                        {productList?.map((product, i) => (
                                            <material.MenuItem key={i} value={product}>{product.treatmentType}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            )}
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            {openAddProduct.productData ? (
                                <material.TextField
                                    label="Product"
                                    size="small"
                                    variant='standard'
                                    fullWidth
                                    {...register("product", { required: true })}
                                    disabled
                                />
                            ) : (
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="product">Product</material.InputLabel>
                                    <material.Select
                                        {...register("product", { required: true })}
                                        id="product"
                                        value={selectProductName}
                                        onChange={handleChangeProduct}
                                    >
                                        {selectProductType?.product?.map((product, i) => (
                                            <material.MenuItem key={i} value={product}>{product.product}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            )}
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Per Unit Price"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("perUnitPrice", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="From Date"
                                    sx={{ width: "100%" }}
                                    format='DD-MM-YYYY'
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    value={fromDate}
                                    onChange={(newValue) => {
                                        setFromDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="To Date"
                                    sx={{ width: "100%" }}
                                    format='DD-MM-YYYY'
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    value={toDate}
                                    onChange={(newValue) => {
                                        setToDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="Time Stamp"
                                    sx={{ width: "100%" }}
                                    format='DD-MM-YYYY'
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end me-2 py-3'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 2 }} color='error' onClick={cancel}>Cancel</material.Button>
                        {openAddProduct.productData ? (
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(updateProduct)} disabled={!isValid}>Update</material.Button>
                        ) : (
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveProduct)} disabled={!isValid}>Save</material.Button>
                        )}
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddCosmeticInjectable);