import React, { useEffect, useState } from "react";
import Navbar from "../../../navbar/Navbar";
import { useForm } from "react-hook-form";
import { material } from "../../../../library/material";
import RadarChart from "./RadarChart";
import { Stepper, Step, StepLabel, Button, Typography } from "@mui/material";
import CustomStepIcon from "./CustomStepIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAssessmentData } from "../../../../services/GRSService";
import { connect } from "react-redux";
import Snackbar from "../../../toastrmessage/Snackbar";
import { rootApiUrl } from "../../../../environment/Environment";

const steps = ["0", "1", "2", "3"];

function AddAssessment(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });
  const location = useLocation();
  const patientData = location.state?.patientData;
  const patientAllImages = location.state?.patientAllImages;
  const navigate = useNavigate();
  const { userData, clinicData } = props;
  const userDetails = userData.authReducer.data;
  const clinicDetails = clinicData.clinicReducer.data;
  const [formData, setFormData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [stepperData, setStepperData] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });
  const [selectedImages, setSelectedImages] = useState([]);

  const saveAssessment = (assessmentData) => {
    let requestBody = {
      ...assessmentData,
      clinicId: clinicDetails?.clinicId,
      orgId: userDetails?.orgId,
      patientId: patientData?.patientId,
      linkedImageUri:
        selectedImages.length > 1
          ? selectedImages?.join(",")
          : selectedImages[0],
    };
    saveAssessmentData(requestBody)
      .then((res) => {
        setOpenSnackBar({
          action: true,
          type: "success",
          message: res.data,
        });
        navigate(-1);
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
    setFormData(
      [
        assessmentData.static,
        assessmentData.dynamic,
        assessmentData.volume_loss,
        assessmentData.sagging,
        assessmentData.asymmetry,
        assessmentData.imbalance,
        assessmentData.loss_Of_Elasticity,
        assessmentData.surface_roughness,
        assessmentData.dehydration,
      ].map(Number)
    ); // Ensure the data is numeric
    setStepperData({
      scars: assessmentData.scars,
      visible_pores: assessmentData.visible_pores,
      pigmentation: assessmentData.pigmentation,
      vasculature: assessmentData.vasculature,
    });
  };
  const renderStepper = (label, value) => (
    <material.Box sx={{ width: "100%" }}>
      {/* <Typography>{label}</Typography> */}
      <Stepper alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index} completed={index <= value}>
            <StepLabel StepIconComponent={CustomStepIcon}>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </material.Box>
  );

  const selectBeforeImg = (imageKey) => {
    // setClickBeforeImg(img);
    if (selectedImages.includes(imageKey)) {
      setSelectedImages(selectedImages.filter((key) => key !== imageKey));
    } else {
      setSelectedImages([...selectedImages, imageKey]);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Navbar />
      <div className="mt-3 p-4 mt-5" style={{ marginTop: "5%" }}>
        <div className="row">
          <div className="col-12">
            <span className="float-end">
              <material.Button
                variant="contained"
                size="small"
                sx={{ textTransform: "none" }}
                onClick={goBack}
                startIcon={<material.ReplyIcon />}
              >
                Back
              </material.Button>
            </span>
          </div>
          <material.Paper elevation={7} sx={{ p: 1, mt: 2 }}>
            <div
              className="container-fluid"
              style={{
                marginTop: "1%",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <h3 style={{ textDecoration: "underline" }}>
                Global Ranking Scale
              </h3>
              <form style={{ marginTop: "2%" }}>
                <div
                  className="border border-2 p-2 my-2 rounded-2 mt-2"
                  style={{
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  }}
                >
                  <div className="row">
                    <h6>SKIN</h6>
                    <div className="col-4">
                      <material.TextField
                        label="Loss of elasticity"
                        id="loss-of-elasticity"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("loss_Of_Elasticity", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.loss_Of_Elasticity?.type === "minLength" ||
                        errors?.loss_Of_Elasticity?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.loss_Of_Elasticity && (
                        <p className="text-danger">
                          {errors.loss_Of_Elasticity.message}
                        </p>
                      )}
                    </div>
                    <div className="col-4">
                      <material.TextField
                        label="Surface Roughness"
                        id="surface-roughness"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("surface_roughness", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.surface_roughness?.type === "minLength" ||
                        errors?.surfaceRoughness?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.surface_roughness && (
                        <p className="text-danger">
                          {errors.surface_roughness.message}
                        </p>
                      )}
                    </div>
                    <div className="col-4">
                      <material.TextField
                        label="Dehydration"
                        id="dehydration"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("dehydration", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.dehydration?.type === "minLength" ||
                        errors?.dehydration?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.dehydration && (
                        <p className="text-danger">
                          {errors.dehydration.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="border border-2 p-2 my-2 rounded-2 mt-2"
                  style={{
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  }}
                >
                  <div className="row">
                    <h6>WRINKLES</h6>
                    <div className="col-6">
                      <material.TextField
                        label="Static"
                        id="static"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("static", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.static?.type === "minLength" ||
                        errors?.static?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.static && (
                        <p className="text-danger">{errors.static.message}</p>
                      )}
                    </div>
                    <div className="col-6">
                      <material.TextField
                        label="Dynamic"
                        id="dynamic"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("dynamic", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.dynamic?.type === "minLength" ||
                        errors?.dynamic?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.dynamic && (
                        <p className="text-danger">{errors.dynamic.message}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="border border-2 p-2 my-2 rounded-2 mt-2"
                  style={{
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  }}
                >
                  <div className="row">
                    <h6>VOLUME</h6>
                    <div className="col-6">
                      <material.TextField
                        label="volume-Loss"
                        id="volume-loss"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("volume_loss", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.volume_loss?.type === "minLength" ||
                        errors?.volume_loss?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.volume_loss && (
                        <p className="text-danger">
                          {errors.volume_loss.message}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      <material.TextField
                        label="Sagging"
                        id="sagging"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("sagging", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.sagging?.type === "minLength" ||
                        errors?.sagging?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.sagging && (
                        <p className="text-danger">{errors.sagging.message}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="border border-2 p-2 my-2 rounded-2 mt-2"
                  style={{
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  }}
                >
                  <div className="row">
                    <h6>MORPHOLOGY</h6>
                    <div className="col-6">
                      <material.TextField
                        label="Asymmetry"
                        id="asymmetry"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("asymmetry", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.asymmetry?.type === "minLength" ||
                        errors?.asymmetry?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.asymmetry && (
                        <p className="text-danger">
                          {errors.asymmetry.message}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      <material.TextField
                        label="Imbalance"
                        id="imbalance"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("imbalance", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.imbalance?.type === "minLength" ||
                        errors?.imbalance?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.imbalance && (
                        <p className="text-danger">
                          {errors.imbalance.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <RadarChart data={formData} openFrom={"addAssessment"} />
                <h3 style={{ marginTop: "3%", textDecoration: "underline" }}>
                  Skin Quality
                </h3>
                <div
                  className="border border-2 p-2 my-2 rounded-2 mt-4"
                  style={{
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  }}
                >
                  <div className="row">
                    <div className="col-3">
                      <material.TextField
                        label="Scars"
                        id="Scars"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("scars", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.scars?.type === "minLength" ||
                        errors?.scars?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.scars && (
                        <p className="text-danger">{errors.scars.message}</p>
                      )}
                    </div>
                    <div className="col-3">
                      <material.TextField
                        label="Visible Pores"
                        id="visible_pores"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("visible_pores", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.visible_pores?.type === "minLength" ||
                        errors?.visible_pores?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.visible_pores && (
                        <p className="text-danger">
                          {errors.visible_pores.message}
                        </p>
                      )}
                    </div>
                    <div className="col-3">
                      <material.TextField
                        label="Pigmentation"
                        id="pigmentation"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("pigmentation", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.pigmentation?.type === "minLength" ||
                        errors?.pigmentation?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.pigmentation && (
                        <p className="text-danger">
                          {errors.pigmentation.message}
                        </p>
                      )}
                    </div>
                    <div className="col-3">
                      <material.TextField
                        label="Vasculature"
                        id="vasculature"
                        variant="standard"
                        type="number"
                        size="small"
                        sx={{ mb: 1 }}
                        fullWidth
                        {...register("vasculature", {
                          required: true,
                          minLength: 0,
                          maxLength: 1,
                          validate: (value) =>
                            (value >= 0 && value <= 3) ||
                            "This fields only contains digits between 0-3",
                        })}
                      />
                      {(errors?.vasculature?.type === "minLength" ||
                        errors?.vasculature?.type === "maxLength") && (
                        <p className="text-danger">
                          This field only contain 1 digits between 0-3
                        </p>
                      )}
                      {errors.vasculature && (
                        <p className="text-danger">
                          {errors.vasculature.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* {stepperData && (
                  <div className="row">
                    <div className="col-3">
                      {renderStepper("Scars", stepperData.scars)}
                    </div>
                    <div className="col-3">
                      {renderStepper("Visible Pores", stepperData.visible_pores)}
                    </div>
                    <div className="col-3">
                      {renderStepper("Pigmentation", stepperData.pigmentation)}
                    </div>
                    <div className="col-3">
                      {renderStepper("Vasculature", stepperData.vasculature)}
                    </div>
                  </div>
                )} */}

                {/* <h3 style={{ marginTop: "2%" }}>Skin Type</h3>
                <h6>Please select the appropriate skin type of your patient:</h6>
                <div className="row">
                  <div className="col-4">
                    <material.FormGroup>
                      <material.FormControlLabel
                        control={<material.Checkbox />}
                        {...register("type_i")}
                        label="Type I:Pale White or Frackled"
                      />
                    </material.FormGroup>
                  </div>
                  <div className="col-4">
                    <material.FormGroup>
                      <material.FormControlLabel
                        control={<material.Checkbox />}
                        {...register("type_ii")}
                        label="Type II:White"
                      />
                    </material.FormGroup>
                  </div>
                  <div className="col-4">
                    <material.FormGroup>
                      <material.FormControlLabel
                        control={<material.Checkbox />}
                        {...register("type_iii")}
                        label="Type III:White to Light Brown"
                      />
                    </material.FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <material.FormGroup>
                      <material.FormControlLabel
                        control={<material.Checkbox />}
                        {...register("type_iv")}
                        label="Type IV:Moderate Brown"
                      />
                    </material.FormGroup>
                  </div>
                  <div className="col-4">
                    <material.FormGroup>
                      <material.FormControlLabel
                        control={<material.Checkbox />}
                        {...register("type_v")}
                        label="Type V:Dark Brown"
                      />
                    </material.FormGroup>
                  </div>
                  <div className="col-4">
                    <material.FormGroup>
                      <material.FormControlLabel
                        control={<material.Checkbox />}
                        {...register("type_vi")}
                        label="Type VI:Very Dark Brown to Black"
                      />
                    </material.FormGroup>
                  </div>
                </div> */}
                <h3 style={{ marginTop: "2%", textDecoration: "underline" }}>
                  Images
                </h3>
                <div
                  className="border border-2 p-2 my-2 rounded-2 mt-4"
                  style={{
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  }}
                >
                  {patientAllImages.length > 0 ? (
                    <div className="row">
                      <div className="col-lg-12 mt-2 ms-4">
                        <span>
                          {patientAllImages.length
                            ? patientAllImages.map((ele, i) => (
                                <img
                                  key={i}
                                  src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                  height={200}
                                  width="18%"
                                  style={{
                                    overflow: "hidden",
                                    margin: "10px",
                                    cursor: "pointer",
                                    border: selectedImages.includes(ele)
                                      ? "2px solid blue"
                                      : "",
                                  }}
                                  onClick={() => selectBeforeImg(ele)}
                                />
                              ))
                            : ""}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <h5
                      style={{
                        textAlign: "center",
                        marginTop: "2%",
                        color: "red",
                      }}
                    >
                      Images is not available for this assessment
                    </h5>
                  )}
                </div>

                <span className="float-end py-3">
                  <material.Button
                    variant="contained"
                    // size="small"
                    sx={{ textTransform: "none", mb: 1 }}
                    className="me-2"
                    onClick={handleSubmit(saveAssessment)}
                    disabled={!isValid}
                  >
                    Save
                  </material.Button>
                </span>
              </form>
            </div>
          </material.Paper>
        </div>
      </div>
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state,
  };
};

export default connect(mapStateToProps)(AddAssessment);
