import React from 'react';
import { material } from '../../library/material';
import { StyledTableCell } from '../TableHeaderStyle';

function NewPatientCount(props) {

    const { patientCountData } = props;

    const renderTableRows = (entries, yearLabel) => {
        return (
            <>
                <material.TableRow sx={{ backgroundColor: "lightgreen" }}>
                    <material.TableCell colSpan="3" sx={{ fontSize: 15, fontWeight: "bold" }}>{yearLabel}</material.TableCell>
                </material.TableRow>
                {Object.entries(entries).map(([month, details], index) => (
                    <material.TableRow key={index}>
                        <material.TableCell>{month}</material.TableCell>
                        <material.TableCell>{details["New Patient"]}</material.TableCell>
                        <material.TableCell>{details["Revisit"]}</material.TableCell>
                    </material.TableRow>
                ))}
            </>
        )
    };

    return (
        <div>
            {patientCountData === null ? (
                <material.Stack>
                    <material.Skeleton variant="rectangular" width="100%" height="auto" />
                </material.Stack>
            ) : (
                <material.TableContainer sx={{ p: 2 }}>
                    <h6>New and Revisit Patient Statistics</h6>
                    <material.Table>
                        <material.TableHead>
                            <material.TableRow>
                                <StyledTableCell>Month</StyledTableCell>
                                <StyledTableCell>New Patient</StyledTableCell>
                                <StyledTableCell>Revisit</StyledTableCell>
                            </material.TableRow>
                        </material.TableHead>
                        <material.TableBody>
                            {renderTableRows(patientCountData.currentFinancialYear, "Current Financial Year")}
                            {renderTableRows(patientCountData.previousFinancialYear, "Previous Financial Year")}
                        </material.TableBody>
                    </material.Table>
                </material.TableContainer>
            )}
        </div>
    );
}

export default NewPatientCount;