import React, { useEffect, useRef, useState } from 'react';
import OT from '@opentok/client';
import './VideoCalling.css';
import { material } from '../../../library/material';
import { socket } from '../../../socket/Socket';


function VideoCalling(props) {

    const { credentialData, getCallEndData } = props;

    const sessionRef = useRef(null);
    const publisherRef = useRef(null);
    socket.on("call-rejected", (res) => {
        callEnd()
    });

    useEffect(() => {
        const element = document.getElementById("call-section")
        element.scrollIntoView({ behavior: "smooth" });
        const session = OT.initSession(credentialData.apikey, credentialData.sessionId);
        session.connect(credentialData.token, (error) => {
            if (error) {
                console.error('Failed to connect', error);
            } else {
                // Create publisher
                const publisher = OT.initPublisher('publisher-element');
                session.publish(publisher);
                publisherRef.current = publisher;
            }
        });
        session.on('streamCreated', function (event) {
            session.subscribe(event.stream, 'subscriber'
            );
        });
        sessionRef.current = session;
        return () => {
            // Disconnect and clean up session
            if (sessionRef.current) {
                sessionRef.current.disconnect();
                sessionRef.current = null;
            }
            // Clean up publisher
            if (publisherRef.current) {
                publisherRef.current.destroy();
                publisherRef.current = null;
            }
        };
    }, [credentialData]);

    const callEnd = () => {
        if (sessionRef.current) {
            sessionRef.current.disconnect();
            sessionRef.current = null;
        }
        if (publisherRef.current) {
            publisherRef.current.destroy();
            publisherRef.current = null;
        }
        getCallEndData("end")
    };

    return (
        <div id='call-section' className='video-section'>
            <div className="video-content">
                <div id="subscriber"></div>
                <div className='screen'>
                    <div id="publisher-element"></div>
                </div>
            </div>
            <span className='d-flex justify-content-center'>
                <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' size="small" onClick={callEnd}><material.CallEndIcon /></material.Button>
            </span>
        </div>
    );
}

export default VideoCalling;