import React, { useState, useEffect } from 'react';
import { material } from '../../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllPatientByClinicId, getAllPAtients } from '../../../services/PatientService';
import { getSkinTreatmentPlanForPatient, getSkinTreatmentPlanInvoice, sendSkinTreatmentPlanInvoice } from '../../../services/SkinTreatmentService';
import { changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import Snackbar from '../../toastrmessage/Snackbar';
import ConsentFormCP from '../consent-form/ConsentFormCP';
import ConsentFormIPL from '../consent-form/ConsentFormIPL';
import ConsentFormLT from '../consent-form/ConsentFormLT';
import ConsentFormPDT from '../consent-form/ConsentFormPDT';
import Hydrafacial from '../consent-form/Hydrafacial';
import EditSkinTreatmentInvoice from '../../dialog/EditSkinTreatmentInvoice';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

var patientId;

function SkinTreatmentList(props) {

    const { userData, clinicData } = props;
    let userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const location = useLocation();
    const patientData = location.state?.patientId;
    const [patientsData, setPatientsData] = useState([]);
    const [treatmentData, setTreatmentData] = useState([]);
    const [openEditInvoice, setOpenEditInvoice] = useState({ action: false, invoiceData: null })
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openConsentForm, setOpenConsentForm] = useState({ action: false, patientData: null });
    const [isLoading, setisLoading] = useState(false);
    localStorage.setItem("menuName", "Skin Treatment Invoice");

    useEffect(() => {
        getAllPatientListByClinicId()
    }, []);

    useEffect(() => {
        if (patientData !== undefined) {
            getTreatmentForPatient(clinicDetails.clinicId, patientData)
        }
    }, [patientData !== undefined]);


    const getAllPatientListByClinicId = async () => {
        await getAllPAtients(clinicDetails.clinicId)
            .then((res) => {
                let patientName = res.data?.map((name) => name.name)
                setPatientsData(res.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectPatient = (e, value) => {
        patientId = value.patientId
        getTreatmentForPatient(clinicDetails.clinicId, patientId)
    };

    const getTreatmentForPatient = async (clinicId, patientId) => {
        setisLoading(true);
        await getSkinTreatmentPlanForPatient(clinicId, patientId)
            .then((resp) => {
                setTreatmentData(resp.data);
                setisLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const viewInvoice = async (rowData) => {
        await getSkinTreatmentPlanInvoice(rowData.invoiceId)
            .then((resp) => {
                const newTab = window.open("", "_blank")
                newTab.document.write(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const sendInvoice = async (rowData) => {
        await sendSkinTreatmentPlanInvoice(rowData.invoiceId)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const editInvoice = (rowData) => {
        setOpenEditInvoice({ action: true, invoiceData: rowData })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-4'>
                        <material.Autocomplete
                            id="name"
                            margin="dense"
                            onChange={selectPatient}
                            options={patientsData}
                            getOptionLabel={(option) => option.firstName + " " + option.lastName}
                            renderInput={(params) => <material.TextField {...params} required variant="standard" label="Patient Name"
                            />}
                        />
                    </div>
                </div>
                <material.Paper className='p-4 pb-5 mt-3' elevation={3}>
                    <material.TableContainer sx={{ maxHeight: 460 }}>
                        <material.Table stickyHeader aria-label="sticky table">
                            <material.TableHead >
                                <material.TableRow>
                                    <StyledTableCell>Treatment Name</StyledTableCell>
                                    <StyledTableCell>Treatment Area</StyledTableCell>
                                    <StyledTableCell>Number of Sessions</StyledTableCell>
                                    <StyledTableCell>Remaining of Sessions</StyledTableCell>
                                    <StyledTableCell>Product/Machine</StyledTableCell>
                                    <StyledTableCell>Created At</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {isLoading ? (
                                    <material.TableRow >
                                        <material.TableCell colSpan={10}>
                                            <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                <Skeleton count={10} />
                                            </SkeletonTheme>
                                        </material.TableCell>
                                    </material.TableRow>
                                ) : (
                                    <>
                                        {treatmentData.length ? treatmentData.map((rowData, i) => (
                                            <material.TableRow key={i}
                                                sx={rowData.remainingSessions === 0 ? { backgroundColor: "lightgreen" } : rowData.state === "REJECTED" ? { backgroundColor: "#FF9999" } : ""}
                                            >
                                                <material.TableCell>{rowData.treatmentName}</material.TableCell>
                                                <material.TableCell>{rowData.treatmentArea}</material.TableCell>
                                                <material.TableCell>{rowData.totalNoOfSession}</material.TableCell>
                                                <material.TableCell>{rowData.remainingSessions}</material.TableCell>
                                                <material.TableCell>{rowData.productOrMachineUsed}</material.TableCell>
                                                <material.TableCell>{changeDateFormate(rowData.createdAt)}</material.TableCell>
                                                <material.TableCell>
                                                    {rowData.state === "REJECTED" ? null : (
                                                        <material.IconButton title='Edit Invoice' aria-label="create" size="small" onClick={() => editInvoice(rowData)}>
                                                            <material.CreateIcon color='primary' sx={{ fontSize: 20 }} />
                                                        </material.IconButton>
                                                    )}
                                                    <material.IconButton title='View Invoice' aria-label="create" size="small" onClick={() => viewInvoice(rowData)}>
                                                        <material.VisibilityIcon color='secondary' sx={{ fontSize: 20 }} />
                                                    </material.IconButton>
                                                    {rowData.state === "REJECTED" ? null : (
                                                        <material.IconButton title='Send Invoice' aria-label="create" size="small" onClick={() => sendInvoice(rowData)}>
                                                            <material.SendIcon color='primary' sx={{ fontSize: 20 }} />
                                                        </material.IconButton>
                                                    )}
                                                </material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </material.Paper>
            </div>
            {openConsentForm.patientData?.treatmentName === "Chemical Peel" ? (
                <ConsentFormCP
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                />
            ) : openConsentForm.patientData?.treatmentName === "IPL" ? (
                <ConsentFormIPL
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                />
            ) : openConsentForm.patientData?.treatmentName === "PDT" ? (
                <ConsentFormPDT
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                />
            ) : openConsentForm.patientData?.treatmentName === "Laser Treatment" ? (
                <ConsentFormLT
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                />
            ) : openConsentForm.patientData?.treatmentName === "Hydrafacial" ? (
                <Hydrafacial
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                />
            ) : null}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <EditSkinTreatmentInvoice
                openEditInvoice={openEditInvoice}
                setOpenEditInvoice={setOpenEditInvoice}
                getTreatmentForPatient={getTreatmentForPatient}
                callFrom="skin-treatment-invoice"
                patientId = {patientData === undefined ? patientId : patientData}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(SkinTreatmentList);