import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';

function ScheduledAppointments(props) {

    const { upcomingAppointList } = props;

    return (
        <div>
            <material.TableContainer>
                <material.Table>
                    <material.TableHead >
                        <material.TableRow>
                            <StyledTableCell>Appointment Date</StyledTableCell>
                            <StyledTableCell>Appointment Time</StyledTableCell>
                            <StyledTableCell>Appointment With</StyledTableCell>
                            <StyledTableCell>Appointment For</StyledTableCell>
                            <StyledTableCell>Booking Status</StyledTableCell>
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {upcomingAppointList.length ? upcomingAppointList.map((item, k) => (
                            <material.TableRow key={k}>
                                <material.TableCell>{moment(item.start).format("DD-MM-YYYY")}</material.TableCell>
                                <material.TableCell>{moment(item.start).format("hh:mm A")} - {moment(item.end).format("hh:mm A")}</material.TableCell>
                                <material.TableCell>{item.injectorName}</material.TableCell>
                                <material.TableCell>{item.title}</material.TableCell>
                                <material.TableCell>{item.bookingStatus}</material.TableCell>
                            </material.TableRow>
                        )) : (
                            <material.TableRow >
                                <material.TableCell colSpan={6}>
                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                </material.TableCell>
                            </material.TableRow>
                        )}
                    </material.TableBody>
                </material.Table>
            </material.TableContainer>
        </div>
    );
}

export default ScheduledAppointments;