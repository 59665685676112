import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const saveAssessmentData = async (requestBody) => {
  return await axios.post(`${rootApiUrl}/add-grs`, requestBody);
};

export const getAssessmentData = async (patientId) => {
  return await axios.get(`${rootApiUrl}/get-grs`, { params: { patientId } });
};

export const linkGrsToProposedTreatmentPlan = async (payload) => {
  return await axios.put(`${rootApiUrl}/link-grs-to-proposed-treatment-plan`, payload);
};

export const getGrsByToProposedTreatmentPlan = async (proposedTreatmentPlanId) => {
  return await axios.get(`${rootApiUrl}/get-grs-by-proposed-treatment-plan`, { params: { proposedTreatmentPlanId } });
};
