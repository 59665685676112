import React, { useEffect, useState } from 'react';
import SwipeDrawer from "../../drawer/Swipedrawer";
import { material } from '../../../library/material';
import { connect } from 'react-redux';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useForm } from 'react-hook-form';
import { getAllClinic } from '../../../services/ClinicService';
import { getAllStocks, getStocksByClinicId, getStocksByOrgId, saveOrUpdateStock } from '../../../services/StocksService';
import moment from 'moment';
import { dateAndTimeFormat, changeDateAndTimeFormate, changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Snackbar from '../../toastrmessage/Snackbar';
import { getVendorByClinicId, getVendorByOrgId } from '../../../services/VendorManagementService';
import { socket } from '../../../socket/Socket';
import { drawerWidth } from '../../drawer/Swipedrawer';

// const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

var allClinicData;
var clinicId;
var editData;
var buttonClickAction;

function Stocks(props) {

    const { userData } = props;
    let userDetails = userData.authReducer.data;
    const [stocksData, setStockesData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setisLoading] = useState(true);
    const [addStocksHideShow, setAddStocksHideShow] = useState(true);
    const [allClinic, setAllClinic] = useState([]);
    const [date, setDate] = React.useState(moment().format("YYYY-MM-DDTHH:mm:ss"));
    const [dateIn, setDateIn] = React.useState();
    const [selectValue, setSelectValue] = useState("");
    const [expiryDate, setExpiryDate] = React.useState();
    const [supplier, setSupplier] = useState([]);
    const { register, handleSubmit, resetField, formState: { errors, isValid }, reset } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openSideNav, setOpenSideNav] = React.useState(true);
    const [stockUpdated, setStockUpdated] = useState();

    socket.on("stock-updated", (res) => {
        setStockUpdated(res)
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Stock Updated",
        })
    });

    useEffect(() => {
        getClinics();
        getAllStocks()
        getSuppliers()
    }, []);

    useEffect(() => {
        getAllStocks()
    }, [stockUpdated]);

    useEffect(() => {
        const element = document.getElementById("add-stock-section")
        element.scrollIntoView({ behavior: "smooth" });
    }, [addStocksHideShow]);

    const getAllStocks = async () => {
        if (userDetails.role === "ADMIN") {
            await getStocksByOrgId(userDetails.orgId)
                .then((resp) => {
                    setStockesData(resp.data)
                    setisLoading(false)
                })
        } else if (userDetails.role === "MANAGER") {
            await getStocksByClinicId(userDetails.clinicId[0])
                .then((resp) => {
                    setStockesData(resp.data)
                    setisLoading(false)
                })
        }
        setStockUpdated("")
    };

    const getClinics = async () => {
        await getAllClinic()
            .then((resp) => {
                allClinicData = resp.data;
                let clinicName = resp.data.map((ele) => ele.clinicName);
                setAllClinic(clinicName)
            })
    };

    const getSuppliers = async () => {
        if (userDetails.role === "MANAGER") {
            await getVendorByClinicId(userDetails.clinicId[0])
                .then((res) => {
                    let supplierName = res.data.map((ele) => ele.companyName)
                    setSupplier(supplierName)
                })
        } else {
            await getVendorByOrgId(userDetails.orgId)
                .then((res) => {
                    let supplierName = res.data.map((ele) => ele.companyName)
                    setSupplier(supplierName)
                })
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const addStocks = () => {
        setAddStocksHideShow(false);
    };

    const selectClinic = (event, value) => {
        clinicId = allClinicData.filter((ele) => ele.clinicName === value).map((res) => res.clinicId)
    };

    const selectSupplier = (e, value) => {
        getSuppliers()
    };

    const selectBoxOrVial = (event) => {
        setSelectValue(event.target.value)
    };

    const editStock = (stockData) => {
        buttonClickAction = stockData.action;
        setAddStocksHideShow(false);
        setSelectValue(stockData.unitType)
        editData = stockData.clinicId
        setDate(stockData.timeStamp)
        setDateIn(stockData.dateIn)
        reset(stockData)
        setSupplier([stockData.supplier])
    };

    const updateStock = async (fromData) => {
        resetField("supplier")
        resetField("product")
        resetField("productType")
        resetField("units")
        resetField("unitType")
        resetField("cost")
        resetField("comment")
        await saveOrUpdateStock(fromData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Updated successfully",
                })
                getAllStocks()
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Something went wrong",
                })
            })
        setAddStocksHideShow(true)
    }

    const saveStock = async (fromData) => {
        resetField("supplier")
        resetField("product")
        resetField("productType")
        resetField("units")
        resetField("unitType")
        resetField("cost")
        resetField("comment")
        let obj = {
            "clinicId": userDetails.role === "ADMIN" ? clinicId[0] : userDetails.clinicId[0],
            "orgId": userDetails.orgId,
            "active": true,
            "supplier": fromData.supplier,
            "product": fromData.product,
            "productType": fromData.productType,
            "units": fromData.units,
            "unitType": selectValue,
            "dateIn": moment(dateIn).format("YYYY-MM-DDTHH:mm:ss"),
            "cost": fromData.cost,
            "enitedBy": userDetails.userId,
            "comment": fromData.comment,
            "timeStamp": date,
            "batchNo": fromData.batchNo,
            "expDate": moment(expiryDate).format("YYYY-MM-DDTHH:mm:ss"),
            "createdAt": "2023-05-26T12:33:05.000Z",
            "updatedAt": "2023-05-26T12:33:05.000Z"
        }
        await saveOrUpdateStock(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Added successfully",
                })
                getAllStocks()
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Something went wrong",
                })
            })
        setAddStocksHideShow(true)
    };

    return (
        <div className='body '>
            <SwipeDrawer openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
            <Main open={openSideNav}>
                <div className='row'>
                    <div className='col-6'>
                        <span><material.Typography variant="h5">Stocks</material.Typography></span>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addStocks}>Add-Stock</material.Button>
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 500 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Supplier</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Product</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Product Type</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Units</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Box/Vial</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Date in</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Cost</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Batch No</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Expiry Date</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Edited by</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Time stamp</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Comments</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 100 }}>Status</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {stocksData.length ? stocksData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.supplier}</material.TableCell>
                                                        <material.TableCell size='small'>{row.product}</material.TableCell>
                                                        <material.TableCell size='small'>{row.productType}</material.TableCell>
                                                        <material.TableCell size='small'>{row.units}</material.TableCell>
                                                        <material.TableCell size='small'>{row.unitType}</material.TableCell>
                                                        <material.TableCell size='small'>{changeDateFormate(row.dateIn)}</material.TableCell>
                                                        <material.TableCell size='small'>{row.cost}</material.TableCell>
                                                        <material.TableCell size='small'>{row.batchNo}</material.TableCell>
                                                        <material.TableCell size='small'>{changeDateFormate(row.expDate)}</material.TableCell>
                                                        <material.TableCell size='small'>{row.enitedBy}</material.TableCell>
                                                        <material.TableCell size='small'>{changeDateAndTimeFormate(row.timeStamp)}</material.TableCell>
                                                        <material.TableCell size='small'>{row.comment}</material.TableCell>
                                                        <material.TableCell size='small'>{row.active ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>De-active</p>)}</material.TableCell>
                                                        <StyledTableCell size='small'>
                                                            <material.IconButton title='Edit Stocks' aria-label="create" size="large" onClick={() => editStock({ ...row, "action": "edit" })}>
                                                                <material.CreateIcon color='primary' />
                                                            </material.IconButton>
                                                            <material.IconButton title={row.active ? 'Stocks Active' : 'Stocks De-active'} aria-label="create" size="large">
                                                                <material.Switch {...label} checked={row.active} />
                                                            </material.IconButton>
                                                        </StyledTableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                                <div id='add-stock-section' className='mt-2 mb-2 ms-2' hidden={addStocksHideShow}>
                                    <div className='d-flex'>
                                        {editData ? (
                                            <material.TextField
                                                label="Select Clinic"
                                                size="small"
                                                variant='standard'
                                                sx={{ minWidth: 220, p: 1 }}
                                                value={editData}
                                                disabled
                                            />
                                        ) : userDetails.role === "ADMIN" ? (
                                            <material.Autocomplete
                                                id="area"
                                                sx={{ minWidth: 220, p: 1 }}
                                                onChange={selectClinic}
                                                options={allClinic}
                                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Select Clinic"
                                                    {...register("clinicId", { required: true })}
                                                />}
                                            />
                                        ) : null}
                                        {/* <material.TextField
                                        label="Supplier"
                                        size="small"
                                        variant='standard'
                                        sx={{ minWidth: 220, p: 1 }}
                                        {...register("supplier", { required: true })}
                                    /> */}
                                        <material.Autocomplete
                                            id="area"
                                            sx={{ minWidth: 220, p: 1 }}
                                            onChange={selectSupplier}
                                            options={supplier}
                                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Select Supplier"
                                                {...register("supplier", { required: true })}
                                            />}
                                        />
                                        <material.TextField
                                            label="Product"
                                            size="small"
                                            variant='standard'
                                            sx={{ minWidth: 220, p: 1 }}
                                            {...register("product", { required: true })}
                                        />
                                        <material.TextField
                                            label="Product Type"
                                            size="small"
                                            variant='standard'
                                            sx={{ minWidth: 220, p: 1 }}
                                            {...register("productType", { required: true })}
                                        />
                                        <material.TextField
                                            label="Units"
                                            size="small"
                                            variant='standard'
                                            sx={{ minWidth: 220, p: 1 }}
                                            {...register("units", { required: true })}
                                        />
                                        <material.FormControl
                                            sx={{ minWidth: 220, p: 1 }}
                                            variant='standard'
                                        >
                                            <material.InputLabel id="demo-simple-select-helper-label">Box/Vial</material.InputLabel>
                                            <material.Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={selectValue}
                                                onChange={selectBoxOrVial}
                                            >
                                                <material.MenuItem value="BOX">BOX</material.MenuItem>
                                                <material.MenuItem value="VIAL">VIAL</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <material.DesktopDatePicker
                                                label="Date In"
                                                views={["day", "month", "year"]}
                                                value={dateIn}
                                                onChange={(newValue) => {
                                                    setDateIn(newValue)
                                                }}
                                                renderInput={(params) => <material.TextField {...params}
                                                    variant="standard"
                                                    fullWidth
                                                    sx={{ minWidth: 220, p: 1 }}
                                                />}
                                            />
                                        </LocalizationProvider>
                                        <material.TextField
                                            label="Cost"
                                            size="small"
                                            variant='standard'
                                            sx={{ minWidth: 220, p: 1 }}
                                            {...register("cost", { required: true })}
                                        />
                                        <material.TextField
                                            label="Batch Id"
                                            size="small"
                                            variant='standard'
                                            sx={{ minWidth: 220, p: 1 }}
                                            {...register("batchNo", { required: true })}
                                        />
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <material.DesktopDatePicker
                                                views={['year', 'month']}
                                                label="Expiry Date"
                                                value={expiryDate}
                                                onChange={(newValue) => {
                                                    setExpiryDate(newValue)
                                                }}
                                                renderInput={(params) => <material.TextField {...params}
                                                    variant="standard"
                                                    fullWidth
                                                    sx={{ minWidth: 220, p: 1 }}
                                                />}
                                            />
                                        </LocalizationProvider>
                                        <material.TextField
                                            label="Time stamp"
                                            size="small"
                                            variant='standard'
                                            sx={{ minWidth: 220, p: 1 }}
                                            value={changeDateAndTimeFormate(date)}
                                        />
                                        <material.TextField
                                            label="Comments"
                                            size="small"
                                            variant='standard'
                                            sx={{ minWidth: 220, p: 1 }}
                                            {...register("comment", { required: true })}
                                        />
                                        <span>
                                            {buttonClickAction === "edit" ? (
                                                <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(updateStock)} disabled={!isValid}>Update</material.Button>
                                            ) : (
                                                <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveStock)} disabled={!isValid}>Submit</material.Button>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={stocksData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
                <div>
                    <Snackbar
                        openSnackBar={openSnackBar}
                        setOpenSnackBar={setOpenSnackBar}
                    />
                </div>
            </Main>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(Stocks);