import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const getInvoiceTreatmentList = async (treatmentPlanRequestId) => {
    return await axios.get(`${rootApiUrl}/get-invoice-treatment-list/${treatmentPlanRequestId}`);
};

export const getInvoiceByTreatmentPlanRequestId = async (treatmentPlanRequestId) => {
    return await axios.get(`${rootApiUrl}/get-invoice/${treatmentPlanRequestId}`);
};

export const generateInvoice = async (invoiceData) => {
    return await axios.post(`${rootApiUrl}/generate-invoice`, invoiceData);
};

export const generateInvoicePreview = async (invoiceData) => {
    return await axios.post(`${rootApiUrl}/generate-invoice-preview`, invoiceData);
};

export const sendInvoiceByTreatmentPlanRequestId = async (treatmentPlanRequestId) => {
    return await axios.post(`${rootApiUrl}/send-invoice`, treatmentPlanRequestId);
};

export const getInvoiceOtherByInvoiceId = async (invoiceId) => {
    return await axios.get(`${rootApiUrl}/get-invoice-other/${invoiceId}`)
};

export const getInvoiceListOtherByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-invoice-list-other/${clinicId}`)
};

export const generateOtherInvoice = async (invoiceData) => {
    return await axios.post(`${rootApiUrl}/generate-invoice-other`, invoiceData);
};

export const sendInvoiceOtherByInvoiceId = async (invoiceId) => {
    return await axios.post(`${rootApiUrl}/send-invoice-other`, null, { params: { invoiceId } })
};

export const getTotalPayableAmount = async (invoiceData) => {
    return await axios.post(`${rootApiUrl}/get-total-payable-amount-injectable-treatment`, invoiceData);
};