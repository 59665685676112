import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const getAllClinic = async () => {
  return await axios.get(`${rootApiUrl}/get-all-clinic`);
};

export const createClinic = async (clinicInformation) => {
  return await axios.post(`${rootApiUrl}/add-clinic`, clinicInformation);
};

export const updateClinic = async (updateClinicInformation) => {
  return await axios.put(
    `${rootApiUrl}/update-clinic`,
    updateClinicInformation
  );
};

export const activeOrDeactiveClinic = async (clinicId) => {
  return await axios.put(
    `${rootApiUrl}/toggle-clinic-by-clinic-id/${clinicId}`
  );
};

export const deleteClinicById = async (clinicId) => {
  return await axios.put(
    `${rootApiUrl}/delete-clinic-by-clinic-id/${clinicId}`
  );
};

export const getClinicForInjector = async () => {
  return await axios.get(`${rootApiUrl}/get-clinic-details-for-injector`);
};

export const getClinicByIdOpen = async (clinicId) => {
  return await axios.get(`${rootApiUrl}/get-clinic-by-id-open/${clinicId}`);
};

export const checkInjectorAvailabilityOpen = async (payload) => {
  return await axios.put(`${rootApiUrl}/check-injector-open`, payload);
};

export const getClinicRotationDetailsByOrgIdAndClinicId = async (params) => {
  return await axios.get(`${rootApiUrl}/get-clinic-roster`, {
    params,
  });
};

export const saveClinicRotation = async (clinicRotationInformation) => {
  return await axios.post(
    `${rootApiUrl}/save-clinic-roster`,
    clinicRotationInformation
  );
};
