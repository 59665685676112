import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Swipedrawer from '../../drawer/Swipedrawer';
import { material } from '../../../library/material';
import { drawerWidth } from '../../drawer/Swipedrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Navbar from '../../navbar/Navbar';


function ComparedImages(props) {

    const [openSideNav, setOpenSideNav] = React.useState(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [afterImgActiveStep, setAfterImgActiveStep] = React.useState(0);
    const [clickBeforeImg, setClickBeforeImg] = useState(false);
    const [clickAfterImg, setClickAfterImg] = useState(false);
    const [afterImage, setAfterImage] = useState(null);
    const [beforeImage, setBeforeImage] = useState(null);
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const videoRef = useRef(null);
    let treatmentPlanDetails = location.state ? location.state : "";
    // const maxStepsBeforeImg = treatmentPlanDetails?.targetAreaBefore?.length;
    // const maxStepsAfterImg = treatmentPlanDetails?.targetAreaAfter?.length;
    const maxStepsBeforeImg = [];
    const maxStepsAfterImg = [];

localStorage.setItem("menuName", "Compared Images")

    useEffect(() => {
        if (clickAfterImg === false) {
            setAfterImage(null)
        }
    }, [clickAfterImg === false]);

    useEffect(() => {
        if (clickBeforeImg === false) {
            setBeforeImage(null)
        }
    }, [clickBeforeImg === false])

    const goBack = () => {
        navigate('/treatment-queue/treatment-plan-details', { state: { treatmentPlanDetails } })
    };

    const handleNextBefore = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackBefore = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const selectBeforeImg = (img) => {
        setClickBeforeImg(!clickBeforeImg);
        setBeforeImage(img);
    };

    const handleStepChangeAfterImg = (step) => {
        setAfterImgActiveStep(step);
    };

    const handleNextAfter = () => {
        setAfterImgActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackAfter = () => {
        setAfterImgActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const selectAfterImg = (img) => {
        setAfterImage(img);
        setClickAfterImg(!clickAfterImg);
    };

    const goNext = () => {
        navigate("/treatment-queue/treatment-plan-details/compared_images/show_comparison", {
            state: { beforeImage: beforeImage, afterImage: afterImage, treatmentPlanDetails }
        })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar/>
            <div>
                <div className='row'>
                    <div className='col-6'>
                        {/* <span><material.Typography variant="h5">Compared Images</material.Typography></span> */}
                    </div>
                    <div className='col-6'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} className='ms-2' onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                
                {/* <div className='row mt-3 my-3'>
                    <div className='col-lg-6 col-md-12'>
                        <material.Paper sx={{ padding: 2 }}>
                            <span><material.Typography variant="h5">Before Images</material.Typography></span>
                            <material.Box sx={{ maxWidth: 400, margin: "auto", justifyContent: "center", marginTop: 3 }}>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {treatmentPlanDetails?.targetAreaBefore?.map((step, i) => (
                                        <div key={i}>
                                            {Math.abs(activeStep - i) <= 2 ? (
                                                <img
                                                    height={255}
                                                    width="100%"
                                                    style={{ overflow: "hidden", display: "block", cursor: "pointer", border: clickBeforeImg ? "2px solid blue" : "" }}
                                                    src={step}
                                                    onClick={() => selectBeforeImg(step)}
                                                    alt=''
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </SwipeableViews>
                                <material.MobileStepper
                                    steps={maxStepsBeforeImg}
                                    position="static"
                                    activeStep={activeStep}
                                    nextButton={
                                        <material.Button
                                            size="small"
                                            onClick={handleNextBefore}
                                            disabled={activeStep === maxStepsBeforeImg - 1 || clickBeforeImg}
                                        >
                                            Next
                                            {theme.direction === 'rtl' ? (
                                                <material.KeyboardArrowLeft />
                                            ) : (
                                                <material.KeyboardArrowRight />
                                            )}
                                        </material.Button>
                                    }
                                    backButton={
                                        <material.Button size="small" onClick={handleBackBefore} disabled={activeStep === 0 || clickBeforeImg}>
                                            {theme.direction === 'rtl' ? (
                                                <material.KeyboardArrowRight />
                                            ) : (
                                                <material.KeyboardArrowLeft />
                                            )}
                                            Back
                                        </material.Button>
                                    }
                                />
                            </material.Box>
                        </material.Paper>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <material.Paper sx={{ padding: 2, height: 400 }}>
                            <div>
                                <material.Typography variant="h5">After Images</material.Typography>
                            </div>
                            <material.Box sx={{ maxWidth: 400, margin: "auto", justifyContent: "center", marginTop: 3 }}>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={afterImgActiveStep}
                                    onChangeIndex={handleStepChangeAfterImg}
                                    enableMouseEvents
                                >
                                    {treatmentPlanDetails?.targetAreaAfter?.map((step, i) => (
                                        <div key={i}>
                                            {Math.abs(afterImgActiveStep - i) <= 2 ? (
                                                <img
                                                    height={255}
                                                    width="100%"
                                                    style={{ overflow: "hidden", display: "block", cursor: "pointer", border: clickAfterImg ? "2px solid blue" : "" }}
                                                    src={step}
                                                    onClick={() => selectAfterImg(step)}
                                                    alt=''
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </SwipeableViews>
                                <material.MobileStepper
                                    steps={maxStepsAfterImg}
                                    position="static"
                                    activeStep={afterImgActiveStep}
                                    nextButton={
                                        <material.Button
                                            size="small"
                                            onClick={handleNextAfter}
                                            disabled={afterImgActiveStep === maxStepsAfterImg - 1 || clickAfterImg}
                                        >
                                            Next
                                            {theme.direction === 'rtl' ? (
                                                <material.KeyboardArrowLeft />
                                            ) : (
                                                <material.KeyboardArrowRight />
                                            )}
                                        </material.Button>
                                    }
                                    backButton={
                                        <material.Button size="small" onClick={handleBackAfter} disabled={afterImgActiveStep === 0 || clickAfterImg}>
                                            {theme.direction === 'rtl' ? (
                                                <material.KeyboardArrowRight />
                                            ) : (
                                                <material.KeyboardArrowLeft />
                                            )}
                                            Back
                                        </material.Button>
                                    }
                                />
                            </material.Box>
                        </material.Paper>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <span className='float-end'>
                            {(afterImage !== null) && (beforeImage !== null) ? (
                                <material.Button variant="contained" sx={{ textTransform: "none" }} className='ms-2' onClick={goNext} startIcon={<material.SkipNextIcon />}>Next</material.Button>
                            ) : null}
                        </span>
                    </div>
                </div> */}
            </div>
        </div >
    );
}

export default ComparedImages;