import axios from "axios";
import { rootApiUrl } from "../environment/Environment";



export const getOtherStockPurchasedByClinic = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-stock-clinic-other/${clinicId}`)
};

export const getOtherStockPurchasedByOrg = async (orgId) => {
    return await axios.get(`${rootApiUrl}/get-stock-org-other/${orgId}`)
};

export const saveOrUpdateOtherStock = async (stocksData) => {
    return await axios.post(`${rootApiUrl}/save-or-update-stock-other`, stocksData)
};

export const saveOrUpdateOtherStockAdjustment = async (data) => {
    return await axios.post(`${rootApiUrl}/save-stock-adjustment-other`, data);
};

export const getOtherStocksAdjustmentByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-stock-adjustment-list-clinic-other/${clinicId}`);
};

export const getOtherStocksAdjustmentByOrgId = async (orgId) => {
    return await axios.get(`${rootApiUrl}/get-stock-adjustment-list-org-other/${orgId}`);
};

export const getOtherInHandStocksByClinic = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-in-hand-stock-other/${clinicId}`);
};