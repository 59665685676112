import React from 'react'
import { material } from '../../library/material';
import { styled, useTheme } from "@mui/material/styles";
import Navbar from "../navbar/Navbar";
import Sidenav from "../sidenav/Sidenav";
import { CssBaseline } from '@mui/material';
import Sidebar from '../sidenav/Sidebar';


export const drawerWidth = 265;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Swipedrawer(props) {

  const theme = useTheme();
  const { openSideNav, setOpenSideNav } = props;

  const handleDrawer = () => {
    setOpenSideNav((prevState) => !prevState);
  };

  const handleDrawerClose = () => {
    setOpenSideNav(false);
  };

  return (
    <div>
      <material.Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Navbar handleDrawer={handleDrawer} openSideNav={openSideNav} />
        <material.Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={openSideNav}
        >
          <DrawerHeader sx={{ backgroundColor: "#4e73df" }}>
            <material.Typography sx={{ mr: 4, fontWeight: "bold", fontSize: 20 }}>Aestheticon</material.Typography>
            <material.IconButton onClick={handleDrawerClose} title='Close Menu'>
              {theme.direction === 'ltr' ? <material.KeyboardDoubleArrowLeftIcon /> : <material.ChevronRightIcon sx={{ color: "white" }} />}
            </material.IconButton>
          </DrawerHeader>
          <material.Divider />
          <Sidenav openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
        </material.Drawer>
      </material.Box>
    </div>
  )
}
