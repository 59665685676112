import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import ConsentFormAW from '../dashboard/consent-form/ConsentFormAW';
import ConsentFormDF from '../dashboard/consent-form/ConsentFormDF';
import SkinBooster from '../dashboard/consent-form/SkinBooster';
import HyalaseTreatment from '../dashboard/consent-form/HyalaseTreatment';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import KioskNavbar from '../navbar/KioskNavbar';
import LocalAnaesthetic from '../dashboard/consent-form/injectable/LocalAnaesthetic';
import Threads from '../dashboard/consent-form/injectable/Threads';
import ExomideScalp from '../dashboard/consent-form/injectable/ExomideScalp';

function KioskConsentForm(props) {

    const theme = useTheme();
    const location = useLocation();
    const concentData = location.state?.resp;
    const [consentData, setConsentData] = useState(null);
    const [openConsentForm, setOpenConsentForm] = useState({ action: true, patientData: null });

    useEffect(() => {
        setOpenConsentForm({ action: false, patientData: concentData[1].patientData })
        setConsentData(concentData)
    }, []);

    return (
        <material.Grid container spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <KioskNavbar />
            <material.Grid item xs={12} sm={12} md={12} lg={12}>
                {consentData !== null && consentData[0] === true ? (
                    <div>
                        {consentData[1].uniqueTreatmentName.map((treatmentName, k) => (
                            <div key={k}>
                                {treatmentName === "Anti-Wrinkle" ? (
                                    <ConsentFormAW
                                        blankTreatmentData={consentData[1].blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        callForm='secondary-user'
                                    />
                                ) : treatmentName === "Dermal Filler" ? (
                                    <ConsentFormDF
                                        blankTreatmentData={consentData[1].blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        callForm='secondary-user'
                                    />
                                ) : treatmentName === "Skin Booster" ? (
                                    <SkinBooster
                                        blankTreatmentData={consentData[1].blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        callForm='secondary-user'
                                    />
                                ) : treatmentName === "Hyalase Treatment" ? (
                                    <HyalaseTreatment
                                        blankTreatmentData={consentData[1].blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        callForm='secondary-user'
                                    />
                                ) : treatmentName === "Local Anaesthetic" ? (
                                    <LocalAnaesthetic
                                        blankTreatmentData={consentData[1].blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        callForm='secondary-user'
                                        clinicDetails={consentData[1].clinicDetails}
                                    />
                                ) : treatmentName === "Threads" ? (
                                    <Threads
                                        blankTreatmentData={consentData[1].blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        callForm='secondary-user'
                                        clinicDetails={consentData[1].clinicDetails}
                                    />
                                ) : treatmentName === "Exomide Scalp" ? (
                                    <ExomideScalp
                                        blankTreatmentData={consentData[1].blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        callForm='secondary-user'
                                        clinicDetails={consentData[1].clinicDetails}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </div>
                ) : null}
            </material.Grid>
        </material.Grid>
    );
}

export default KioskConsentForm;