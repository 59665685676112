import axios from "axios";
import { rootApiUrl } from "../environment/Environment";



export const getStocksByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-stock-clinic/${clinicId}`);
};

export const getDistinctStocksByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-distinct-stock-clinic/${clinicId}`);
};

export const getStocksByOrgId = async (orgId) => {
    return await axios.get(`${rootApiUrl}/get-stock-org/${orgId}`);
};

export const saveOrUpdateStock = async (data) => {
    return await axios.post(`${rootApiUrl}/save-or-update-stock`, data);
};

export const saveOrUpdateStockAdjustment = async (data) => {
    return await axios.post(`${rootApiUrl}/save-stock-adjustment`, data);
};

export const getStocksAdjustmentByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-stock-adjustment-list-clinic/${clinicId}`);
};

export const getStocksAdjustmentByOrgId = async (orgId) => {
    return await axios.get(`${rootApiUrl}/get-stock-adjustment-list-org/${orgId}`);
};

export const getInHandStocksByClinic = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-in-hand-stock/${clinicId}`);
};