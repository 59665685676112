import React from 'react';
import { material } from '../../library/material';
import { NavLink } from 'react-router-dom';
import "./Sidenav.css"
import { connect } from 'react-redux';

// const roles = ["SUPERADMIN", "ADMIN", "MANAGER", "INJECTOR", "PRESCRIBER"]

function Sidebar(props) {

    const { openSideNav, setOpenSideNav, userData, clinicData } = props;

    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;
    const [openUserManagement, setOpenUserManagement] = React.useState(false);
    const [openClinicManagement, setOpenClinicManagement] = React.useState(false);
    const [openDrugBook, setOpenDrugBook] = React.useState(false);
    const [openMedicine, setOpenMedicine] = React.useState(false);
    const [openTreatmentList, setOpenTreatmentList] = React.useState(false);
    const [openPriseManagement, setOpenPriseManagement] = React.useState(false);
    const [openInvoiceManagement, setOpenInvoiceManagement] = React.useState(false);

    const userManagement = () => {
        setOpenUserManagement(true)
        setOpenInvoiceManagement(false)
        setOpenPriseManagement(false)
        setOpenTreatmentList(false)
        setOpenMedicine(false)
        setOpenDrugBook(false)
        setOpenClinicManagement(false)
    };
    const closeUserManagement = () => {
        setOpenUserManagement(false)
    };
    const clinicManagement = () => {
        setOpenClinicManagement(true)
        setOpenUserManagement(false)
        setOpenInvoiceManagement(false)
        setOpenPriseManagement(false)
        setOpenTreatmentList(false)
        setOpenMedicine(false)
        setOpenDrugBook(false)
    };
    const closeClinicManagement = () => {
        setOpenClinicManagement(false)
    };
    const drugBook = () => {
        setOpenDrugBook(true)
        setOpenClinicManagement(false)
        setOpenUserManagement(false)
        setOpenInvoiceManagement(false)
        setOpenPriseManagement(false)
        setOpenTreatmentList(false)
        setOpenMedicine(false)
    };
    const closeDrugBook = () => {
        setOpenDrugBook(false)
    };
    const medicines = () => {
        setOpenMedicine(true)
        setOpenDrugBook(false)
        setOpenClinicManagement(false)
        setOpenUserManagement(false)
        setOpenInvoiceManagement(false)
        setOpenPriseManagement(false)
        setOpenTreatmentList(false)
    };
    const closeMedicines = () => {
        setOpenMedicine(false)
    };
    const treatmentList = () => {
        setOpenTreatmentList(true)
        setOpenMedicine(false)
        setOpenDrugBook(false)
        setOpenClinicManagement(false)
        setOpenUserManagement(false)
        setOpenInvoiceManagement(false)
        setOpenPriseManagement(false)
    };
    const closeTreatmentList = () => {
        setOpenTreatmentList(true)
    };
    const priceManagement = () => {
        setOpenPriseManagement(true)
        setOpenTreatmentList(false)
        setOpenMedicine(false)
        setOpenDrugBook(false)
        setOpenClinicManagement(false)
        setOpenUserManagement(false)
        setOpenInvoiceManagement(false)
    };
    const closePriceManagement = () => {
        setOpenPriseManagement(false)
    };
    const invoiceManagement = () => {
        setOpenInvoiceManagement(true)
        setOpenPriseManagement(false)
        setOpenTreatmentList(false)
        setOpenMedicine(false)
        setOpenDrugBook(false)
        setOpenClinicManagement(false)
        setOpenUserManagement(false)
    };
    const closeInvoiceManagement = () => {
        setOpenInvoiceManagement(false)
    };

    return (
        <div>
            {/* {userDetails.role.map((role, i) => ( */}
            <div>
                {userDetails?.menu?.map((item, i) => (
                    <div key={i}>
                        {/* {console.log(!item.hasOwnProperty("parent"))} */}
                        {!item.parent ? (
                            <div className='mb-2'>
                                {item.children.map((child, j) => (
                                    <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to={child.route} key={j}>
                                        <material.ListItemButton>
                                            <material.CalendarMonthIcon sx={{ fontSize: 25}} />{openSideNav === true ? (<span className='ms-3' style={{ fontSize: "15px" }}>{child.child}</span>) : null}
                                        </material.ListItemButton>
                                    </NavLink>
                                ))}
                            </div>
                        ) : (
                            <>
                                {item.parent === "Clinic Management" ? (
                                    <div style={{ marginTop: -20 }}>
                                        <material.List>
                                            <material.ListItemButton>
                                                <material.ListItemIcon>
                                                    <material.MedicalServicesOutlinedIcon />
                                                </material.ListItemIcon>
                                                <material.ListItemText primary={item.parent} sx={{ ml: -2 }} />
                                                {openClinicManagement ? <material.ExpandLess onClick={closeClinicManagement} /> : <material.ExpandMore onClick={clinicManagement} />}
                                            </material.ListItemButton>
                                            <material.Collapse in={openClinicManagement} timeout="auto" unmountOnExit>
                                                <material.List component="div" disablePadding>
                                                    {item.children.map((child, k) => (
                                                        <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to={child.route} key={k}>
                                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                                {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>{child.child}</span>) : null}
                                                            </material.ListItemButton>
                                                        </NavLink>
                                                    ))}
                                                </material.List>
                                            </material.Collapse>
                                        </material.List>
                                    </div>
                                ) : item.parent === "Drug Book" ? (
                                    <div style={{ marginTop: -20 }}>
                                        <material.List>
                                            <material.ListItemButton>
                                                <material.ListItemIcon>
                                                    <material.BookIcon />
                                                </material.ListItemIcon>
                                                <material.ListItemText primary={item.parent} sx={{ ml: -2 }} />
                                                {openDrugBook ? <material.ExpandLess onClick={closeDrugBook} /> : <material.ExpandMore onClick={drugBook} />}
                                            </material.ListItemButton>
                                            <material.Collapse in={openDrugBook} timeout="auto" unmountOnExit>
                                                <material.List component="div" disablePadding>
                                                    {item.children.map((child, k) => (
                                                        <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to={child.route} key={k}>
                                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                                {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>{child.child}</span>) : null}
                                                            </material.ListItemButton>
                                                        </NavLink>
                                                    ))}
                                                </material.List>
                                            </material.Collapse>
                                        </material.List>
                                    </div>
                                ) : item.parent === "General Purpose Medicine" ? (
                                    <div style={{ marginTop: -20 }}>
                                        <material.List>
                                            <material.ListItemButton>
                                                <material.ListItemIcon>
                                                    <material.MedicationIcon />
                                                </material.ListItemIcon>
                                                <material.ListItemText primary={item.parent} sx={{ ml: -2 }} />
                                                {openMedicine ? <material.ExpandLess onClick={closeMedicines} /> : <material.ExpandMore onClick={medicines} />}
                                            </material.ListItemButton>
                                            <material.Collapse in={openMedicine} timeout="auto" unmountOnExit>
                                                <material.List component="div" disablePadding>
                                                    {item.children.map((child, k) => (
                                                        <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to={child.route} key={k}>
                                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                                {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>{child.child}</span>) : null}
                                                            </material.ListItemButton>
                                                        </NavLink>
                                                    ))}
                                                </material.List>
                                            </material.Collapse>
                                        </material.List>
                                    </div>
                                ) : item.parent === "Price Management" ? (
                                    <div style={{ marginTop: -20 }}>
                                        <material.List>
                                            <material.ListItemButton>
                                                <material.ListItemIcon>
                                                    <material.AttachMoneyIcon />
                                                </material.ListItemIcon>
                                                <material.ListItemText primary={item.parent} sx={{ ml: -2 }} />
                                                {openPriseManagement ? <material.ExpandLess onClick={closePriceManagement} /> : <material.ExpandMore onClick={priceManagement} />}
                                            </material.ListItemButton>
                                            <material.Collapse in={openPriseManagement} timeout="auto" unmountOnExit>
                                                <material.List component="div" disablePadding>
                                                    {item.children.map((child, k) => (
                                                        <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to={child.route} key={k}>
                                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                                {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>{child.child}</span>) : null}
                                                            </material.ListItemButton>
                                                        </NavLink>
                                                    ))}
                                                </material.List>
                                            </material.Collapse>
                                        </material.List>
                                    </div>
                                ) : item.parent === "Invoice Management" ? (
                                    <div style={{ marginTop: -20 }}>
                                        <material.List>
                                            <material.ListItemButton>
                                                <material.ListItemIcon>
                                                    <material.ReceiptIcon />
                                                </material.ListItemIcon>
                                                <material.ListItemText primary={item.parent} sx={{ ml: -2 }} />
                                                {openInvoiceManagement ? <material.ExpandLess onClick={closeInvoiceManagement} /> : <material.ExpandMore onClick={invoiceManagement} />}
                                            </material.ListItemButton>
                                            <material.Collapse in={openInvoiceManagement} timeout="auto" unmountOnExit>
                                                <material.List component="div" disablePadding>
                                                    {item.children.map((child, k) => (
                                                        <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to={child.route} key={k}>
                                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                                {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>{child.child}</span>) : null}
                                                            </material.ListItemButton>
                                                        </NavLink>
                                                    ))}
                                                </material.List>
                                            </material.Collapse>
                                        </material.List>
                                    </div>
                                ) : item.parent === "User Management" ? (
                                    <div style={{ marginTop: -20 }}>
                                        <material.List>
                                            <material.ListItemButton>
                                                <material.ListItemIcon>
                                                    <material.GroupIcon />
                                                </material.ListItemIcon>
                                                <material.ListItemText primary={item.parent} sx={{ ml: -2 }} />
                                                {openUserManagement ? <material.ExpandLess onClick={closeUserManagement} /> : <material.ExpandMore onClick={userManagement} />}
                                            </material.ListItemButton>
                                            <material.Collapse in={openUserManagement} timeout="auto" unmountOnExit>
                                                <material.List component="div" disablePadding>
                                                    {item.children.map((child, k) => (
                                                        <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to={child.route} key={k}>
                                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                                {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>{child.child}</span>) : null}
                                                            </material.ListItemButton>
                                                        </NavLink>
                                                    ))}
                                                </material.List>
                                            </material.Collapse>
                                        </material.List>
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>
                ))}
            </div>
            {/* ))} */}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(Sidebar);

// <div key={i}>
//     {role === "SUPERADMIN" ? (
//         <>
//             <div>
//                 <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/organisation">
//                     <material.ApartmentIcon sx={{ fontSize: 25, ml: 2 }} />{openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Organisation</span>) : null}
//                 </NavLink>
//             </div>
//             <div className='mt-3'>
//                 <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/user_management" >
//                     <material.ManageAccountsIcon sx={{ fontSize: 25, ml: 2 }} /> {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>User Management</span>) : null}
//                 </NavLink>
//             </div>
//         </>
//     ) : role === "ADMIN" ? (
//         <>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.MedicalServicesOutlinedIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Clinic Management" sx={{ ml: -2 }} />
//                         {openClinicManagement ? <material.ExpandLess onClick={closeClinicManagement} /> : <material.ExpandMore onClick={clinicManagement} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openClinicManagement} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/clinic_management/clinic_list" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Clinic List</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/clinic_management/document-management" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Document Management</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.GroupIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="User Management" sx={{ ml: -2 }} />
//                         {openUserManagement ? <material.ExpandLess onClick={closeUserManagement} /> : <material.ExpandMore onClick={userManagement} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openUserManagement} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/user_management/users_list">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Users List</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/user_management/vendor_management" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Vendor List</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/user_management/patient-list">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Patient List</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/user_management/injector-list">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Injector List</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.AttachMoneyIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Price Management" sx={{ ml: -2 }} />
//                         {openPriseManagement ? <material.ExpandLess onClick={closePriceManagement} /> : <material.ExpandMore onClick={priceManagement} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openPriseManagement} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/price_management/cosmetic_injectables" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Cosmetic Injectables</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             {/* <material.ListItemButton sx={{ pl: 6 }}>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/price_management/skin_treatment" >
//                                 {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Skin Treatment</span>) : null}
//                             </NavLink>
//                         </material.ListItemButton> */}
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/price_management/other_stock" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Other Stock</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.FormatListBulletedIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Treatment List" sx={{ ml: -2 }} />
//                         {openTreatmentList ? <material.ExpandLess onClick={closeTreatmentList} /> : <material.ExpandMore onClick={treatmentList} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openTreatmentList} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/tretment_list/cosmetic_injectables" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Cosmetic Injectables</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             {/* <material.ListItemButton sx={{ pl: 6 }}>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/tretment_list/skin_treatment">
//                                 {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Skin Treatments</span>) : null}
//                             </NavLink>
//                         </material.ListItemButton> */}
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.BookIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Drug Book" sx={{ ml: -2 }} />
//                         {openDrugBook ? <material.ExpandLess onClick={closeDrugBook} /> : <material.ExpandMore onClick={drugBook} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openDrugBook} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/drug_book/stock_purchased">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock  Purchased</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/drug_book/stock_adjustment">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock  Adjustment </span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             {/* <material.ListItemButton sx={{ pl: 6 }}>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/drug_book/stock_used">
//                                 {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock  Used</span>) : null}
//                             </NavLink>
//                         </material.ListItemButton> */}
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/drug_book/stock_insight">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stocks Insight</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -25 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.MedicationIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="General Purpose Medicines" sx={{ ml: -2 }} />
//                         {openMedicine ? <material.ExpandLess onClick={closeMedicines} /> : <material.ExpandMore onClick={medicines} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openMedicine} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/other_stock/stock_purchased">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock Purchased</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/other_stock/stock_adjustment">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock Adjustment</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             {/* <material.ListItemButton sx={{ pl: 6 }}>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/other_stock/stock_sold">
//                                 {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock Sold</span>) : null}
//                             </NavLink>
//                         </material.ListItemButton> */}
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/other_stock/stock_insight">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stocks Insight</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.ReceiptIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Invoice Management" sx={{ ml: -2 }} />
//                         {openInvoiceManagement ? <material.ExpandLess onClick={closeInvoiceManagement} /> : <material.ExpandMore onClick={invoiceManagement} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openInvoiceManagement} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/invoice_management/treatment_invoice" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Treatment Invoice</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/invoice_management/other_invoice">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Other Invoice</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//         </>
//     ) : role === "PRESCRIBER" ? (
//         <div>
//             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/waiting-room">
//                 <material.HourglassTopIcon sx={{ fontSize: 25, ml: 2 }} />{openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Waiting Room</span>) : null}
//             </NavLink>
//         </div>
//     ) : role === "INJECTOR" ? (
//         <>
//             <div className='mt-3' hidden={clinicDetails.clinicName}>
//                 <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/clinic" >
//                     <material.MedicalServicesOutlinedIcon sx={{ fontSize: 25, ml: 2 }} /> {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Clinic</span>) : null}
//                 </NavLink>
//             </div>
//             <div hidden={!clinicDetails.clinicName}>
//                 <div className='mt-3'>
//                     <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/patient-list">
//                         <material.FormatListBulletedIcon sx={{ fontSize: 25, ml: 2 }} />{openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Patients List</span>) : null}
//                     </NavLink>
//                 </div>
//                 <div className='mt-3'>
//                     <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/appointment-schedule">
//                         <material.CalendarMonthIcon sx={{ fontSize: 25, ml: 2 }} />{openSideNav === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Appointment Schedule</span>) : null}
//                     </NavLink>
//                 </div>
//                 <div className='mt-3'>
//                     <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/approval-waiting-quere">
//                         <material.HourglassTopIcon sx={{ fontSize: 25, ml: 2 }} />{openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Treatment Quere</span>) : null}
//                     </NavLink>
//                 </div>
//                 <div className='mt-3'>
//                     <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/invoice_management">
//                         <material.ReceiptIcon sx={{ fontSize: 25, ml: 2 }} /><span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Invoice Management</span>
//                     </NavLink>
//                 </div>
//             </div>
//         </>
//     ) : role === "MANAGER" ? (
//         <>
//             <div className='mt-3'>
//                 <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/appointment-schedule">
//                     <material.CalendarMonthIcon sx={{ fontSize: 25, ml: 2 }} />{openSideNav === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Appointment Schedule</span>) : null}
//                 </NavLink>
//             </div>
//             <div className=''>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.GroupIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="User Management" sx={{ ml: -2 }} />
//                         {openUserManagement ? <material.ExpandLess onClick={closeUserManagement} /> : <material.ExpandMore onClick={userManagement} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openUserManagement} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/user_management/patient-list">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Patient List</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/user_management/injector-list">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Injector List</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/user_management/vendor_management" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Vendor List</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.AttachMoneyIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Price Management" sx={{ ml: -2 }} />
//                         {openPriseManagement ? <material.ExpandLess onClick={closePriceManagement} /> : <material.ExpandMore onClick={priceManagement} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openPriseManagement} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/price_management/cosmetic_injectables" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Cosmetic Injectables</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             {/* <material.ListItemButton sx={{ pl: 6 }}>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/price_management/skin_treatment" >
//                                 {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Skin Treatment</span>) : null}
//                             </NavLink>
//                         </material.ListItemButton> */}
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/price_management/other_stock" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Other Stock</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.FormatListBulletedIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Treatment List" sx={{ ml: -2 }} />
//                         {openTreatmentList ? <material.ExpandLess onClick={closeTreatmentList} /> : <material.ExpandMore onClick={treatmentList} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openTreatmentList} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/tretment_list/cosmetic_injectables" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Cosmetic Injectables</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             {/* <material.ListItemButton sx={{ pl: 6 }}>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/tretment_list/skin_treatment">
//                                 {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Skin Treatments</span>) : null}
//                             </NavLink>
//                         </material.ListItemButton> */}
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.MedicalServicesOutlinedIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Clinic Management" sx={{ ml: -2 }} />
//                         {openClinicManagement ? <material.ExpandLess onClick={closeClinicManagement} /> : <material.ExpandMore onClick={clinicManagement} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openClinicManagement} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/clinic_management/document-management" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Document Management</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/clinic_management/configure-room" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Configure Room</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/clinic_management/configure-machines" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Configure Machines</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.BookIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Drug Book" sx={{ ml: -2 }} />
//                         {openDrugBook ? <material.ExpandLess onClick={closeDrugBook} /> : <material.ExpandMore onClick={drugBook} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openDrugBook} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/drug_book/stock_purchased">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock  Purchased</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/drug_book/stock_adjustment">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock  Adjustment </span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             {/* <material.ListItemButton sx={{ pl: 6 }}>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/drug_book/stock_used">
//                                 {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock  Used</span>) : null}
//                             </NavLink>
//                         </material.ListItemButton> */}
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/drug_book/stock_insight">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stocks Insight</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -25 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.MedicationIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="General Purpose Medicines" sx={{ ml: -2 }} />
//                         {openMedicine ? <material.ExpandLess onClick={closeMedicines} /> : <material.ExpandMore onClick={medicines} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openMedicine} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/other_stock/stock_purchased">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock Purchased</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/other_stock/stock_adjustment">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock Adjustment</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             {/* <material.ListItemButton sx={{ pl: 6 }}>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/other_stock/stock_sold">
//                                 {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock Sold</span>) : null}
//                             </NavLink>
//                         </material.ListItemButton> */}
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/other_stock/stock_insight">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Stock Insight</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//             <div style={{ marginTop: -20 }}>
//                 <material.List>
//                     <material.ListItemButton>
//                         <material.ListItemIcon>
//                             <material.ReceiptIcon />
//                         </material.ListItemIcon>
//                         <material.ListItemText primary="Invoice Management" sx={{ ml: -2 }} />
//                         {openInvoiceManagement ? <material.ExpandLess onClick={closeInvoiceManagement} /> : <material.ExpandMore onClick={invoiceManagement} />}
//                     </material.ListItemButton>
//                     <material.Collapse in={openInvoiceManagement} timeout="auto" unmountOnExit>
//                         <material.List component="div" disablePadding>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/invoice_management/treatment_invoice" >
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Treatment Invoice</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                             <NavLink className={(navData) => (navData.isActive ? 'sideNav-text' : 'text-content')} to="/invoice_management/other_invoice">
//                                 <material.ListItemButton sx={{ pl: 6 }}>
//                                     {openSideNav === true ? (<span className='ms-2' style={{ fontSize: "15px", fontWeight: "bold" }}>Other Invoice</span>) : null}
//                                 </material.ListItemButton>
//                             </NavLink>
//                         </material.List>
//                     </material.Collapse>
//                 </material.List>
//             </div>
//         </>
//     ) : null}
// </div>