import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import { material } from '../../library/material';

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT
};
const uploadImage = [];

function AddSupportingDoc(props) {

    const { setAddSupportingDoc, addSupportingDoc, viewDoc, setViewDoc } = props;
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);
    const [isWebcamActive, setIsWebcamActive] = useState(true);
    const [stream, setStream] = useState(null);
    const [image, setImage] = useState("");
    const videoRef = useRef(null);

    useEffect(() => {
        if (addSupportingDoc?.action) {
            openCamera()
        }
    }, [addSupportingDoc?.action === true]);

    async function openCamera() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(stream);
            videoRef.current.srcObject = stream;
        } catch (error) {
            console.error(error);
        }
        setIsWebcamActive(true)
    };

    function closeCamera() {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);
        }
        setIsWebcamActive(false)
    };

    function capturePhoto() {
        const imageSrc = videoRef.current.getScreenshot();
        uploadPhoto(imageSrc);
        closeCamera();
    };

    async function uploadPhoto(photo) {
        setImage(photo)
        uploadImage.push(photo)
    };

    const imageUpload = () => {
        setAddSupportingDoc({ action: false, data: uploadImage })
    };

    const close = () => {
        setViewDoc({ action: false })
    };


    return (
        <div>
            <material.Dialog maxWidth="md" fullWidth hideBackdrop open={addSupportingDoc?.action || viewDoc?.action}>
                {viewDoc?.action ? (
                    <material.DialogTitle>View Supporting Document</material.DialogTitle>
                ) : (
                    <material.DialogTitle>Add Supporting Document</material.DialogTitle>
                )}
                <material.DialogContent>
                    {viewDoc?.action ? (
                        <span className='d-flex justify-content-center text-danger'>
                            {(viewDoc.data === "" || viewDoc.data === null) ? (
                                <span>Supporting document not present</span>
                            ) : (
                                <img
                                    src={viewDoc.data}
                                    height={450}
                                    width="100%"
                                />
                            )}
                        </span>
                    ) : (
                        <div className='border'>
                            {image !== "" ? (
                                <>
                                    <span>
                                        <img
                                            src={image}
                                            height={450}
                                            width="100%"
                                        />
                                    </span>
                                </>
                            ) : (
                                <span>
                                    < Webcam
                                        height={450}
                                        width="100%"
                                        screenshotFormat="image/jpeg"
                                        ref={videoRef}
                                        videoConstraints={{
                                            ...videoConstraints,
                                            facingMode
                                        }}
                                    />
                                    <span className='d-flex justify-content-center mt-2 mb-2'>
                                        <material.Button sx={{ textTransform: "none" }} size='small' variant="contained" onClick={capturePhoto} startIcon={<material.CameraAltIcon />} >Take photo</material.Button>
                                    </span>
                                </span>
                            )}
                        </div>
                    )}
                </material.DialogContent>
                <material.DialogActions sx={{ mb: 2 }}>
                    {viewDoc?.action ? (
                        <material.Button onClick={close} variant="contained" sx={{ textTransform: "none", marginRight: 3 }} color='error' startIcon={<material.CloseIcon />}>Close</material.Button>
                    ) : (
                        <material.Button onClick={imageUpload} variant="contained" sx={{ textTransform: "none", marginRight: 3 }} disabled={image === ""} startIcon={<material.UploadIcon />}>Upload</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
        </div >
    );
}

export default AddSupportingDoc;