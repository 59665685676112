import React from 'react';
import { styled } from '@mui/material/styles';
import Swipedrawer from '../../drawer/Swipedrawer';
import { material } from '../../../library/material';
import { drawerWidth } from '../../drawer/Swipedrawer';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

function OtherStockUsed(props) {

    const [openSideNav, setOpenSideNav] = React.useState(true);

    return (
        <div className='body'>
            <Swipedrawer openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
            <Main open={openSideNav}>
                <div className='row'>
                    <div className='col-6'>
                        <span><material.Typography variant="h5">Stock Used</material.Typography></span>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }}>Add-Stock</material.Button>
                        </span>
                    </div>
                </div>
            </Main>
        </div>
    );
}

export default OtherStockUsed;