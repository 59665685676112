import React from 'react';
import { material } from '../../library/material';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getBddFormRefData, saveBddFormData } from '../../services/BddFormService';
import Snackbar from '../toastrmessage/Snackbar';
import dayjs from 'dayjs';


function AddBDDFormByInviteCode(props) {

    const { register, handleSubmit, reset, resetField, formState: { errors } } = useForm({
        mode: "onTouched"
    });

    const [questions, setquestions] = useState({
        q1: "",
        q2: "",
        q3: "",
        q4: "",
        q5: ""
    });
    const [date, setDate] = useState(dayjs(new Date()));
    const [isEnableFormField, setisEnableFormField] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const selectQuestions = (qNumber, value) => {
        setquestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };

    const checkInviteCode = async (event) => {
        let inviteCode = event.target.value;
        await getBddFormRefData(inviteCode)
            .then((resp) => {
                reset(resp.data)
                setisEnableFormField(false)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const isValid = Object.values(questions).some(value => value === "");

    const saveBddForm = async (formData) => {
        let obj = {
            ...formData,
            ...questions
        }
        await saveBddFormData(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "BDD form save successfully",
                })
                setquestions({
                    q1: "",
                    q2: "",
                    q3: "",
                    q4: "",
                    q5: ""
                })
                resetField("inviteCode")
                resetField("clinicName")
                resetField("patientName")
                resetField("dateOfBirth")
                resetField("userEmail")
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div className='content mt-3 p-4'>
            <material.Paper elevation={7} sx={{ p: 4, width: "75%" }}>
                <span><material.Typography variant="h5">BDD Form</material.Typography></span>
                <hr />
                <div className='row'>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.inviteCode?.type === "required"}
                            {...register("inviteCode", { required: true })}
                            label="Enter Invite Code"
                            id="standard-error"
                            variant="standard"
                            type="text"
                            onChange={checkInviteCode}
                            size="small"
                            fullWidth
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <material.TextField
                            error={errors.clinicName?.type === "required"}
                            {...register("clinicName", { required: true })}
                            label="Clinic Name"
                            id="standard-error"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            disabled={isEnableFormField}
                            readOnly
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <material.TextField
                            error={errors.patientName?.type === "required"}
                            {...register("patientName", { required: true })}
                            label="Patient Name"
                            id="standard-error"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            disabled={isEnableFormField}
                            readOnly
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <material.TextField
                            error={errors.dateOfBirth?.type === "required"}
                            {...register("dateOfBirth", { required: true })}
                            label="Date of Birth"
                            id="standard-error"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            disabled={isEnableFormField}
                            readOnly
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <material.TextField
                            error={errors.userEmail?.type === "required"}
                            {...register("userEmail", { required: true })}
                            label="Email"
                            id="standard-error"
                            variant="standard"
                            type="email"
                            size="small"
                            fullWidth
                            disabled={isEnableFormField}
                            readOnly
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <material.DateTimePicker
                                label="Time Stamp"
                                format='DD-MM-YYYY'
                                readOnly
                                value={date}
                                onChange={(newValue) => {
                                    setDate(newValue)
                                }}
                                slotProps={{ textField: { variant: 'standard' } }}
                                sx={{ width: "100%" }}
                                disabled={isEnableFormField}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className='mt-5'>
                    <material.Typography>
                        The below questions will assess underlying psychological conditions such as (BDD) body dysmorphic disorder.
                        Please speak to your practitioner if you require further information regarding BDD.
                    </material.Typography>
                    <material.Typography sx={{ mt: 2 }}>
                        If there are indications of significant underlying psychological issues which may make
                        you an unsuitable candidate for the cosmetic procedure, you may be referred for evaluation to a
                        psychologist, psychiatrist or general practitioner who works independently of the Healthcare professional who will perform the procedure or the medical practitioner who will prescribe the cosmetic injectable.
                    </material.Typography>
                    <div className='row'>
                        <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                            1- Are you worried about the way you look, and do you think about your appearance a lot and wish you could think about it less
                        </material.Typography>
                        <span className='col-lg-3 col-md-4 col-sm-4'>
                            <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                <material.RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={event => selectQuestions("q1", event.target.value)}
                                    value={questions.q1}
                                >
                                    <material.FormControlLabel disabled={isEnableFormField} value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                    <material.FormControlLabel disabled={isEnableFormField} value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                </material.RadioGroup>
                            </material.FormControl>
                        </span>
                    </div>
                    <div className='row'>
                        <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                            2- How has it, the way you feel about it, affected your daily life activities, such as school, work, or other activities?
                        </material.Typography>
                        <span className='col-lg-3 col-md-4 col-sm-4'>
                            <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                <material.RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={event => selectQuestions("q2", event.target.value)}
                                    value={questions.q2}
                                >
                                    <material.FormControlLabel disabled={isEnableFormField} value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                    <material.FormControlLabel disabled={isEnableFormField} value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                </material.RadioGroup>
                            </material.FormControl>
                        </span>
                    </div>
                    <div className='row'>
                        <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                            3- Are there things you avoid because of how you look, such as gatherings, going out etc
                        </material.Typography>
                        <span className='col-lg-3 col-md-4 col-sm-4'>
                            <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                <material.RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={event => selectQuestions("q3", event.target.value)}
                                    value={questions.q3}
                                >
                                    <material.FormControlLabel disabled={isEnableFormField} value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                    <material.FormControlLabel disabled={isEnableFormField} value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                </material.RadioGroup>
                            </material.FormControl>
                        </span>
                    </div>
                    <div className='row'>
                        <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                            Do you spend more than 2-3 hours a day thinking about how you look
                        </material.Typography>
                        <span className='col-lg-3 col-md-4 col-sm-4'>
                            <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                <material.RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={event => selectQuestions("q4", event.target.value)}
                                    value={questions.q4}
                                >
                                    <material.FormControlLabel disabled={isEnableFormField} value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                    <material.FormControlLabel disabled={isEnableFormField} value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                </material.RadioGroup>
                            </material.FormControl>
                        </span>
                    </div>
                    <div className='row'>
                        <material.Typography className='col-lg-9 col-md-8 col-sm-8'>
                            5- If you could not have a cosmetic treatment today, would this affect your life adversely?
                        </material.Typography>
                        <span className='col-lg-3 col-md-4 col-sm-4'>
                            <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                                <material.RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={event => selectQuestions("q5", event.target.value)}
                                    value={questions.q5}
                                >
                                    <material.FormControlLabel disabled={isEnableFormField} value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                    <material.FormControlLabel disabled={isEnableFormField} value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                </material.RadioGroup>
                            </material.FormControl>
                        </span>
                        <hr />
                        <div className='col-12 mt-3'>
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveBddForm)} disabled={isValid}>Save</material.Button>
                            </span>
                        </div>
                    </div>
                </div>
            </material.Paper>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddBDDFormByInviteCode;