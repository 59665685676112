import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { styled } from '@mui/material/styles';
import AddRoom from '../../dialog/AddRoom';
import { getRoomList } from '../../../services/ConfigureService';
import { connect } from 'react-redux';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Navbar from '../../navbar/Navbar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function ConfigureRoom(props) {

    const { userData, clinicData } = props;
    const [openAddRoom, setOpenAddRoom] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [roomData, setRoomData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;

    localStorage.setItem("menuName", "Configure Room");

    useEffect(() => {
        getRoomListByClinicId()
    }, []);

    const getRoomListByClinicId = async () => {
        await getRoomList(clinicDetails.clinicId)
            .then((resp) => {
                setisLoading(false)
                setRoomData(resp.data)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const addRoom = () => {
        setOpenAddRoom(true)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addRoom} startIcon={<material.AddIcon />}> Add-Room</material.Button>
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 500 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Room Name</StyledTableCell>
                                            <StyledTableCell>Room No.</StyledTableCell>
                                            <StyledTableCell>Description</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {roomData.length ? roomData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.roomName}</material.TableCell>
                                                        <material.TableCell size='small'>{row.roomNumber}</material.TableCell>
                                                        <material.TableCell size='small'>{row.roomDescription}</material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={roomData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
            </div>
            <AddRoom
                openAddRoom={openAddRoom}
                setOpenAddRoom={setOpenAddRoom}
                getRoomListByClinicId={getRoomListByClinicId}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ConfigureRoom);