import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { saveEmailConfigData, savePhoneConfig, savePrePopulateEmailSubject } from '../../../services/ClinicConfigService';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function ClinicConfigDialog(props) {

    const { openClinicConfigDialog, setOpenClinicConfigDialog, clinicData, userData, getPrePopulateEmail, getEmailConfigData, getSmsConfigData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const userDetails = userData.authReducer.data;

    return (
        <div>
            <material.Dialog open={openClinicConfigDialog.action} hideBackdrop fullWidth maxWidth="md">
                <material.DialogTitle>
                    {openClinicConfigDialog.callFrom === "email-config" ? "Add Email Config" : openClinicConfigDialog.callFrom === "sms-config" ? "Add SMS Config" : "Add Pre-populate Email Subject"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openClinicConfigDialog.callFrom === "email-config" ? (
                        <EmailConfig
                            setOpenClinicConfigDialog={setOpenClinicConfigDialog}
                            clinicDetails={clinicDetails}
                            userDetails={userDetails}
                            openClinicConfigDialog={openClinicConfigDialog}
                            getEmailConfigData={getEmailConfigData}
                        />
                    ) : openClinicConfigDialog.callFrom === "sms-config" ? (
                        <SmsConfig
                            setOpenClinicConfigDialog={setOpenClinicConfigDialog}
                            clinicDetails={clinicDetails}
                            userDetails={userDetails}
                            openClinicConfigDialog={openClinicConfigDialog}
                            getSmsConfigData={getSmsConfigData}
                        />
                    ) : (
                        <PrePopulateEmail
                            setOpenClinicConfigDialog={setOpenClinicConfigDialog}
                            clinicDetails={clinicDetails}
                            userDetails={userDetails}
                            openClinicConfigDialog={openClinicConfigDialog}
                            getPrePopulateEmail={getPrePopulateEmail}
                        />
                    )}
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

const EmailConfig = (props) => {

    const { setOpenClinicConfigDialog, clinicDetails, userDetails, openClinicConfigDialog, getEmailConfigData } = props;

    const { register, handleSubmit, reset, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [selectEmailService, setSelectEmailService] = useState("");
    const [selectPurpose, setSelectPurpose] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isVisible, setIsVisible] = useState(false);
    const [type, setType] = useState("password");

    useEffect(() => {
        if (openClinicConfigDialog.action && openClinicConfigDialog.tableData) {
            reset(openClinicConfigDialog.tableData);
            setSelectEmailService(openClinicConfigDialog.tableData.email_service);
            setSelectPurpose(openClinicConfigDialog.tableData.purpose)
        }
    }, [openClinicConfigDialog.action === true]);

    function showPassword() {
        setIsVisible(!isVisible)
        setType("text")
    };

    function hidePassword() {
        setIsVisible(!isVisible)
        setType("password")
    };

    const handleChange = (event) => {
        setSelectEmailService(event.target.value);
    };

    const handleChangePurpose = (event) => {
        setSelectPurpose(event.target.value)
    };

    const handleClose = () => {
        reset({ email_address: "", email_app_password: "", email_service: "", purpose: "", displayName: "" });
        setSelectPurpose("");
        setSelectEmailService("");
        setOpenClinicConfigDialog({ action: false, callFrom: null, tableData: null });
    };

    const saveEmailConfig = (data) => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            ...data,
        }
        saveEmailConfigData(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getEmailConfigData();
                reset({ email_address: "", email_app_password: "", email_service: "", purpose: "", displayName: "" });
                setSelectPurpose("");
                setSelectEmailService("");
                setOpenClinicConfigDialog({ action: false, callFrom: null, tableData: null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <material.Grid spacing={2}>
            <div>
                <material.TextField
                    error={errors.email_address?.type === "required"}
                    required
                    label="Email ID"
                    variant="standard"
                    type="email"
                    size="small"
                    fullWidth
                    {...register("email_address", { require: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                />
                <p className='form-text text-danger'>{errors.email_address?.type === "required" && 'This field is required'}</p>
                {(errors?.email?.type === "pattern") && (
                    <p className='text-danger'>This is not a valid Email</p>
                )}
            </div>
            <div>
                <material.TextField
                    error={errors.password?.type === "required"}
                    required
                    label="Password"
                    variant="standard"
                    type={type}
                    size="small"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <material.InputAdornment position="end">
                                {isVisible === false ? (<material.VisibilityIcon onClick={showPassword} sx={{ cursor: "pointer" }} />) : (<material.VisibilityOffIcon onClick={hidePassword} sx={{ cursor: "pointer" }} />)}
                            </material.InputAdornment>
                        ),
                    }}
                    {...register("email_app_password", { required: true })}
                />
                <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
            </div>
            <div>
                <material.FormControl variant="standard" required fullWidth>
                    <material.InputLabel id="demo-simple-select-standard-label">Email Service</material.InputLabel>
                    <material.Select
                        {...register("email_service", { required: true })}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectEmailService}
                        onChange={handleChange}
                    >
                        <material.MenuItem value="gmail">gmail</material.MenuItem>
                    </material.Select>
                </material.FormControl>
            </div>
            <div>
                <material.FormControl variant="standard" required fullWidth>
                    <material.InputLabel id="demo-simple-select-standard-label">Purpose</material.InputLabel>
                    <material.Select
                        {...register("purpose", { required: true })}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectPurpose}
                        onChange={handleChangePurpose}
                    >
                        <material.MenuItem value="CAMPAIGN">CAMPAIGN</material.MenuItem>
                        <material.MenuItem value="GENERAL">GENERAL</material.MenuItem>
                    </material.Select>
                </material.FormControl>
            </div>
            <div>
                <material.TextField
                    error={errors.displayName?.type === "required"}
                    required
                    label="Display Name"
                    variant="standard"
                    type="text"
                    size="small"
                    fullWidth
                    {...register("displayName", { required: true })}
                />
                <p className='form-text text-danger'>{errors.displayName?.type === "required" && 'This field is required'}</p>
            </div>
            <div className='mt-2'>
                <span className='float-end'>
                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openClinicConfigDialog.tableData === null ? (
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveEmailConfig)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    ) : (
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveEmailConfig)} startIcon={<material.DoneIcon />}>Update</material.Button>
                    )}
                </span>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    )
};

const PrePopulateEmail = (props) => {

    const { setOpenClinicConfigDialog, clinicDetails, userDetails, openClinicConfigDialog, getPrePopulateEmail } = props;

    const { register, handleSubmit, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        setValue("hour", 0);
        setValue("minutes", 0);
    }, [openClinicConfigDialog.action === true]);

    useEffect(() => {
        if (openClinicConfigDialog.action && openClinicConfigDialog.tableData) {
            reset(openClinicConfigDialog.tableData);
            const timeArray = openClinicConfigDialog.tableData.duration.split(" ");
            const hoursIndex = timeArray.indexOf("hour");
            const minutesIndex = timeArray.indexOf("min");
            let hours;
            let minutes;
            if (hoursIndex > 0 && minutesIndex < 0) {
                hours = Number(timeArray[hoursIndex - 1]);
                setValue("hour", hours)
            } else if (minutesIndex > 0 && hoursIndex < 0) {
                minutes = Number(timeArray[minutesIndex - 1]);
                setValue("minutes", minutes)
            } else if (hoursIndex > 0 && minutesIndex > 0) {
                hours = Number(timeArray[hoursIndex - 1]);
                minutes = Number(timeArray[minutesIndex - 1]);
                setValue("hour", hours);
                setValue("minutes", minutes);
            }
        };
    }, [openClinicConfigDialog.action === true]);

    const handleClose = () => {
        reset({ treatmentName: "", instructions: "", hour: "", minutes: "" });
        setOpenClinicConfigDialog({ action: false, callFrom: null, tableData: null });
    };

    const savePrePopulateEmail = (data) => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            duration: Number(data.hour) == 0 ? data.minutes + " " + "min" : Number(data.minutes) == 0 ? data.hour + " " + "hour" : data.hour + " " + "hour" + " " + data.minutes + " " + "min",
            treatmentName: data.treatmentName,
            instructions: data.instructions,
            id: data.id
        }
        savePrePopulateEmailSubject(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getPrePopulateEmail();
                reset({ treatmentName: "", instructions: "", hour: "", minutes: "" });
                setOpenClinicConfigDialog({ action: false, callFrom: null, tableData: null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <material.Grid spacing={2}>
            <div>
                <material.TextField
                    error={errors.treatmentName?.type === "required"}
                    required
                    label="Treatment Name"
                    variant="standard"
                    type="text"
                    size="small"
                    fullWidth
                    {...register("treatmentName", { required: true })}
                />
                <p className='form-text text-danger'>{errors.treatmentName?.type === "required" && 'This field is required'}</p>
            </div>
            <div>
                <material.TextField
                    error={errors.instructions?.type === "required"}
                    required
                    label="Instructions"
                    variant="standard"
                    type="text"
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                    {...register("instructions", { required: true })}
                />
                <p className='form-text text-danger'>{errors.instructions?.type === "required" && 'This field is required'}</p>
            </div>
            <div>
                <material.TextField
                    error={errors.hour?.type === "required"}
                    required
                    label="Duration (hour)"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    {...register("hour", { required: true })}
                />
                <p className='form-text text-danger'>{errors.hour?.type === "required" && 'This field is required'}</p>
            </div>
            <div>
                <material.TextField
                    error={errors.minutes?.type === "required"}
                    required
                    label="Duration (minutes)"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    {...register("minutes", { required: true })}
                />
                <p className='form-text text-danger'>{errors.minutes?.type === "required" && 'This field is required'}</p>
            </div>
            <div className='mt-2'>
                <span className='float-end'>
                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openClinicConfigDialog.tableData === null ? (
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(savePrePopulateEmail)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    ) : (
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(savePrePopulateEmail)} startIcon={<material.DoneIcon />}>Update</material.Button>
                    )}
                </span>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    )
}

const SmsConfig = (props) => {

    const { setOpenClinicConfigDialog, clinicDetails, userDetails, openClinicConfigDialog, getSmsConfigData } = props;

    const { register, handleSubmit, reset, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [selectPurpose, setSelectPurpose] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [phone, setPhone] = useState("");

    useEffect(() => {
        if (openClinicConfigDialog.action && openClinicConfigDialog.tableData) {
            reset(openClinicConfigDialog.tableData);
            setSelectPurpose(openClinicConfigDialog.tableData.purpose);
            setPhone(openClinicConfigDialog.tableData.phoneNumber)
        };
    }, [openClinicConfigDialog.action === true]);

    const handleChangePurpose = (event) => {
        setSelectPurpose(event.target.value)
    };

    const handleClose = () => {
        setOpenClinicConfigDialog({ action: false, callFrom: null, tableData: null });
        reset({ phoneNumber: "", purpose: "" });
        setSelectPurpose("");
    };

    const saveSmsConfig = (data) => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            phoneNumber: phone,
            purpose: data.purpose,
        }
        savePhoneConfig(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getSmsConfigData()
                reset({ phoneNumber: "", purpose: "" });
                setSelectPurpose("");
                setOpenClinicConfigDialog({ action: false, callFrom: null, tableData: null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })

    };

    return (
        <material.Grid spacing={2}>
            <div>
                {/* <material.TextField
                    error={errors.phoneNumber?.type === "required"}
                    required
                    label="Phone"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                    {...register("phoneNumber", { required: true, minLength: 10, maxLength: 10 })}
                />
                <p className='form-text text-danger'>{errors.phoneNumber?.type === "required" && 'This field is required'}</p>
                {(errors?.phoneNumber?.type === "minLength" || errors?.phoneNumber?.type === "maxLength") && (
                    <p className='text-danger'>This field only contain 10 digits</p>
                )} */}
                <PhoneInput
                    required
                    placeholder="Phone"
                    defaultCountry="AU"
                    inputRef={register}
                    enableSearch={true}
                    value={phone}
                    control={control}
                    international
                    rules={{ required: true }}
                    onChange={(phone) => setPhone(phone)}
                    error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
            </div>
            <div>
                <material.FormControl variant="standard" required fullWidth>
                    <material.InputLabel id="demo-simple-select-standard-label">Purpose</material.InputLabel>
                    <material.Select
                        {...register("purpose", { required: true })}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectPurpose}
                        onChange={handleChangePurpose}
                    >
                        <material.MenuItem value="CAMPAIGN">CAMPAIGN</material.MenuItem>
                        <material.MenuItem value="GENERAL">GENERAL</material.MenuItem>
                    </material.Select>
                </material.FormControl>
            </div>
            <div className='mt-2'>
                <span className='float-end'>
                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openClinicConfigDialog.tableData === null ? (
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveSmsConfig)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    ) : (
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveSmsConfig)} startIcon={<material.DoneIcon />}>Update</material.Button>
                    )}
                </span>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ClinicConfigDialog);