import moment from 'moment/moment';


export const localDateTimeFormat = (date) => {
    return moment(date, "YYYY-MM-DDTHH:mm:ss").format('YYYY-MM-DD HH:mm A')
};

export const dateAndTimeFormat = (data) => {
    return moment(data).format('MM-DD-YYYY, HH:mm')
};

export const dateFormat = (data) => {
    return moment(data).format('YYYY-MM-DD')
};

export const changeDateFormate = (date) => {
    return moment(date).format('DD/MM/YYYY')
};

export const changeTimeFormate = (date) => {
    return moment(date, 'HH:mm:ss').format('h:mm A')
};

export const changeTimeFormateInDay = (date) => {
    return moment(date, 'HH:mm:ss').format('HH:mm:ss')
};

export const changeDateAndTimeFormate = (date) => {
    return moment(date).format('DD-MM-YYYY, HH:mm')
}