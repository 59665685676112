import React from 'react';
import { material } from '../../library/material';
import { getVendorByClinicId, getVendorByOrgId } from '../../services/VendorManagementService';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createProductTreatmentMap } from '../../services/TreatmentListService';
import Snackbar from '../toastrmessage/Snackbar';

const treatmentName = ["Dermal Filler", "Anti-Wrinkle", "Skin Booster", "Hyalase Treatment", "Local Anaesthetic", "Threads", "Exomide Scalp"];

function AddProductTreatmentMapping(props) {

    const { userData, clinicData } = props;
    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, resetField, reset, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const { openProductTreatmentMapping, setOpenProductTreatmentMapping, getProductTreatmentList } = props;
    const [companyName, setCompanyName] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openProductTreatmentMapping.action) {
            getVendors()
        }
    }, [openProductTreatmentMapping.action === true]);

    const getVendors = async () => {
        if (userDetails.role.includes("MANAGER")) {
            await getVendorByClinicId(userDetails.clinicId[0])
                .then((res) => {
                    let companyName = res.data?.map((ele) => ele.companyName)
                    setCompanyName(companyName)
                })
        } else {
            await getVendorByOrgId(userDetails.orgId)
                .then((res) => {
                    let companyName = res.data?.map((ele) => ele.companyName)
                    setCompanyName(companyName)
                })
        };
    };

    const handleChangeCompany = () => {

    };

    const handleChangeTreatment = () => {

    };

    const handleClose = () => {
        setOpenProductTreatmentMapping({ action: false, data: null });
        reset({ companyName: "", treatmentType: "", product: "" })
    };
    
    const save = async (data) => {
        await createProductTreatmentMap({ clinicId: clinicDetails.clinicId, orgId: userDetails.orgId, ...data })
            .then((res) => {
                getProductTreatmentList()
                setOpenProductTreatmentMapping({ action: false, data: null })
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data,
                })
                reset({ companyName: "", treatmentType: "", product: "" })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="sm" open={openProductTreatmentMapping.action} hideBackdrop >
                <material.DialogTitle>Add Product Treatment Mapping</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div>
                            <material.Autocomplete
                                id="companyName"
                                fullWidth
                                onChange={handleChangeCompany}
                                options={companyName}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Company"
                                    {...register("companyName", { required: true })}
                                />}
                            />
                        </div>
                        <div>
                            <material.Autocomplete
                                id="treatment"
                                fullWidth
                                onChange={handleChangeTreatment}
                                options={treatmentName}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Treatment"
                                    {...register("treatmentType", { required: true })}
                                />}
                            />
                        </div>
                        <div>
                            <material.TextField
                                // error={errors.subject?.type === "required"}
                                {...register("product", { required: true })}
                                id="product"
                                label="Product"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ mr: 1.3 }}>
                    <material.Button onClick={handleClose} variant="contained" sx={{ textTransform: "none" }} color="error">Cancel</material.Button>
                    <material.Button onClick={handleSubmit(save)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddProductTreatmentMapping);