import React from "react";
import { StepIconProps } from "@mui/material/StepIcon";
import { Typography, Box } from "@mui/material";

function CustomStepIcon(props) {
  const { active, completed, icon } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        height: 22,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          color: completed ? '#1976d2' : '#bdbdbd',
          display: 'flex',
          height: 22,
          width: 22,
          borderRadius: '50%',
          backgroundColor: completed ? '#1976d2' : 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          border: `1px solid ${completed ? '#1976d2' : '#bdbdbd'}`,
        }}
      >
        <Typography variant="caption" color="textSecondary" sx={{textAlign:'center',marginTop:'10%'}}>
          {icon-1}
        </Typography>
      </Box>
    </Box>
  );
}

export default CustomStepIcon;
