import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { addPatientCredit } from '../../../services/PatientService';
import Snackbar from '../../toastrmessage/Snackbar';

function AddPatientCredit(props) {

    const { register, handleSubmit, reset, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const { openPatientCreditDialog, setOpenPatientCreditDialog, userData, clinicData, getPatientCreditListData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;

    const [selectType, setSelectType] = useState("CREDIT");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openPatientCreditDialog.action) {
            setValue("purpose", "CREDIT");
        }
    }, [openPatientCreditDialog.action === true]);

    const handleClose = () => {
        reset({ purpose: "", amount: "" });
        setSelectType("CREDIT");
        setOpenPatientCreditDialog({ action: false, patientData: null });
    };

    const addCredit = async (formData) => {
        let obj = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId,
            patientId: openPatientCreditDialog.patientData?.patientId,
            ...formData
        }
        await addPatientCredit(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getPatientCreditListData();
                setSelectType("CREDIT");
                reset({ purpose: "", amount: "" });
                setOpenPatientCreditDialog({ action: false, patientData: null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const handleChange = (event) => {
        setSelectType(event.target.value)
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth hideBackdrop open={openPatientCreditDialog.action}>
                <material.DialogTitle>Add Patient Credit</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className='col-lg-12'>
                            <material.TextField
                                error={errors.purpose?.type === "required"}
                                {...register("purpose", { required: true, minLength: 3 })}
                                required
                                margin="dense"
                                id="name"
                                label="Purpose"
                                type="text"
                                fullWidth
                                variant="standard"
                                multiline
                                rows={3}
                            // defaultValue={"CREDIT"}
                            />
                            <p className='form-text text-danger'>{errors.purpose?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className='col-lg-12'>
                            <material.FormControl variant="standard" fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label">Type</material.InputLabel>
                                <material.Select
                                    {...register("type", { required: true })}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectType}
                                    onChange={handleChange}
                                >
                                    <material.MenuItem value={"CREDIT"}>CREDIT</material.MenuItem>
                                    <material.MenuItem value={"DEBIT"}>DEBIT</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='col-lg-12'>
                            <material.TextField
                                error={errors.amount?.type === "required"}
                                {...register("amount", { required: true })}
                                required
                                margin="dense"
                                id="name"
                                label="Amount"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                            <p className='form-text text-danger'>{errors.amount?.type === "required" && 'This field is required'}</p>
                            {/* {errors.amount && errors.amount.type === "pattern" && (
                                <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                            )} */}
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button onClick={handleSubmit(addCredit)} variant="outlined" sx={{ textTransform: "none" }} disabled={!isValid} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddPatientCredit);