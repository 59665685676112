
import { authActionType } from "../Constant";

const initialState = {
    patientIds: []
};

export default function patientIdReducer(state = initialState, action) {
    switch (action.type) {
        case authActionType.ADD_PATIENT_ID:
            const newPatientId = action.payload;
            const updatedPatientIds = state.patientIds.filter(id => id !== newPatientId);
            return {
                ...state,
                patientIds: [...updatedPatientIds, newPatientId]
            };
        default:
            return state;
    }
}
