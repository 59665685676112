import React, { useEffect, useState } from 'react'
import { getProposedTreatmentPlans } from '../../../../services/TreatmentPlanService'
import { connect } from 'react-redux'
import { material } from '../../../../library/material'
import { StyledTableCell } from '../../../../shared/TableHeaderStyle'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import moment from 'moment'
import AddTreatmentPlan from './AddTreatmentPlan'
import Snackbar from '../../../toastrmessage/Snackbar'
import { useNavigate } from 'react-router-dom';
import { getGrsByToProposedTreatmentPlan } from '../../../../services/GRSService';
import RadarChart from './RadarChart';
import { rootApiUrl } from '../../../../environment/Environment'

const Arrow = ({ height }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ width: '12px', height: `${height}px`, backgroundColor: 'black' }}></div>
        <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderTop: '20px solid black' }}></div>
    </div>
);

function ViewTreatmentPlans(props) {
    const { userData, clinicData, patientData, patientIds } = props
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const allPatientIds = patientIds.patientIdReducer.patientIds;
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(true);
    const [proposedTreatmentData, setProposedTreatmentData] = useState({})
    const [openAddTreatmentPlan, setOpenAddTreatmentPlan] = useState({ action: false, });
    const [page, setPage] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [treatmentPlanId, setTreatmentPlanId] = useState([]);
    const [getGrsData, setGetGrsData] = useState([]);



    useEffect(() => {
        getProposedTreatmentPlansByClinicIdAndOrgId()
    }, []);

    const getProposedTreatmentPlansByClinicIdAndOrgId = (pageSize) => {
        setisLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            patientId: patientData.patientId,
            limit: 1,
            page: pageSize ? pageSize.page : 0
        }
        getProposedTreatmentPlans(payload)
            .then((res) => {
                setTreatmentPlanId(Object.keys(res.data.treatmentPlans));
                setProposedTreatmentData(Object.values(res.data.treatmentPlans)[0]);
                setTotalPage(res.data.totalPages);
                setisLoading(false);
                if (Object.keys(res.data.treatmentPlans).length) {
                    getGrsDataByProposedTreatmentPlan(Object.keys(res.data.treatmentPlans));
                } else {
                    setGetGrsData([])
                }
            }).catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            })
    };

    const getGrsDataByProposedTreatmentPlan = (proposedTreatmentPlanId) => {
        getGrsByToProposedTreatmentPlan(proposedTreatmentPlanId[0])
            .then((resp) => {
                setGetGrsData(resp.data)
            })
            .catch((error) => {

            })
    };

    const goToPrevious = () => {
        setPage(page - 1)
        const payload = {
            limit: 1,
            page: page - 1
        }
        getProposedTreatmentPlansByClinicIdAndOrgId(payload)
    };

    const goToNext = () => {
        setPage(page + 1)
        const payload = {
            limit: 1,
            page: page + 1
        }
        getProposedTreatmentPlansByClinicIdAndOrgId(payload)
    }
    const openAddProposedTreatmentPlan = () => {
        setOpenAddTreatmentPlan({ action: true });
    };

    const goToGRSLink = () => {
        navigate("/patient-list/view-patient-details/LinkGRS", {
            state: { patientData, treatmentPlanId },
        })
    };
    const rowHeight = 300;
    const arrowHeight = getGrsData.length * rowHeight;

    return (
        <div >
            <span className="float-end">
                <material.Button
                    variant="contained"
                    sx={{ textTransform: "none", mb: 2, ml: 1 }}
                    size="medium"
                    color="primary"
                    onClick={openAddProposedTreatmentPlan}
                >
                    Add Proposed Treatment Plan
                </material.Button>
            </span>
            <span className="float-end">
                <material.Button
                    variant="contained"
                    sx={{ textTransform: "none", mb: 2 }}
                    size="medium"
                    color="primary"
                    onClick={goToGRSLink}
                >
                    GRS Link
                </material.Button>
            </span>

            <material.Paper elevation={7} sx={{ width: '100%', overflow: 'hidden', p: 3, }}>
                <material.TableContainer sx={{ maxHeight: "70vh" }}>
                    <material.Table stickyHeader aria-label="sticky table">
                        <material.TableHead >
                            <material.TableRow>
                                <StyledTableCell>Treatment Session</StyledTableCell>
                                <StyledTableCell>Sessions Focus</StyledTableCell>
                                <StyledTableCell>Rational</StyledTableCell>
                                <StyledTableCell>Treatment Name</StyledTableCell>
                                <StyledTableCell>Treatment Area</StyledTableCell>
                                <StyledTableCell>Product</StyledTableCell>
                                <StyledTableCell>Product Area</StyledTableCell>
                                <StyledTableCell>Cost</StyledTableCell>
                                <StyledTableCell>Day/Month/Year</StyledTableCell>
                                <StyledTableCell>Created Date</StyledTableCell>
                                <StyledTableCell>Propose Date</StyledTableCell>
                            </material.TableRow>
                        </material.TableHead>
                        <material.TableBody>
                            {isLoading ? (
                                <material.TableRow >
                                    <material.TableCell colSpan={12}>
                                        <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                            <Skeleton count={12} />
                                        </SkeletonTheme>
                                    </material.TableCell>
                                </material.TableRow>
                            ) : (
                                <>
                                    {proposedTreatmentData?.length ? proposedTreatmentData?.map((row, i) => (
                                        <material.TableRow
                                            key={i}
                                        >
                                            <material.TableCell>{row.treatmentSession}</material.TableCell>
                                            <material.TableCell>{row.sessionFocus}</material.TableCell>
                                            <material.TableCell>{row.rational}</material.TableCell>
                                            <material.TableCell>{(row.treatment === "" || row.treatment === null) ? "N/A" : row.treatment}</material.TableCell>
                                            <material.TableCell>{(row.treatmentArea === "" || row.treatmentArea === null) ? "N/A" : row.treatmentArea}</material.TableCell>
                                            <material.TableCell>{(row.productName === "" || row.productName === null) ? "N/A" : row.productName}</material.TableCell>
                                            <material.TableCell>{(row.productArea === "" || row.productArea === null) ? "N/A" : row.productArea}</material.TableCell>
                                            <material.TableCell>{row.cost}</material.TableCell>
                                            <material.TableCell>{row.days} {row.dateFormat}</material.TableCell>
                                            <material.TableCell>{moment(row.createdAt).format('DD-MM-YYYY')}</material.TableCell>
                                            <material.TableCell>{moment(row.timeApart).format('DD-MM-YYYY')}</material.TableCell>
                                        </material.TableRow>
                                    )) : (
                                        <material.TableRow >
                                            <material.TableCell colSpan={12}>
                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                            </material.TableCell>
                                        </material.TableRow>
                                    )}
                                </>)}
                        </material.TableBody>
                    </material.Table>
                </material.TableContainer>
                <span className='float-end mt-2'>
                    <material.Button variant="outlined" size='small' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.KeyboardArrowLeft />} onClick={goToPrevious} disabled={page === 0}>Previous</material.Button>
                    <material.Button variant="outlined" size='small' sx={{ textTransform: "none" }} endIcon={<material.KeyboardArrowRight />} onClick={goToNext} disabled={totalPage === page}>Next</material.Button>
                </span>
                <div className='mt-5'>
                    {isLoading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '10%' }}>
                            <material.CircularProgress color='secondary' />
                        </div>
                    ) : (
                        <>
                            {getGrsData.length > 0 ? (
                                <div className="row">
                                    <div className="col-1" style={{ marginTop: '2%', }}>
                                        <Arrow height={arrowHeight} />
                                    </div>
                                    <div className="col-11">
                                        {getGrsData?.map((row, i) => {
                                            const chartData = [
                                                row.static,
                                                row.dynamic,
                                                row.volume_loss,
                                                row.sagging,
                                                row.asymmetry,
                                                row.imbalance,
                                                row.loss_Of_Elasticity,
                                                row.surface_roughness,
                                                row.dehydration,
                                            ];
                                            return (
                                                <div className='row' key={i}>
                                                    {i === 0 ? null : (
                                                        <div className='row'>
                                                            <div className='col-12' style={{ width: '100%', height: `2px`, backgroundColor: 'black', marginTop: '-1%' }}></div>
                                                        </div>
                                                    )}

                                                    <div className="col-4 mb-4">
                                                        <material.Paper elevation={7} sx={{ p: 1, mt: 2 }}>
                                                            <div style={{ marginTop: '8%' }}>
                                                                <RadarChart data={chartData} />
                                                            </div>
                                                            <h4 style={{ textAlign: "center", marginTop: '8%' }}>
                                                                {moment(row?.createdAt).format("DD-MM-YYYY")}
                                                            </h4>
                                                        </material.Paper>
                                                    </div>
                                                    <div className='col-7 mb-4'>
                                                        <span>
                                                            {row?.linkedImageUri !== "" || row?.linkedImageUri !== null ? row?.linkedImageUri?.split(",").map((ele, i) => (
                                                                <img
                                                                    key={i}
                                                                    src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                                                    height={200} width="35%"
                                                                    style={{ overflow: "hidden", margin: "10px" }}
                                                                />
                                                            )) : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        </>

                    )}
                </div>
            </material.Paper>
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
            <AddTreatmentPlan
                setOpenAddTreatmentPlan={setOpenAddTreatmentPlan}
                openAddTreatmentPlan={openAddTreatmentPlan}
                patientData={patientData}
                getProposedTreatmentPlansByClinicIdAndOrgId={getProposedTreatmentPlansByClinicIdAndOrgId}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    userData: state,
    clinicData: state,
    patientIds: state
});

export default connect(mapStateToProps)(ViewTreatmentPlans)