import React, { useEffect, useState } from 'react';
import Navbar from '../navbar/Navbar';
import { connect } from 'react-redux';
import { material } from '../../library/material';
import { useNavigate } from 'react-router-dom';

var menuList;

function TabView(props) {

    const [allMenu, setAllMenu] = useState([]);

    const { userData } = props;
    let navigate = useNavigate();
    let userDetails = userData.authReducer.data;
    localStorage.setItem("menuName", "Menu")
    useEffect(() => {
        menuList = userDetails.menu.sort((a, b) => a.child.localeCompare(b.child));
        setAllMenu(menuList)
    }, []);

    const clickToNavigation = (menuData) => {
        localStorage.setItem("menuName", menuData.child)
        navigate(`${menuData.route}`);
    };

    const filterByMenuName = (value) => {
        const filteredRows = menuList.filter((row) => {
            return row.child
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setAllMenu(menuList);
        } else {
            setAllMenu(filteredRows);
        }
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%", paddingBottom: "2%" }}>
            <Navbar />
            <div>
                <span>
                    <material.TextField
                        fullWidth
                        variant="standard"
                        label="Filter by Menu Name"
                        type='text'
                        sx={{ width: "40ch" }}
                        onChange={(e) => filterByMenuName(e.target.value)}
                    />
                </span>
            </div>
            <div>
                <div className="row">
                    {allMenu?.map((ele, i) => (
                        <div className="col-lg-3" key={i}>
                            <material.Card elevation={3} sx={{ p: 2, mt: 2, backgroundColor: "#3877b5", height: "150px" }}>
                                <material.CardActionArea onClick={() => clickToNavigation(ele)}>
                                    <material.CardContent>
                                        <material.Typography sx={{ color: "white", fontSize: 22 }} className='text-center fw-bold'>{ele.child}</material.Typography>
                                    </material.CardContent>
                                </material.CardActionArea>
                            </material.Card>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
        sideBarData: state
    };
};

export default connect(mapStateToProps)(TabView);
