import React, { useState } from 'react';
import { connect } from 'react-redux';
import { material } from '../../../library/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useEffect } from 'react';
import AddSkinTreatment from '../../dialog/AddSkinTreatment';
import { deleteSkinTreatmentList, getSkinTreatmentList } from '../../../services/TreatmentListService';
import Snackbar from '../../toastrmessage/Snackbar';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';

var clinicID;
var treatmentData;

function SkinTreatment(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [isLoading, setisLoading] = useState(true);
    const [treatmentList, setTreatmentList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openAddTreatmentDialog, setOpenAddTreatmentDialog] = useState({ "action": false, "treatmentData": null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    localStorage.setItem("menuName", "Skin Treatment List");

    useEffect(() => {
        getSkinTreatmentData()
    }, []);

    const getSkinTreatmentData = async () => {
        await getSkinTreatmentList(clinicDetails.clinicId)
            .then((resp) => {
                treatmentData = resp.data;
                setTreatmentList(resp.data)
                setisLoading(false)
            })
            .catch((error) => {

            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const addPrice = () => {
        setOpenAddTreatmentDialog({ "action": true, "treatmentData": null })
    };

    const editTreatment = (rowData) => {
        setOpenAddTreatmentDialog({ "action": true, "treatmentData": rowData })
    };

    const filterByTreatmentName = (value) => {
        const filteredRows = treatmentData.filter((row) => {
            return row.treatmentName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setTreatmentList(treatmentData);
        } else {
            setTreatmentList(filteredRows);
        }
    };

    const filterByTreatmentArea = (value) => {
        setPage(0);
        const filteredRows = treatmentData.filter((row) => {
            return row.treatmentArea
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setTreatmentList(treatmentData);
        } else {
            setTreatmentList(filteredRows);
        }
    };

    const deleteTreatment = async (rowData) => {
        await deleteSkinTreatmentList(rowData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getSkinTreatmentData()
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="row">
                            <div className='col-lg-6 col-md-6'>
                                <material.TextField
                                    fullWidth
                                    variant="standard"
                                    label="Filter by Treatment Name"
                                    type='text'
                                    onChange={(e) => filterByTreatmentName(e.target.value)}
                                />
                            </div>
                            <div className='col-lg-6 col-md-6'>
                                <material.TextField
                                    fullWidth
                                    variant="standard"
                                    label="Filter by Treatment Area"
                                    type='text'
                                    onChange={(e) => filterByTreatmentArea(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addPrice}> Add-Treatment</material.Button>
                        </span>
                    </div>
                </div>
                <div className='mt-3'>
                    <material.Paper sx={{ width: "100%", overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 460 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Treatment Name</StyledTableCell>
                                        <StyledTableCell>Treatment Area</StyledTableCell>
                                        <StyledTableCell>Number of Session</StyledTableCell>
                                        <StyledTableCell>Product Name</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {treatmentList?.length ? treatmentList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.treatmentName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.treatmentArea}</material.TableCell>
                                                    <material.TableCell size='small'>{row.numberOfSessions}</material.TableCell>
                                                    <material.TableCell size='small'>{row.productUsed}</material.TableCell>
                                                    <material.TableCell size='small'>
                                                        <material.IconButton title='Edit Treatment' aria-label="create" size="large" onClick={() => editTreatment(row)}>
                                                            <material.CreateIcon color='primary' />
                                                        </material.IconButton>
                                                        <material.IconButton title="Delete Treatment" aria-label="create" size="large" onClick={() => deleteTreatment(row)}>
                                                            <material.DeleteIcon color='warning' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>)}
                                </material.TableBody>
                            </material.Table>
                            <hr />
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={treatmentList?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <AddSkinTreatment
                getSkinTreatmentData={getSkinTreatmentData}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
                openAddTreatmentDialog={openAddTreatmentDialog}
                setOpenAddTreatmentDialog={setOpenAddTreatmentDialog}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(SkinTreatment);