import React, { useEffect, useState } from "react";
import { material } from "../../../../library/material";
import RadarChart from "./RadarChart";
import { Stepper, Step, StepLabel, Button, Typography } from "@mui/material";
import CustomStepIcon from "./CustomStepIcon";
import AddTreatmentPlan from "./AddTreatmentPlan";

const steps = ["0", "1", "2", "3"];
function ViewGRSDetails(props) {
  const { setOpenAssessmentDetails, openAssessmentDetails, patientData } = props;
  const [formData, setFormData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [stepperData, setStepperData] = useState(null);



  useEffect(() => {
    if (
      openAssessmentDetails?.action &&
      openAssessmentDetails?.rowData !== null
    ) {
      setFormData(
        [
          openAssessmentDetails?.rowData?.static,
          openAssessmentDetails?.rowData?.dynamic,
          openAssessmentDetails?.rowData?.volume_loss,
          openAssessmentDetails?.rowData?.sagging,
          openAssessmentDetails?.rowData?.asymmetry,
          openAssessmentDetails?.rowData?.imbalance,
          openAssessmentDetails?.rowData?.loss_Of_Elasticity,
          openAssessmentDetails?.rowData?.surface_roughness,
          openAssessmentDetails?.rowData?.dehydration,
        ].map(Number)
      );
    }
  }, [openAssessmentDetails]);

  useEffect(() => {
    setStepperData({
      scars: openAssessmentDetails?.rowData?.scars,
      visible_pores: openAssessmentDetails?.rowData?.visible_pores,
      pigmentation: openAssessmentDetails?.rowData?.pigmentation,
      vasculature: openAssessmentDetails?.rowData?.vasculature,
    });
  }, [openAssessmentDetails]);

  const renderStepper = (label, value) => (
    <material.Box sx={{ width: "100%" }}>
      {/* <Typography>{label}</Typography> */}
      <Stepper alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index} completed={index <= value}>
            <StepLabel StepIconComponent={CustomStepIcon}>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </material.Box>
  );

  const cancel = () => {
    setOpenAssessmentDetails({ action: false, rowData: null });
  };

  return (
    <div>
      <material.Dialog
        fullWidth
        maxWidth="xl"
        open={openAssessmentDetails.action}
        hideBackdrop
      >
        <material.DialogTitle sx={{ textAlign: "center", textDecoration: 'underline' }}>
          Assessment
        </material.DialogTitle>
        <material.DialogContent>
          <div className="row">

            <div className="col-6">
              <h3 style={{ textDecorationLine: "underline", textAlign: 'center' }}>
                Global Ranking Scale
              </h3>
              <div style={{ marginTop: '6%' }}>
                <RadarChart data={formData} openFrom={"viewAssessment"} />
              </div>
            </div>
            <div className="col-6">
              <h3 style={{ textDecorationLine: "underline", textAlign: 'center' }}>Skin Quality</h3>
              <div style={{marginTop:'2%'}}>
                {stepperData && (
                  <div className="row">
                    <div className="col-6">
                      <h6 style={{ marginLeft: "9%", marginBottom: "5%" }}>
                        Scars
                      </h6>
                      {renderStepper("Scars", stepperData.scars)}
                      <h6 style={{ marginLeft: "9%", marginTop: "5%" }}>
                        Visible Pores
                      </h6>
                      {renderStepper("Visible Pores", stepperData.visible_pores)}
                    </div>

                    <div className="col-6">
                      <h6 style={{ marginLeft: "9%", marginBottom: "5%" }}>
                        Pigmentation
                      </h6>
                      {renderStepper("Pigmentation", stepperData.pigmentation)}
                      <h6 style={{ marginLeft: "9%", marginTop: "5%" }}>
                        Vasculature
                      </h6>
                      {renderStepper("Vasculature", stepperData.vasculature)}
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        </material.DialogContent>
        <material.DialogActions>
          <div className="mt-3">
            <span className="float-end py-3">
              <material.Button
                variant="contained"
                sx={{ textTransform: "none" }}
                size="medium"
                className="me-2"
                color="error"
                onClick={cancel}
              >
                Close
              </material.Button>
            </span>
          </div>
        </material.DialogActions>
      </material.Dialog>
    </div>
  );
}

export default ViewGRSDetails;
