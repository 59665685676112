import React, { useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { sendBddFormAssessment } from '../../services/BddFormService';
import { connect } from 'react-redux';
import Snackbar from '../toastrmessage/Snackbar';

function AssessedForm(props) {

    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const { openAssessedForm, userData, setOpenAssessedForm } = props;
    let userDetails = userData.authReducer.data;
    const [questions, setquestions] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const selectQuestions = (value) => {
        setquestions(value)
    };

    const save = async (data) => {
        let obj = {
            assessed_by: userDetails.userId,
            assessment_note: data.assessment_note,
            formId: openAssessedForm.bddFormData.formId,
            assessment_result: questions
        }
        await sendBddFormAssessment(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "BDD Assessment form save successfully",
                })
                setOpenAssessedForm({ action: false })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            });
    };

    return (
        <div>
            <material.Dialog maxWidth="md" fullWidth hideBackdrop open={openAssessedForm.action}>
                <material.DialogTitle>BDD Assessment Form</material.DialogTitle>
                <material.DialogContent>
                    <div className='content'>
                        <material.Paper elevation={7} sx={{ p: 4, width: "100%" }}>
                            <span><material.Typography variant="h5">BDD Form</material.Typography></span>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Patient Name</td>
                                                <td className='col-8'>{openAssessedForm.bddFormData?.patientName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Patient Email</td>
                                                <td className='col-8'>{openAssessedForm.bddFormData?.userEmail}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Date of Birth</td>
                                                <td className='col-8'>{openAssessedForm.bddFormData?.dateOfBirth}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Clinic Name</td>
                                                <td className='col-8'>{openAssessedForm.bddFormData?.clinicName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Exp Date</td>
                                                <td className='col-8'>{openAssessedForm.bddFormData?.exp_date}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <material.Typography>
                                    The below questions will assess underlying psychological conditions such as (BDD) body dysmorphic disorder.
                                    Please speak to your practitioner if you require further information regarding BDD.
                                </material.Typography>
                                <material.Typography sx={{ mt: 2 }}>
                                    If there are indications of significant underlying psychological issues which may make
                                    you an unsuitable candidate for the cosmetic procedure, you may be referred for evaluation to a
                                    psychologist, psychiatrist or general practitioner who works independently of the Healthcare professional who will perform the procedure or the medical practitioner who will prescribe the cosmetic injectable.
                                </material.Typography>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='col-11'> 1- Are you worried about the way you look, and do you think about your appearance a lot and wish you could think about it less</td>
                                                <td className='col-1 fw-bold'>{openAssessedForm.bddFormData?.q1}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>2- How has it, the way you feel about it, affected your daily life activities, such as school, work, or other activities?</td>
                                                <td className='col-1 fw-bold'>{openAssessedForm.bddFormData?.q2}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>3- Are there things you avoid because of how you look, such as gatherings, going out etc</td>
                                                <td className='col-1 fw-bold'>{openAssessedForm.bddFormData?.q3}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>Do you spend more than 2-3 hours a day thinking about how you look</td>
                                                <td className='col-1 fw-bold'>{openAssessedForm.bddFormData?.q4}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>5- If you could not have a cosmetic treatment today, would this affect your life adversely?</td>
                                                <td className='col-1 fw-bold'>{openAssessedForm.bddFormData?.q5}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </material.Paper>
                    </div>
                    <div>
                        <material.FormControl sx={{ marginTop: 1, ml: 2 }}>
                            <material.RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={event => selectQuestions(event.target.value)}
                                value={questions}
                            >
                                <material.FormControlLabel value="Positive" control={<material.Radio color="secondary" />} label="Positive" />
                                <material.FormControlLabel value="Negative" control={<material.Radio color="secondary" />} label="Negative" />
                            </material.RadioGroup>
                        </material.FormControl>
                    </div>
                    <div>
                        <material.TextField
                            label="Assessment Note"
                            fullWidth
                            multiline
                            type='text'
                            {...register("assessment_note")}
                        />
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleSubmit(save)} variant="contained" sx={{ textTransform: "none", mr: 3, mb: 2 }}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AssessedForm);