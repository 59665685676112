import { rootApiUrl } from "../environment/Environment";
import axios from "axios";


export const savePrePopulateEmailSubject = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-schedule-master`, payload)
};

export const getPrePopulateEmailSubject = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-schedule-master`, { params: { clinicId: clinicId } })
};

export const saveEmailConfigData = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-email-config`, payload)
};

export const getEmailConfig = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-email-config`, { params: { clinicId: clinicId } })
};

export const savePhoneConfig = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-phone-config`, payload)
};

export const getPhoneConfig = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-phone-config`, { params: { clinicId: clinicId } })
};

export const getSmsRemainderData = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-sms-reminder-settings`, { params: { clinicId: clinicId } })
};

export const saveSmsRemainderData = async (remainderData) => {
    return await axios.post(`${rootApiUrl}/save-sms-reminder-settings`, remainderData)
};

export const getClinicHoliday = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-clinic-holiday`, { params: { ...payload } });
  };
  
  export const saveClinicHoliday = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-clinic-holiday`, payload);
  };