import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { rootApiUrl } from '../../../environment/Environment';
import { addToPortfolio, getCompareImageList } from '../../../services/TreatmentPlanService';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import { getTreatmentListByClinicId } from '../../../services/TreatmentListService';
import { useForm } from 'react-hook-form';


const skinTreatmentName = [
    "Chemical Peel Treatment",
    "IPL Treatment",
    "PDT Light Therapy Treatments",
    "Laser Hair Removal",
    "Microneedling Treatment",
    "Aqua-Infusion Skin Therapy"
];

function ComparedImageDialog(props) {

    const { compareImages, setSelectedImages, setComparedImg, comparedImg, setCompareImages } = props;
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDialog, setOpenDialog] = useState({ action: false, portfolioData: null });

    useEffect(() => {
        if (comparedImg === true) {
            const element = document.getElementById("compared-section");
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [comparedImg === true]);

    const close = () => {
        setSelectedImages([]);
        setComparedImg(false);
        setCompareImages([]);
    };

    const addPortFolio = (portfolioData) => {
        setOpenDialog({ action: true, portfolioData: portfolioData })
    };

    const getDialogData = (payload) => {
        addToPortfolio(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div id='compared-section'>
            <material.Paper elevation={3} sx={{ p: 3, pb: 7 }}>
                <span><material.Typography variant='h6'>Compared Images</material.Typography></span>
                {compareImages.length ? compareImages.map((images, i) => (
                    <div className="row mt-3" key={i}>
                        <div className="col-lg-5">
                            <img
                                src={`${rootApiUrl}/get-skin-treatment-image?key=${images.before_image_link}`}
                                height={400} width="100%"
                                alt=''
                            />
                        </div>
                        <div className="col-lg-5">
                            <img
                                src={`${rootApiUrl}/get-skin-treatment-image?key=${images.after_image_link}`}
                                height={400} width="100%"
                                alt=''
                            />
                        </div>
                        <div className="col-lg-2">
                            <span style={{ display: "flex", justifyContent: "center", alignContent: "center", height: "auto", width: "100%" }}>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={() => addPortFolio(images)}>Add Portfolio</material.Button>
                            </span>
                        </div>
                    </div>
                )) : (
                    <div className="row">
                        <div className="col-12">
                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                        </div>
                    </div>
                )}
                <span className='float-end'>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={close}>Close</material.Button>
                </span>
            </material.Paper>
            <PermissionDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                getDialogData={getDialogData}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
};

var allTreatmentList;

export const PermissionDialog = (props) => {

    const { openDialog, setOpenDialog, getDialogData, userDetails, clinicDetails, callFrom } = props;
    const [selectValue, setSelectValue] = React.useState("");
    const [treatmentName, setTreatmentName] = useState([]);
    const [selectTreatmentName, setSelectTreatmentName] = useState("");
    const [tagName, setTagName] = useState("");
    const [areaOption, setAreaOption] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const { register, handleSubmit, resetField, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });

    useEffect(() => {
        if (openDialog.action) {
            getAllTreatmentList()
        }
    }, [openDialog.action === true]);

    const handleChange = (event) => {
        setSelectValue(event.target.value)
    };

    const getAllTreatmentList = async () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please Wait....!",
        })
        await getTreatmentListByClinicId(clinicDetails.clinicId, userDetails.orgId)
            .then((resp) => {
                allTreatmentList = resp.data;
                let treatmentName = resp.data?.map((res) => res.treatmentName);
                setTreatmentName(treatmentName);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Fetching complete",
                })
            })
    };

    const selectTreatment = (e, value) => {
        reset({ treatmentArea: "" });
        let treatmentArea = allTreatmentList?.filter((ele) => ele.treatmentName === value);
        setAreaOption(treatmentArea?.length ? treatmentArea[0].treatmentArea : []);
        setSelectTreatmentName(value)
    };

    const selectArea = async (e, value) => {
        setTagName(selectTreatmentName + "-" + value);
    };

    const selectSkinTreatment = (e, value) => {
        setTagName(value)
    };

    const save = () => {
        let payload = {}
        if (callFrom === "Portfolio") {
            getDialogData(tagName);
        } else {
            payload = {
                "patientId": openDialog.portfolioData?.patientId,
                "injectorId": openDialog.portfolioData?.injectorId,
                "clinicId": openDialog.portfolioData?.clinicId,
                "linkedTo": openDialog.portfolioData?.linkedTo,
                "addToPortfolio": true,
                "before_image_link": openDialog.portfolioData?.before_image_link,
                "after_image_link": openDialog.portfolioData?.after_image_link,
                refNo: openDialog.portfolioData?.refNo,
                tag: tagName
            }
            getDialogData(payload);
        };
        reset({ treatmentArea: "", treatmentType:"", treatmentArea:"" });
        setOpenDialog({ action: false, portfolioData: null });
        setTagName("");
        setSelectValue("");
    };

    const close = () => {
        reset({ treatmentArea: "", treatmentType:"", treatmentArea:"" });
        setOpenDialog({ action: false, portfolioData: null });
        setSelectValue("");
        setTagName("");
    };

    return (
        <div>
            <material.Dialog open={openDialog.action} hideBackdrop fullWidth maxWidth="sm">
                <material.DialogContent>
                    <material.FormControl fullWidth>
                        <material.InputLabel id="demo-simple-select-label">Treatment Type</material.InputLabel>
                        <material.Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectValue}
                            label="Age"
                            variant='standard'
                            onChange={handleChange}
                            inputProps={{ ...register("treatmentType", { required: true }) }}
                        >
                            <material.MenuItem value={"Cosmetic Injectable"}>Cosmetic Injectable</material.MenuItem>
                            <material.MenuItem value={"Skin Treatment"}>Skin Treatment</material.MenuItem>
                        </material.Select>
                    </material.FormControl>
                    {selectValue === "Cosmetic Injectable" ? (
                        <div>
                            <material.Autocomplete
                                className='mt-3'
                                id="treatment"
                                onChange={selectTreatment}
                                options={treatmentName}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Treatment"
                                    {...register("treatmentName", { required: true })}
                                />}
                            />
                            <material.Autocomplete
                                id="area"
                                className='mt-3'
                                onChange={selectArea}
                                options={areaOption}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Area"
                                    {...register("treatmentArea", { required: true })}
                                />}
                            />
                        </div>
                    ) : selectValue === "Skin Treatment" ? (
                        <div>
                            <material.Autocomplete
                                className='mt-3'
                                id="treatment"
                                onChange={selectSkinTreatment}
                                options={skinTreatmentName}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Treatment"
                                    {...register("treatmentName", { required: true })}
                                />}
                            />
                        </div>
                    ) : null}
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={close}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!isValid} onClick={handleSubmit(save)}>{callFrom === "Portfolio" ? "Apply Filter" : "Save"}</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ComparedImageDialog);