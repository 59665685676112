import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { socket } from '../../socket/Socket';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import KioskNavbar from '../navbar/KioskNavbar';
import ConsentFormCP from '../dashboard/consent-form/ConsentFormCP';
import ConsentFormIPL from '../dashboard/consent-form/ConsentFormIPL';
import ConsentFormLT from '../dashboard/consent-form/ConsentFormLT';
import ConsentFormPDT from '../dashboard/consent-form/ConsentFormPDT';
import Hydrafacial from '../dashboard/consent-form/Hydrafacial';
import Microneedling from '../dashboard/consent-form/Microneedling';
import './Menu.css';

function Menu(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const navigate = useNavigate();
    const [openConsentForm, setOpenConsentForm] = useState({ action: false, patientData: null });

    socket.emit('kiosk-info', userDetails)
    socket.on("add-patient", (resp) => {
        navigate("/kiosk-menu/kiosk-add-patient", { state: { clinicData: resp[1] } })
    });

    socket.on("add-photo-kiosk", (resp) => {
        navigate("/kiosk-menu/kiosk-add-treatment-photo", { state: { obj: resp[1] } })
    });

    socket.on("add-concent-kiosk", (resp) => {
        if (resp[1].treatmentType === "skin-treatment") {
            setOpenConsentForm({ action: true, patientData: resp[1].patientData });
        } else {
            navigate('/kiosk-menu/kiosk-consent', { state: { resp } });
        };
    });


    return (
        <material.Grid container spacing={2}>
            <KioskNavbar />
            <div className='bg-img'>

            </div>
            {openConsentForm.patientData?.treatmentName.toLowerCase().includes("chemical") ? (
                <ConsentFormCP
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    callFrom="secondary-user"
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("ipl") ? (
                <ConsentFormIPL
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    callFrom="secondary-user"
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("pdt") ? (
                <ConsentFormPDT
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    callFrom="secondary-user"
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("laser") ? (
                <ConsentFormLT
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    callFrom="secondary-user"
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("aqua") ? (
                <Hydrafacial
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    callFrom="secondary-user"
                />
            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("microneedling") ? (
                <Microneedling
                    openConsentForm={openConsentForm}
                    setOpenConsentForm={setOpenConsentForm}
                    callFrom="secondary-user"
                />
            ) : null}
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(Menu);