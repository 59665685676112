import axios from "axios";
import { rootApiUrl } from "../environment/Environment";



export const saveRoomsData = async (roomsData) => {
    return await axios.post(`${rootApiUrl}/save-rooms-data`, roomsData);
};

export const saveMachineList = async (data) => {
    return await axios.post(`${rootApiUrl}/save-machine-list`, data);
};

export const getMachineList = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-machine-list/${clinicId}`);
};

export const getRoomList = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-room-list/${clinicId}`);
};

export const getMachineScheduleClinic = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-machine-schedule-clinic/${clinicId}`);
};

export const getRoomScheduleClinic = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-room-schedule-clinic/${clinicId}`);
};