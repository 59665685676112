import React, { useEffect, useState, useRef } from 'react';
import Webcam from 'react-webcam';
import { material } from '../../../library/material';
import { connect } from 'react-redux';
import { uploadSkinBeforeImage, uploadSkinBeforeImageFile } from '../../../services/SkinTreatmentService';
import Snackbar from '../../toastrmessage/Snackbar';
import SkinTreatmentNote from '../../dialog/SkinTreatmentNote';
import { socket } from '../../../socket/Socket';

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT,
    width: 1920,
    height: 1080
};
var uploadImage = [];
var allImages = [];

function AddAndUploadPhotoDialog(props) {

    const { openPhotoUploadDialog, setOpenPhotoUploadDialog, userData, clinicData, getImageList, callFrom, setOpenConsentForm } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);
    const [close, setClose] = useState(true);
    const [stream, setStream] = useState(null);
    const [image, setImage] = useState(null);
    const [isWebcamActive, setIsWebcamActive] = useState(false);
    const videoRef = useRef(null);
    const [buttonHideShow, setButtonHideShow] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openPermissionDialog, setOpenPermissionDialog] = useState({ action: false, patientData: null });
    const [clickType, setClickType] = useState(null);
    const [selectFolder, setSelectFolder] = useState([]);

    const inputRef = useRef(null);

    const addPhoto = (type) => {
        setClickType(type);
        setButtonHideShow(true);
        setClose(false);
        openCamera();
        setImage(null);
    };

    const selectPhoto = (type) => {
        setClickType(type);
    }

    async function openCamera() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(stream);
            videoRef.current.srcObject = stream;
        } catch (error) {
            console.error(error);
        }
        setIsWebcamActive(true)
    };

    function closeCamera() {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);
        }
        setIsWebcamActive(false)
    };


    function capturePhoto() {
        const imageSrc = videoRef.current.getScreenshot();
        uploadPhoto(imageSrc);
        closeCamera();
    };

    async function uploadPhoto(photo) {
        setImage(photo)
        allImages.push({ "image": photo });
        uploadImage.push(photo);
    };

    const switchCamera = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    function toggleCamera() {
        if (stream) {
            closeCamera();
        } else {
            openCamera();
        }
    };

    const chooseFile = (e) => {
        let fileName = e.target.files;
        setSelectFolder(fileName);
        console.log(fileName)
    };

    const cancel = () => {
        closeCamera();
        setButtonHideShow(false);
        setImage(null);
        allImages = [];
        uploadImage = [];
        setClose(true);
        setClickType(null);
        setSelectFolder(null);
        if (callFrom === "skin_treatment_details") {
            setOpenPhotoUploadDialog({ action: false, treatmentData: null });
        } else {
            setOpenPhotoUploadDialog({ action: false, treatmentData: null });
            setOpenConsentForm({ action: false, patientData: null });
        }
    };

    const savePhoto = async () => {
        let obj = {
            'image': image,
            'orgId': userDetails.orgId,
            'clinicId': clinicDetails.clinicId,
            'sessionId': openPhotoUploadDialog.treatmentData.skinTreatmentSessionId,
            patientId: openPhotoUploadDialog.treatmentData.patientId,
        }
        await uploadSkinBeforeImage(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                if (callFrom === "skin_treatment_details") {
                    getImageList()
                }
                setButtonHideShow(false);
                setImage(null);
                allImages = [];
                uploadImage = [];
                setClose(true);
                closeCamera();
                setClickType(null);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const uploadFile = () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait...",
        })
        let data = new FormData();
        for (const file of selectFolder) {
            data.append('images', file);
        }
        data.append('sessionId', openPhotoUploadDialog.treatmentData.skinTreatmentSessionId,);
        data.append('patientId', openPhotoUploadDialog.treatmentData.patientId);
        data.append('orgId', userDetails.orgId);
        data.append('clinicId', clinicDetails.clinicId);
        uploadSkinBeforeImageFile(data)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                if (callFrom === "skin_treatment_details") {
                    getImageList()
                };
                setClickType(null);
                setSelectFolder(null);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const nextFlow = () => {
        closeCamera();
        setButtonHideShow(false);
        setImage(null);
        allImages = [];
        uploadImage = [];
        setClose(true);
        setOpenPermissionDialog({ action: true, patientData: openPhotoUploadDialog.treatmentData });
        // setOpenPhotoUploadDialog({ action: false, treatmentData: null });
        setClickType(null);
        setSelectFolder(null);
    };

    return (
        <div>
            <material.Dialog open={openPhotoUploadDialog.action} fullWidth maxWidth="md" hideBackdrop>
                <material.DialogTitle>Add Photo
                    <span hidden={buttonHideShow}>
                        <material.IconButton color='primary' title='Add Photo' onClick={() => addPhoto("click_photo")}>
                            <material.AddAPhotoIcon />
                        </material.IconButton>
                        <material.IconButton color='primary' title='Upload Photo' sx={{ ml: 1 }} onClick={() => selectPhoto("upload_file")}>
                            <material.UploadFileIcon />
                        </material.IconButton>
                        {/* <material.Button sx={{ textTransform: "none", ml: 1 }} size='small' variant="outlined" onClick={addPhotoUsingSecondary} startIcon={<material.AddAPhotoIcon />}>Add Photo Using Secondary</material.Button> */}
                    </span>
                </material.DialogTitle>
                {clickType === "upload_file" ? (
                    <material.DialogContent>
                        <input
                            margin="dense"
                            id="image-upload"
                            label="Choose Photo"
                            accept=".jpg, .jpeg, .png"
                            fullWidth
                            type="file"
                            onChange={chooseFile}
                            multiple
                        />
                    </material.DialogContent>
                ) : (
                    <material.DialogContent>
                        <div className='py-2' hidden={close}>
                            {image ? (
                                <>
                                    <span>
                                        {allImages.length ? allImages.map((ele, i) => (
                                            <img
                                                key={i}
                                                src={ele.image}
                                                height={500} width="100%"
                                            />
                                        )) : ""}
                                    </span>
                                </>
                            ) : (
                                <span>
                                    {isWebcamActive ? (
                                        <Webcam
                                            height={500}
                                            width="100%"
                                            screenshotFormat="image/jpeg"
                                            ref={videoRef}
                                            videoConstraints={{
                                                ...videoConstraints,
                                                facingMode,

                                            }}
                                            style={{ objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <div style={{ height: "300px", width: "100%" }}></div>
                                    )}
                                    <span className='d-flex justify-content-center mt-2'>
                                        <material.Button sx={{ textTransform: "none", mr: 1 }} size='small' variant="outlined" onClick={switchCamera} startIcon={<material.CameraswitchIcon />}>Switch camera</material.Button>
                                        <material.Button sx={{ textTransform: "none" }} size='small' variant="outlined" onClick={capturePhoto} startIcon={<material.CameraAltIcon />}>Take photo</material.Button>
                                        <material.Button sx={{ textTransform: "none" }} size='small' variant="outlined" onClick={toggleCamera} className='ms-2'>
                                            {stream ? 'Close camera' : 'Open camera'}
                                        </material.Button>
                                    </span>
                                </span>
                            )}
                        </div>
                    </material.DialogContent>
                )}
                <material.DialogActions sx={{ m: 1 }}>
                    <material.Button sx={{ textTransform: "none", mr: 1 }} size='small' color='error' variant="outlined" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {(image || selectFolder) !== null ? (
                        <span>
                            {clickType === "upload_file" ? (
                                <material.Button sx={{ textTransform: "none" }} size='small' variant="outlined" onClick={uploadFile} startIcon={<material.UploadFileIcon />}>Upload File</material.Button>
                            ) : (
                                <material.Button sx={{ textTransform: "none" }} size='small' variant="outlined" onClick={savePhoto} startIcon={<material.UploadIcon />}>Upload Photo</material.Button>
                            )}
                        </span>
                    ) : null}
                    <material.Button sx={{ textTransform: "none" }} size='small' variant="outlined" onClick={nextFlow}>Next</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <PermissionDialog
                callFrom="Add Photo"
                setOpenConsentForm={setOpenConsentForm}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
                openPermissionDialog={openPermissionDialog}
                setOpenPermissionDialog={setOpenPermissionDialog}
            />
        </div>
    );
}

const PermissionDialog = (props) => {

    const { setOpenConsentForm, setOpenPhotoUploadDialog, setOpenPermissionDialog, openPermissionDialog } = props;
    const [addSkinTreatmentNots, setAddSkinTreatmentNots] = useState({ action: false, patientData: null });

    const reAssessment = (data) => {
        if (data === "yes") {
            setAddSkinTreatmentNots({ action: true, patientData: openPermissionDialog.patientData });
        } else {
            setOpenPermissionDialog({ action: false, patientData: null });
        };
    };

    return (
        <div>
            <material.Dialog maxWidth="auto" hideBackdrop open={openPermissionDialog.action}>
                <material.DialogContent className='d-flex flex-colum'>
                    Begin your treatment and record the session's settings. For multiple settings, simply press enter to move to the next line.
                    <span>
                        <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} color='error' size="medium" onClick={() => reAssessment("no")} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} size="medium" onClick={() => reAssessment("yes")}>Start</material.Button>
                    </span>
                </material.DialogContent>
            </material.Dialog>
            <SkinTreatmentNote
                addSkinTreatmentNots={addSkinTreatmentNots}
                setAddSkinTreatmentNots={setAddSkinTreatmentNots}
                callFrom="Add Photo"
                setOpenConsentForm={setOpenConsentForm}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddAndUploadPhotoDialog);