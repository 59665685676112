import React from 'react';
import { material } from '../../library/material';
import { StyledTableCell } from '../TableHeaderStyle';

function SalesAverageByPerPatient(props) {

    const { averageSalesData } = props;

    const renderTableRows = (entries, yearLabel) => {
        return (
            <>
                <material.TableRow sx={{ backgroundColor: "lightgreen" }}>
                    <material.TableCell colSpan="3" sx={{ fontSize: 15, fontWeight: "bold" }}>{yearLabel}</material.TableCell>
                </material.TableRow>
                {Object.entries(entries).map(([month, treatments], index) => (
                    <React.Fragment key={index}>
                        <material.TableRow sx={{ backgroundColor: "skyblue" }}>
                            <material.TableCell colSpan="3" sx={{ fontSize: 15, fontWeight: "bold" }}>{month}</material.TableCell>
                        </material.TableRow>
                        {treatments.map((treatment, idx) => (
                            <material.TableRow key={idx}>
                                <material.TableCell>{treatment.treatmentcategory}</material.TableCell>
                                <material.TableCell>{treatment.patientCount}</material.TableCell>
                                <material.TableCell>{Number(treatment.averageSalePerPatient).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</material.TableCell>
                            </material.TableRow>
                        ))}
                    </React.Fragment>
                ))}
            </>
        )
    };
    return (
        <div>
            <material.TableContainer sx={{ p: 2 }}>
                <h6>Average Sales Per Patient</h6>
                <material.Table>
                    <material.TableHead>
                        <material.TableRow>
                            <StyledTableCell>Treatment Category</StyledTableCell>
                            <StyledTableCell>Patient Count</StyledTableCell>
                            <StyledTableCell>Average Sale Per Patient</StyledTableCell>
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {renderTableRows(averageSalesData.currentFinancialYear, "Current Financial Year")}
                        {renderTableRows(averageSalesData.previousFinancialYear, "Previous Financial Year")}
                    </material.TableBody>
                </material.Table>
            </material.TableContainer>
        </div>
    );
}

export default SalesAverageByPerPatient;