import React from 'react';
import { Chart } from "react-google-charts";
import { connect } from 'react-redux';

function ColumnChart(props) {

    const { userData, clinicData, columnChartData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;


    const options = {
        title: `Rolling 31 Days of ${clinicDetails.clinicName}`,
        // isStacked: true,
        hAxis: {
            title: 'Date',
        },
        vAxis: {
            title: 'Sales Amount',
        },
        legend: { position: 'top' },
        // annotations: {
        //     alwaysOutside: true,
        //     textStyle: {
        //         fontSize: 12,
        //         auraColor: 'none',
        //         color: '#555'
        //     }
        // }
    };

    return (
        <div>
            <Chart
                chartType="ColumnChart"
                width="100%" height="400px"
                data={columnChartData}
                options={options}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ColumnChart);