import React, { useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { saveMachineList } from '../../services/ConfigureService';
import Snackbar from '../toastrmessage/Snackbar';

function AddMachine(props) {

    const { openAddMachine, setOpenAddMachine, userData, clinicData, getAllMachineList } = props;
    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const save = async (data) => {
        let obj = {
            ...data,
            clinicId: clinicDetails.clinicId
        }
        await saveMachineList(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Machine added successfully",
                })
                reset({ "machineName": "", "machineNumber": "", "machineDescription": "" })
                setOpenAddMachine(false)
                getAllMachineList()
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const handleClose = () => {
        reset({ "machineName": "", "machineNumber": "", "machineDescription": "" })
        setOpenAddMachine(false)
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth hideBackdrop open={openAddMachine}>
                <material.DialogTitle>Add Machine</material.DialogTitle>
                <material.DialogContent>
                    <div>
                        <material.TextField
                            error={errors.machineName?.type === "required"}
                            {...register("machineName", { required: true, minLength: 3 })}
                            margin="dense"
                            id="name"
                            label="Machine Name"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.machineName?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div>
                        <material.TextField
                            error={errors.machineNumber?.type === "required"}
                            {...register("machineNumber", { required: true })}
                            margin="dense"
                            id="name"
                            label="Machine Number"
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.machineNumber?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div>
                        <material.TextField
                            error={errors.machineDescription?.type === "required"}
                            {...register("machineDescription", { required: true, minLength: 3 })}
                            margin="dense"
                            id="name"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            multiline
                        />
                        <p className='form-text text-danger'>{errors.machineDescription?.type === "required" && 'This field is required'}</p>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="contained" sx={{ textTransform: "none" }} color="error">Cancel</material.Button>
                    <material.Button onClick={handleSubmit(save)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddMachine);