import React, { useState } from 'react';
import { material } from '../../../library/material';
import SignaturePad from 'react-signature-canvas';
import { saveConsentFormOpen, saveConsentForm } from '../../../services/ConsentFormService';
import Snackbar from '../../toastrmessage/Snackbar';
import { useNavigate } from 'react-router-dom';
import { socket } from '../../../socket/Socket';
import './ConsentForm.css';
import { useTheme } from '@mui/material';

var sigPad = {};

function HyalaseTreatment(props) {

    const { blankTreatmentData, openConsentForm, setGetConsentFormHTData, consentData, callForm } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const [refNo, setRefNo] = useState('');
    const [aw_text2, setAw_text2] = useState('');
    const [saveConsentData, setSaveConsentData] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [questions, setQuestions] = useState({
        aw_q1: "",
        aw_q2: "",
        aw_q3: "",
        aw_q4: "",
        aw_q5: "",
        aw_q6: "",
        aw_q7: "",
        aw_q8: "",
        aw_q9: "",
        aw_q10: "",
        aw_q11: "",
        aw_q12: "",
        aw_q13: "",
    });
    const selectQuestions = (qNumber, value) => {
        setQuestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");

    const saveConsent = async (data) => {
        if (openConsentForm.action === false) {
            let obj = {
                patientId: blankTreatmentData.patientId,
                treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                concent_type: "Hyalase Treatment",
                concent_data: { ...questions, aw_text2: aw_text2, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentForm(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Consent Form Submit successfully",
                    })
                    if (callForm === "primary-user") {
                        setTimeout(() => {
                            setGetConsentFormHTData("save")
                        }, 2000);
                    } else {
                        socket.emit("new-concent-added", "Hyalase Consent Form Submit successfully");
                        // navigate('/kiosk-menu')
                        setSaveConsentData(true);
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        } else {
            let obj = {
                refNo: refNo,
                concent_type: "Hyalase Treatment",
                concent_data: { ...questions, aw_text2: aw_text2, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentFormOpen(obj)
                .then((resp) => {
                    setGetConsentFormHTData(resp.data)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Consent Form Submit successfully",
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };
    };
    const clear = () => {
        sigPad.clear()
    };

    return (
        <div className={callForm === "secondary-user" ? "consent-form-body" : 'content'} id='form-section' hidden={saveConsentData}>
            <material.Paper elevation={3} sx={{
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '70%',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '70%',
                },
                mt: 2, m: 2, p: 3, pb: 10
            }}>
                <div className='mt-2 fw-bold'>
                    <span className='d-flex justify-content-center'>Consent Form for Hyalase Treatment (Dermal Filler Dissolution)</span>
                </div>
                <div className='mt-5 ms-5'>
                    <div hidden={openConsentForm.action === false || consentData} className='mb-3'>
                        <material.TextField
                            type='text'
                            variant="standard"
                            sx={{ width: "30%", ml: 1, mr: 1, mt: -1 }}
                            name='refNo'
                            label="Reference No"
                            onChange={e => setRefNo(e.target.value)}
                        />
                    </div>
                    <div className='mb-3'>
                        <span>
                            This consent form is designed to provide comprehensive information about the Hyalase treatment you will undergo today.
                        </span>
                    </div>
                    <h5>About Hyalase :</h5>
                    <div>
                        <span>
                            Hyalase (hyaluronidase 1500 units) is an enzyme used in aesthetic medicine to dissolve HA fillers. Before proceeding with Hyalase, a skin patch test is required. This test involves injecting Hyalase into the subcutaneous tissue of the forearm and observing for signs of reaction. A positive test result (indicating an allergic reaction) means Hyalase treatment cannot be administered.
                        </span>
                    </div>
                    <h5>Common Risk :</h5>
                    <div>
                        <span>
                            I understand that risks associated with Hyalase treatment for dissolving dermal fillers include, but are not limited to:
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q1}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                        value={questions.aw_q1}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <ul className='ms-3'>
                        <li>Localized discomfort or sensation of heat during treatment</li>
                        <li>Erythema (redness) or irritation at the treatment site, which are common and expected side effects</li>
                        <li>Swelling or tenderness</li>
                        <li>Mild bruising, which can occasionally be more significant</li>
                        <li>Temporary change in skin pigmentation</li>
                        <li>Infection at the treatment site</li>
                        <li>Incomplete dissolution of the filler, potentially necessitating additional treatment</li>
                        <li>Loss of volume and potential skin laxity, possibly impacting the aesthetic result</li>
                    </ul>
                    <h5>Uncommon Risk :</h5>
                    <div>
                        <span>
                            I acknowledge that less common and rare effects include, but are not limited to:
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q2}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                        value={questions.aw_q2}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <ul className='ms-3'>
                        <li>Anaphylaxis: a severe, potentially life-threatening allergic reaction requiring immediate medical attention</li>
                        <li>Scarring or keloid formation</li>
                        <li>Hyperpigmentation or hypopigmentation</li>
                        <li>Prolonged redness or sensitivity</li>
                        <li>Allergic reactions beyond the treatment site</li>
                    </ul>
                    <h5>
                        Patient Acknowledgements:
                    </h5>
                    <ul className='ms-3'>
                        <li>I have disclosed all relevant medical history.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q3}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q3", event.target.value)}
                                        value={questions.aw_q3}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I have no history of anaphylaxis reaction to bee or wasp stings.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q4}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q4", event.target.value)}
                                        value={questions.aw_q4}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I have no active infection or skin condition in the area to be treated.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q5}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q5", event.target.value)}
                                        value={questions.aw_q5}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I understand the treatment process, including the necessity of a patch test, and the time required for final results (up to one week).
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q6}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q6", event.target.value)}
                                        value={questions.aw_q6}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I acknowledge the need for possibly multiple treatments and will return for a follow-up in 1-2 weeks.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q7}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q7", event.target.value)}
                                        value={questions.aw_q7}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I will remain at the clinic for thirty minutes post-procedure for observation.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q8}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q8", event.target.value)}
                                        value={questions.aw_q8}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I have been informed about the procedure, including its potential benefits, limitations, and alternatives.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q9}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q9", event.target.value)}
                                        value={questions.aw_q9}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I understand that results are not guaranteed, and I accept the risks, side effects, and potential complications associated with Hyalase treatment.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q10}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q10", event.target.value)}
                                        value={questions.aw_q10}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I am aware that no refunds will be provided once the treatment is administered.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q11}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q11", event.target.value)}
                                        value={questions.aw_q11}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                    </ul>
                    <h5>Financial Consent</h5>
                    <span>
                        I, {consentData ? openConsentForm?.patientData?.patientName : openConsentForm?.patientData?.name}, consent to the cost of my Hyalase treatment, amounting to
                        {consentData ? (
                            <material.TextField
                                id="input-with-icon-textfield"
                                InputProps={{
                                    startAdornment: (
                                        <material.InputAdornment position="start">
                                            <material.AttachMoneyIcon />
                                        </material.InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                type='number'
                                sx={{ width: "20%", ml: 1, mr: 1, mt: -1 }}
                                name='aw_text2'
                                onChange={e => setAw_text2(e.target.value)}
                                value={consentData ? consentData.aw_text2 : ""}
                            />
                        ) : (
                            <material.TextField
                                id="input-with-icon-textfield"
                                InputProps={{
                                    startAdornment: (
                                        <material.InputAdornment position="start">
                                            <material.AttachMoneyIcon />
                                        </material.InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                type='number'
                                sx={{ width: "20%", ml: 1, mr: 1, mt: -1 }}
                                name='aw_text2'
                                onChange={e => setAw_text2(e.target.value)}
                            />
                        )} (Excluding Surcharge)
                        . I understand that additional charges may apply if further treatment is required. In the event of complications necessitating specialist medical intervention, I am aware that the specialist may charge additional fees.
                    </span>
                    <h5 className='mt-2'>Photo Consent</h5>
                    <div className='mt-3'>
                        <span>
                            I consent to before and after photos taken for clinical use:
                        </span>
                        {consentData ? (
                            <span className='me-2'>{consentData.aw_q12}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, mr: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q12", event.target.value)}
                                        value={questions.aw_q12}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <div className='mt-3'>
                        <span>
                            I consent to before and after photos taken for marketing and advertisement purposes:
                        </span>
                        {consentData ? (
                            <span className='me-2'>{consentData.aw_q13}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, mr: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q13", event.target.value)}
                                        value={questions.aw_q13}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                        <div className='mt-3'>
                            <span>
                                By signing below, I confirm that I understand the risks, benefits, and instructions associated with the Hyalase treatment. I have had the opportunity to ask questions and have received satisfactory answers. I hereby give my informed consent to undergo the Hyalase treatment.
                            </span>
                        </div>
                    </div>
                    <div className='mt-5 d-flex'>
                        <span>Patient Signature</span>
                        {consentData ? (
                            <span className='ms-3'>
                                <img
                                    src={consentData.patientSignature}
                                />
                            </span>
                        ) : (
                            <>
                                <span className='sigBox ms-3'>
                                    <SignaturePad penColor='black'
                                        canvasProps={{ width: 400, height: 100, className: 'sigPad' }}
                                        ref={(ref) => { sigPad = ref }}
                                    />
                                </span>
                                <span className='ms-2'>
                                    <material.Button size='small' sx={{ textTransform: "none" }} onClick={clear}>Clear</material.Button>
                                </span>
                            </>
                        )}
                    </div>
                    <span className='float-end mt-3 pe-3'>
                        <material.Button variant="contained" hidden={consentData} sx={{ textTransform: "none" }} onClick={saveConsent} disabled={isValid}>Save Consent Form</material.Button>
                    </span>
                </div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Paper>
        </div>
    );
}

export default HyalaseTreatment;