import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import AddVendor from '../../dialog/AddVendor';
import { getVendorByClinicId, getVendorByOrgId } from '../../../services/VendorManagementService';
import { connect } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';


function VendorManagement(props) {

    const { userData, clinicData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openAddVendor, setOpenAddVendor] = useState({ action: false, supplierData: null });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isLoading, setisLoading] = useState(true);
    const [vendorsData, setVendorsData] = useState([]);
    const userDetails = userData.authReducer.data;

    localStorage.setItem("menuName", "Supplier List");

    useEffect(() => {
        getVendors()
    }, []);

    const getVendors = async () => {
        if (userDetails.role.includes("MANAGER")) {
            await getVendorByClinicId(clinicDetails.clinicId)
                .then((res) => {
                    setVendorsData(res.data)
                    setisLoading(false)
                })
        } else {
            await getVendorByOrgId(userDetails.orgId)
                .then((res) => {
                    setVendorsData(res.data)
                    setisLoading(false)
                })
        }
    };

    const addVendor = () => {
        setOpenAddVendor({ action: true, supplierData: null })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const editSupplier = (rowData) => {
        setOpenAddVendor({ action: true, supplierData: rowData })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className="col-lg-6"></div>
                    <div className='col-lg-6'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addVendor}> Add-Supplier</material.Button>
                        </span>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <material.Paper sx={{ width: "100%", overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 500 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Company Name</StyledTableCell>
                                            <StyledTableCell>Manager</StyledTableCell>
                                            <StyledTableCell>Owner</StyledTableCell>
                                            <StyledTableCell>Contact Name</StyledTableCell>
                                            <StyledTableCell>Contact Number</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Registered Office</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell align="right">Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {vendorsData.length ? vendorsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 },
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.companyName}</material.TableCell>
                                                        <material.TableCell size='small'>{row.manager}</material.TableCell>
                                                        <material.TableCell size='small'>{row.owner}</material.TableCell>
                                                        <material.TableCell size='small'>{row.contactName}</material.TableCell>
                                                        <material.TableCell size='small'>{row.contactNumber}</material.TableCell>
                                                        <material.TableCell size='small'>{row.email}</material.TableCell>
                                                        <material.TableCell size='small'>{row.registeredOffice}</material.TableCell>
                                                        <material.TableCell size='small'>
                                                            {row.active ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>De-active</p>)}
                                                        </material.TableCell>
                                                        <material.TableCell size='small' align="right">
                                                            <material.IconButton title='Edit Supplier' aria-label="create" size="large" onClick={() => editSupplier(row)}>
                                                                <material.CreateIcon color='primary' />
                                                            </material.IconButton>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                                <hr />
                            </material.TableContainer>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={vendorsData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
                <AddVendor
                    open={openAddVendor}
                    setOpen={setOpenAddVendor}
                    getVendors={getVendors}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(VendorManagement);