import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { getPatientCreditList, getPatientPurchaseHistory } from '../../../services/PatientService';
import AddPatientCredit from './AddPatientCredit';
import moment from 'moment';

var purchaseAllData = [];

function PurchaseHistory(props) {

    const { patientData } = props;
    const [purchaseData, setPurchaseData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [category, setCategory] = useState([]);
    const [patientCreditList, setPatientCreditList] = useState([]);
    const [openPatientCreditDialog, setOpenPatientCreditDialog] = useState({ action: false, patientData: null });
    const [availableBalance, setAvailableBalance] = useState(null);

    useEffect(() => {
        getPatientPurchaseHistoryData();
        getPatientCreditListData();
    }, []);

    const getPatientPurchaseHistoryData = async () => {
        await getPatientPurchaseHistory(patientData.patientId)
            .then((resp) => {
                let category = [... new Set(resp.data.map((ele) => ele.category))];
                setCategory(category)
                purchaseAllData = resp.data;
                setPurchaseData(resp.data)
            })
            .catch((error) => {

            })
    };

    const getPatientCreditListData = () => {
        let payload = {
            patientId: patientData.patientId,
            onlyNotUsed: false
        }
        getPatientCreditList(payload)
            .then((resp) => {
                setAvailableBalance(resp.data[0]?.balance);
                setPatientCreditList(resp.data)
            })
            .catch((error) => {

            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filterByCategory = (e, value) => {
        setPage(0);
        if (value !== null) {
            const filterCategory = purchaseAllData.filter((ele) => ele.category === value);
            setPurchaseData(filterCategory);
        } else {
            setPurchaseData(purchaseAllData);
        }
    };

    const addCredit = () => {
        setOpenPatientCreditDialog({ action: true, patientData: patientData });
    };

    return (
        <div>
            <material.Accordion>
                <material.AccordionSummary
                    expandIcon={<material.ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <material.Typography variant='h6'>Patient Credits</material.Typography>
                </material.AccordionSummary>
                <material.AccordionDetails>
                    <div className='row'>
                        <div className="col-lg-12 mb-3">
                            <span style={{ fontWeight: "bold" }}>
                                Available Balance: {availableBalance}
                            </span>
                            <span className='float-end'>
                                <material.Button variant="contained" size='medium' sx={{ textTransform: "none" }} onClick={addCredit}>Add Credit</material.Button>
                            </span>
                        </div>
                        <div className='col-lg-12'>
                            <material.TableContainer sx={{ maxHeight: "50vh" }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Credit Id</StyledTableCell>
                                            <StyledTableCell>Date</StyledTableCell>
                                            <StyledTableCell style={{ width: "40rem" }}>Purpose</StyledTableCell>
                                            <StyledTableCell>Type</StyledTableCell>
                                            <StyledTableCell>Amount</StyledTableCell>
                                            <StyledTableCell>Balance</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {patientCreditList.length ? patientCreditList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, k) => (
                                            <material.TableRow key={k}
                                                sx={{ backgroundColor: item.type === "CREDIT" ? "rgb(166, 243, 166)" : "#f6b7b7", color: "#fff" }}
                                            >
                                                <material.TableCell>{item.creditId}</material.TableCell>
                                                <material.TableCell>{moment(item.date).format("DD-MM-YYYY")}</material.TableCell>
                                                <material.TableCell className='break-cell'>{item.description}</material.TableCell>
                                                <material.TableCell>{item.type}</material.TableCell>
                                                <material.TableCell>{item.amount}</material.TableCell>
                                                <material.TableCell>{item.balance}</material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={patientCreditList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </material.AccordionDetails>
            </material.Accordion>
            <material.Accordion sx={{ mt: 3 }}>
                <material.AccordionSummary
                    expandIcon={<material.ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <material.Typography variant='h6'>Purchase History</material.Typography>
                </material.AccordionSummary>
                <material.AccordionDetails>
                    <div className='row'>
                        <span>
                            <material.Autocomplete
                                fullWidth
                                options={category}
                                onChange={filterByCategory}
                                renderInput={(params) =>
                                    <material.TextField {...params}
                                        label="Filter by Category"
                                        sx={{ width: "40ch" }}
                                        variant="standard"
                                    />}
                            />
                        </span>
                        <div className='col-lg-12 mt-3'>
                            <material.TableContainer sx={{ maxHeight: "50vh" }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Date</StyledTableCell>
                                            <StyledTableCell>Generated By</StyledTableCell>
                                            <StyledTableCell>Sales Account</StyledTableCell>
                                            <StyledTableCell>Category</StyledTableCell>
                                            <StyledTableCell>Treatment Name</StyledTableCell>
                                            <StyledTableCell>Treatment Area</StyledTableCell>
                                            <StyledTableCell>Product</StyledTableCell>
                                            <StyledTableCell>Units</StyledTableCell>
                                            <StyledTableCell>Price</StyledTableCell>
                                            <StyledTableCell>Total</StyledTableCell>
                                            <StyledTableCell>Discount</StyledTableCell>
                                            <StyledTableCell>Total Payable</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {purchaseData.length ? purchaseData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, k) => (
                                            <material.TableRow key={k}>
                                                <material.TableCell>{item.date}</material.TableCell>
                                                <material.TableCell>{item.generatedBy}</material.TableCell>
                                                <material.TableCell>{item.salesAccount}</material.TableCell>
                                                <material.TableCell>{item.category}</material.TableCell>
                                                <material.TableCell>{item.treatmentName}</material.TableCell>
                                                <material.TableCell>{item.treatmentArea}</material.TableCell>
                                                <material.TableCell>{item.product}</material.TableCell>
                                                <material.TableCell>{item.units}</material.TableCell>
                                                <material.TableCell>{item.price}</material.TableCell>
                                                <material.TableCell>{item.total}</material.TableCell>
                                                <material.TableCell>{item.discount}</material.TableCell>
                                                <material.TableCell>{item.totalPayable}</material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow >
                                                <material.TableCell colSpan={12}>
                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={purchaseData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </material.AccordionDetails>
            </material.Accordion>
            <AddPatientCredit
                openPatientCreditDialog={openPatientCreditDialog}
                setOpenPatientCreditDialog={setOpenPatientCreditDialog}
                getPatientCreditListData={getPatientCreditListData}
            />
        </div>
    );
}

export default PurchaseHistory;