import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { getAllPAtients } from '../../../services/PatientService';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { getEmailSubject, saveSchedule } from '../../../services/AppointmentService';
import Snackbar from '../../toastrmessage/Snackbar';
import ViewMachineSchedule from '../../dialog/ViewMachineSchedule';
import ViewRoomSchedule from '../../dialog/ViewRoomSchedule';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

var allPatientDetails = [];

function CreateAppointment(props) {

    const { userData, clinicData, showModal, setShowModal, selectedEvent, formData, getAllScheduleData, setOpenViewAppointment } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const userDetails = userData.authReducer.data;
    const {
        register, handleSubmit, reset, resetField, setValue, control, formState: { errors, isValid }, } = useForm({
            mode: "onTouched",
        });
    const [addNewPatient, setAddNewPatient] = useState(false);
    const [patientId, setPatientId] = useState(null);
    const [patientsName, setPatientsName] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectTimeDuration, setSelectTimeDuration] = useState("");
    const [emailSubject, setEmailSubject] = useState([]);
    const [treatmentName, setTreatmentName] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [openBookedMachineSchedule, setOpenBookedMachineSchedule] = useState({ action: false, data: null });
    const [openRoomBookedSchedule, setOpenRoomBookedSchedule] = useState({ action: false, data: null });
    const [getDurationAndInstructionData, setGetDurationAndInstructionData] = useState([]);
    const [changeEmailSubject, setChangeEmailSubject] = useState(false);
    const [getDurationData, setGetDurationData] = useState([]);
    const [phone, setPhone] = useState("");
    const [selectValue, setSelectValue] = useState("In Clinic Consultation");

    useEffect(() => {
        setValue("RoomName", openRoomBookedSchedule.data?.roomName);
        setValue("RoomNumber", openRoomBookedSchedule.data?.roomNumber);
    }, [openRoomBookedSchedule.data]);

    useEffect(() => {
        setValue("MachineName", openBookedMachineSchedule.data?.machineName);
        setValue("MachineNumber", openBookedMachineSchedule.data?.machineNumber);
    }, [openBookedMachineSchedule.data]);

    useEffect(() => {
        if (showModal && selectedEvent === null) {
            getAllPatientData()
            setStartDate(dayjs(formData.start))
            getEmailSubjectData();
        } else if (showModal && selectedEvent !== null) {
            getEmailSubjectData();
            getAllPatientData()
            reset(selectedEvent.resource);
            setStartDate(dayjs(selectedEvent.resource.start))
            setEndDate(dayjs(selectedEvent.resource.end));
            setSelectTimeDuration(selectedEvent.resource.duration);
            setValue("EndTime", moment(selectedEvent.resource.end).format("HH:mm"));
        }
    }, [showModal === true]);

    useEffect(() => {
        if (showModal && selectedEvent !== null && startDate !== null) {
            getDuration(selectedEvent.resource.duration)
        }
    }, [showModal === true, startDate?.$d]);

    const getAllPatientData = async () => {
        await getAllPAtients(clinicDetails.clinicId)
            .then((resp) => {
                allPatientDetails = resp.data;
                let patientName = resp.data.map((ele) => ele.name);
                setPatientsName(patientName);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            });
    };

    const getEmailSubjectData = () => {
        getEmailSubject(clinicDetails.clinicId)
            .then((resp) => {
                setEmailSubject(resp.data);
                const treatmentName = resp.data?.map((ele) => ele.treatmentName);
                setTreatmentName(treatmentName);
                if (selectedEvent !== null) {
                    const matchEmailSubject = resp.data?.filter((ele) => ele.treatmentName == selectedEvent.resource.title);
                    if (matchEmailSubject?.length) {
                        setChangeEmailSubject(true)
                        const getDuration = matchEmailSubject[0].data.map((resp) => resp.duration);
                        setGetDurationData(getDuration)
                        setGetDurationAndInstructionData(matchEmailSubject[0].data)
                    } else {
                        setChangeEmailSubject(false)
                    }
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            });
    };

    const newPatient = () => {
        setAddNewPatient(true);
    };

    const selectPatient = (e, value) => {
        let data = allPatientDetails.filter((ele) => ele.name === value);
        setPatientId(data[0].patientId);
        setValue("Email", data[0].email);
        setValue("PhoneNumber", data[0].phoneNumber);
    };

    const handleChangeTimeDuration = (event) => {
        setSelectTimeDuration(event.target.value);
        getDuration(event.target.value);
        setValue("Purpose", "");
    };

    const handleSelectTimeDuration = (e, newValue) => {
        if (newValue !== null) {
            getDuration(newValue);
            const purpose = getDurationAndInstructionData?.filter((ele) => ele.duration === newValue)[0];
            setValue("Purpose", purpose.instructions);
        } else {
            setValue("EndTime", "");
            setValue("Purpose", "");
        }
    };

    const getDuration = (duration) => {
        const timeArray = duration.split(" ");
        const hoursIndex = timeArray.indexOf("hour");
        const minutesIndex = timeArray.indexOf("min");
        let hours;
        let minutes;
        if (hoursIndex > 0 && minutesIndex < 0) {
            hours = Number(timeArray[hoursIndex - 1]);
            const time = hours * 60;
            let getTimeDuration = calculateNextTime(
                moment(startDate.$d).format("HH:mm"),
                time
            );
            setValue("EndTime", getTimeDuration);
        } else if (minutesIndex > 0 && hoursIndex < 0) {
            minutes = Number(timeArray[minutesIndex - 1]);
            const time = minutes;
            let getTimeDuration = calculateNextTime(
                moment(startDate.$d).format("HH:mm"),
                time
            );
            setValue("EndTime", getTimeDuration);
        } else if (hoursIndex > 0 && minutesIndex > 0) {
            hours = Number(timeArray[hoursIndex - 1]);
            minutes = Number(timeArray[minutesIndex - 1]);
            const time = hours * 60 + minutes;
            let getTimeDuration = calculateNextTime(
                moment(startDate.$d).format("HH:mm"),
                time
            );
            setValue("EndTime", getTimeDuration);
        }
    };

    const calculateNextTime = (givenTime, duration) => {
        const [givenHours, givenMinutes] = givenTime.split(":").map(Number);
        const givenDate = new Date();
        givenDate.setHours(givenHours);
        givenDate.setMinutes(givenMinutes);
        givenDate.setTime(givenDate.getTime() + duration * 60000);
        const nextTime = `${String(givenDate.getHours()).padStart(
            2,
            "0"
        )}:${String(givenDate.getMinutes()).padStart(2, "0")}`;
        return moment(nextTime, "HH:mm").format("HH:mm");
    };

    const saveAppointment = async (data) => {
        const payload = {
            Id: selectedEvent ? data.Id : "",
            InjectorId: selectedEvent ? selectedEvent.resource.InjectorId : formData.injectorId,
            ClinicId: clinicDetails.clinicId,
            title: data.title,
            PatientName: data.PatientName,
            Email: data.Email,
            PhoneNumber: addNewPatient ? phone : data.PhoneNumber,
            start: moment(startDate.$d).format("YYYY-MM-DD HH:mm"),
            end:
                moment(startDate.$d).format("YYYY-MM-DD") +
                " " +
                moment(data.EndTime, "HH:mm A").format("HH:mm"),
            MachineName: data.MachineName,
            MachineNumber: data.MachineNumber,
            MachineId: openBookedMachineSchedule.data?.machineId,
            Purpose: data.Purpose,
            RoomName: data.RoomName,
            RoomNumber: data.RoomNumber,
            RoomId: openRoomBookedSchedule.data?.roomId,
            startTime: moment(startDate.$d).utc(),
            duration: data.duration,
            patientId: selectedEvent ? data.patientId : patientId,
            rescheduledCounter: selectedEvent ? selectedEvent.rescheduledCounter : 0,
            appointmentType: data.appointmentType,
        }
        await saveSchedule(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: "Appointment created successfully",
                });
                getAllScheduleData();
                setChangeEmailSubject(false);
                if (selectedEvent) {
                    setOpenViewAppointment(false)
                }
                setAddNewPatient(false);
                reset({
                    title: "",
                    PatientName: "",
                    Email: "",
                    PhoneNumber: "",
                    MachineNumber: "",
                    MachineName: "",
                    RoomName: "",
                    RoomNumber: "",
                    Purpose: "",
                    EndTime: "",
                    treatmentName: ""
                });
                setSelectTimeDuration("");
                setShowModal(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            });
    };

    const selectEmailSubject = (e, newValue) => {
        setValue("duration", "");
        setValue("Purpose", "");
        setGetDurationData([]);
        if (newValue !== null) {
            const filterData = emailSubject.filter((ele) => ele.treatmentName === newValue)[0];
            const getDuration = filterData?.data.map((resp) => resp.duration);
            setGetDurationData(getDuration)
            setChangeEmailSubject(true);
            setGetDurationAndInstructionData(filterData.data)
        } else {
            setGetDurationAndInstructionData([]);
            setValue("EndTime", "");
            setValue("duration", "");
            setValue("Purpose", "");
            setChangeEmailSubject(false);
        }
    };

    const showBookedMachineSlot = () => {
        setOpenBookedMachineSchedule({ action: true });
    };

    const showRoomBookedSlot = () => {
        setOpenRoomBookedSchedule({ action: true });
    };

    const handleClose = () => {
        reset({
            title: "",
            PatientName: "",
            Email: "",
            PhoneNumber: "",
            MachineNumber: "",
            MachineName: "",
            RoomName: "",
            RoomNumber: "",
            Purpose: "",
            EndTime: "",
            treatmentName: ""
        });
        setChangeEmailSubject(false);
        setAddNewPatient(false);
        setSelectTimeDuration("");
        setShowModal(false);
    };

    const handleChange = (e) => {
        setSelectValue(e.target.value)
    };

    return (
        <div>
            <material.Dialog open={showModal} hideBackdrop fullWidth maxWidth="md">
                <material.DialogTitle>
                    {selectedEvent ? "Edit Schedule" : "Add New Schedule"}
                </material.DialogTitle>
                <material.DialogContent>
                    <form>
                        <div className="row">
                            <div>
                                <material.Typography>Patient Details</material.Typography>
                                {/* {event ? null : ( */}
                                <span className="float-end" style={{ marginTop: -25 }}>
                                    <material.Button
                                        variant="contained"
                                        sx={{ textTransform: "none" }}
                                        onClick={newPatient}
                                        hidden={addNewPatient}
                                    >
                                        New Patient
                                    </material.Button>
                                </span>
                                {/* )} */}
                            </div>
                            {addNewPatient ? (
                                <>
                                    <div className="col-6">
                                        <material.TextField
                                            error={errors.patientName?.type === "required"}
                                            {...register("PatientName", { required: true })}
                                            required
                                            id="patientName"
                                            label="Patient Name"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                        />
                                        <p className="form-text text-danger">
                                            {errors.patientName?.type === "required" &&
                                                "This field is required"}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <material.TextField
                                            error={errors.email?.type === "required"}
                                            {...register("Email", { required: true })}
                                            required
                                            id="email"
                                            label="Email"
                                            type="email"
                                            fullWidth
                                            variant="standard"
                                        />
                                        <p className="form-text text-danger">
                                            {errors.email?.type === "required" &&
                                                "This field is required"}
                                        </p>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <PhoneInput
                                            required
                                            placeholder="Phone"
                                            defaultCountry="AU"
                                            inputRef={register}
                                            enableSearch={true}
                                            value={phone}
                                            control={control}
                                            international
                                            rules={{ required: true }}
                                            onChange={(phone) => setPhone(phone)}
                                            error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-6">
                                        <material.Autocomplete
                                            id="patientName"
                                            fullWidth
                                            onChange={selectPatient}
                                            defaultValue={selectedEvent?.resource.PatientName}
                                            readOnly={selectedEvent === null ? false : true}
                                            options={patientsName}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    {...params}
                                                    required
                                                    variant="standard"
                                                    label="Select Patient"
                                                    {...register("PatientName", { required: true })}
                                                />
                                            )}
                                        />
                                        <p className="form-text text-danger">
                                            {errors.patientName?.type === "required" &&
                                                "This field is required"}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <material.TextField
                                            error={errors.email?.type === "required"}
                                            {...register("Email", { required: true })}
                                            required
                                            id="email"
                                            label="Email"
                                            type="email"
                                            fullWidth
                                            variant="standard"
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        <p className="form-text text-danger">
                                            {errors.email?.type === "required" &&
                                                "This field is required"}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <material.TextField
                                            error={errors.phoneNumber?.type === "required"}
                                            {...register("PhoneNumber", { required: true })}
                                            required
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ readOnly: true }}
                                            id="phoneNumber"
                                            label="Phone Number"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                        />
                                        <p className="form-text text-danger">
                                            {errors.phoneNumber?.type === "required" &&
                                                "This field is required"}
                                        </p>
                                    </div>
                                </>
                            )}
                            <div className="col-6">
                                <material.Autocomplete
                                    id="patientName"
                                    fullWidth
                                    freeSolo
                                    defaultValue={selectedEvent?.resource?.title}
                                    onChange={selectEmailSubject}
                                    options={treatmentName}
                                    // getOptionLabel={(option) => option.treatmentName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            {...params}
                                            label="Email Subject"
                                            variant="standard"
                                            error={errors.title?.type === "required"}
                                            {...register("title", { required: true })}
                                        />
                                    )}
                                />
                            </div>
                            <material.Typography>Calender</material.Typography>
                            <div className="col-4">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DateTimePicker
                                        label="Start"
                                        format="DD-MM-YYYY, HH:mm"
                                        readOnly={selectedEvent === null ? true : false}
                                        value={startDate}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        sx={{ width: "100%" }}
                                        slotProps={{ textField: { variant: "standard" }, readOnly: true }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-4">
                                {!changeEmailSubject ? (
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel
                                            id="demo-simple-select-standard-label"
                                            required
                                        >
                                            Time Duration
                                        </material.InputLabel>
                                        <material.Select
                                            {...register("duration", { required: true })}
                                            required
                                            label="Unit"
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            fullWidth
                                            value={selectTimeDuration}
                                            onChange={handleChangeTimeDuration}
                                        >
                                            <material.MenuItem value={"15 min"}>15 min</material.MenuItem>
                                            <material.MenuItem value={"30 min"}>30 min</material.MenuItem>
                                            <material.MenuItem value={"45 min"}>45 min</material.MenuItem>
                                            <material.MenuItem value={"1 hour"}>1 hour</material.MenuItem>
                                            <material.MenuItem value={"1 hour 15 min"}>
                                                1 hour 15 min
                                            </material.MenuItem>
                                            <material.MenuItem value={"1 hour 30 min"}>
                                                1 hour 30 min
                                            </material.MenuItem>
                                            <material.MenuItem value={"1 hour 45 min"}>
                                                1 hour 45 min
                                            </material.MenuItem>
                                            <material.MenuItem value={"2 hour"}>2 hour</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                ) : (
                                    <material.Autocomplete
                                        id="patientName"
                                        fullWidth
                                        freeSolo
                                        onChange={handleSelectTimeDuration}
                                        options={getDurationData}
                                        defaultValue={selectedEvent?.resource?.duration}
                                        // getOptionLabel={(option) => option.duration}
                                        renderInput={(params) => (
                                            <material.TextField
                                                required
                                                {...params}
                                                label="Time Duration"
                                                variant="standard"
                                                error={errors.title?.type === "required"}
                                                {...register("duration", { required: true })}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <div className="col-4">
                                <material.TextField
                                    error={errors.EndTime?.type === "required"}
                                    {...register("EndTime", { required: true })}
                                    id="roomNumber"
                                    label="End Time"
                                    type="text"
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div>
                                <material.Typography>Configure Room</material.Typography>
                                {selectedEvent ? null : (
                                    <span className="float-end" style={{ marginTop: -25 }}>
                                        <material.Button
                                            variant="contained"
                                            sx={{ textTransform: "none" }}
                                            onClick={showRoomBookedSlot}
                                        >
                                            Show Booked Room Slot
                                        </material.Button>
                                    </span>
                                )}
                            </div>
                            <div className="col-6">
                                <material.TextField
                                    error={errors.roomNumber?.type === "required"}
                                    {...register("RoomNumber")}
                                    id="roomNumber"
                                    label="Room Number"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                />
                                <p className="form-text text-danger">
                                    {errors.roomNumber?.type === "required" &&
                                        "This field is required"}
                                </p>
                            </div>
                            <div className="col-6">
                                <material.TextField
                                    error={errors.roomName?.type === "required"}
                                    {...register("RoomName")}
                                    id="roomName"
                                    label="Room Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                />
                                <p className="form-text text-danger">
                                    {errors.roomName?.type === "required" &&
                                        "This field is required"}
                                </p>
                            </div>
                            <div>
                                <material.Typography>Configure Machine</material.Typography>
                                {selectedEvent ? null : (
                                    <span className="float-end" style={{ marginTop: -25 }}>
                                        <material.Button
                                            variant="contained"
                                            sx={{ textTransform: "none" }}
                                            onClick={showBookedMachineSlot}
                                        >
                                            Show Booked Machine Slot
                                        </material.Button>
                                    </span>
                                )}
                            </div>
                            <div className="col-6">
                                <material.TextField
                                    {...register("MachineNumber")}
                                    id="machineNumber"
                                    label="Machine Number"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div className="col-6">
                                <material.TextField
                                    {...register("MachineName")}
                                    id="machineName"
                                    label="Machine Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div>
                                <material.TextField
                                    error={errors.Purpose?.type === "required"}
                                    {...register("Purpose")}
                                    multiline
                                    id="purpose"
                                    label="Instructions for Patient"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    rows={3}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div className="mt-2">
                                <material.FormControl variant="standard" required fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Type of Appointment (Please choose either 'In Clinic' or 'Phone' Consultation)</material.InputLabel>
                                    <material.Select
                                        {...register("appointmentType", { required: true })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectValue}
                                        onChange={handleChange}
                                    >
                                        <material.MenuItem value="In Clinic Consultation">In Clinic Consultation</material.MenuItem>
                                        <material.MenuItem value="Phone Consultation">Phone Consultation</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </form>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={handleClose}>
                        Close
                    </material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} onClick={handleSubmit(saveAppointment)}>
                        {selectedEvent ? "Update" : "Save"}
                    </material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
            <ViewMachineSchedule
                openBookedMachineSchedule={openBookedMachineSchedule}
                setOpenBookedMachineSchedule={setOpenBookedMachineSchedule}
            />
            <ViewRoomSchedule
                openRoomBookedSchedule={openRoomBookedSchedule}
                setOpenRoomBookedSchedule={setOpenRoomBookedSchedule}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(CreateAppointment);