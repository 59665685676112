import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { material } from '../../library/material'
import { getClinicList, getOrgList, inviteUser, userRegisterByAdmin, userRegisterBySuperAdmin } from '../../services/UserManagementService';
import Snackbar from '../toastrmessage/Snackbar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateAndTimeFormat, dateFormat, changeDateFormate } from '../../date-and-time-format/DateAndTimeFormat';
import { useTheme } from '@mui/material/styles';
import { getHighestPriorityRole } from '../../highest-priority-role/HighestPriorityRole';
import dayjs from 'dayjs';

let organisationDetails;
let organisationId;
let clinicDetalis;
let clinicId;

const roles = [
    { role: "ADMIN", user: "SUPERADMIN" },
    { role: "PRESCRIBER", user: "SUPERADMIN" },
    { role: "MANAGER", user: "ADMIN" },
    { role: "INJECTOR", user: "ADMIN" },
    { role: "THERAPIST", user: "ADMIN" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function InviteUser(props) {
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const { openInvitePopup, setOpenInvitePopup, userData, getAllUserList, setisLoading } = props;

    let userDetails = userData.authReducer.data;
    let userRole = getHighestPriorityRole(userDetails.role)
    const theme = useTheme();
    const [role, setRole] = useState("");
    const [multipleRole, setMultipleRole] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [organisation, setOrganisation] = useState([]);
    const [clinicData, setClinicData] = useState([]);
    const [dateOfBirth, setDateOfBirth] = useState(dayjs(new Date()));
    const [date, setDate] = useState(dayjs(new Date()));
    const [selectValue, setSelectValue] = useState('');
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [startDate, setStartDate] = useState(dayjs(new Date()));
    const [usersRole, setUsersRole] = useState([]);

    useEffect(() => {
        if (userRole === "SUPERADMIN") {
            getAllOrg();
        } else if (userRole === "ADMIN") {
            getAllClinic();
        }
        let role = roles.filter((ele) => ele.user === userRole).map((res) => res.role)
        setUsersRole(role)
    }, []);

    const getAllOrg = async () => {
        await getOrgList()
            .then((resp) => {
                organisationDetails = resp.data
                let orgName = resp.data.map((ele) => ele.orgName)
                setOrganisation(orgName)
            })
    };

    const getAllClinic = async () => {
        await getClinicList()
            .then((resp) => {
                clinicDetalis = resp.data
                let clinicName = resp.data.map((ele) => ele.clinicName)
                setClinicData(clinicName)
            })
    };

    const selectOrg = (e, value) => {
        let orgId = organisationDetails.filter((ele) => ele.orgName === value).map((element) => element.orgId)
        organisationId = orgId
    };

    const handleClose = () => {
        setOpenInvitePopup({ open: false, action: "" });
        reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
        setRole("");
        setMultipleRole([]);
        setDateOfBirth(dayjs(new Date()));
        setDate(dayjs(new Date()));
        setEndDate(dayjs(new Date()));
    };

    const roleChange = (event) => {
        setRole(event.target.value)
    };

    const selectMultipleRole = (event) => {
        setMultipleRole(event.target.value)
    };

    const selectClinic = (e, value) => {
        let id = clinicDetalis.filter((ele) => ele.clinicName === value).map((res) => res.clinicId)
        clinicId = id
    };

    const selectMultipleClinic = (e, value) => {
        let id = clinicDetalis.filter((ele) => value.includes(ele.clinicName)).map((res) => res.clinicId)
        clinicId = id
    };

    const handleChange = (event) => {
        setSelectValue(event.target.value)
    };

    const inviteUserByMail = async (fromData) => {
        await inviteUser(fromData).then((res) => {
            reset({ email: "" })
            setRole("")
            setMultipleRole([])
            setOpenSnackBar({
                "action": true,
                "type": "success",
                "message": "Invite send successfully"
            })
            setOpenInvitePopup({ open: false, action: "" });
        }).catch((error) => {
            // console.log()
            setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": error.message
            })
        })
    };

    const addUserByOrg = async (fromData) => {
        if (userRole === "SUPERADMIN") {
            if (role === "ADMIN") {
                let obj = {
                    "firstName": fromData.firstName,
                    "lastName": fromData.lastName,
                    "dateOfBirth": dateFormat(dateOfBirth.$d),
                    "role": [fromData.role],
                    "email": fromData.email,
                    "orgId": organisationId[0],
                    "password": fromData.password
                }
                await userRegisterBySuperAdmin(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": "User add successfully"
                        })
                        setOpenInvitePopup({ open: false, action: "" });
                        reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
                        setRole("");
                        setMultipleRole([]);
                        setDateOfBirth(dayjs(new Date()));
                        setDate(dayjs(new Date()));
                        setEndDate(dayjs(new Date()));
                        setisLoading(true);
                        getAllUserList();
                    })
                    .catch((error) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.message
                        })
                    })
            } else if (role === "PRESCRIBER") {
                let obj = {
                    "firstName": fromData.firstName,
                    "lastName": fromData.lastName,
                    "dateOfBirth": dateFormat(dateOfBirth.$d),
                    "role": [fromData.role],
                    "email": fromData.email,
                    "gender": selectValue,
                    "AHPRA_Reg_ID": fromData.AHPRA_Reg_ID,
                    "address": fromData.address,
                    "startDate": dateFormat(startDate.$d),
                    "endDate": dateFormat(endDate.$d),
                    "timeStamp": dateAndTimeFormat(date.$d),
                    "phone": fromData.phone,
                    "password": fromData.password
                }
                await userRegisterBySuperAdmin(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": "User add successfully"
                        })
                        setOpenInvitePopup({ open: false, action: "" });
                        reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
                        setMultipleRole([]);
                        setRole("");
                        setDateOfBirth(dayjs(new Date()));
                        setDate(dayjs(new Date()));
                        setEndDate(dayjs(new Date()));
                        setisLoading(true);
                        getAllUserList();
                    })
                    .catch((error) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.message
                        })
                    })
            }
        } else if (userRole === "ADMIN") {
            let obj = {
                "firstName": fromData.firstName,
                "lastName": fromData.lastName,
                "dateOfBirth": dateFormat(dateOfBirth.$d),
                "role": fromData.role,
                "email": fromData.email,
                "orgId": userDetails.orgId,
                "clinicId": clinicId,
                "password": fromData.password
            }
            await userRegisterByAdmin(obj)
                .then((res) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "User add successfully"
                    })
                    setMultipleRole([]);
                    setRole("");
                    reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
                    setOpenInvitePopup({ open: false, action: "" });
                    setDateOfBirth(dayjs(new Date()));
                    setDate(dayjs(new Date()));
                    setEndDate(dayjs(new Date()));
                    setisLoading(true);
                    getAllUserList();
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.message
                    })
                })
        }

    };


    return (
        <div>
            <form >
                <material.Dialog maxWidth="md" fullWidth open={openInvitePopup.open} hideBackdrop >
                    {openInvitePopup.action === "add-user" ? (
                        <>
                            <material.DialogTitle>Add User For Organization</material.DialogTitle>
                            <material.DialogContent sx={{ height: "50vh" }}>
                                <div>
                                    <material.TextField
                                        error={errors.firstName?.type === "required"}
                                        {...register("firstName", { required: true, minLength: 3 })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="First Name"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                                    {(errors?.firstName?.type === "minLength") && (
                                        <p className='text-danger'>This field contain minimum 3 digits</p>
                                    )}
                                </div>
                                <div>
                                    <material.TextField
                                        error={errors.lastName?.type === "required"}
                                        {...register("lastName", { required: true, minLength: 3 })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="Last Name"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <p className='form-text text-danger'>{errors.lastName?.type === "required" && 'This field is required'}</p>
                                    {(errors?.lastName?.type === "minLength") && (
                                        <p className='text-danger'>This field contain minimum 3 digits</p>
                                    )}
                                </div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DesktopDatePicker
                                        format='DD-MM-YYYY'
                                        label="Date of Birth"
                                        value={dateOfBirth}
                                        onChange={(newValue) => {
                                            setDateOfBirth(newValue)
                                        }}
                                        slotProps={{ textField: { variant: 'standard' } }}
                                        sx={{ width: "100%", marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    />
                                </LocalizationProvider>
                                <material.TextField
                                    {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                                <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                                {(errors?.email?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                                {userRole === "SUPERADMIN" ? (
                                    <material.FormControl variant="standard" fullWidth className='mt-2'>
                                        <material.InputLabel id="demo-simple-select-label">Role</material.InputLabel>
                                        <material.Select
                                            {...register("role", { required: true, })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={role}
                                            onChange={roleChange}
                                        >
                                            <material.MenuItem value="ADMIN">ADMIN</material.MenuItem>
                                            <material.MenuItem value="PRESCRIBER">PRESCRIBER</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                ) : (
                                    <material.FormControl variant="standard" fullWidth className='mt-2'>
                                        <material.InputLabel id="demo-simple-select-label">Role</material.InputLabel>
                                        <material.Select
                                            {...register("role", { required: true, })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={multipleRole}
                                            onChange={selectMultipleRole}
                                            multiple
                                            renderValue={(selected) => (
                                                <material.Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <material.Chip key={value} label={value} />
                                                    ))}
                                                </material.Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {usersRole?.map((role, i) => (
                                                <material.MenuItem key={i} value={role}>{role}</material.MenuItem>
                                            ))}
                                        </material.Select>
                                    </material.FormControl>
                                )}
                                {userRole === "SUPERADMIN" ? (
                                    <>
                                        {role !== "PRESCRIBER" ? (
                                            <material.Autocomplete
                                                fullWidth
                                                id="orgId"
                                                className='mt-2'
                                                onChange={selectOrg}
                                                options={organisation}
                                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Organization Name"
                                                    {...register("orgId", {
                                                        required: true,
                                                    })}
                                                />}
                                            />
                                        ) : null}
                                    </>
                                ) : userRole === "ADMIN" && role !== "INJECTOR" ? (
                                    <material.Autocomplete
                                        fullWidth
                                        id="orgId"
                                        className='mt-2'
                                        onChange={selectClinic}
                                        options={clinicData}
                                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Clinic Name"
                                            {...register("clinicName", {
                                                required: true,
                                            })}
                                        />}
                                    />
                                ) : null}
                                {role === "INJECTOR" ? (
                                    <material.Autocomplete
                                        multiple
                                        fullWidth
                                        id="orgId"
                                        className='mt-2'
                                        onChange={selectMultipleClinic}
                                        options={clinicData}
                                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Clinic Name"
                                        />}
                                    />
                                ) : null}
                                {role === "PRESCRIBER" ? (
                                    <div>
                                        <material.FormControl sx={{ marginTop: 4 }}>
                                            <material.FormLabel id="demo-radio-buttons-group-label">Gender</material.FormLabel>
                                            <material.RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={handleChange}
                                                value={selectValue}
                                            >
                                                <material.FormControlLabel value="MALE" control={<material.Radio color="secondary" />} label="Male" />
                                                <material.FormControlLabel value="FEMALE" control={<material.Radio color="secondary" />} label="Female" />
                                                <material.FormControlLabel value="TRANSGENDER" control={<material.Radio color="secondary" />} label="Other" />
                                            </material.RadioGroup>
                                        </material.FormControl>
                                        <div>
                                            <material.TextField
                                                {...register("AHPRA_Reg_ID", { required: true })}
                                                required
                                                margin="dense"
                                                id="name"
                                                label="AHPRA Reg ID"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                            />
                                            <p className='form-text text-danger'>{errors.AHPRA_Reg_ID?.type === "required" && 'This field is required'}</p>
                                        </div>
                                        <div>
                                            <material.TextField
                                                {...register("address", { required: true })}
                                                required
                                                margin="dense"
                                                id="name"
                                                label="Address"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                            />
                                            <p className='form-text text-danger'>{errors.address?.type === "required" && 'This field is required'}</p>
                                        </div>
                                        <div>
                                            <material.TextField
                                                error={errors.phone?.type === "required"}
                                                {...register("phone", { required: true, minLength: 10, maxLength: 10 })}
                                                required
                                                margin="dense"
                                                id="name"
                                                label="Phone"
                                                type="number"
                                                fullWidth
                                                variant="standard"
                                            />
                                            <p className='form-text text-danger'>{errors.phone?.type === "required" && 'This field is required'}</p>
                                            {(errors?.phone?.type === "minLength" || errors?.phone?.type === "maxLength") && (
                                                <p className='text-danger'>This field only contain 10 digits</p>
                                            )}
                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <material.DesktopDatePicker
                                                label="Start Date"
                                                format='DD-MM-YYYY'
                                                readOnly
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    setStartDate(newValue)
                                                }}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                                sx={{ width: "100%", marginTop: { xs: 3, sm: 3, md: 3 } }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <material.DesktopDatePicker
                                                label="End Date"
                                                value={endDate}
                                                format='DD-MM-YYYY'
                                                onChange={(newValue) => {
                                                    setEndDate(newValue)
                                                }}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                                sx={{ width: "100%", marginTop: { xs: 3, sm: 3, md: 3 } }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <material.DateTimePicker
                                                readOnly
                                                label="Date Of Entry"
                                                format='DD-MM-YYYY'
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                                sx={{ width: "100%", marginTop: { xs: 3, sm: 3, md: 3 } }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                ) : null}
                                <material.TextField
                                    {...register("password", { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    variant="standard"
                                />
                                <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
                                {(errors?.password?.type === "password") && (
                                    <p className='text-danger'>This is not a valid Password</p>
                                )}
                            </material.DialogContent>
                            <material.DialogActions className='me-3'>
                                <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                                <material.Button onClick={handleSubmit(addUserByOrg)} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.ForwardToInboxIcon />} disabled={!isValid}>Send</material.Button>
                            </material.DialogActions>
                        </>
                    ) : openInvitePopup.action === "invite-user" ? (
                        <>
                            <material.DialogTitle>Invite User</material.DialogTitle>
                            <material.DialogContent>
                                <material.TextField
                                    {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                                <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                                {(errors?.email?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                                <material.FormControl variant="standard" fullWidth className='mt-2'>
                                    <material.InputLabel id="demo-simple-select-label">Role</material.InputLabel>
                                    <material.Select
                                        {...register("role", { required: true, })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={role}
                                        onChange={roleChange}
                                    >
                                        <material.MenuItem value="ADMIN">ADMIN</material.MenuItem>
                                        <material.MenuItem value="PRESCRIBER">PRESCRIBER</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </material.DialogContent>
                            <material.DialogActions className='me-3'>
                                <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                                <material.Button onClick={handleSubmit(inviteUserByMail)} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.ForwardToInboxIcon />}>Send</material.Button>
                            </material.DialogActions>
                        </>
                    ) : null}
                    {/* <material.DialogActions className='me-3'>
                        <material.Button onClick={handleClose} variant="outlined" color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        <material.Button onClick={handleSubmit(inviteUserByMail)} variant="contained" startIcon={<material.ForwardToInboxIcon />}>Send</material.Button>
                    </material.DialogActions> */}
                </material.Dialog>
            </form>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(InviteUser)
