import axios from "axios";
import { rootApiUrl } from "../environment/Environment";



export const getTreatmentPlanInjector = async (status) => {
    return await axios.get(`${rootApiUrl}/get-treatment-plan-injector/${status}`);
};

export const getPrescription = async (treatmentPlanRequestId) => {
    return await axios.get(`${rootApiUrl}/get-prescription`, { params: { treatmentPlanRequestId: treatmentPlanRequestId } });
};