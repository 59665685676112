import React, { useState, useEffect } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { getEmailSubjectOpen, saveAppointmentOutside } from '../../services/AppointmentService';
import Snackbar from '../toastrmessage/Snackbar';

function AddAppointmentOutside(props) {

    const { showModal, setShowModal, formData, campaignTag, injectorId, clinicId, orgId, setHideSchedule } = props;
    const { register, handleSubmit, reset, resetField, control, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [phone, setPhone] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [treatmentName, setTreatmentName] = useState([]);
    const [emailSubject, setEmailSubject] = useState([]);
    const [getDurationData, setGetDurationData] = useState(null);

    useEffect(() => {
        if (showModal) {
            setStartDate(dayjs(formData.start))
            // const end = dayjs(startDate).add(30, "minute");
            // setEndDate(end.format("HH:mm"));
            getEmailSubjectData();
        }
    }, [showModal === true]);

    const getEmailSubjectData = () => {
        getEmailSubjectOpen(clinicId)
            .then((resp) => {
                setEmailSubject(resp.data);
                const treatmentName = resp.data?.map((ele) => ele.treatmentName);
                setTreatmentName(treatmentName);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data,
                });
            });
    };

    const handleChange = (e) => {
        setSelectValue(e.target.value)
    };

    const handleClose = () => {
        setSelectValue("");
        setPhone("");
        reset({ "PatientName": "", "Email": "" });
        setShowModal(false);
    };

    const saveAppointment = (data) => {
        const payload = {
            Id: "",
            title: data.title + " " + campaignTag,
            InjectorId: injectorId,
            ClinicId: clinicId,
            orgId: orgId,
            PatientName: data.PatientName,
            Email: data.Email,
            PhoneNumber: phone,
            start: moment(startDate.$d).format("YYYY-MM-DD HH:mm"),
            end: moment(startDate.$d).format("YYYY-MM-DD") + " " + endDate,
            startTime: moment(startDate.$d).utc(),
            MachineName: "",
            MachineNumber: "",
            MachineId: "",
            Purpose: getDurationData.instructions,
            RoomName: "",
            RoomNumber: "",
            RoomId: "",
            duration: getDurationData.duration,
            patientId: "",
            rescheduledCounter: 0,
            appointmentType: data.appointmentType,
            concerns: data.concerns
        }
        saveAppointmentOutside(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Appointment created successfully",
                })
                setHideSchedule(true);
                setSelectValue("");
                setPhone("");
                reset({ "PatientName": "", "Email": "" });
                setShowModal(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectEmailSubject = (e, newValue) => {
        if (newValue !== null) {
            const filterData = emailSubject.filter((ele) => ele.treatmentName === newValue)[0];
            setGetDurationData(filterData.data[0]);
            getDuration(filterData.data[0].duration);
        }
    };

    const getDuration = (duration) => {
        const timeArray = duration.split(" ");
        const hoursIndex = timeArray.indexOf("hour");
        const minutesIndex = timeArray.indexOf("min");
        let hours;
        let minutes;
        if (hoursIndex > 0 && minutesIndex < 0) {
            hours = Number(timeArray[hoursIndex - 1]);
            const time = hours * 60;
            let getTimeDuration = calculateNextTime(
                moment(startDate.$d).format("HH:mm"),
                time
            );
            setEndDate(getTimeDuration);
        } else if (minutesIndex > 0 && hoursIndex < 0) {
            minutes = Number(timeArray[minutesIndex - 1]);
            const time = minutes;
            let getTimeDuration = calculateNextTime(
                moment(startDate.$d).format("HH:mm"),
                time
            );
            setEndDate(getTimeDuration);
        } else if (hoursIndex > 0 && minutesIndex > 0) {
            hours = Number(timeArray[hoursIndex - 1]);
            minutes = Number(timeArray[minutesIndex - 1]);
            const time = hours * 60 + minutes;
            let getTimeDuration = calculateNextTime(
                moment(startDate.$d).format("HH:mm"),
                time
            );
            setEndDate(getTimeDuration);
        }
    };

    const calculateNextTime = (givenTime, duration) => {
        const [givenHours, givenMinutes] = givenTime.split(":").map(Number);
        const givenDate = new Date();
        givenDate.setHours(givenHours);
        givenDate.setMinutes(givenMinutes);
        givenDate.setTime(givenDate.getTime() + duration * 60000);
        const nextTime = `${String(givenDate.getHours()).padStart(
            2,
            "0"
        )}:${String(givenDate.getMinutes()).padStart(2, "0")}`;
        return moment(nextTime, "HH:mm").format("HH:mm");
    };

    return (
        <div>
            <material.Dialog open={showModal} hideBackdrop fullWidth maxWidth="md">
                <material.DialogTitle>
                    <material.Typography variant="h5">Additional Information Required</material.Typography>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12'>
                            <material.TextField
                                error={errors.patientName?.type === "required"}
                                {...register("PatientName", { required: true })}
                                required
                                id="patientName"
                                label="Patient Name"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                            <p className='form-text text-danger'>{errors.patientName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className='col-12'>
                            <material.TextField
                                error={errors.email?.type === "required"}
                                {...register("Email", { required: true })}
                                required
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                            <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-12">
                            <material.Autocomplete
                                id="patientName"
                                fullWidth
                                // freeSolo
                                onChange={selectEmailSubject}
                                options={treatmentName}
                                renderInput={(params) => (
                                    <material.TextField
                                        {...params}
                                        label="Purpose"
                                        variant="standard"
                                        error={errors.title?.type === "required"}
                                        {...register("title", { required: true })}
                                    />
                                )}
                            />
                        </div>
                        <div className='col-12 mt-2'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DateTimePicker
                                    label="Start"
                                    format='DD-MM-YYYY, HH:mm'
                                    value={startDate}
                                    disabled
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-12 mt-2'>
                            <PhoneInput
                                required
                                placeholder="Phone"
                                defaultCountry="AU"
                                inputRef={register}
                                enableSearch={true}
                                value={phone}
                                control={control}
                                international
                                rules={{ required: true }}
                                onChange={(phone) => setPhone(phone)}
                                error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <material.FormControl variant="standard" required fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label">Type of Appointment (Please choose either 'In Clinic' or 'Phone' Consultation)</material.InputLabel>
                                <material.Select
                                    {...register("appointmentType", { required: true })}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectValue}
                                    onChange={handleChange}
                                >
                                    <material.MenuItem value="In Clinic Consultation">In Clinic Consultation</material.MenuItem>
                                    <material.MenuItem value="Phone Consultation">Phone Consultation</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='col-12 mt-2'>
                            <material.TextField
                                {...register("concerns")}
                                id="concerns"
                                label="Please specify any concerns you have:"
                                type="text"
                                fullWidth
                                variant="standard"
                                multiline
                                rows={3}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={handleClose}>
                        Close
                    </material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} onClick={handleSubmit(saveAppointment)}>
                        Save
                    </material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddAppointmentOutside;