export function getHighestPriorityRole(roles) {
    const precedence = ["ADMIN", "MANAGER", "INJECTOR"];
    if (roles.length === 1) {
        return roles[0];
    }
    for (const role of precedence) {
        if (roles.includes(role)) {
            return role;
        }
    }

    return null;
};