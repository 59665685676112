import React, { useEffect, useState, useRef } from "react";
import { material } from "../../library/material";
import { useParams } from "react-router-dom";
import { getAllScheduleByInjectorIdOpen } from "../../services/AppointmentService";
import moment from "moment";
import Snackbar from "../toastrmessage/Snackbar";
import { checkInjectorAvailabilityOpen, getClinicByIdOpen } from "../../services/ClinicService";
import { getInjectorByIdOpen } from "../../services/InjectorService";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import AddAppointmentOutside from "./AddAppointmentOutside";


function CreateAppointmentOutside() {
  const { orgId } = useParams();
  const { clinicId } = useParams();
  const { injectorId } = useParams();
  const { campaignTag } = useParams();
  const [hideSchedule, setHideSchedule] = useState(false);
  const [events, setEvents] = useState([]);
  const [clinicData, setClinicData] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [injectorData, setInjectorData] = useState(null);
  const [injectorAvailability, setInjectorAvailability] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    start: "",
    end: "",
    patientName: "",
  });

  const localizer = momentLocalizer(moment);

  useEffect(() => {
    getAllScheduleByInjectorId();
    getClinicDataById();
    setOpenDialog(true);
    getInjectorById();
    checkingInjectorAvailability();
  }, []);

  const getInjectorById = () => {
    getInjectorByIdOpen(injectorId)
      .then((resp) => {
        setInjectorData(resp.data)
      })
      .catch((error) => {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": error.response.data,
        })
      })
  };

  const getAllScheduleByInjectorId = () => {
    getAllScheduleByInjectorIdOpen(injectorId)
      .then((resp) => {
        setEvents(resp.data.map((event) => ({
          id: event.event_id,
          start: moment(event.start, "YYYY-MM-DD HH:mm").toDate(),
          end: moment(event.end, "YYYY-MM-DD HH:mm").toDate(),
        })));
        setIsLoading(false);
      })
      .catch((error) => {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": error.response.data,
        })
      })
  };

  const getClinicDataById = () => {
    getClinicByIdOpen(clinicId)
      .then((resp) => {
        setClinicData(resp.data)
      })
      .catch((error) => {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": error.response.data,
        })
      })
  };

  const checkingInjectorAvailability = async () => {
    const payload = {
      "orgId": orgId,
      "clinicId": clinicId,
      "injector": injectorId
    }
    await checkInjectorAvailabilityOpen(payload)
      .then((resp) => {
        setInjectorAvailability(resp.data)
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      })
  };

  // const handleSelectEvent = (event) => {
  //   setSelectedEvent(event);
  // };

  const slotPropGetter = (date, resourceId) => {
    const dayOfWeek = date.getDay();
    const availability = injectorAvailability[injectorId]?.[dayOfWeek];
    const leaveDates = injectorAvailability[injectorId]?.leaveDates || [];
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const timeInMinutes = hours * 60 + minutes;

    if (moment(date).isBefore(moment(), 'day')) {
      return {
        style: {
          backgroundColor: '#d3d3d3',
          pointerEvents: 'none',
          opacity: 0.7,
        },
      };
    }

    const leaveDate = leaveDates.find(ld => ld.date === formattedDate);
    if (leaveDate) {
      const leaveStartMinutes = leaveDate.start * 60;
      const leaveEndMinutes = leaveDate.end * 60;
      if (timeInMinutes >= leaveStartMinutes && timeInMinutes < leaveEndMinutes) {
        return {
          style: {
            backgroundColor: '#d3d3d3',
            pointerEvents: 'none',
            opacity: 0.7,
          },
        };
      }
    }

    if (!availability || availability.unavailable) {
      return {
        style: {
          backgroundColor: '#d3d3d3',
          pointerEvents: 'none',
          opacity: 0.7,
        },
      };
    }

    const startTime = moment(availability.start, 'HH:mm:ss');
    const endTime = moment(availability.end, 'HH:mm:ss');
    if (timeInMinutes < startTime.hours() * 60 + startTime.minutes() ||
      timeInMinutes >= endTime.hours() * 60 + endTime.minutes()) {
      return {
        style: {
          backgroundColor: '#e0e0e0',
          pointerEvents: 'none',
          opacity: 0.5,
        },
      };
    }

    return {};
  };

  const handleSelectSlot = (slotInfo) => {
    const dayOfWeek = slotInfo.start.getDay();
    const availability = injectorAvailability[injectorId]?.[dayOfWeek];
    const leaveDates = injectorAvailability[injectorId]?.leaveDates || [];
    const formattedDate = moment(slotInfo.start).format('YYYY-MM-DD');
    const hours = slotInfo.start.getHours();
    const minutes = slotInfo.start.getMinutes();
    const timeInMinutes = hours * 60 + minutes;

    if (moment(slotInfo.start).isBefore(moment(), 'day')) {
      return;
    }

    const leaveDate = leaveDates.find(ld => ld.date === formattedDate);
    if (leaveDate) {
      const leaveStartMinutes = leaveDate.start * 60;
      const leaveEndMinutes = leaveDate.end * 60;
      if (timeInMinutes >= leaveStartMinutes && timeInMinutes < leaveEndMinutes) {
        return;
      }
    }

    if (!availability || availability.unavailable) {
      return;
    }
    const startTime = moment(availability.start, 'HH:mm:ss');
    const endTime = moment(availability.end, 'HH:mm:ss');
    if (timeInMinutes < startTime.hours() * 60 + startTime.minutes() ||
      timeInMinutes >= endTime.hours() * 60 + endTime.minutes()) {
      return;
    }
    setFormData({
      title: '',
      start: moment(slotInfo.start).format('YYYY-MM-DDTHH:mm'),
      end: moment(slotInfo.end).format('YYYY-MM-DDTHH:mm'),
      patientName: '',
      purpose: '',
      bookingStatus: 'NOT_CONFIRMED'
    });
    setShowModal(true);
  };

  const TimeSlotWrapper = ({ children, value, resource }) => {
    const props = slotPropGetter(value, resource);
    return React.cloneElement(children, props);
  };

  return (
    <div>
      {hideSchedule ? (
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}>
          <material.Stack sx={{ width: '50%' }} spacing={2}>
            <material.Alert variant="outlined" severity="success">
              Thank you for booking your appointment!  You should receive an email confirmation shortly.
              If there are any discrepancies, please feel free to reach out to our support team.
              They’ll be happy to assist you!
            </material.Alert>
          </material.Stack>
        </div>
      ) : (
        <div className="container-fluid mt-3">
          <material.Paper elevation={3} sx={{ p: 3 }}>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100vw",
            }}>
              <material.Typography variant="h5" className="fw-bold">{clinicData.clinicName}</material.Typography>
            </div>
            <div>
              <span className="fw-bold">Contact Info:</span><br />
              <span>Phone : {clinicData.contact1Phone}, {clinicData.contact2Phone}</span><br />
              <span>Email : {clinicData.contact1Mail}, {clinicData.contact2Mail}</span><br />
              <span>Address : {clinicData.contact1Address}</span><br />
              <span>You are booking with <span className="fw-bold">{injectorData?.title + " " + injectorData?.firstName + " " + injectorData?.lastName}</span></span><br />
              <span>Select Your Prefer Date and Time</span>
            </div>
            <material.Paper elevation={7} sx={{ p: 3, mt: 3 }}>
              <div>
                {isLoading ? (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "80vh" }}>
                    <material.Stack sx={{ color: 'grey.500' }}>
                      <material.CircularProgress color="secondary" />
                    </material.Stack>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 670 }}
                        // onSelectEvent={handleSelectEvent}
                        selectable
                        onSelectSlot={handleSelectSlot}
                        defaultView={Views.WEEK}
                        min={new Date(new Date().setHours(6, 0, 0, 0))}
                        max={new Date(new Date().setHours(22, 0, 0, 0))}
                        views={{
                          day: true,
                          week: true,
                          month: true,
                          agenda: true,
                          work_week: true,
                        }}
                        eventPropGetter={(event) => ({
                          className: event.className,
                        })}
                        components={{
                          timeSlotWrapper: TimeSlotWrapper,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </material.Paper>
          </material.Paper>
        </div>
      )}
      <NotificationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <AddAppointmentOutside
        showModal={showModal}
        setShowModal={setShowModal}
        formData={formData}
        campaignTag={campaignTag}
        injectorId={injectorId}
        clinicId={clinicId}
        orgId={orgId}
        setHideSchedule={setHideSchedule}
      />
    </div>
  );
}

const NotificationDialog = (props) => {

  const { openDialog, setOpenDialog } = props;

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <material.Dialog open={openDialog} fullWidth maxWidth="md" hideBackdrop>
      <material.DialogTitle>
        <span className="float-end">
          <material.IconButton color="error" title="Close" onClick={handleClose}>
            <material.CloseIcon />
          </material.IconButton>
        </span>
      </material.DialogTitle>
      <material.DialogContent>
        <div className="booking-page">
          <h2>Welcome to Our Booking Page!</h2>

          <h3>Overview:</h3>
          <p>
            You are viewing our booking calendar where you can select your preferred time slot for your appointment or booking. The calendar displays available and booked slots to help you plan accordingly.
          </p>

          <h3>Instructions:</h3>
          <ol>
            <li>
              <strong>Select Date:</strong> Click on the calendar icon to choose the date you wish to book. Available dates are selectable; grayed-out dates are not available for booking.
            </li>
            <li>
              <strong>Choose Time Slot:</strong> The calendar will display available time slots in white and already booked slots in gray. Click on a white time slot to select the time you prefer for your booking.
            </li>
            <li>
              <strong>Book Your Slot:</strong> After selecting a time slot, you will be prompted to fill out additional details to confirm your booking. Please provide your name, email address, and phone number.
            </li>
            <li>
              <strong>Confirmation:</strong> Once you have filled out the details and confirmed your booking, you will receive a confirmation message with your booking details.
            </li>
          </ol>

          <h3>Additional Information:</h3>
          <p>
            If you need to change your booking or have any questions, please contact us.
            <br />
            We look forward to welcoming you!
          </p>

          <p>Thank you for choosing our service. Enjoy your booking experience!</p>
        </div>
      </material.DialogContent>
    </material.Dialog>
  )

};

export default CreateAppointmentOutside;
