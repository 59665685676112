import React from 'react';
import { Chart } from "react-google-charts";
// import { getSalesPercentageByTreatment } from '../../services/reporting-service/ReportingService';
import { connect } from 'react-redux';


function PieChart(props) {

    const { userData, clinicData, pieChartData, title } = props;
    // const userDetails = userData.authReducer.data;
    // const clinicDetails = clinicData.clinicReducer.data;

    const options = {
        title: title,
        pieSliceText: 'value',
    };

    const createChartData = (chartData) => {
        let data = [];
        let header = ["Treatment Name", "Percentage"];
        data.push(header);
        for (const iterator of chartData) {
            data.push([iterator.treatmentName, Number(iterator.totalSales)]);
        }
        return data;
    };

    return (
        <div>
            <Chart
                chartType="PieChart"
                data={createChartData(pieChartData.data)}
                options={options}
                width={"100%"}
                height={"400px"}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(PieChart);