import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Swipedrawer from '../../drawer/Swipedrawer';
import { material } from '../../../library/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getInvoiceByTreatmentPlanRequestId, getInvoiceTreatmentList, sendInvoiceByTreatmentPlanRequestId } from '../../../services/InvoiceManagementService';
import DiscountDialog from '../../dialog/DiscountDialog';
import Snackbar from '../../toastrmessage/Snackbar';
import { drawerWidth } from '../../drawer/Swipedrawer';
import Navbar from '../../navbar/Navbar';


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

var allTreatmentListData;

function InvoiceManagement(props) {

    const [patientData, setPatientData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setisLoading] = useState(true);
    const [openDiscountDialog, setOpenDiscountDialog] = useState({ "action": false, "data": "" });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectValue, setSelectValue] = useState("NOT_GENERATED");

    localStorage.setItem("menuName", "Treatment Invoice")

    useEffect(() => {
        getTreatmentList()
    }, [selectValue, openDiscountDialog.action === false]);

    const changeInvoice = (e, value) => {
        setSelectValue(value)
    };

    const getTreatmentList = async () => {
        if (selectValue === "NOT_GENERATED") {
            setisLoading(true)
            await getInvoiceTreatmentList(selectValue)
                .then((res) => {
                    allTreatmentListData = res.data;
                    setPatientData(res.data)
                    setisLoading(false)
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.message,
                    })
                })
        } else if (selectValue === "GENERATED") {
            setisLoading(true)
            await getInvoiceTreatmentList(selectValue)
                .then((res) => {
                    allTreatmentListData = res.data;
                    setPatientData(res.data)
                    setisLoading(false)
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.message,
                    })
                })
        };
    };

    const filterByPatientId = (value) => {
        const filteredRows = patientData.filter((row) => {
            return row.patientName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setPatientData(allTreatmentListData);
        } else {
            setPatientData(filteredRows);
        };
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const invoiceGenerate = (rowData) => {
        setOpenDiscountDialog({ action: true, data: rowData })
    };

    const showInvoice = async (rowData) => {
        await getInvoiceByTreatmentPlanRequestId(rowData.treatmentPlanRequestId)
            .then((resp) => {
                const newTab = window.open("", "_blank")
                newTab.document.write(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const sendInvoice = async (rowData) => {
        await sendInvoiceByTreatmentPlanRequestId({ treatmentPlanRequestId: rowData.treatmentPlanRequestId })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Send invoice successfully",
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'></div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <span style={{ marginLeft: 5 }}>
                            <material.TextField
                                sx={{ width: "30ch" }}
                                variant="standard"
                                label="Filter by Patient Name"
                                onChange={(e) => filterByPatientId(e.target.value)}
                            />
                        </span>
                    </div>
                    <div className='col-6'>
                        <span className='float-end me-2'>
                            <material.ToggleButtonGroup
                                color="info"
                                value={selectValue}
                                exclusive
                                onChange={changeInvoice}
                                aria-label="Platform"
                            >
                                <material.ToggleButton sx={{ textTransform: "none" }} value="NOT_GENERATED">Invoice is't Generated</material.ToggleButton>
                                <material.ToggleButton sx={{ textTransform: "none" }} value="GENERATED">Generated</material.ToggleButton>
                            </material.ToggleButtonGroup>
                        </span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={3}>
                            <material.TableContainer sx={{ maxHeight: 460 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Patient Name</StyledTableCell>
                                            <StyledTableCell>Injector Name</StyledTableCell>
                                            <StyledTableCell>Treatment Plan Request ID</StyledTableCell>
                                            <StyledTableCell>Approver Name</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {patientData.length ? patientData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small' scope="row">{row.patientName}</material.TableCell>
                                                        <material.TableCell size='small' scope="row">{row.injectorName}</material.TableCell>
                                                        <material.TableCell size='small' scope="row">{row.treatmentPlanRequestId}</material.TableCell>
                                                        <material.TableCell size='small' scope="row">{row?.approverName}</material.TableCell>
                                                        <material.TableCell size='small'>
                                                            {row.status === "USED" ? (
                                                                <span className="badge bg-success">
                                                                    USED
                                                                </span>
                                                            ) : null}
                                                        </material.TableCell>
                                                        <material.TableCell>
                                                            <span className='d-flex flex-column'>
                                                                {row.invoiceGenerated ? (
                                                                    <>
                                                                        <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='secondary' size="small" onClick={() => showInvoice(row)}>Show Invoice</material.Button>
                                                                        <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='success' size="small" onClick={() => sendInvoice(row)}>Send Invoice</material.Button>
                                                                    </>
                                                                ) : (
                                                                    <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='info' size="small" onClick={() => invoiceGenerate(row)}>Generate Invoice</material.Button>
                                                                )}
                                                            </span>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={patientData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
            </div>
            <DiscountDialog
                openDiscountDialog={openDiscountDialog}
                setOpenDiscountDialog={setOpenDiscountDialog}
                getTreatmentList={getTreatmentList}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default InvoiceManagement;