import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getAllPAtients = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-all-patients`, { params: { clinicId } });
};

export const addNewPatient = async (patientData) => {
    return await axios.post(`${rootApiUrl}/add-patient-details`, patientData);
};

export const getPatientHistory = async (patientId) => {
    return await axios.get(`${rootApiUrl}/get-patient-history`, { params: { patientId: patientId } });
};

export const addPatientHistory = async (patientHistory) => {
    return await axios.post(`${rootApiUrl}/add-patient-history`, patientHistory);
};

export const getAllPatientByClinicId = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-patient-details-by-clinicId`, { params: { ...payload } });
};

export const getPatientNote = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-patient-note`, { params: { patientId: payload.patientId, typeOfNotes: payload.typeOfNotes } });
};

export const getPatientPastProcedureList = async (patientId) => {
    return await axios.get(`${rootApiUrl}/get-patient-past-procedure-list/${patientId}`);
};

export const getPatientById = async (patientId) => {
    return await axios.get(`${rootApiUrl}/get-patient-by-id/${patientId}`);
};

export const deletePatientById = async (patientId) => {
    return await axios.put(`${rootApiUrl}/delete-patient`, null, { params: { patientId: patientId } });
};

export const getPatientPurchaseHistory = async (patientId) => {
    return await axios.get(`${rootApiUrl}/get-patient-purchase-history`, { params: { patientId: patientId } });
};

export const getPatientCreditList = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-patient-credit-list`, { params: { ...payload } });
};

export const addPatientCredit = async (creditData) => {
    return await axios.post(`${rootApiUrl}/add-patient-credit`, creditData);
};

export const getAllPatientImage = async (payload) => {
    return await axios.get(`${rootApiUrl}/get-all-image-patient`, { params: { ...payload } });
};

export const uploadPatientGeneralImage = async (data) => {
    return await axios.post(`${rootApiUrl}/upload-general-image`, data)
};

export const uploadPatientGeneralImageFile = async (file) => {
    return await axios.post(`${rootApiUrl}/upload-general-image-file`, file)
};

export const getPatientCommunication = async (patientId) => {
    return await axios.get(`${rootApiUrl}/get-patient-communication`, { params: { patientId: patientId } });
};

export const reviewEmailByPatientId = async (patientId) => {
    return await axios.post(`${rootApiUrl}/send-review-email`, null, { params: { patientId } })
};