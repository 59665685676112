import React, { useState, useEffect } from 'react';
import { material } from '../../../library/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { getAllClinic } from '../../../services/ClinicService';
import { getProductTreatment } from '../../../services/TreatmentListService';
import { changeDateAndTimeFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import { connect } from 'react-redux';
import { getHighestPriorityRole } from '../../../highest-priority-role/HighestPriorityRole';
import Snackbar from '../../toastrmessage/Snackbar';
import { saveOrUpdateStockAdjustment } from '../../../services/StocksService';

var allClinicData;
var clinicId;

function AddStockAdjustment(props) {

    const { openAddStock, setOpenAddStock, userData, getStockAdjustmentList, clinicData } = props;
    let userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, resetField, formState: { errors, isValid }, reset } = useForm({
        mode: "onTouched"
    });
    const [allClinic, setAllClinic] = useState([]);
    const [productList, setProductList] = useState([]);
    const [selectProductType, setSelectProductType] = useState("");
    const [selectProductName, setSelectProductName] = useState("");
    const [date, setDate] = React.useState(moment().format("YYYY-MM-DDTHH:mm:ss"));
    const [dateIn, setDateIn] = useState(dayjs(new Date()));
    const [selectValue, setSelectValue] = useState("");
    const [expiryDate, setExpiryDate] = useState(dayjs(new Date()));
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddStock.action && openAddStock.stockData) {
            reset(openAddStock.stockData)
            setSelectValue(openAddStock.stockData.unitType)
            setDate(openAddStock.stockData.timeStamp)
            setDateIn(dayjs(openAddStock.stockData.dateIn))
            setExpiryDate(dayjs(openAddStock.stockData.expDate))
        }
    }, [openAddStock.action === true]);

    useEffect(() => {
        getProductTreatmentList()
    }, [openAddStock.action === true]);

    const getProductTreatmentList = async () => {
        await getProductTreatment(clinicDetails.clinicId, userDetails.orgId)
            .then((resp) => {
                setProductList(resp.data)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const handleChangeProductType = (e, value) => {
        setSelectProductType(e.target.value)
    };

    const handleChangeProduct = (event) => {
        setSelectProductName(event.target.value)
        reset({ supplier: event.target.value.companyName })
    };

    const selectBoxOrVial = (event) => {
        setSelectValue(event.target.value)
    };

    const addStock = async (fromData) => {
        let obj = {
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            "active": true,
            "supplier": fromData.supplier,
            "product": selectProductName.product,
            "productType": selectProductType.treatmentType,
            "units": fromData.units,
            "unitType": selectValue,
            "dateIn": moment(dateIn.$d).format("YYYY-MM-DDTHH:mm:ss"),
            "cost": fromData.cost,
            "enitedBy": userDetails.userId,
            "comment": fromData.comment,
            "timeStamp": date,
            "batchNo": fromData.batchNo,
            "expDate": moment(expiryDate.$d).format("YYYY-MM-DDTHH:mm:ss"),
        }
        await saveOrUpdateStockAdjustment(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setSelectValue("")
                setOpenAddStock({ action: false, stockData: null })
                reset({ "supplier": "", "product": "", "productType": "", "units": "", "unitType": "", "cost": "", "comment": "", "batchNo": "" })
                getStockAdjustmentList();
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Something went wrong",
                })
            })
    };
    
    const updateStock = async (fromData) => {
        await saveOrUpdateStockAdjustment(fromData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Updated successfully",
                })
                setSelectValue("")
                setOpenAddStock({ action: false, stockData: null })
                reset({ "supplier": "", "product": "", "productType": "", "units": "", "unitType": "", "cost": "", "comment": "", "batchNo": "" })
                getStockAdjustmentList();
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Something went wrong",
                })
            })
    };

    const close = () => {
        setSelectValue("")
        setOpenAddStock({ action: false, stockData: null })
        reset({ "supplier": "", "product": "", "productType": "", "units": "", "unitType": "", "cost": "", "comment": "", "batchNo": "" })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openAddStock.action} hideBackdrop >
                <material.DialogTitle>{openAddStock.stockData ? "Edit Stock" : "Add Stock"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            {openAddStock.stockData ? (
                                <material.TextField
                                    label="Product Type"
                                    size="small"
                                    variant='standard'
                                    fullWidth
                                    {...register("productType", { required: true })}
                                    disabled
                                />
                            ) : (
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="productType">Product Type</material.InputLabel>
                                    <material.Select
                                        {...register("productType", { required: true })}
                                        id="productType"
                                        value={selectProductType}
                                        onChange={handleChangeProductType}
                                    >
                                        {productList?.map((product, i) => (
                                            <material.MenuItem key={i} value={product}>{product.treatmentType}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            )}
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            {openAddStock.stockData ? (
                                <material.TextField
                                    label="Product"
                                    size="small"
                                    variant='standard'
                                    fullWidth
                                    {...register("product", { required: true })}
                                    disabled
                                />
                            ) : (
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="product">Product</material.InputLabel>
                                    <material.Select
                                        {...register("product", { required: true })}
                                        id="product"
                                        value={selectProductName}
                                        onChange={handleChangeProduct}
                                    >
                                        {selectProductType?.product?.map((product, i) => (
                                            <material.MenuItem key={i} value={product}>{product.product}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            )}
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Supplier"
                                size="small"
                                variant='standard'
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                fullWidth
                                {...register("supplier", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Units"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("units", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.FormControl
                                fullWidth
                                variant='standard'
                            >
                                <material.InputLabel id="demo-simple-select-helper-label">Box/Vial</material.InputLabel>
                                <material.Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectValue}
                                    onChange={selectBoxOrVial}
                                >
                                    <material.MenuItem value="BOX">BOX</material.MenuItem>
                                    <material.MenuItem value="VIAL">VIAL</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DesktopDatePicker
                                    label="Date In"
                                    value={dateIn}
                                    format='DD-MM-YYYY'
                                    onChange={(newValue) => {
                                        setDateIn(newValue)
                                    }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Cost"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("cost", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Batch Id"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("batchNo", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DesktopDatePicker
                                    views={['year', 'month']}
                                    label="Expiry Date"
                                    value={expiryDate}
                                    onChange={(newValue) => {
                                        setExpiryDate(newValue)
                                    }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Time stamp"
                                size="small"
                                variant='standard'
                                fullWidth
                                value={changeDateAndTimeFormate(date)}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Comments"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("comment")}
                            />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <span className='float-end'>
                            <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={close} startIcon={<material.CloseIcon />}>
                                Cancel
                            </material.Button>
                            {openAddStock.stockData ? (
                                <material.Button variant="outlined" sx={{ textTransform: "none" }} onClick={handleSubmit(updateStock)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            ) : (
                                <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(addStock)} startIcon={<material.DoneIcon />}>
                                    Save
                                </material.Button>
                            )}
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddStockAdjustment);