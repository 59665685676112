import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { changePlanStatus } from '../../services/PrescriberService';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getHighestPriorityRole } from '../../highest-priority-role/HighestPriorityRole';
import { getPreWrittenNotesInjector, getPreWrittenNotesPrescriber } from '../../services/TreatmentPlanService';
import PreSelectNotes from './PreSelectNotes';

function StatusChangeReasonDialog(props) {
    const navigate = useNavigate();
    const { openStatusChangeDialog, setOpenStatusChangeDialog, getTreatmentPlan, pendingTreatmantRequest, userData, getStatusChangeData } = props;
    let userDetails = userData.authReducer.data;
    let role = getHighestPriorityRole(userDetails.role);
    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [hideShow, setHideShow] = useState(true);
    const [openPreSelectNote, setOpenPreSelectNote] = useState({ action: false, treatmentData: null });
    const [selectNotes, setSelectNotes] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    console.log(openStatusChangeDialog)

    const onChangeNotes = watch('reason');

    useEffect(() => {
        getReasonValue();
    }, [onChangeNotes]);

    const getReasonValue = () => {
        let val1 = getValues('reason');
        setSelectNotes(val1)
    };

    useEffect(() => {
        if (openStatusChangeDialog.action) {
            // getPreWrittenNotes()
        }
    }, [openStatusChangeDialog.action === true]);

    const addPreWrittenNote = () => {
        setOpenPreSelectNote({ action: true, treatmentData: openStatusChangeDialog.data.action === "approve" ? openStatusChangeDialog?.data.treatmentPlan : openStatusChangeDialog?.data.treatmentPlanData })
    };

    const getNoteData = (data) => {
        let notesString = "";
        data?.map((ele) => {
            notesString = notesString + '\n' + ele.treatmentName + "__" + ele.treatmentArea + " :- " + " " + ele.notes + '\n \n \n';
        })
        if (selectNotes !== "" && data !== null) {
            setSelectNotes(selectNotes + '\n' + notesString + '\n')
            setValue("reason", selectNotes + '\n' + notesString + '\n');
        } else if (selectNotes === "") {
            setSelectNotes(notesString + '\n')
            setValue("reason", notesString + '\n');
        };
    };

    const changeStatus = async (data) => {
        reset({ "reason": "", treatmentNote: "" })
        setHideShow(true)
        if (openStatusChangeDialog.data.action === "delete") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "REJECTED",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Rejected successfully",
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} });
                    if (role === "INJECTOR" && openStatusChangeDialog.data.parentComponent === "approvalWaiting") {
                        getTreatmentPlan();
                    } else if (role === "PRESCRIBER" && openStatusChangeDialog.data.component === "waitingRoom") {
                        pendingTreatmantRequest();
                    };
                    if (role === "INJECTOR") {
                        navigate("/treatment-queue")
                    } else if (role === "PRESCRIBER") {
                        navigate("/waiting-list")
                    };
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Something went wrong",
                    })
                })
        } else if (openStatusChangeDialog.data.action === "approve") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "APPROVED",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Approved successfully",
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} })
                    if (role === "PRESCRIBER" && openStatusChangeDialog.data.component === "waitingRoom") {
                        pendingTreatmantRequest()
                    }
                    navigate("/waiting-list")
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Something went wrong",
                    })
                })
        } else if (openStatusChangeDialog.data.action === "pending") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "PENDING",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Pending successfully",
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Something went wrong",
                    })
                })
        } else if (openStatusChangeDialog.data.action === "cancel") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "CANCEL",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Canceled successfully",
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} })
                    if (openStatusChangeDialog.data.parentComponent === "approvalWaiting") {
                        getTreatmentPlan()
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Something went wrong",
                    })
                })
        } else if (openStatusChangeDialog.data.action === "used") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "USED",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Used successfully",
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} })
                    if (openStatusChangeDialog.data.parentComponent === "approvalWaiting") {
                        getTreatmentPlan()
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Something went wrong",
                    })
                })
        } else if (openStatusChangeDialog.data.parentComponent === "treatmentPlan") {
            getStatusChangeData(data.reason);
            reset({ "reason": "", treatmentNote: "" });
            setOpenStatusChangeDialog({ action: false, data: {} });
        };
    };

    const close = () => {
        setOpenStatusChangeDialog({ action: false, data: {} });
        reset({ "reason": "", treatmentNote: "" });
        setHideShow(true);
        setSelectNotes("");
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openStatusChangeDialog.action} hideBackdrop >
                <material.DialogTitle>{openStatusChangeDialog.data.action === "used" ? "Add Treatment Notes" : openStatusChangeDialog.data.action === "approve" ? "Add Prescriber Notes" : "Add Notes"}
                    <material.Fab sx={{ ml: 2 }} color="primary" size='small' aria-label="add" title='Add Notes' onClick={addPreWrittenNote}>
                        <material.AddIcon />
                    </material.Fab>
                </material.DialogTitle>
                <material.DialogContent>
                    <hr />
                    <div className='row'>
                        {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-2 mt-2" hidden={!hideShow}>
                            <material.Autocomplete
                                id="machineName"
                                onChange={handleChangeNotes}
                                options={preWrittenNotes}
                                renderInput={(params) => <material.TextField {...params} variant="standard"
                                    label="Select Treatment Notes"
                                    {...register("treatmentNote")}
                                    sx={{ width: "100%" }}
                                    multiline
                                    rows={5}
                                />}
                            />
                            <p className='form-text text-danger'>{errors.reason?.type === "required" && 'This field is required'}</p>
                        </div> */}
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
                            <material.TextField
                                id="standard-error"
                                label={openStatusChangeDialog.data.action === "used" ? "Treatment Notes" : (openStatusChangeDialog.data.action === "approve" ? "Prescriber Notes" : "Reason")}
                                variant="standard"
                                type="text"
                                size="small"
                                multiline
                                fullWidth
                                {...register("reason", { required: true, minLength: 5 })}
                                error={errors.reason?.type === "required"}
                                rows={10}
                                InputLabelProps={{ shrink: true }}
                            />
                            <p className='form-text text-danger'>{errors.reason?.type === "required" && 'This field is required'}</p>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={close}>
                                Cancel
                            </material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(changeStatus)}>
                                Submit
                            </material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <PreSelectNotes
                openPreSelectNote={openPreSelectNote}
                setOpenPreSelectNote={setOpenPreSelectNote}
                userDetails={userDetails}
                getNoteData={getNoteData}
            />
        </div>
    );
};

// const PreSelectNotes = (props) => {

//     const { openPreSelectNote, setOpenPreSelectNote, userDetails, getNoteData } = props;
//     const { register, handleSubmit, reset, setValue, formState: { errors, isValid } } = useForm({
//         mode: "onTouched",
//     });
//     let role = getHighestPriorityRole(userDetails.role);
//     const [preWrittenNotes, setPreWrittenNotes] = useState([]);
//     const [treatmentData, setTreatmentData] = useState([]);
//     const [treatmentAreaData, setTreatmentAreaData] = useState([]);
//     const [selectTreatmentData, setSelectTreatmentData] = useState("");

//     useEffect(() => {
//         if (openPreSelectNote.action) {
//             let treatmentName = openPreSelectNote?.treatmentData?.map((ele) => ele.treatmentName);
//             setTreatmentData(treatmentName)
//         };
//     }, [openPreSelectNote.action === true]);

//     const handleChangeTreatment = (e, value) => {
//         setSelectTreatmentData(value)
//         let treatmentArea = openPreSelectNote.treatmentData?.filter((ele) => ele.treatmentName === value).map((resp) => resp.treatmentArea);
//         setTreatmentAreaData(treatmentArea);
//     };

//     const handleChangeArea = (e, value) => {
//         getPreWrittenNotes(value)
//     };

//     const getPreWrittenNotes = async (treatmentName) => {
//         if (role === "INJECTOR" && userDetails.isDoctor === false) {
//             await getPreWrittenNotesInjector(selectTreatmentData, treatmentName)
//                 .then((resp) => {
//                     setPreWrittenNotes(resp.data)
//                 })
//                 .catch((error) => {

//                 })
//         } else if (role === "PRESCRIBER") {
//             await getPreWrittenNotesPrescriber(selectTreatmentData, treatmentName)
//                 .then((resp) => {
//                     setPreWrittenNotes(resp.data)
//                 })
//                 .catch((error) => {

//                 })
//         } else if (role === "INJECTOR" && userDetails.isDoctor === true) {
//             await getPreWrittenNotesPrescriber(selectTreatmentData, treatmentName)
//                 .then((resp) => {
//                     setPreWrittenNotes(resp.data)
//                 })
//                 .catch((error) => {

//                 })
//         };
//     };

//     const handleChangeNotes = (event, value) => {
//         // setValue("reason", value)
//     };

//     const saveNote = (data) => {
//         getNoteData(data);
//         reset({ treatmentName: "", treatmentNote: "" });
//         setOpenPreSelectNote({ action: false, treatmentData: null });
//     };

//     const close = () => {
//         setOpenPreSelectNote({ action: false, treatmentData: null });
//         reset({ treatmentName: "", treatmentNote: "" });
//     };

//     return (
//         <div>
//             <material.Dialog fullWidth maxWidth="lg" open={openPreSelectNote.action} hideBackdrop>
//                 <material.DialogTitle>Add Pre Written Notes</material.DialogTitle>
//                 <material.DialogContent>
//                     <div className='row'>
//                         <div className='col-lg-4'>
//                             <material.Autocomplete
//                                 id="machineName"
//                                 onChange={handleChangeTreatment}
//                                 options={treatmentData}
//                                 renderInput={(params) => <material.TextField {...params} variant="standard"
//                                     label="Select Treatment"
//                                     required
//                                     {...register("treatmentName", { required: true })}
//                                 />}
//                             />
//                         </div>
//                         <div className='col-lg-4'>
//                             <material.Autocomplete
//                                 id="machineName"
//                                 onChange={handleChangeArea}
//                                 options={treatmentAreaData}
//                                 renderInput={(params) => <material.TextField {...params} variant="standard"
//                                     label="Treatment Area"
//                                     required
//                                     {...register("treatmentArea", { required: true })}
//                                 />}
//                             />
//                         </div>
//                         <div className="col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
//                             <material.Autocomplete
//                                 id="machineName"
//                                 onChange={handleChangeNotes}
//                                 options={preWrittenNotes}
//                                 renderInput={(params) => <material.TextField {...params} variant="standard"
//                                     label="Select Pre Written Notes"
//                                     {...register("treatmentNote", { required: true })}
//                                     sx={{ width: "100%" }}
//                                     multiline
//                                     rows={5}
//                                 />}
//                             />
//                             <p className='form-text text-danger'>{errors.reason?.type === "required" && 'This field is required'}</p>
//                         </div>
//                     </div>
//                 </material.DialogContent>
//                 <material.DialogActions>
//                     <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={close}>
//                         Cancel
//                     </material.Button>
//                     <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(saveNote)}>
//                         Save
//                     </material.Button>
//                 </material.DialogActions>
//             </material.Dialog>
//         </div>
//     )
// };

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(StatusChangeReasonDialog);