import { authActionType } from "../Constant";


const initialState = {
    data: ""
};


export default function patientReducer(state = initialState, action) {
    switch (action.type) {
        case authActionType.PATIENT_DETALIS:
            const patientState = {
                data: action.payload,
            }
            return patientState
        case authActionType.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
};