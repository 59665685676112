import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import FileUpload from '../../dialog/FileUpload';
import { downloadDocument, getDocumentDetails } from '../../../services/DocumentManagement';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Navbar from '../../navbar/Navbar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function DocumentManagement(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [documentDetails, setDocumentDetails] = useState([]);

    localStorage.setItem("menuName", "Document Upload")

    useEffect(() => {
        getAllDocumentList()
    }, []);

    const getAllDocumentList = async () => {
        // let clinicId = userDetails.role.includes("ADMIN") ? clinicDetails.clinicId : userDetails.clinicId;
        await getDocumentDetails(userDetails.orgId, clinicDetails.clinicId)
            .then((resp) => {
                setDocumentDetails(resp.data)
                setisLoading(false)
            })
            .catch((error) => {

            })
    };

    const uploadFile = () => {
        setOpenFileUpload(true)
    };

    const downloadFile = async (folderName, fileName) => {
        await downloadDocument(userDetails.orgId, folderName, fileName)
            .then((resp) => {
                const file = new Blob([resp.data])
                const fileURL = URL.createObjectURL(file)
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const addDocument = () => {
        setOpenFileUpload(true)
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addDocument}>Add Document</material.Button>
                        </span>
                    </div>
                </div>
                <material.Paper elevation={3} sx={{ pb: 3, pl: 3, pr: 3, mt: 3 }}>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <material.TableContainer sx={{ maxHeight: 500 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Treatment Name</StyledTableCell>
                                            <StyledTableCell>File Name</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={2}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={2} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {documentDetails.length ? documentDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.treatmentName}</material.TableCell>
                                                        <material.TableCell size='small'>{row.fileName}</material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={2}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={documentDetails.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </material.Paper>
                {/* <div className='container d-flex flex-direction-row'>
                        <div>
                            {folderName?.map((folder, i) => (
                                <material.TreeView
                                    key={i}
                                    aria-label="multi-select"
                                    defaultCollapseIcon={<material.ExpandMoreIcon />}
                                    defaultExpandIcon={<material.ChevronRightIcon />}
                                    // multiSelect
                                    sx={{ height: "auto", flexGrow: 1, maxWidth: 350, overflowY: 'auto' }}
                                >
                                    <material.TreeItem nodeId={folder.folderName} label={folder.folderName} icon={<material.FolderIcon />}>
                                        {folder.files?.map((file, k) => (
                                            <span key={k} className='d-flex flex-direction-row'>
                                                <material.TreeItem nodeId={file} label={file} icon={<material.InsertDriveFileIcon />} />
                                                <material.Tooltip title="Download File">
                                                    <material.IconButton onClick={() => downloadFile(folder.folderName, file)}>
                                                        <material.DownloadIcon title="Download" sx={{ color: "blue" }} />
                                                    </material.IconButton>
                                                </material.Tooltip>
                                            </span>
                                        ))}
                                    </material.TreeItem>
                                </material.TreeView>
                            ))}
                        </div>
                    </div> */}
            </div>
            <FileUpload
                open={openFileUpload}
                setOpen={setOpenFileUpload}
                getAllDocumentList={getAllDocumentList}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(DocumentManagement);