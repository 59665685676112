import React from "react";
import { material } from "../../library/material";


function PreviewHtmlTemplate(props) {
  const { setOpenPreviewHtmlTemplate, openPreviewHtmlTemplate } = props;
  console.log(openPreviewHtmlTemplate.data);
  return (
    <div>
      <material.Dialog open={openPreviewHtmlTemplate.action} fullScreen>
        <material.DialogContent dividers>
          <div
            dangerouslySetInnerHTML={{ __html: openPreviewHtmlTemplate.data }}
          />
        </material.DialogContent>
        <material.DialogActions className="no-print">
          <div className="mt-3">
            <span className="float-end py-3">
              <material.Button
                variant="contained"
                sx={{ textTransform: "none" }}
                size="medium"
                color="error"
                onClick={() =>
                  setOpenPreviewHtmlTemplate({ action: false, data: null })
                }
              >
                Close
              </material.Button>
            </span>
          </div>
        </material.DialogActions>
      </material.Dialog>
    </div>
  );
}

export default PreviewHtmlTemplate;
