import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { material } from '../../library/material';
import { getAllClinic } from '../../services/ClinicService';
import { createOrUpdateTreatmentList } from '../../services/TreatmentListService';
import { connect } from 'react-redux';
import Snackbar from '../toastrmessage/Snackbar';
import { getInventoryListByClinicId, getProductPriceDataByClinicId, getProductPriceDataByOrgId } from '../../services/ProductPriceManagment';
import { getDistinctStocksByClinicId } from '../../services/StocksService';


var allClinicData;
var clinicId;
var allProductDetails;

const treatmentName = ["Dermal Filler", "Anti-Wrinkle", "Skin Booster", "Hyalase Treatment", "Local Anaesthetic", "Threads", "Exomide Scalp"];

function ConfigureTreatmentList(props) {

    const { open, setOpen, userData, getAllTreatmentList, clinicData } = props;
    const [allClinic, setAllClinic] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [productName, setProductName] = useState([]);
    const [product, setProduct] = useState([]);

    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, resetField, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const { fields, append, remove } = useFieldArray({
        control, // the 'control' object from useForm
        name: 'fieldName', // the name of the field array
    });

    useEffect(() => {
        getClinics()
        // getAllProduct()
        // append()
    }, []);

    const getClinics = async () => {
        await getAllClinic()
            .then((resp) => {
                allClinicData = resp.data;
                let clinicName = resp?.data?.map((ele) => ele.clinicName);
                setAllClinic(clinicName)
            })
            .catch((error) => {

            })
    };

    const selectClinic = async (e, value) => {
        clinicId = allClinicData.filter((ele) => ele.clinicName === value).map((res) => res.clinicId)
        if (clinicId.length) {
            await getInventoryListByClinicId(clinicId[0])
                .then((resp) => {
                    allProductDetails = resp.data
                    let productName = resp.data.map((res) => res.product);
                    setProductName(productName)
                    setProduct(resp.data)
                })
        }
    };

    const handleChangeTreatment = () => {

    }

    const cancel = () => {
        setOpen(false);
        resetField("treatment");
        resetField("area");
        resetField("product");
        resetField("clinicName");
        remove();
    };

    const saveTreatment = async (fromData) => {
        let obj = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId,
            treatmentList: fromData.fieldName
        }
        await createOrUpdateTreatmentList(obj)
            .then((res) => {
                setOpen(false);
                resetField("treatmentName");
                resetField("treatmentArea");
                resetField("clinicName");
                remove();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Added successfully",
                })
                getAllTreatmentList()
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            });
    };

    return (
        <div>
            <material.Dialog maxWidth="md" fullWidth open={open} hideBackdrop>
                <material.DialogTitle>
                    <span><material.Typography variant="h5">Configure Treatment List</material.Typography></span>
                    <span>
                        <material.Fab color="primary" aria-label="add" size='small' title='Add Treatment' sx={{ mt: 2 }} onClick={() => append({ "treatmentName": "", "treatmentArea": "" })}>
                            <material.AddIcon />
                        </material.Fab>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    {/* {userDetails.role.includes("ADMIN") ? (
                        <span>
                            <material.Autocomplete
                                id="clinicName"
                                onChange={selectClinic}
                                options={allClinic}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Select Clinic"
                                    {...register("clinicName", { required: true })}
                                    sx={{ width: "30%" }}
                                />}
                            />
                        </span>
                    ) : null} */}
                    {fields.map((field, index) => (
                        <div className='row' key={field.id}>
                            <div className='col-5'>
                                <material.Autocomplete
                                    id="clinicName"
                                    onChange={handleChangeTreatment}
                                    options={treatmentName}
                                    renderInput={(params) => <material.TextField {...params} variant="standard" label="Treatment"
                                        {...register(`fieldName.${index}.treatmentName`, { required: true })}
                                        fullWidth
                                    />}
                                />
                            </div>
                            <div className='col-5'>
                                <material.TextField
                                    multiline
                                    label="Treatment Area"
                                    id="product"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                    {...register(`fieldName.${index}.treatmentArea`, { required: true })}
                                />
                            </div>
                            <span className='col-1'>
                                <material.IconButton title='Delete Treatment' aria-label="delete" size="large" sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }} onClick={() => remove(index)}>
                                    <material.DeleteIcon color='warning' />
                                </material.IconButton>
                            </span>
                        </div>
                    ))}
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} onClick={cancel}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveTreatment)} disabled={!isValid}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ConfigureTreatmentList);