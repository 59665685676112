import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getClinicAction } from '../../store/action/Action';
import Navbar from '../navbar/Navbar';
import ColumnChart from '../../shared/reporting/ColumnChart';
import PieChart from '../../shared/reporting/PieChart';
import { getDataForRollingOneMonthReportByClinicId, getAverageSalesByCategoryPerPatient, getMonthSalesByCategoryAndTreatment, getPatientCountsByPeriodStatus, getSalesPercentageByTreatment } from '../../services/reporting-service/ReportingService';
import TableWidgets from '../../shared/reporting/TableWidgets';
import NewPatientCount from '../../shared/reporting/NewPatientCount';
import SalesAverageByPerPatient from '../../shared/reporting/SalesAverageByPerPatient';
import Snackbar from '../toastrmessage/Snackbar';



function Dashboard(props) {
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const navigate = useNavigate();
    localStorage.setItem("menuName", "Dashboard");
    const [pieChartData, setPieChartData] = useState(null);
    const [tableWidgetsData, setTableWidgetsData] = useState(null);
    const [patientCountData, setPatientCountData] = useState(null);
    const [averageSalesData, setAverageSalesData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [columnChartData, setColumnChartData] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getColumnChartData();
        getPieChartData();
        getTableWidgetsData();
        getPatientCountsData();
        getAverageSalesByPerPatientData();
    }, []);

    const getColumnChartData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId: (userDetails.role.includes("MANAGER") || userDetails.role.includes("ADMIN")) ? null : userDetails.userId
        }
        await getDataForRollingOneMonthReportByClinicId(payload)
            .then((resp) => {
                setColumnChartData(resp.data.formattedData);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    const getPieChartData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId: (userDetails.role.includes("MANAGER") || userDetails.role.includes("ADMIN")) ? null : userDetails.userId
        }
        await getSalesPercentageByTreatment(payload)
            .then((resp) => {
                setPieChartData(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    const getTableWidgetsData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId: (userDetails.role.includes("MANAGER") || userDetails.role.includes("ADMIN")) ? null : userDetails.userId
        }
        await getMonthSalesByCategoryAndTreatment(payload)
            .then((resp) => {
                setTableWidgetsData(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    const getPatientCountsData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId: (userDetails.role.includes("MANAGER") || userDetails.role.includes("ADMIN")) ? null : userDetails.userId
        }
        await getPatientCountsByPeriodStatus(payload)
            .then((resp) => {
                setPatientCountData(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    const getAverageSalesByPerPatientData = async () => {
        setIsLoading(true);
        let payload = {
            clinicId: clinicDetails.clinicId,
            injectorId: (userDetails.role.includes("MANAGER") || userDetails.role.includes("ADMIN")) ? null : userDetails.userId
        }
        await getAverageSalesByCategoryPerPatient(payload)
            .then((resp) => {
                setAverageSalesData(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <material.CircularProgress color='secondary' />
                </div>
            ) : (
                <div className='row pb-3'>
                    <div className="col-lg-12">
                        <material.Paper elevation={3}>
                            {columnChartData !== null ? (
                                <ColumnChart columnChartData={columnChartData} />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <material.Paper elevation={3}>
                            {pieChartData !== null ? (
                                <PieChart
                                    pieChartData={pieChartData?.thisMonth}
                                    title="Net Sales of This Month"
                                />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <material.Paper elevation={3}>
                            {pieChartData !== null ? (
                                <PieChart
                                    pieChartData={pieChartData?.lastMonth}
                                    title="Net Sales of Previous Month"
                                />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <material.Paper elevation={3}>
                            {pieChartData !== null ? (
                                <PieChart
                                    pieChartData={pieChartData?.financialYear}
                                    title="Net Sales of This Financial Year"
                                />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <material.Paper elevation={3}>
                            {tableWidgetsData !== null ? (
                                <TableWidgets
                                    tableWidgetsData={tableWidgetsData}
                                />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <material.Paper elevation={3}>
                            {patientCountData !== null ? (
                                <NewPatientCount
                                    patientCountData={patientCountData}
                                />
                            ) : null}
                        </material.Paper>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <material.Paper elevation={3}>
                            {averageSalesData !== null ? (
                                <SalesAverageByPerPatient
                                    averageSalesData={averageSalesData}
                                />
                            ) : null}
                        </material.Paper>
                    </div>
                </div>
            )}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClinicDetails: (data) => {
            dispatch(getClinicAction(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);