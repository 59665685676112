import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { material } from '../../../library/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getStocksAdjustmentByClinicId, getStocksAdjustmentByOrgId } from '../../../services/StocksService';
import { changeDateAndTimeFormate, changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import Snackbar from '../../toastrmessage/Snackbar';
import { socket } from '../../../socket/Socket';
import { connect } from 'react-redux';
import AddStockAdjustment from '../../dialog/drug-book/AddStockAdjustment';
import Navbar from '../../navbar/Navbar';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

var allProductData;

function StockAdjustment(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [stockAdjustmentData, setStockAdjustmentData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setisLoading] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [stockUpdated, setStockUpdated] = useState();
    const [openAddStock, setOpenAddStock] = useState({ action: false, stockData: null })

    socket.on("stock-updated", (res) => {
        setStockUpdated(res)
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Stock Updated",
        })
    });

    localStorage.setItem("menuName", "Injectable Adjustment");

    useEffect(() => {
        getStockAdjustmentList()
    }, [stockUpdated]);

    const getStockAdjustmentList = async () => {
        if (userDetails.role.includes("ADMIN")) {
            await getStocksAdjustmentByOrgId(userDetails.orgId)
                .then((resp) => {
                    allProductData = resp.data;
                    setStockAdjustmentData(resp.data)
                    setisLoading(false)
                })
        } else if (userDetails.role.includes("MANAGER")) {
            await getStocksAdjustmentByClinicId(clinicDetails.clinicId)
                .then((resp) => {
                    allProductData = resp.data;
                    setStockAdjustmentData(resp.data)
                    setisLoading(false)
                })
        };
        setStockUpdated("")
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const addStocks = () => {
        setOpenAddStock({ action: true, stockData: null })
    };

    const editStock = (stockData) => {
        setOpenAddStock({ action: true, stockData: stockData })
    };

    const filterByPrice = (value) => {
        setPage(0);
        const filterData = stockAdjustmentData.filter((ele) => {
            return ele.product
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setStockAdjustmentData(allProductData)
        } else {
            setStockAdjustmentData(filterData)
        }
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                        <span>
                            <material.TextField
                                fullWidth
                                variant="standard"
                                label="Filter by Product Name"
                                type='text'
                                onChange={(e) => filterByPrice(e.target.value)}
                                sx={{ width: "40ch" }}
                            />
                        </span>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addStocks}>Add New Adjustment</material.Button>
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 500 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Supplier</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Product</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Product Type</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Units</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Unit Type</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Date in</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Cost</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Batch No</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Expiry Date</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Edited by</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Time stamp</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Comments</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {stockAdjustmentData.length ? stockAdjustmentData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.supplier}</material.TableCell>
                                                        <material.TableCell size='small'>{row.product}</material.TableCell>
                                                        <material.TableCell size='small'>{row.productType}</material.TableCell>
                                                        <material.TableCell size='small'>{row.units}</material.TableCell>
                                                        <material.TableCell size='small'>{row.unitType}</material.TableCell>
                                                        <material.TableCell size='small'>{changeDateFormate(row.dateIn)}</material.TableCell>
                                                        <material.TableCell size='small'>{row.cost}</material.TableCell>
                                                        <material.TableCell size='small'>{row.batchNo}</material.TableCell>
                                                        <material.TableCell size='small'>{changeDateFormate(row.expDate)}</material.TableCell>
                                                        <material.TableCell size='small'>{row.enitedBy}</material.TableCell>
                                                        <material.TableCell size='small'>{changeDateAndTimeFormate(row.timeStamp)}</material.TableCell>
                                                        <material.TableCell size='small'>{row.comment}</material.TableCell>
                                                        <StyledTableCell size='small'>
                                                            <material.IconButton title='Edit Stocks' aria-label="create" size="large" onClick={() => editStock({ ...row, "action": "edit" })}>
                                                                <material.CreateIcon color='primary' />
                                                            </material.IconButton>
                                                        </StyledTableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={stockAdjustmentData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
                <div>
                    <Snackbar
                        openSnackBar={openSnackBar}
                        setOpenSnackBar={setOpenSnackBar}
                    />
                </div>
            </div>
            <AddStockAdjustment
                openAddStock={openAddStock}
                setOpenAddStock={setOpenAddStock}
                getStockAdjustmentList={getStockAdjustmentList}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(StockAdjustment);