import React, { useState, useEffect } from 'react';
import { material } from '../../../library/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { getVendorByClinicId, getVendorByOrgId } from '../../../services/VendorManagementService';
import { connect } from 'react-redux';
import { changeDateAndTimeFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import moment from 'moment';
import { saveOrUpdateOtherStock } from '../../../services/OtherStockService';
import Snackbar from '../../toastrmessage/Snackbar';

function OtherStockPurchasedDialog(props) {

    const { openAddOtherStock, setOpenAddOtherStock, userData, getOtherStockPurchasedData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:mm:ss"));
    const [dateIn, setDateIn] = useState(dayjs(new Date()));
    const [selectValue, setSelectValue] = useState("");
    const [expiryDate, setExpiryDate] = useState(dayjs(new Date()));
    const [supplier, setSupplier] = useState([]);
    const { register, handleSubmit, resetField, formState: { errors, isValid }, reset } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddOtherStock.action) {
            getSuppliers();
        }
    }, [openAddOtherStock.action === true]);

    useEffect(() => {
        if (openAddOtherStock.action && openAddOtherStock.stockData) {
            reset(openAddOtherStock.stockData);
            setSelectValue(openAddOtherStock.stockData.unitType)
            setDate(openAddOtherStock.stockData?.timeStamp ? dayjs(openAddOtherStock.stockData.timeStamp) : dayjs(new Date()))
            setDateIn(openAddOtherStock.stockData?.dateIn ? dayjs(openAddOtherStock.stockData.dateIn) : dayjs(new Date()))
            setExpiryDate(openAddOtherStock.stockData?.expDate ? dayjs(openAddOtherStock.stockData.expDate) : dayjs(new Date()))
        }
    }, [openAddOtherStock.action === true]);

    const selectSupplier = (e, value) => {
        // getSuppliers()
    };

    const getSuppliers = async () => {
        if (userDetails.role.includes("MANAGER")) {
            await getVendorByClinicId(clinicDetails.clinicId)
                .then((res) => {
                    let supplierName = res.data.map((ele) => ele.companyName)
                    setSupplier(supplierName)
                })
        } else {
            await getVendorByOrgId(userDetails.orgId)
                .then((res) => {
                    let supplierName = res.data.map((ele) => ele.companyName)
                    setSupplier(supplierName)
                })
        };
    };

    const selectBoxOrVial = (event) => {
        setSelectValue(event.target.value)
    };
    
    const saveStock = async (fromData) => {
        let obj = {
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            "active": true,
            "supplier": fromData.supplier,
            "product": fromData.product,
            "productType": fromData.productType,
            "units": fromData.units,
            "unitType": selectValue,
            "dateIn": moment(dateIn.$d).format("YYYY-MM-DDTHH:mm:ss"),
            "cost": fromData.cost,
            "enitedBy": userDetails.userId,
            "comment": fromData.comment,
            "timeStamp": date,
            "batchNo": fromData.batchNo,
            "expDate": moment(expiryDate.$d).format("YYYY-MM-DDTHH:mm:ss"),
        }
        await saveOrUpdateOtherStock(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Added successfully",
                })
                setOpenAddOtherStock({ action: false, stockData: null })
                reset({ "supplier": "", "product": "", "productType": "", "units": "", "unitType": "", "cost": "", "comment": "", "batchNo": "" })
                getOtherStockPurchasedData();
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Something went wrong",
                })
            })
    };

    const updateStock = async (fromData) => {
        await saveOrUpdateOtherStock(fromData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Updated successfully",
                })
                setOpenAddOtherStock({ action: false, stockData: null })
                reset({ "supplier": "", "product": "", "productType": "", "units": "", "unitType": "", "cost": "", "comment": "", "batchNo": "" })
                getOtherStockPurchasedData();
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Something went wrong",
                })
            })
    };

    const close = () => {
        reset({ "supplier": "", "product": "", "productType": "", "units": "", "unitType": "", "cost": "", "comment": "", "batchNo": "" })
        setOpenAddOtherStock({ action: false, stockData: null })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openAddOtherStock.action} hideBackdrop >
                <material.DialogTitle>{openAddOtherStock.stockData ? "Edit Other Stock" : "Add Other Stock"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            {openAddOtherStock.stockData ? (
                                <material.TextField
                                    required
                                    label="Supplier"
                                    size="small"
                                    variant='standard'
                                    fullWidth
                                    {...register("supplier", { required: true })}
                                    disabled
                                />
                            ) : (
                                <material.Autocomplete
                                    id="area"
                                    fullWidth
                                    onChange={selectSupplier}
                                    options={supplier}
                                    renderInput={(params) => <material.TextField required {...params} variant="standard" label="Select Supplier"
                                        {...register("supplier", { required: true })}
                                    />}
                                />
                            )}
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                required
                                label="Product"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("product", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                required
                                label="Product Type"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("productType", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                required
                                label="Units"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("units", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.FormControl
                                fullWidth
                                variant='standard'
                                required
                            >
                                <material.InputLabel id="demo-simple-select-helper-label">Box/Vial</material.InputLabel>
                                <material.Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectValue}
                                    onChange={selectBoxOrVial}
                                >
                                    <material.MenuItem value="BOX">BOX</material.MenuItem>
                                    <material.MenuItem value="VIAL">VIAL</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    label="Date In"
                                    format='DD-MM-YYYY'
                                    value={dateIn}
                                    onChange={(newValue) => {
                                        setDateIn(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                required
                                label="Cost"
                                size="small"
                                variant='standard'
                                sx={{ width: "100%" }}
                                {...register("cost", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                required
                                label="Batch Id"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("batchNo", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    views={['year', 'month']}
                                    label="Expiry Date"
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    value={expiryDate}
                                    onChange={(newValue) => {
                                        setExpiryDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Time stamp"
                                size="small"
                                variant='standard'
                                fullWidth
                                value={changeDateAndTimeFormate(date)}
                                disabled
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Comments"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("comment")}
                            />
                        </div>
                        <material.DialogActions>
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={close}>
                                    Cancel
                                </material.Button>
                                {openAddOtherStock.stockData ? (
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(updateStock)} disabled={!isValid}>Update</material.Button>
                                ) : (
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveStock)} disabled={!isValid}>Submit</material.Button>
                                )}
                            </span>
                        </material.DialogActions>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(OtherStockPurchasedDialog);