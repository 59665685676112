import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import StartCampaignDialog from './StartCampaignDialog';
import { getCampaignNameByCampaignRefNo, getCampaignNameByClinicId, startSendingEmailByCampaignRefNo, startSendingSmsByCampaignRefNo } from '../../../services/CampaignService';
import Snackbar from '../../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import UploadTemplate from './UploadTemplate';
import PatientSelection from './PatientSelection';
import { useNavigate } from "react-router-dom";
import CreateTemplateDialog from './CreateTemplateDialog';

function Campaign(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const navigate = useNavigate();
    const clinicDetails = clinicData.clinicReducer.data;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [campaignList, setCampaignList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openStartCampaignDialog, setOpenStartCampaignDialog] = useState(false);
    const [allCampaignName, setAllCampaignName] = useState([]);
    const [campaignRefNo, setCampaignRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openUploadTemplate, setOpenUploadTemplate] = useState({ action: false, data: null });
    const [openPatientSelection, setOpenPatientSelection] = useState({ action: false, clinicData: null });
    const [openCreateTemplate, setOpenCreateTemplate] = useState({ action: false, data: null })
    localStorage.setItem("menuName", "Campaign Tracker");

    useEffect(() => {
        getCampaignName();
    }, []);

    const getCampaignName = () => {
        getCampaignNameByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                setAllCampaignName(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const selectCampaign = (e, value) => {
        setCampaignRefNo(value.campaignRefNo)
        getCampaignListByCampaignRefNo(value.campaignRefNo)
    };

    const getCampaignListByCampaignRefNo = async (campaignRefNo) => {
        setIsLoading(true)
        await getCampaignNameByCampaignRefNo(campaignRefNo)
            .then((resp) => {
                setCampaignList(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const startCampaign = () => {
        setOpenStartCampaignDialog(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const startEmailSending = async () => {
        await startSendingEmailByCampaignRefNo({ campaignRefNo })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const startSmsSending = async () => {
        await startSendingSmsByCampaignRefNo({ campaignRefNo })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const uploadTemplate = () => {
        setOpenUploadTemplate({ action: true, data: null })
    };

    const createTemplate = () => {
        // setOpenCreateTemplate({ action: true, data: null })
        navigate('/campaign/create-template')
    }

    const selectPatient = () => {
        setOpenPatientSelection({ action: true, clinicData: clinicDetails });
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div className="row">
                <div className="col-lg-2 col-sm-12">
                    <material.Autocomplete
                        id="campaignName"
                        onChange={selectCampaign}
                        options={allCampaignName}
                        getOptionLabel={(option) => option.campaignName}
                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Select Campaign"
                            sx={{ mt: -2 }}
                        />}
                    />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={startEmailSending} startIcon={<material.EmailIcon />}>Start Email Sending</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={startSmsSending} startIcon={<material.TextsmsIcon />}>Start SMS Sending</material.Button>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <span className="float-end">
                        <material.Button variant="contained" color='secondary' sx={{ textTransform: "none", mr: 1 }} onClick={selectPatient}>Select Patients</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={createTemplate} startIcon={<material.AddIcon />}>Create Template</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={uploadTemplate} startIcon={<material.UploadIcon />}>Upload Template</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={startCampaign} startIcon={<material.CampaignIcon />}>Start Campaign</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 500 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Campaign Name</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Unsubscribe/Subscribe</StyledTableCell>
                                        <StyledTableCell>Phone</StyledTableCell>
                                        <StyledTableCell>Phone Unsubscribe/Subscribe</StyledTableCell>
                                        <StyledTableCell>Email Sent</StyledTableCell>
                                        <StyledTableCell>SMS Sent</StyledTableCell>
                                        <StyledTableCell>Email Opened</StyledTableCell>
                                        <StyledTableCell>Invalid Email</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {campaignList.length ? campaignList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 }
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.campaignName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.firstName + " " + row.lastName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.email}</material.TableCell>
                                                    <material.TableCell size='small'>{row.unsubscribe}</material.TableCell>
                                                    <material.TableCell size='small'>{row.phoneNumberWithCountryCode}</material.TableCell>
                                                    <material.TableCell size='small'>{row.phoneUnsubscribe}</material.TableCell>
                                                    <material.TableCell size='small'>{row.emailSent}</material.TableCell>
                                                    <material.TableCell size='small'>{row.smsSent}</material.TableCell>
                                                    <material.TableCell size='small'>{row.emailOpened}</material.TableCell>
                                                    <material.TableCell size='small'>{row.invalidEmail}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={campaignList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <StartCampaignDialog
                openStartCampaignDialog={openStartCampaignDialog}
                setOpenStartCampaignDialog={setOpenStartCampaignDialog}
                getCampaignName={getCampaignName}
            />
            <UploadTemplate
                openUploadTemplate={openUploadTemplate}
                setOpenUploadTemplate={setOpenUploadTemplate}
            />
            <PatientSelection
                openPatientSelection={openPatientSelection}
                setOpenPatientSelection={setOpenPatientSelection}
            />
            {/* <CreateTemplateDialog openCreateTemplate={openCreateTemplate} setOpenCreateTemplate={setOpenCreateTemplate} /> */}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(Campaign);