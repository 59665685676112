import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Snackbar from '../toastrmessage/Snackbar';
import { getHighestPriorityRole } from '../../highest-priority-role/HighestPriorityRole';
import { getAllClinic } from '../../services/ClinicService';
import { saveEmailText } from '../../services/EmailConfigService';

const purposes = ["After Care Email"]

function AddEmailConfig(props) {

    const { open, setOpen, userData, getAllEmailTextData, clinicData } = props;
    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm({
        mode: "onTouched",
    });

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const save = async (formData) => {
        // let clinicid = userDetails.role.includes("ADMIN") ? clinicId : userDetails.clinicId[0]
        await saveEmailText({ ...formData, clinicId: clinicDetails.clinicId, orgId: userDetails.orgId })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpen(false)
                reset({ content: "", purpose: "" });
                getAllEmailTextData()
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.message,
                })
            })
    };

    const cancel = () => {
        setOpen(false)
        reset({ content: "", purpose: "" })
    };

    const selectPurpose = () => {

    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="sm" open={open} hideBackdrop>
                <material.DialogTitle>Add Email Configure</material.DialogTitle>
                <material.DialogContent>
                    {/* {userDetails.role.includes("ADMIN") ? (
                        <material.Autocomplete
                            id="generatedBy"
                            margin="dense"
                            onChange={selectClinic}
                            options={clinicList}
                            getOptionLabel={(option) => option.clinicName}
                            renderInput={(params) => <material.TextField {...params} required variant="standard" label="Sales Clinic"
                                {...register("generatedBy", { required: true })}
                            />}
                        />
                    ) : null} */}
                    <material.TextField
                        required
                        margin="dense"
                        id="number"
                        label="Content"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("content", { required: true })}
                    />
                    <material.Autocomplete
                        id="area"
                        onChange={selectPurpose}
                        options={purposes}
                        renderInput={(params) => <material.TextField fullWidth {...params} required variant="standard" label="Purpose"
                            {...register("purpose", { required: true })}
                        />}
                    />
                </material.DialogContent>
                <material.DialogActions>
                    <span>
                        <material.Button sx={{ textTransform: "none", mr: 2 }} variant="contained" color='error' size="small" onClick={cancel}>Cancel</material.Button>
                        <material.Button sx={{ textTransform: "none" }} variant="contained" color='info' size="small" onClick={handleSubmit(save)}>Save</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddEmailConfig);