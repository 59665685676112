import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import { material } from '../../../library/material';
import { getBddFormDataByFormId, sendBddForm } from '../../../services/BddFormService';
import { deletePatientById, getAllPatientImage, getPatientById, getPatientCommunication, getPatientHistory, getPatientNote, getPatientPastProcedureList, getTreatmentImage, reviewEmailByPatientId } from '../../../services/PatientService';
import AddCustomNote from '../../dialog/AddCustomNote';
import AddNotes from '../../dialog/AddNotes';
import AddPatientNote from '../../dialog/AddPatientNote';
import PostSessionNote from '../../dialog/PostSessionNote';
import Navbar from '../../navbar/Navbar';
import Snackbar from '../../toastrmessage/Snackbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AssessmentAskDialog from '../../dialog/AssessmentAskDialog';
import ScheduledAppointments from './ScheduledAppointments';
import { getPatientUpcomingSchedule } from '../../../services/AppointmentService';
import PurchaseHistory from './PurchaseHistory';
import { getAllInjectors } from '../../../services/InjectorService';
import { rootApiUrl } from '../../../environment/Environment';
import { UploadPhotoDialog } from '../treatment-plan/TreatmentPlan';
import axios from 'axios';
import ComparedImageDialog from './ComparedImageDialog';
import { saveCompareImages, getCompareImageList } from '../../../services/TreatmentPlanService';
import moment from 'moment';
import GRS from './GRS/GRS'
import ViewTreatmentPlans from './GRS/ViewTreatmentPlans';
import { useDispatch } from 'react-redux';
import { addPatientId } from '../../../store/action/Action';


function ViewPatientDetails(props) {
    const { userData, clinicData, storePatientId, patientId } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const patientIds = patientId.patientIdReducer.patientIds;
    const [value, setValue] = React.useState("Patient Details");
    const [history, setHistory] = useState([]);
    const [getDefaultNote, setGetDefaultNote] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openWarningDialog, setOPenWarningDialog] = useState({ action: false, patientId: null });
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const [getPatientData, setGetPatientData] = useState({});
    const [openPatientNote, setOpenPatientNote] = useState({ action: false, note: null });
    const [openAssessmentAskDialog, setOpenAssessmentAskDialog] = useState({ action: false, patient: null });
    const [upcomingAppointList, setUpcomingAppointmentList] = useState([]);
    const [isLoading, setIsLoading] = useState(null);
    const [patientAllImages, setPatientAllImages] = useState([]);
    const [openUploadPhoto, setOpenUploadPhoto] = useState({ action: false, uploadType: null });
    const [comparedImg, setComparedImg] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [compareImages, setCompareImages] = useState([]);
    const [openAlertMessage, setOpenAlertMessage] = useState(false);

    const patientData = location.state ? location.state.patientData : "";

    localStorage.setItem("menuName", `Patient Details for ${patientData?.name ? patientData.name : patientData.PatientName}`);

    useEffect(() => {
        if (value === "Injector Notes") {
            getInjectorNotesByPatientId()
        } else if (value === "Dermal Therapists Notes") {
            getDermalTherapistsNotesByPatientId()
        } else if (value === "Patient Details") {
            getPatientDetailsByPatientId()
        } else if (value === "Photo") {
            getAllImagePatient()
        };
    }, [value]);

    const getAllImagePatient = () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait fetching data",
        })
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            patientId: patientData.patientId
        }
        getAllPatientImage(payload)
            .then((resp) => {
                setPatientAllImages(resp.data)
                // let images = resp.data.map((ele) => {
                //     let data = getTreatmentImage(ele);
                //     return data;
                // })
                // console.log(images)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                setTimeout(() => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "info",
                        "message": "Please wait while images are loading",
                    })
                }, 3000);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getPatientDetailsByPatientId = () => {
        setIsLoading(true);
        getPatientById(patientData.patientId)
            .then((resp) => {
                setGetPatientData(resp.data);
                let patientData = resp.data;
                getPatientUpcomingScheduleList(resp.data.patientId)
                if (resp.data.dateOfBirth === null && resp.data.gender === null) {
                    navigate("/patient-list/view-patient-details/edit-patient", { state: { patientData } })
                } else {
                    getPatientHistoryByPatientId(resp.data.patientId);
                }
                setIsLoading(false);
                storePatientId(resp.data.patientId);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getPatientHistoryByPatientId = async (patientId) => {
        setIsLoading(true);
        await getPatientHistory(patientId)
            .then((resp) => {
                setGetDefaultNote(resp.data);
                let filterData = resp.data?.filter((ele) => ele.noteDefinition === "DefaultNotes");
                let matchedPatientId = [];
                matchedPatientId = patientIds.filter((id) => id === patientId);
                if (userDetails.role.includes("INJECTOR") || userDetails.role.includes("THERAPIST")) {
                    if (!matchedPatientId.length) {
                        if (!filterData?.length) {
                            setOpenAssessmentAskDialog({ action: true, patient: "new_patient" });
                        } else {
                            setOpenAssessmentAskDialog({ action: true, patient: "old_patient" });
                        }
                    }
                };
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getInjectorNotesByPatientId = async () => {
        setIsLoading(true);
        let payload = {
            patientId: patientData.patientId,
            typeOfNotes: "INJECTOR_NOTES"
        }
        await getPatientNote(payload)
            .then((resp) => {
                setHistory(resp.data)
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };
    const getDermalTherapistsNotesByPatientId = async () => {
        setIsLoading(true);
        let payload = {
            patientId: patientData.patientId,
            typeOfNotes: "DERMAL_THERAPISTS_NOTES"
        }
        await getPatientNote(payload)
            .then((resp) => {
                setHistory(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const getPatientUpcomingScheduleList = (patientId) => {
        setIsLoading(true);
        Promise.all([getAllInjectors(clinicDetails.clinicId), getPatientUpcomingSchedule(patientId)])
            .then((resp) => {
                const injectorData = resp[0].data;
                const upcomingAppoint = resp[1].data;
                upcomingAppoint?.forEach((appointment, index) => {
                    let data = injectorData?.filter((ele) => ele.userId === appointment.InjectorId);
                    appointment.injectorName = data[0].name;
                })
                setUpcomingAppointmentList(upcomingAppoint);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (event, value) => {
        setValue(value);
    };

    const goBack = () => {
        navigate("/patient-list");
    };

    const openInjectableTreatment = () => {
        // if (getDefaultNote[0].noteDefinition === "") {
        //     console.log("open")
        // } else {
        //     navigate("/patient-list/treatment-plan", { state: { patientData } })
        // }
        navigate("/patient-list/view-patient-details/treatment-plan", { state: { patientData } })
    };

    const openSkinTreatment = () => {
        navigate("/patient-list/view-patient-details/patient_skin_treatment_list", { state: { patientData } })
    };

    const editPatientData = () => {
        navigate("/patient-list/view-patient-details/edit-patient", { state: { patientData } })
    };

    const deletePatientData = async () => {
        setOPenWarningDialog({ action: true, patientId: patientData.patientId })
    };

    const selectAndUploadPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "upload_file" });
    };

    const addPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "click_and_upload" });
    };

    const selectBeforeImg = (imageKey) => {
        // setClickBeforeImg(img);
        if (selectedImages.includes(imageKey)) {
            setSelectedImages(selectedImages.filter(key => key !== imageKey));
        } else {
            setSelectedImages([...selectedImages, imageKey]);
        }
    };

    const comparedImage = async () => {
        const payload = {
            "patientId": patientData.patientId,
            "injectorId": userDetails.role.includes("INJECTOR") ? userDetails.userId : "",
            "clinicId": patientData.clinicId,
            "linkedTo": "",
            "addToPortfolio": false,
            "before_image_link": selectedImages[0],
            "after_image_link": selectedImages[1],
        }
        await saveCompareImages(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setSelectedImages([]);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data,
                })
            })

        // setComparedImg(true);
        // const element = document.getElementById("compared-section");
        // if (element) {
        //     element.scrollIntoView({ behavior: "smooth" });
        // }
    };

    const viewComparison = () => {
        const payload = {
            "patientId": patientData.patientId,
        }
        getCompareImageList(payload)
            .then((resp) => {
                setCompareImages(resp.data)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                setComparedImg(true);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const reviewEmail = () => {
        setOpenAlertMessage(true);
    };

    const getAlertData = () => {
        reviewEmailByPatientId(getPatientData.patientId)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenAlertMessage(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div>
            <Navbar />
            {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <material.CircularProgress color='secondary' />
                </div>
            ) : (
                <div className='container-fluid' style={{ marginTop: "6%" }}>
                    <div className='row'>
                        <div className='col-12'>
                            <span className="float-end">
                                {userDetails.role.includes("MANAGER") ? (
                                    <material.Button variant="contained" size='small' sx={{ textTransform: "none" }} className='me-2' color='error' onClick={deletePatientData}>Delete Patient</material.Button>
                                ) : null}
                                <material.Button variant="contained" size='small' sx={{ textTransform: "none" }} className='me-2' onClick={reviewEmail}>Review Email</material.Button>
                                <material.Button variant="contained" size='small' sx={{ textTransform: "none" }} className='me-2' onClick={editPatientData} hidden={!(userDetails.role.includes("INJECTOR") || userDetails.role.includes("THERAPIST"))}>Edit Patient</material.Button>
                                <material.Button variant="contained" size='small' sx={{ textTransform: "none" }} className='me-2' onClick={openInjectableTreatment} hidden={!userDetails.role.includes("INJECTOR")}>Injectable Treatment</material.Button>
                                <material.Button variant="contained" size='small' sx={{ textTransform: "none" }} className='me-2' onClick={openSkinTreatment} hidden={!(userDetails.role.includes("INJECTOR") || userDetails.role.includes("THERAPIST"))}>Skin Treatment</material.Button>
                                <material.Button variant="contained" size='small' sx={{ textTransform: "none" }} onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                            </span>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <material.Box sx={{ bgcolor: 'background.paper' }}>
                            <material.TabContext
                                value={value}
                            >
                                <material.AppBar position="static" sx={{ backgroundColor: "primary" }}>
                                    <material.TabList
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <material.Tab sx={{ textTransform: "none" }} label="Patient Details" value="Patient Details" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Patient Assessment" value="General Notes" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Injector's Pre Treatment Notes" value="Injector Notes" hidden={userDetails.role.includes("THERAPIST")} />
                                        <material.Tab sx={{ textTransform: "none" }} label="Dermal Therapists Notes" value="Dermal Therapists Notes" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Patient Past Procedure" value="Patient Past Procedure" />
                                        <material.Tab sx={{ textTransform: "none" }} label="BDD Details" value="BDD Details" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Photos" value="Photo" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Patient Purchases" value="Patient Purchases" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Patient Communication" value="Patient Communication" />
                                        <material.Tab sx={{ textTransform: "none" }} label="GRS" value="GRS" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Treatment Plan" value="ViewTreatmentPlans" />
                                    </material.TabList>
                                </material.AppBar>
                                <material.TabPanel value="Patient Details">
                                    <PatientDetails
                                        patientData={getPatientData}
                                        upcomingAppointList={upcomingAppointList}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="General Notes">
                                    <GeneralNotes
                                        patientData={getPatientData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Injector Notes" >
                                    <InjectorNotes
                                        patientData={getPatientData}
                                        getInjectorNotesByPatientId={getInjectorNotesByPatientId}
                                        history={history}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Dermal Therapists Notes">
                                    <DermalTherapistsNotes
                                        patientData={getPatientData}
                                        history={history}
                                        getDermalTherapistsNotesByPatientId={getDermalTherapistsNotesByPatientId}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Patient Past Procedure">
                                    <PastProcedureList
                                        patientData={getPatientData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="BDD Details">
                                    <ViewBddForm
                                        patientData={getPatientData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Photo">
                                    <div className='row'>
                                        <div className="col-12">
                                            <span className='float-end'>
                                                <material.Button className='me-2' size="small" variant="contained" sx={{ textTransform: "none" }} startIcon={<material.VisibilityIcon />} onClick={viewComparison}>View Comparison</material.Button>
                                                {selectedImages.length === 2 ? (
                                                    <material.Button className='me-2' size="small" variant="contained" sx={{ textTransform: "none" }} onClick={comparedImage}>Add To Compared Images</material.Button>
                                                ) : null}
                                                <material.Button className='me-2' size="small" variant="contained" sx={{ textTransform: "none" }} startIcon={<material.UploadFileIcon />} onClick={selectAndUploadPhoto}>Upload Photo</material.Button>
                                                <material.Button className='me-2' size="small" variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddAPhotoIcon />} onClick={addPhoto} >Add Photo</material.Button>
                                            </span>
                                        </div>
                                        <div className='col-lg-12 mt-2 ms-4'>
                                            <span>
                                                {patientAllImages.length ? patientAllImages.map((ele, i) => (
                                                    <img
                                                        key={i}
                                                        src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                                        height={200} width="18%"
                                                        style={{ overflow: "hidden", margin: "10px", cursor: "pointer", border: selectedImages.includes(ele) ? "2px solid blue" : "" }}
                                                        onClick={() => selectBeforeImg(ele)}
                                                    />
                                                )) : ""}
                                            </span>
                                        </div>
                                    </div>
                                    {comparedImg ? (
                                        <div>
                                            <ComparedImageDialog
                                                compareImages={compareImages}
                                                setSelectedImages={setSelectedImages}
                                                setComparedImg={setComparedImg}
                                                setCompareImages={setCompareImages}
                                            />
                                        </div>
                                    ) : null}
                                </material.TabPanel>
                                <material.TabPanel value="Patient Purchases">
                                    <PurchaseHistory
                                        patientData={getPatientData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Patient Communication">
                                    <PatientCommunication
                                        patientData={getPatientData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="GRS">
                                    <GRS patientData={getPatientData} />
                                </material.TabPanel>
                                <material.TabPanel value="ViewTreatmentPlans">
                                    <ViewTreatmentPlans patientData={getPatientData} />
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                    <Snackbar
                        openSnackBar={openSnackBar}
                        setOpenSnackBar={setOpenSnackBar}
                    />
                    <WarningDialog
                        openWarningDialog={openWarningDialog}
                        setOPenWarningDialog={setOPenWarningDialog}
                    />
                    <AddPatientNote
                        openPatientNote={openPatientNote}
                        setOpenPatientNote={setOpenPatientNote}
                        patientData={patientData}
                        getPatientHistoryByPatientId={getPatientHistoryByPatientId}
                    />
                    <AssessmentAskDialog
                        openAssessmentAskDialog={openAssessmentAskDialog}
                        setOpenAssessmentAskDialog={setOpenAssessmentAskDialog}
                        openPatientNote={openPatientNote}
                        setOpenPatientNote={setOpenPatientNote}
                        patientData={patientData}
                    />
                    <UploadPhotoDialog
                        openUploadPhoto={openUploadPhoto}
                        setOpenUploadPhoto={setOpenUploadPhoto}
                        patientData={patientData}
                        userDetails={userDetails}
                        clinicDetails={clinicDetails}
                        callFrom="patient-details"
                        getAllImagePatient={getAllImagePatient}
                    />
                    <AlertMessage
                        openAlertMessage={openAlertMessage}
                        setOpenAlertMessage={setOpenAlertMessage}
                        getAlertData={getAlertData}
                    />
                </div>
            )}
        </div>
    );
};

const AlertMessage = (props) => {

    const { openAlertMessage, setOpenAlertMessage, getAlertData } = props;
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const handleClose = () => {
        setOpenAlertMessage(false)
    };

    const submit = () => {
        getAlertData()
    };

    return (
        <material.Stack sx={{ width: '100%' }} spacing={2}>
            <material.Snackbar open={openAlertMessage} key={vertical + horizontal} sx={{ marginTop: "70px" }} anchorOrigin={{ vertical, horizontal }}>
                <material.Alert variant="filled" severity="info"
                    action={
                        <>
                            <material.Button color="inherit" size="small" onClick={handleClose}>No</material.Button>
                            <material.Button color="inherit" size="small" onClick={submit}>Yes</material.Button>
                        </>
                    }
                >
                    Are you sure you want to review email ?
                </material.Alert>
            </material.Snackbar>
        </material.Stack>
    )
};

const WarningDialog = (props) => {

    const { openWarningDialog, setOPenWarningDialog } = props;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const navigate = useNavigate();

    const close = () => {
        setOPenWarningDialog({ action: false, patientId: null })
    };

    const deletePatient = async () => {
        await deletePatientById(openWarningDialog.patientId)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data,
                })
                setOPenWarningDialog({ action: false, patientId: null })
                navigate(-1)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    return (
        <div>
            <material.Dialog open={openWarningDialog.action} hideBackdrop>
                <material.DialogContent>
                    Delete this patient ?
                    <material.Button variant="outlined" size='small' color='error' sx={{ textTransform: "none" }} className='me-2 ms-2' onClick={close}>No</material.Button>
                    <material.Button variant="outlined" size='small' sx={{ textTransform: "none" }} onClick={deletePatient}>Yes</material.Button>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
};

const PatientDetails = (props) => {

    const { patientData, upcomingAppointList } = props;

    return (
        <div>
            <div className='row ms-2'>
                <div className='col-12 mt-2'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='fw-bold'>
                                <td>Patient Id</td>
                                <td>{patientData.patientId}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Patient Name</td>
                                <td>{patientData.name}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Date of Birth</td>
                                <td>{patientData.dateOfBirth ? changeDateFormate(patientData.dateOfBirth) : null}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Email</td>
                                <td>{patientData.email}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Phone</td>
                                <td>{patientData.phoneNumber}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Gender</td>
                                <td>{patientData.gender}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Medicare Expiry</td>
                                <td>{patientData.medicareExpiry}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Medicare Number</td>
                                <td>{patientData.medicareNumber}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Medicare Reference Number</td>
                                <td>{patientData.medicareNumberReference}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Patient Preferred Payment Method</td>
                                <td>{patientData.patientPreferredPaymentChoice}</td>
                            </tr>
                            <tr className='fw-bold'>
                                <td>Address</td>
                                <td style={{ wordBreak: "break-all" }}>{patientData.address}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <material.Accordion>
                <material.AccordionSummary
                    expandIcon={<material.ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <material.Typography variant='h6'>Scheduled Appointments</material.Typography>
                </material.AccordionSummary>
                <material.AccordionDetails>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ScheduledAppointments
                                upcomingAppointList={upcomingAppointList}
                            />
                        </div>
                    </div>
                </material.AccordionDetails>
            </material.Accordion>
        </div>
    )

};

const GeneralNotes = (props) => {

    const { patientData } = props;
    const [history, setHistory] = useState([]);
    const [openPatientNote, setOpenPatientNote] = useState({ action: false, note: null });
    const [openCustomNote, setOpenCustomNote] = useState(false);

    useEffect(() => {
        getPatientHistoryByPatientId()
    }, []);

    const getPatientHistoryByPatientId = async () => {
        await getPatientHistory(patientData.patientId)
            .then((resp) => {
                setHistory(resp.data)
            })
            .catch((error) => {

            })
    };
    const addDefaultNotes = () => {
        setOpenPatientNote({ action: true, note: null })
    };
    const addCustomNotes = () => {
        setOpenCustomNote(true)
    };

    const viewDefaultNote = (note) => {
        setOpenPatientNote({ action: true, note: note })
    };

    return (
        <div>
            <div className='d-flex justify-content-end' style={{ marginTop: -15 }}>
                <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={addDefaultNotes} startIcon={<material.AddIcon />}>Skin Assessment Form</material.Button>
                <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addCustomNotes} startIcon={<material.AddIcon />}>Add Custom Notes</material.Button>
            </div>
            <div className='mt-5'>
                {history?.length ? history.map((ele, i) =>
                    <span key={i}>
                        <ul className='fw-bold text-align-justify'>
                            {ele.noteDefinition === "DefaultNotes" ? (
                                <li className='ms-5 fw-normal'>{ele.user} | {changeDateFormate(ele.dateOfEntry)} : <material.Link onClick={() => viewDefaultNote(ele.history)} style={{ cursor: "pointer" }}>View Skin Assessment Note</material.Link></li>
                            ) : ele.noteDefinition === "CustomNotes" ? (
                                <li className='ms-5 fw-normal'>{ele.user} | {changeDateFormate(ele.dateOfEntry)} | {Object.keys(ele.history)} : {Object.values(ele.history)}</li>
                            ) : null}
                        </ul>
                    </span>
                ) : (<div>
                    <span className='d-flex justify-content-center'>No data found</span>
                </div>
                )}
            </div>
            <AddPatientNote
                openPatientNote={openPatientNote}
                setOpenPatientNote={setOpenPatientNote}
                patientData={patientData}
                getPatientHistoryByPatientId={getPatientHistoryByPatientId}
            />
            <AddCustomNote
                openCustomNote={openCustomNote}
                setOpenCustomNote={setOpenCustomNote}
                patientData={patientData}
                getPatientHistoryByPatientId={getPatientHistoryByPatientId}
            />
        </div>
    )
};

const InjectorNotes = (props) => {

    const { patientData, getInjectorNotesByPatientId, history } = props;

    const [openAddNotes, setOpenAddNotes] = useState({ action: false, callFrom: null });

    const addNotes = () => {
        setOpenAddNotes({ action: true, callFrom: "InjectorNotes" })
    }

    return (
        <div>
            <span className='float-end'>
                <material.Button variant="contained" sx={{ textTransform: "none", mt: -3 }} onClick={addNotes} startIcon={<material.AddIcon />}> Add-Notes</material.Button>
            </span>
            <div>
                {history.length ? history.map((ele, k) => (
                    <span key={k}>
                        <ul className='fw-bold text-align-justify'>
                            <li className='ms-5 fw-normal'>{ele.name} | {changeDateFormate(ele.dateOfEntry)}: {ele.notes}</li>
                        </ul>
                    </span>
                )) : (
                    <div>
                        <span className='d-flex justify-content-center'>No data found</span>
                    </div>
                )}
            </div>
            <AddNotes
                openAddNotes={openAddNotes}
                setOpenAddNotes={setOpenAddNotes}
                patientData={patientData}
                getInjectorNotesByPatientId={getInjectorNotesByPatientId}
            />
        </div>
    )
};

const DermalTherapistsNotes = (props) => {

    const { patientData, getDermalTherapistsNotesByPatientId, history } = props;
    const [openAddNotes, setOpenAddNotes] = useState({ action: false, callFrom: null });

    const addNotes = () => {
        setOpenAddNotes({ action: true, callFrom: "DermalTherapistsNotes" })
    };

    const [addPostSessionNots, setAddPostSessionNots] = useState({ action: false, patientData: null, note: null });

    const viewPostSessionNote = (note) => {
        setAddPostSessionNots({ action: true, patientData: null, note: note })
    };

    return (
        <div>
            <span className='float-end'>
                <material.Button variant="contained" sx={{ textTransform: "none", mt: -3 }} onClick={addNotes} startIcon={<material.AddIcon />}> Add-Notes</material.Button>
            </span>
            <div>
                {history.length ? history.map((ele, k) => (
                    <span key={k}>
                        <ul className='fw-bold text-align-justify'>
                            {ele?.notes ? (
                                <li className='ms-5 fw-normal'>{ele.name} | {changeDateFormate(ele.dateOfEntry)} : {ele.notes}</li>
                            ) : (
                                <li className='ms-5 fw-normal'>{ele.name} | {changeDateFormate(ele.dateOfEntry)} | <material.Link onClick={() => viewPostSessionNote(ele)} style={{ cursor: "pointer" }}>View Post Session Note</material.Link></li>
                            )}
                        </ul>
                    </span>
                )) : (
                    <div>
                        <span className='d-flex justify-content-center'>No data found</span>
                    </div>
                )}
            </div>
            <AddNotes
                openAddNotes={openAddNotes}
                setOpenAddNotes={setOpenAddNotes}
                typeOfNotes="DermalTherapistsNotes"
                patientData={patientData}
                getDermalTherapistsNotesByPatientId={getDermalTherapistsNotesByPatientId}
            />
            <PostSessionNote
                addPostSessionNots={addPostSessionNots}
                setAddPostSessionNots={setAddPostSessionNots}
            />
        </div>
    )
};

const PastProcedureList = (props) => {
    const { patientData } = props;
    const navigate = useNavigate();
    const [pastProcedureList, setPastProcedureList] = useState([]);

    useEffect(() => {
        getPatientsPastProcedureData()
    }, []);

    const getPatientsPastProcedureData = async () => {
        await getPatientPastProcedureList(patientData.patientId)
            .then((resp) => {
                setPastProcedureList(resp.data)
            })
            .catch((error) => {

            })
    };

    const viewPatientPastProcedureData = (rowData) => {
        navigate('/patient-list/view-patient-details/view-patient-past-procedure', { state: { pastProcedureData: rowData } });
    };

    return (
        <div className='ms-5'>
            <material.Accordion>
                <material.AccordionSummary
                    expandIcon={<material.ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <material.Typography>Skin Treatment</material.Typography>
                </material.AccordionSummary>
                <material.AccordionDetails>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead >
                                <material.TableRow>
                                    <StyledTableCell>Treatment</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Purchased Session</StyledTableCell>
                                    <StyledTableCell>Session No.</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {pastProcedureList.length ? pastProcedureList?.map((treatment, i) => (
                                    treatment.skinTreatment?.map((row, k) => (
                                        <material.TableRow key={k}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                            onClick={() => viewPatientPastProcedureData({ ...row, treatmentName: "Skin", patientId: patientData.patientId })}
                                        >
                                            <material.TableCell sx={{ pt: 3, pb: 3 }} size='small' component="th" scope="row">{row.treatment}</material.TableCell>
                                            <material.TableCell size='small'>{row.area}</material.TableCell>
                                            <material.TableCell size='small'>{row.date}</material.TableCell>
                                            <material.TableCell size='small'>{row.purchasedSession}</material.TableCell>
                                            <material.TableCell size='small'>{row.sessionNumber}</material.TableCell>
                                        </material.TableRow>
                                    ))
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={6}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </material.AccordionDetails>
            </material.Accordion>
            <material.Accordion sx={{ mt: 2 }}>
                <material.AccordionSummary
                    expandIcon={<material.ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <material.Typography>Cosmetic Injectable</material.Typography>
                </material.AccordionSummary>
                <material.AccordionDetails>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead >
                                <material.TableRow>
                                    <StyledTableCell>Treatment</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Product</StyledTableCell>
                                    <StyledTableCell>Quantity</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {pastProcedureList.length ? pastProcedureList?.map((treatment, i) => (
                                    treatment?.cosmeticInjectable?.map((row, k) => (
                                        <material.TableRow
                                            key={k}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                            onClick={() => viewPatientPastProcedureData({ ...row, treatmentName: "Cosmetic", patientId: patientData.patientId })}
                                        >
                                            <material.TableCell sx={{ pt: 3, pb: 3 }} size='small' component="th" scope="row">{row.treatment}</material.TableCell>
                                            <material.TableCell size='small'>{row.area}</material.TableCell>
                                            <material.TableCell size='small'>{row.date}</material.TableCell>
                                            <material.TableCell size='small'>{row.product}</material.TableCell>
                                            <material.TableCell size='small'>{row.quantity}</material.TableCell>
                                        </material.TableRow>
                                    ))
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={6}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </material.AccordionDetails>
            </material.Accordion>
        </div>
    )
};

const ViewBddForm = (props) => {

    const { patientData } = props;
    const [dbbFormData, setDbbFormData] = useState({});
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getPatientData, setGetPatientData] = useState({});

    useEffect(() => {
        getPatientDataById()
    }, []);

    const getPatientDataById = async () => {
        await getPatientById(patientData.patientId)
            .then((resp) => {
                setGetPatientData(resp.data)
                getBddFormData(resp.data)
            })
            .catch((error) => {

            })
    };

    const getBddFormData = async (data) => {
        if (data.bdd_form_id) {
            await getBddFormDataByFormId(data.bdd_form_id)
                .then((resp) => {
                    setDbbFormData(resp.data)
                })
                .catch((error) => {

                })
        }
    };

    const sendBdd = async () => {
        let obj = {
            "email": getPatientData.email,
            "patientId": getPatientData.patientId,
            "clinicId": getPatientData.clinicId
        }
        await sendBddForm(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "BDD form send successfully",
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    }

    return (
        <div>
            <div className='content mt-3 p-4'>
                <material.Paper elevation={7} sx={{ p: 4, width: "75%" }}>
                    <span className='d-flex justify-content-between'>
                        <material.Typography variant="h5">BDD Form</material.Typography>
                        <material.Button hidden={patientData.bdd_form_id !== null} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.SendIcon />} onClick={sendBdd}>Send BDD</material.Button>
                    </span>
                    {patientData.bdd_form_id !== null ? (
                        <div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Patient Name</td>
                                                <td className='col-8'>{dbbFormData?.patientName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Patient Email</td>
                                                <td className='col-8'>{dbbFormData?.userEmail}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Date of Birth</td>
                                                <td className='col-8'>{dbbFormData?.dateOfBirth}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Clinic Name</td>
                                                <td className='col-8'>{dbbFormData?.clinicName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Exp Date</td>
                                                <td className='col-8'>{dbbFormData?.exp_date}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <material.Typography>
                                    The below questions will assess underlying psychological conditions such as (BDD) body dysmorphic disorder.
                                    Please speak to your practitioner if you require further information regarding BDD.
                                </material.Typography>
                                <material.Typography sx={{ mt: 2 }}>
                                    If there are indications of significant underlying psychological issues which may make
                                    you an unsuitable candidate for the cosmetic procedure, you may be referred for evaluation to a
                                    psychologist, psychiatrist or general practitioner who works independently of the Healthcare professional who will perform the procedure or the medical practitioner who will prescribe the cosmetic injectable.
                                </material.Typography>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='col-11'> 1- Are you worried about the way you look, and do you think about your appearance a lot and wish you could think about it less</td>
                                                <td className='col-1 fw-bold'>{dbbFormData?.q1}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>2- How has it, the way you feel about it, affected your daily life activities, such as school, work, or other activities?</td>
                                                <td className='col-1 fw-bold'>{dbbFormData?.q2}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>3- Are there things you avoid because of how you look, such as gatherings, going out etc</td>
                                                <td className='col-1 fw-bold'>{dbbFormData?.q3}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>Do you spend more than 2-3 hours a day thinking about how you look</td>
                                                <td className='col-1 fw-bold'>{dbbFormData?.q4}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>5- If you could not have a cosmetic treatment today, would this affect your life adversely?</td>
                                                <td className='col-1 fw-bold'>{dbbFormData?.q5}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-10'>Assessed By</td>
                                                <td className='col-2 fw-bold'>{dbbFormData?.assessed_by}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-10'>Assessment Result</td>
                                                <td className='col-2 fw-bold'>{dbbFormData?.assessment_result}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-10'>Assessment Note</td>
                                                <td className='col-2 fw-bold'>{dbbFormData?.assessment_note}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <span className='d-flex justify-content-center mt-3 text-danger'>No BDD</span>
                        </div>
                    )}
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const PatientCommunication = (props) => {

    const { patientData } = props;
    const [communicationData, setCommunicationData] = useState([]);

    useEffect(() => {
        getCommunicationData();
    }, []);

    const getCommunicationData = () => {
        getPatientCommunication(patientData.patientId)
            .then((resp) => {
                setCommunicationData(resp.data)
            })
            .catch((error) => {

            })
    };

    return (
        <div>
            <material.TableContainer>
                <material.Table>
                    <material.TableHead >
                        <material.TableRow>
                            <StyledTableCell>Message</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Direction</StyledTableCell>
                            <StyledTableCell>Created Date</StyledTableCell>
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {communicationData.length ? communicationData?.map((row, i) => (
                            <material.TableRow
                                key={i}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 }
                                }}
                            >
                                <material.TableCell sx={{ pt: 3, pb: 3 }} size='small' component="th" scope="row">{row.message}</material.TableCell>
                                <material.TableCell size='small'>{row.type}</material.TableCell>
                                <material.TableCell size='small'>{row.direction === "INBOUND" ? (
                                    <span className='badge bg-success'>{row.direction}</span>
                                ) : row.direction === "OUTBOUND" ? (
                                    <span className='badge bg-danger'>{row.direction}</span>
                                ) : null}</material.TableCell>
                                <material.TableCell size='small'>{moment(row.createdAt).format("DD-MM-YYYY, HH:mm")}</material.TableCell>
                            </material.TableRow>
                        )) : (
                            <material.TableRow >
                                <material.TableCell colSpan={6}>
                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                </material.TableCell>
                            </material.TableRow>
                        )}
                    </material.TableBody>
                </material.Table>
            </material.TableContainer>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
        patientId: state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storePatientId: (patientId) => {
            dispatch(addPatientId(patientId))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPatientDetails);