import React, { useEffect, useState, useRef } from 'react';
import { material } from '../../../library/material';
import Webcam from 'react-webcam';
import { saveTreatmentPlan } from '../../../services/TreatmentPlanService';
import Snackbar from '../../toastrmessage/Snackbar';
import { UploadPhotoDialog } from '../treatment-plan/TreatmentPlan';
import { connect } from 'react-redux';
import { socket } from '../../../socket/Socket';

var uploadImage = [];
var allImages = [];
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT
};

function AfterImages(props) {

    const { openAfterImage, setOpenAfterImage, getTreatmentPlanDataByPlanId, userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);
    const [isWebcamActive, setIsWebcamActive] = useState(true);
    const [image, setImage] = useState([]);
    const [close, setClose] = useState(true);
    const [stream, setStream] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openUploadPhoto, setOpenUploadPhoto] = useState({ action: false, uploadType: null });
    const videoRef = useRef(null);

    const addPhoto = () => {
        setClose(false)
        openCamera();
        setImage("")
    };

    async function openCamera() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(stream);
            videoRef.current.srcObject = stream;
        } catch (error) {
            // console.error(error);
        }
        setIsWebcamActive(true)
    };

    function closeCamera() {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);
        }
        setIsWebcamActive(false)
    };

    function capturePhoto() {
        const imageSrc = videoRef.current.getScreenshot();
        uploadPhoto(imageSrc);
        closeCamera();
    };

    async function uploadPhoto(photo) {
        setImage(photo)
        allImages.push({ "image": photo });
        uploadImage.push(photo);
    };

    const switchCamera = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    function toggleCamera() {
        if (stream) {
            closeCamera();
        } else {
            openCamera();
        }
    };

    const uploadAfterImage = async () => {
        let obj = {
            treatmentPlanRequestId: openAfterImage.patientData.treatmentPlanRequestId,
            patientId: openAfterImage.patientData.patientId,
            injectorId: openAfterImage.patientData.injectorId,
            timeStamp: openAfterImage.patientData.timeStamp,
            targetAreaBefore: openAfterImage.patientData.targetAreaBefore,
            treatmentPlan: openAfterImage.patientData.treatmentPlan,
            clinicId: openAfterImage.patientData.clinicId,
            bdd_form_id: openAfterImage.patientData.bdd_form_id,
            bdd_support_image: openAfterImage.patientData.bdd_support_image,
            targetAreaAfter: uploadImage
        };
        await saveTreatmentPlan(obj)
            .then((resp) => {
                setOpenAfterImage({ action: false, patientData: null })
                uploadImage = [];
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Submit successfully",
                })
                getTreatmentPlanDataByPlanId()
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const cancel = () => {
        setOpenAfterImage({ action: false, patientData: null });
        uploadImage = [];
        setStream(null);
        closeCamera();
        setClose(true);
        setImage("");
        allImages = [];
    };

    const clickPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "click_and_upload" });
    };

    const selectPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "upload_file" });
    };

    const addPhotoUsingSecondary = () => {
        let obj = {
            blankTreatmentData: openAfterImage.patientData,
            clinicDetails,
            userDetails,
            imageType: openAfterImage.imageType
        }
        socket.emit("add-photo-kiosk", obj)
    };

    return (
        <div>
            <material.Dialog open={openAfterImage.action} hideBackdrop fullWidth maxWidth="md">
                <material.DialogTitle>{openAfterImage.imageType === "after_image" ? "After Images" : "Before Images"}
                    {/* <span>
                        <material.IconButton color='primary' title='Add Photo' onClick={addPhoto}>
                            <material.AddAPhotoIcon />
                        </material.IconButton>
                    </span> */}
                </material.DialogTitle>
                <material.DialogContent>
                    <div>
                        <span>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={clickPhoto} startIcon={<material.AddAPhotoIcon />}>Add Photo</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={selectPhoto} startIcon={<material.UploadFileIcon />}>Upload Photo</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="small" onClick={addPhotoUsingSecondary} startIcon={<material.AddAPhotoIcon />}>Add Photo Using Secondary</material.Button>
                        </span>
                    </div>
                    {/* <div className='col-lg-12 py-2' hidden={close}>
                        {image ? (
                            <>
                                <span className='me-5'>
                                    {allImages.length ? allImages.map((ele, i) => (
                                        <img
                                            key={i}
                                            src={ele.image}
                                            height={200} width="25%"
                                            style={{ cursor: "pointer", margin: "5px" }}
                                        />
                                    )) : ""}
                                </span>
                            </>
                        ) : (
                            <span>
                                {isWebcamActive ? (
                                    <Webcam
                                        height={300}
                                        width="100%"
                                        screenshotFormat="image/jpeg"
                                        ref={videoRef}
                                        videoConstraints={{
                                            ...videoConstraints,
                                            facingMode
                                        }}
                                    />
                                ) : (
                                    <div style={{ height: "300px", width: "100%" }}></div>
                                )}
                                <span className='d-flex justify-content-center mt-2'>
                                    <material.Button sx={{ textTransform: "none", mr: 1 }} size='small' variant="contained" onClick={switchCamera} startIcon={<material.CameraswitchIcon />} >Switch camera</material.Button>
                                    <material.Button sx={{ textTransform: "none" }} size='small' variant="contained" onClick={capturePhoto} startIcon={<material.CameraAltIcon />} >Take photo</material.Button>
                                    <material.Button sx={{ textTransform: "none" }} size='small' variant="contained" onClick={toggleCamera} className='ms-2'>
                                        {stream ? 'Close camera' : 'Open camera'}
                                    </material.Button>
                                </span>
                            </span>
                        )}
                    </div> */}
                </material.DialogContent>
                <material.DialogActions>
                    <span className='m-3'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} color='error' size="medium" onClick={cancel}>Cancel</material.Button>
                        {uploadImage?.length ? (
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={uploadAfterImage} startIcon={<material.UploadIcon />}>Upload</material.Button>
                        ) : null}
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <UploadPhotoDialog
                openUploadPhoto={openUploadPhoto}
                setOpenUploadPhoto={setOpenUploadPhoto}
                blankTreatmentData={openAfterImage.patientData}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getCosmeticImageList={getTreatmentPlanDataByPlanId}
                callFrom="primary-user"
                typeOfImage={openAfterImage.imageType}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AfterImages);