import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { material } from '../../../library/material';
import { connect } from 'react-redux';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getInHandStocksByClinic } from '../../../services/StocksService';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';

var allProductData;

function StockInHand(props) {

    const { userData, clinicData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const [products, setProducts] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    localStorage.setItem("menuName", "Injectable Insight");

    useEffect(() => {
        getOtherInHandStockData();
    }, []);

    const getOtherInHandStockData = async () => {
        await getInHandStocksByClinic(clinicDetails.clinicId)
            .then((resp) => {
                allProductData = resp.data;
                setProducts(resp.data)
                setisLoading(false)
            })
            .catch((error) => {

            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filterByPrice = (value) => {
        setPage(0);
        const filterData = products.filter((ele) => {
            return ele.product
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setProducts(allProductData)
        } else {
            setProducts(filterData)
        }
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                        <span>
                            <material.TextField
                                fullWidth
                                variant="standard"
                                label="Filter by Product Name"
                                type='text'
                                onChange={(e) => filterByPrice(e.target.value)}
                                sx={{ width: "40ch" }}
                            />
                        </span>
                    </div>
                </div>
                <material.Paper elevation={3} sx={{ pb: 3, pl: 3, pr: 3, mt: 3 }}>
                    <div className='row'>
                        <div className='col-12 mt-3'>
                            <material.TableContainer sx={{ maxHeight: 500 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Product Name</StyledTableCell>
                                            <StyledTableCell>Total Purchased Unit</StyledTableCell>
                                            <StyledTableCell>Total Sold</StyledTableCell>
                                            <StyledTableCell>Total Adjusted</StyledTableCell>
                                            <StyledTableCell>In-Hand</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {products.length ? products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.product}</material.TableCell>
                                                        <material.TableCell size='small'>{row.total_purchased}</material.TableCell>
                                                        <material.TableCell size='small'>{row.total_used}</material.TableCell>
                                                        <material.TableCell size='small'>{row.total_adjustment}</material.TableCell>
                                                        <material.TableCell size='small'>{row.in_hand}</material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={products.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </material.Paper>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(StockInHand);