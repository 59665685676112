import React from 'react';
import { material } from '../../../library/material';
import Swipedrawer from '../../drawer/Swipedrawer';
import { styled } from '@mui/material/styles';
import { drawerWidth } from '../../drawer/Swipedrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Navbar from '../../navbar/Navbar';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function ImageComparedNotes(props) {

    const location = useLocation();
    const navigate = useNavigate();
    let treatmentPlanDetails = location.state ? location.state : "";

    console.log(treatmentPlanDetails)

    const goBack = () => {
        navigate(-1)
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                        {/* <span><material.Typography variant="h5">Compared Images</material.Typography></span> */}
                    </div>
                    <div className='col-6'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} className='ms-2' onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                <material.Paper sx={{ p: 4, mt: 2, pb: 6 }} className='container'>
                    <div className="row">
                        <div className="col-lg-6">
                            <span>Before Image</span>
                            <img
                                height={300}
                                width="100%"
                                src={treatmentPlanDetails.beforeImage}
                                alt=''
                            />
                        </div>
                        <div className="col-lg-6">
                            <span>After Image</span>
                            <img
                                height={300}
                                width="100%"
                                src={treatmentPlanDetails.afterImage}
                                alt=''
                            />
                        </div>
                        <div className='mt-3'>
                            <material.TextField
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                multiline
                                label="Additional Info"
                                rows={3}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <material.TableContainer>
                                <material.Table>
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Treatment</StyledTableCell>
                                            <StyledTableCell>Area</StyledTableCell>
                                            <StyledTableCell>Product</StyledTableCell>
                                            <StyledTableCell>Qty</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {treatmentPlanDetails.treatmentPlanDetails?.treatmentPlan?.length ? treatmentPlanDetails.treatmentPlanDetails.treatmentPlan.map((row, i) => (
                                            <material.TableRow key={i}>
                                                <material.TableCell sx={{ pt: 3, pb: 3 }} size='small' component="th" scope="row">{row.treatmentName}</material.TableCell>
                                                <material.TableCell size='small'>{row.treatmentArea}</material.TableCell>
                                                <material.TableCell size='small'>{row.product}</material.TableCell>
                                                <material.TableCell size='small'>{row.qty}</material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow >
                                                <material.TableCell colSpan={6}>
                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                        </div>
                    </div>
                </material.Paper>
            </div>
        </div>
    );
}

export default ImageComparedNotes;