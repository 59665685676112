import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import SignaturePad from 'react-signature-canvas';
import { saveSkinConsentForm } from '../../../services/ConsentFormService';
import Snackbar from '../../toastrmessage/Snackbar';
import { getClinicDetailsByClinicId } from '../../../services/TreatmentPlanService';
import AddAndUploadPhotoDialog from '../skin-treatment-booking/AddAndUploadPhotoDialog';

var sigPad = {};

function ConsentFormCP(props) {

    const { getConsentFormData, openConsentForm, setOpenConsentForm, patientDataDetails, callFrom } = props;
    const [questions, setQuestions] = useState({
        aw_q1: "", aw_q2: "", aw_q3: "", aw_q4: "", aw_q5: "", aw_q6: "", aw_q7: "", aw_q8: "", aw_q9: "", aw_q10: "", aw_q11: "", aw_q12: "", aw_q13: "",
        aw_q14: "", aw_q15: ""
    });
    const [isHide, setIsHide] = useState(false);
    const [getSignature, setGetSignature] = useState(null);
    const [clinicDetails, setClinicDetails] = useState({});
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState({ action: false, treatmentData: null });

    useEffect(() => {
        if (openConsentForm.action) {
            getClinicByClinicId()
        }
    }, [openConsentForm.action === true]);

    const getClinicByClinicId = async () => {
        await getClinicDetailsByClinicId(openConsentForm.patientData.clinicId)
            .then((resp) => {
                setClinicDetails(resp.data.dataValues);
                if (openConsentForm.patientData?.concent_data?.data) {
                    const element = document.getElementById("button-section")
                    element.scrollIntoView({ behavior: "smooth" });
                };
            })
    };

    const selectQuestions = (qNumber, value) => {
        setQuestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");

    const getPatientSignature = () => {
        setGetSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    };

    const saveConsent = async () => {
        let obj = {
            patientId: openConsentForm.patientData.patientId,
            skinTreatmentPlanId: openConsentForm.patientData.skinTreatmentPlanId,
            concent_data: { ...questions, getSignature }
        }
        await saveSkinConsentForm(obj)
            .then((resp) => {
                setOpenPhotoUploadDialog({ action: true, treatmentData: patientDataDetails });
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
        setIsHide(true)
    };

    const cancel = () => {
        setOpenConsentForm({ action: false, patientData: null })
    };

    const clear = () => {
        sigPad.clear()
        setGetSignature(null)
    };

    const openSettings = () => {
        setOpenPhotoUploadDialog({ action: true, treatmentData: patientDataDetails });
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="md" open={openConsentForm.action} hideBackdrop>
                <material.DialogContent>
                    <div className=''>
                        {/* <material.Paper elevation={3} sx={{ mt: 2, m: 2, p: 3, pb: 10 }}> */}
                        <div className='ms-3'>
                            <div className='mt-2 fw-bold'>
                                <span className='d-flex justify-content-center'>CHEMICAL PEEL TREATMENT CONSENT FORM</span>
                            </div>
                            <h5 className='mt-5'>Procedure Information and Risks:</h5>
                            <div className='mt-2'>
                                <div>
                                    <span>
                                        I acknowledge that I have been informed about the Chemical Peels procedure, its potential benefits, and the associated risks. These include, but are not limited to:
                                    </span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Temporary or permanent change in skin pigmentation.</li>
                                        <li>Scarring.</li>
                                        <li>Swelling or redness.</li>
                                        <li>Infection.</li>
                                        <li>Allergic reaction to the peeling agent.</li>
                                        <li>Unexpected results or lack of desired results.</li>
                                        <li>Reactivation of cold sores.</li>
                                        <li>Sensitivity or skin irritation.</li>
                                    </ul>
                                </div>
                                <div>
                                    <span>
                                        I understand and acknowledge that multiple treatments will be required and results are not guaranted.
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        I understand and acknowledge that treatment packages are not transferrable to any other person and no refund will be provided for change of mind or for any other reason.
                                    </span>
                                </div>
                                <div>
                                    <div className='mt-2 fw-bold'>
                                        <span className=''>Contraindications:</span>
                                    </div>
                                </div>
                                <div>
                                    <span>
                                        I confirm I have not applied a topical prescription retinol to the treatment area in the last two weeks.
                                    </span>
                                </div>
                                <h5 className='mt-2'>Please indicate YES or NO for the following:</h5>
                                <div>
                                    <ul className='ms-3'>
                                        <li>Are you pregnant, breastfeeding, undergoing IVF treatment, or trying to conceive?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q1}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                                        value={questions.aw_q1}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have a history of keloids or hypertrophic scarring?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q2}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                                        value={questions.aw_q2}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Are you currently taking Accutane or have you taken it in the past six months?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q3}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q3", event.target.value)}
                                                        value={questions.aw_q3}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have active skin infections or wounds?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q4}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q4", event.target.value)}
                                                        value={questions.aw_q4}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have a history of photosensitivity or medical conditions exacerbated by sunlight?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q5}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q5", event.target.value)}
                                                        value={questions.aw_q5}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Have you recently undergone facial surgery or received other facial treatments?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q6}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q6", event.target.value)}
                                                        value={questions.aw_q6}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Have you used cosmeceutical retinol within the last 3 days (in the treatment area)?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q7}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q7", event.target.value)}
                                                        value={questions.aw_q7}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Have you used topical prescription retinol within the last 2 weeks (in the treatment area)?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q8}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q8", event.target.value)}
                                                        value={questions.aw_q8}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have eczema or psoriasis (in the treatment area)?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q9}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q9", event.target.value)}
                                                        value={questions.aw_q9}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Have you had laser or IPL hair reduction or waxing within the last 1 – 2 weeks (in the treatment area)?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q10}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q10", event.target.value)}
                                                        value={questions.aw_q10}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have an auto-immune disease?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q11}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q11", event.target.value)}
                                                        value={questions.aw_q11}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have rosacea?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q12}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q12", event.target.value)}
                                                        value={questions.aw_q12}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have fungal dermatitis on the area to be treated?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q13}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q13", event.target.value)}
                                                        value={questions.aw_q13}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have a cold sore on the area to be treated?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q14}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q14", event.target.value)}
                                                        value={questions.aw_q14}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Are you undergoing chemo or radiotherapy?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q15}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q15", event.target.value)}
                                                        value={questions.aw_q15}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <h5>Pre-Procedure Care:</h5>
                                <div>
                                    <span>I confirm that I have taken the following pre-procedure care:</span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Discontinued use of Retin-A, Retinols, or similar products 5-7 days before treatment.</li>
                                        <li>Avoided sun exposure for at least one week before the procedure.</li>
                                        <li>Followed the pre-treatment advice given by <span style={{ fontWeight: "bold" }}>{clinicDetails.clinicName}</span>.</li>
                                    </ul>
                                </div>
                                <h5>Photography Consent:</h5>
                                <div>
                                    <ul>
                                        <li>I consent to before and after photos taken for clinical use:</li>
                                        <li>I consent to before and after photos taken for marketing and advertisement purposes:</li>
                                    </ul>
                                </div>
                                <h5>Aftercare Advice:</h5>
                                <div>
                                    <span>I understand that I need to follow the provided aftercare advice:</span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Avoid direct sunlight and apply a broad-spectrum sunscreen with at least SPF 30 daily.</li>
                                        <li>Refrain from picking, scratching, or scrubbing the treated area.</li>
                                        <li>Keep the skin moisturized.</li>
                                        <li>Avoid strenuous exercises that induce heavy sweating for the next 48 hours.</li>
                                        <li>Refrain from using makeup for at least 24 hours after the treatment.</li>
                                        <li>Report any prolonged redness, swelling, or discomfort to the clinic.</li>
                                        <li>Use a mild cleanser and moisturizer as directed by the practitioner.</li>
                                    </ul>
                                </div>
                                <h5>Consent:</h5>
                                <div>
                                    <span>By signing below, I acknowledge that I understand the risks, benefits, and instructions associated with the Chemical Peels procedure. I have been given an opportunity to ask questions and have had them answered to my satisfaction. I hereby give my consent to undergo the Chemical Peels procedure at <span style={{ fontWeight: "bold" }}>{clinicDetails.clinicName}</span>.</span>
                                </div>
                            </div>
                            <div className='mt-5 d-flex'>
                                <span>Patient Signature</span>
                                {openConsentForm.patientData?.concent_data ? (
                                    <span className='ms-3'>
                                        <img
                                            src={openConsentForm.patientData?.concent_data?.data?.getSignature}
                                        />
                                    </span>
                                ) : (
                                    <span className='sigBox ms-3'>
                                        <SignaturePad penColor='black'
                                            canvasProps={{ width: 400, height: 100, className: 'sigPad' }}
                                            ref={(ref) => { sigPad = ref }}
                                            onEnd={getPatientSignature}
                                        />
                                    </span>
                                )}
                                {openConsentForm.patientData?.concent_data ? null : (
                                    <span className='ms-2'>
                                        <material.Button size='small' sx={{ textTransform: "none" }} onClick={clear}>Clear</material.Button>
                                    </span>
                                )}
                            </div>
                        </div>
                        {/* </material.Paper> */}
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <div id='button-section' className='m-3'>
                        {!isHide ? (
                            <span className='float-end'>
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 2 }} color='error' onClick={cancel}>Cancel</material.Button>
                                {openConsentForm.patientData?.concent_data ? (
                                    <material.Button variant="outlined" sx={{ textTransform: "none" }} hidden={callFrom === "patient_past_procedure"} onClick={openSettings}>Next</material.Button>
                                ) : (
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} hidden={callFrom === "patient_past_procedure"} onClick={saveConsent} disabled={isValid}>Save Consent</material.Button>
                                )}
                            </span>
                        ) : null}
                    </div>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddAndUploadPhotoDialog
                openPhotoUploadDialog={openPhotoUploadDialog}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
                callFrom="Consent Form"
                setOpenConsentForm={setOpenConsentForm}
            />
        </div>
    );
}

export default ConsentFormCP;