import React from 'react';
import { material } from '../../library/material';
import { Slide } from '@mui/material';

const TransitionSide = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function ViewPreWrittenNote(props) {

    const { openViewNotesDialog, setOpenViewNotesDialog } = props;

    const close = () => {
        setOpenViewNotesDialog({ action: false, notes: null });
    };

    return (
        <div>
            <material.Dialog fullScreen hideBackdrop open={openViewNotesDialog.action} TransitionComponent={TransitionSide}>
                <material.AppBar sx={{ position: 'relative' }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Patient Care Documentation
                        </material.Typography>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <material.Typography>{openViewNotesDialog.notes}</material.Typography>
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default ViewPreWrittenNote;