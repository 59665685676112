import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import Snackbar from '../toastrmessage/Snackbar';
import { useForm } from 'react-hook-form';
import { addPatientHistory } from '../../services/PatientService';
import moment from 'moment';
import { connect } from 'react-redux';
import PreSessionWarning from './PreSessionWarning';
import { getSkinTreatmentNotes } from '../../services/SkinTreatmentService';

function PostSessionNote(props) {

    const { addPostSessionNots, setAddPostSessionNots, userData, setOpenConsentForm, setAddSkinTreatmentNots, callFrom, setOpenPhotoUploadDialog } = props;
    const userDetails = userData.authReducer.data;
    const { register, handleSubmit, resetField, reset, formState: { errors, isValid }, setValue } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [questions, setQuestions] = useState({
        aw_q1: "",
        aw_q2: "",
        aw_q3: "",
        aw_q4: ""
    });
    const [selectDoubleCleansed, setSelectDoubleCleansed] = useState(false);
    const [selectAfterCleansing, setSelectAfterCleansing] = useState(false);
    const [selectObservedAseptic, setSelectObservedAseptic] = useState(false);
    const [selectOtherCleansing, setSelectOtherCleansing] = useState(false);
    const [selectProtectiveEyewear, setSelectProtectiveEyewear] = useState(false);
    const [selectWoreProtectiveEyewear, setSelectWoreProtectiveEyewear] = useState(false);
    const [selectWoreGloves, setSelectWoreGloves] = useState(false);
    const [selectOtherMeasure, setSelectOtherMeasure] = useState(false);
    const [selectSlightly, setSelectSlightly] = useState(false);
    const [selectNeededBreaks, setSelectNeededBreaks] = useState(false);
    const [selectFeelingHot, setSelectFeelingHot] = useState(false);
    const [selectOtherTreatment, setSelectOtherTreatment] = useState(false);
    const [selectNoInterruption, setSelectNoInterruption] = useState(false);
    const [selectOtherInterruption, setSelectOtherInterruption] = useState(false);
    const [selectImmediateResponse1, setSelectImmediateResponse1] = useState(false);
    const [selectImmediateResponse2, setSelectImmediateResponse2] = useState(false);
    const [selectImmediateResponse3, setSelectImmediateResponse3] = useState(false);
    const [selectImmediateResponse4, setSelectImmediateResponse4] = useState(false);
    const [selectPostTreatmentComment1, setSelectPostTreatmentComment1] = useState(false);
    const [selectPostTreatmentComment2, setSelectPostTreatmentComment2] = useState(false);
    const [selectPostTreatmentCommentOther, setSelectPostTreatmentCommentOther] = useState(false);
    const [openPreSessionWarningDialog, setOpenPreSessionWarningDialog] = useState({ action: false, patientData: null });
    const [nextStep, setNextStep] = useState(false);

    useEffect(() => {
        if (addPostSessionNots.action && addPostSessionNots.note !== null) {
            reset(addPostSessionNots.note)
            setSelectDoubleCleansed(addPostSessionNots.note.cleansing1)
            setSelectAfterCleansing(addPostSessionNots.note.cleansing2)
            setSelectObservedAseptic(addPostSessionNots.note.cleansing3)
            setSelectOtherCleansing(addPostSessionNots.note.cleansing4)
            setSelectProtectiveEyewear(addPostSessionNots.note.protectiveMeasure1)
            setSelectWoreProtectiveEyewear(addPostSessionNots.note.protectiveMeasure2)
            setSelectWoreGloves(addPostSessionNots.note.protectiveMeasure3)
            setSelectOtherMeasure(addPostSessionNots.note.protectiveMeasure4)
            setSelectSlightly(addPostSessionNots.note.patientResponse1)
            setSelectNeededBreaks(addPostSessionNots.note.patientResponse2)
            setSelectFeelingHot(addPostSessionNots.note.patientResponse3)
            setSelectOtherTreatment(addPostSessionNots.note.patientResponse4)
            setSelectNoInterruption(addPostSessionNots.note.interruptionOrModifications1)
            setSelectOtherInterruption(addPostSessionNots.note.interruptionOrModifications2)
            setSelectImmediateResponse1(addPostSessionNots.note.postTreatmentObservation1)
            setSelectImmediateResponse2(addPostSessionNots.note.postTreatmentObservation2)
            setSelectImmediateResponse3(addPostSessionNots.note.postTreatmentObservation3)
            setSelectImmediateResponse4(addPostSessionNots.note.postTreatmentObservation4)
            setSelectPostTreatmentComment1(addPostSessionNots.note.postTreatmentComment1)
            setSelectPostTreatmentComment2(addPostSessionNots.note.postTreatmentComment2)
            setSelectPostTreatmentCommentOther(addPostSessionNots.note.postTreatmentCommentOther)
            setQuestions({
                aw_q1: addPostSessionNots.note.aw_q1,
                aw_q2: addPostSessionNots.note.aw_q2,
                aw_q3: addPostSessionNots.note.aw_q3,
                aw_q4: addPostSessionNots.note.aw_q4
            })
            getSkinTreatmentNotesBySessionId();
        }
    }, [addPostSessionNots.action === true]);

    const getSkinTreatmentNotesBySessionId = () => {
        getSkinTreatmentNotes(addPostSessionNots.patientData?.skinTreatmentSessionId)
            .then((resp) => {
                reset(resp.data?.postSessionNotes?.history)
                setSelectDoubleCleansed(resp.data?.postSessionNotes?.history?.cleansing1)
                setSelectAfterCleansing(resp.data?.postSessionNotes?.history?.cleansing2)
                setSelectObservedAseptic(resp.data?.postSessionNotes?.history?.cleansing3)
                setSelectOtherCleansing(resp.data?.postSessionNotes?.history?.cleansing4)
                setSelectProtectiveEyewear(resp.data?.postSessionNotes?.history?.protectiveMeasure1)
                setSelectWoreProtectiveEyewear(resp.data?.postSessionNotes?.history?.protectiveMeasure2)
                setSelectWoreGloves(resp.data?.postSessionNotes?.history?.protectiveMeasure3)
                setSelectOtherMeasure(resp.data?.postSessionNotes?.history?.protectiveMeasure4)
                setSelectSlightly(resp.data?.postSessionNotes?.history?.patientResponse1)
                setSelectNeededBreaks(resp.data?.postSessionNotes?.history?.patientResponse2)
                setSelectFeelingHot(resp.data?.postSessionNotes?.history?.patientResponse3)
                setSelectOtherTreatment(resp.data?.postSessionNotes?.history?.patientResponse4)
                setSelectNoInterruption(resp.data?.postSessionNotes?.history?.interruptionOrModifications1)
                setSelectOtherInterruption(resp.data?.postSessionNotes?.history?.interruptionOrModifications2)
                setSelectImmediateResponse1(resp.data?.postSessionNotes?.history?.postTreatmentObservation1)
                setSelectImmediateResponse2(resp.data?.postSessionNotes?.history?.postTreatmentObservation2)
                setSelectImmediateResponse3(resp.data?.postSessionNotes?.history?.postTreatmentObservation3)
                setSelectImmediateResponse4(resp.data?.postSessionNotes?.history?.postTreatmentObservation4)
                setSelectPostTreatmentComment1(resp.data?.postSessionNotes?.history?.postTreatmentComment1)
                setSelectPostTreatmentComment2(resp.data?.postSessionNotes?.history?.postTreatmentComment2)
                setSelectPostTreatmentCommentOther(resp.data?.postSessionNotes?.history?.postTreatmentCommentOther)
                setQuestions({
                    aw_q1: resp.data?.postSessionNotes?.history?.aw_q1,
                    aw_q2: resp.data?.postSessionNotes?.history?.aw_q2,
                    aw_q3: resp.data?.postSessionNotes?.history?.aw_q3,
                    aw_q4: resp.data?.postSessionNotes?.history?.aw_q4
                })
                if (resp.data?.postSessionNotes?.history) {
                    setNextStep(true);
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const cancel = () => {
        setSelectDoubleCleansed(false)
        setSelectAfterCleansing(false)
        setSelectObservedAseptic(false)
        setSelectOtherCleansing(false)
        setSelectProtectiveEyewear(false)
        setSelectWoreProtectiveEyewear(false)
        setSelectWoreGloves(false)
        setSelectOtherMeasure(false)
        setSelectSlightly(false)
        setSelectNeededBreaks(false)
        setSelectFeelingHot(false)
        setSelectOtherTreatment(false)
        setSelectNoInterruption(false)
        setSelectOtherInterruption(false)
        setSelectImmediateResponse1(false)
        setSelectImmediateResponse2(false)
        setSelectImmediateResponse3(false)
        setSelectImmediateResponse4(false)
        setSelectPostTreatmentComment1(false)
        setSelectPostTreatmentComment2(false)
        setSelectPostTreatmentCommentOther(false)
        // if (callFrom === "skin_treatment_details") {
            setAddPostSessionNots({ action: false, patientData: null, note: null });
        // } else {
        //     setAddSkinTreatmentNots({ action: false, patientData: null });
        //     setOpenConsentForm({ action: false, patientData: null });
        //     setOpenPhotoUploadDialog({ action: false, treatmentData: null });
        // }

    };

    const selectQuestions = (qNumber, value) => {
        setQuestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };

    const onChangeDoubleCleansed = () => {
        setSelectDoubleCleansed(!selectDoubleCleansed)
    };

    const onChangeAfterCleansing = () => {
        setSelectAfterCleansing(!selectAfterCleansing)
    };

    const onChangeObservedAseptic = () => {
        setSelectObservedAseptic(!selectObservedAseptic)
    };

    const onChangeOtherCleansing = () => {
        setSelectOtherCleansing(!selectOtherCleansing)
    };

    const onChangeProtectiveEyewear = () => {
        setSelectProtectiveEyewear(!selectProtectiveEyewear)
    };

    const onChangeWoreProtectiveEyewear = () => {
        setSelectWoreProtectiveEyewear(!selectWoreProtectiveEyewear)
    };

    const onChangeWoreGloves = () => {
        setSelectWoreGloves(!selectWoreGloves)
    };

    const onChangeOtherMeasure = () => {
        setSelectOtherMeasure(!selectOtherMeasure)
    };

    const onChangeSlightly = () => {
        setSelectSlightly(!selectSlightly)
    };

    const onChangeNeededBreaks = () => {
        setSelectNeededBreaks(!selectNeededBreaks)
    };

    const onChangeFeelingHot = () => {
        setSelectFeelingHot(!selectFeelingHot)
    };

    const onChangeOtherTreatment = () => {
        setSelectOtherTreatment(!selectOtherTreatment)
    };

    const onChangeNoInterruption = () => {
        setSelectNoInterruption(!selectNoInterruption)
    };

    const onChangeOtherInterruption = () => {
        setSelectOtherInterruption(!selectOtherInterruption)
    };

    const onChangeImmediateResponse1 = () => {
        setSelectImmediateResponse1(!selectImmediateResponse1)
    };

    const onChangeImmediateResponse2 = () => {
        setSelectImmediateResponse2(!selectImmediateResponse2)
    };

    const onChangeImmediateResponse3 = () => {
        setSelectImmediateResponse3(!selectImmediateResponse3)
    };

    const onChangeImmediateResponse4 = () => {
        setSelectImmediateResponse4(!selectImmediateResponse4)
    };

    const onChangePostTreatmentCommentOther = () => {
        setSelectPostTreatmentCommentOther(!selectPostTreatmentCommentOther)
    };

    const onChangePostTreatmentComment1 = () => {
        setSelectPostTreatmentComment1(!selectPostTreatmentComment1)
    };

    const onChangePostTreatmentComment2 = () => {
        setSelectPostTreatmentComment2(!selectPostTreatmentComment2)
    };

    const save = async (formData) => {
        let obj = {
            typeOfNotes: "DERMAL_THERAPISTS_NOTES",
            patientId: addPostSessionNots.patientData.patientId,
            dateOfEntry: moment().format("YYYY-MM-DDTHH:mm:ss"),
            user: userDetails.name,
            skinTreatmentSessionId: addPostSessionNots.patientData.skinTreatmentSessionId,
            history: {
                cleansing1: selectDoubleCleansed,
                cleansing2: selectAfterCleansing,
                cleansing3: selectObservedAseptic,
                cleansing4: selectOtherCleansing,
                other_cleansing_text: formData.other_cleansing_text,
                anaesthetic_text: formData.anaesthetic_text,
                protectiveMeasure1: selectProtectiveEyewear,
                protectiveMeasure2: selectWoreProtectiveEyewear,
                protectiveMeasure3: selectWoreGloves,
                protectiveMeasure4: selectOtherMeasure,
                other_measure_text: formData.other_measure_text,
                patientResponse1: selectSlightly,
                patientResponse2: selectNeededBreaks,
                patientResponse3: selectFeelingHot,
                patientResponse4: selectOtherTreatment,
                other_Treatment_text: formData.other_Treatment_text,
                dropped_the_settings: formData.dropped_the_settings,
                interruptionOrModifications1: selectNoInterruption,
                interruptionOrModifications2: selectOtherInterruption,
                other_interruption_text: formData.other_interruption_text,
                postTreatmentObservation1: selectImmediateResponse1,
                postTreatmentObservation2: selectImmediateResponse2,
                postTreatmentObservation3: selectImmediateResponse3,
                postTreatmentObservation4: selectImmediateResponse4,
                other_immediate_response_text: formData.other_immediate_response_text,
                adverseReactionText: formData.adverseReactionText,
                postTreatmentCareProvidedText: formData.postTreatmentCareProvidedText,
                postTreatmentComment1: selectPostTreatmentComment1,
                postTreatmentComment2: selectPostTreatmentComment2,
                postTreatmentCommentOther: selectPostTreatmentCommentOther,
                not_satisfied: formData.not_satisfied,
                other_immediate_post_treatment_text: formData.other_immediate_post_treatment_text,
                questionedConcernsAddressedText: formData.questionedConcernsAddressed,
                therapist_remark_text: formData.therapist_remark_text,
                ...questions
            }
        }
        await addPatientHistory(obj)
            .then((resp) => {
                reset({ "notes": "" })
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Save successfully",
                })
                setSelectDoubleCleansed(false)
                setSelectAfterCleansing(false)
                setSelectObservedAseptic(false)
                setSelectOtherCleansing(false)
                setSelectProtectiveEyewear(false)
                setSelectWoreProtectiveEyewear(false)
                setSelectWoreGloves(false)
                setSelectOtherMeasure(false)
                setSelectSlightly(false)
                setSelectNeededBreaks(false)
                setSelectFeelingHot(false)
                setSelectOtherTreatment(false)
                setSelectNoInterruption(false)
                setSelectOtherInterruption(false)
                setSelectImmediateResponse1(false)
                setSelectImmediateResponse2(false)
                setSelectImmediateResponse3(false)
                setSelectImmediateResponse4(false)
                setSelectPostTreatmentComment1(false)
                setSelectPostTreatmentComment2(false)
                setSelectPostTreatmentCommentOther(false)
                setOpenPreSessionWarningDialog({ action: true, patientData: addPostSessionNots.patientData })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "",
                })
            })
    };

    const nextFlow = () => {
        setOpenPreSessionWarningDialog({ action: true, patientData: addPostSessionNots.patientData });
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="md" open={addPostSessionNots.action} hideBackdrop>
                <material.DialogTitle className='fw-bold'>{addPostSessionNots.note !== null ? "View Post Session Notes" : "Add Post Session Notes"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <span className='fw-bold'>Procedure Notes:</span>
                        <div className='ps-4'>
                            <span className='fw-bold'>Cleansing:</span>
                            <ul>
                                <li>Double cleansed  the area to be treated with a cleanser.
                                    <material.Checkbox onChange={onChangeDoubleCleansed} checked={selectDoubleCleansed} color='secondary' />
                                </li>
                                <li>After cleansing alcohol swabbed the area.
                                    <material.Checkbox onChange={onChangeAfterCleansing} checked={selectAfterCleansing} color='secondary' />
                                </li>
                                <li>Observed aseptic technique throughout the procedure.
                                    <material.Checkbox onChange={onChangeObservedAseptic} checked={selectObservedAseptic} color='secondary' />
                                </li>
                                <li>Other
                                    <material.Checkbox onChange={onChangeOtherCleansing} checked={selectOtherCleansing} color='secondary' />
                                </li>
                                {selectOtherCleansing ? (
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        label="Please Specify"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("other_cleansing_text")}
                                    />
                                ) : null}
                            </ul>
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Anaesthetic:
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                        value={questions.aw_q1}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="Nil applied" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                            {questions.aw_q1 === "Yes" ? (
                                <material.TextField
                                    margin="dense"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    label="Please Specify"
                                    size="small"
                                    multiline
                                    fullWidth
                                    InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                    {...register("anaesthetic_text")}
                                />
                            ) : null}
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Protective Measure:</span>
                            <ul>
                                <li>Client wore appropriate wavelength protective eyewear.
                                    <material.Checkbox onChange={onChangeProtectiveEyewear} checked={selectProtectiveEyewear} color='secondary' />
                                </li>
                                <li>Technician wore protective eyewear.
                                    <material.Checkbox onChange={onChangeWoreProtectiveEyewear} checked={selectWoreProtectiveEyewear} color='secondary' />
                                </li>
                                <li>Technician also wore gloves.
                                    <material.Checkbox onChange={onChangeWoreGloves} checked={selectWoreGloves} color='secondary' />
                                </li>
                                <li>Other
                                    <material.Checkbox onChange={onChangeOtherMeasure} checked={selectOtherMeasure} color='secondary' />
                                </li>
                                {selectOtherMeasure ? (
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        label="Please Specify"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("other_measure_text")}
                                    />
                                ) : null}
                            </ul>
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Patient Response during the treatment:</span>
                            <ul>
                                <li>Slightly uncomfortable during the treatment.
                                    <material.Checkbox onChange={onChangeSlightly} checked={selectSlightly} color='secondary' />
                                </li>
                                <li>Needed breaks during the treatment.
                                    <material.Checkbox onChange={onChangeNeededBreaks} checked={selectNeededBreaks} color='secondary' />
                                </li>
                                <li>Feeling hot during treatment.
                                    <material.Checkbox onChange={onChangeFeelingHot} checked={selectFeelingHot} color='secondary' />
                                </li>
                                <li>Other
                                    <material.Checkbox onChange={onChangeOtherTreatment} checked={selectOtherTreatment} color='secondary' />
                                </li>
                                {selectOtherTreatment ? (
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        label="Please Specify"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("other_Treatment_text")}
                                    />
                                ) : null}
                            </ul>
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Interruption / modifications required:</span>
                            <ul>
                                <li>Dropped the settings to
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("dropped_the_settings")}
                                    />
                                </li>
                                <li>No interruption.
                                    <material.Checkbox onChange={onChangeNoInterruption} checked={selectNoInterruption} color='secondary' />
                                </li>
                                <li>Other
                                    <material.Checkbox onChange={onChangeOtherInterruption} checked={selectOtherInterruption} color='secondary' />
                                </li>
                                {selectOtherInterruption ? (
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        label="Please Specify"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("other_interruption_text")}
                                    />
                                ) : null}
                            </ul>
                        </div>
                        <span className='fw-bold'>Post Treatment Observation:</span>
                        <div className='ps-4'>
                            <span className='fw-bold'>Immediate response:</span>
                            <ul>
                                <li>Slight redness to the treated area.
                                    <material.Checkbox onChange={onChangeImmediateResponse1} checked={selectImmediateResponse1} color='secondary' />
                                </li>
                                <li>Normal follicular swelling observed.
                                    <material.Checkbox onChange={onChangeImmediateResponse2} checked={selectImmediateResponse2} color='secondary' />
                                </li>
                                <li>Warmth sensation to the treated area.
                                    <material.Checkbox onChange={onChangeImmediateResponse3} checked={selectImmediateResponse3} color='secondary' />
                                </li>
                                <li>Other
                                    <material.Checkbox onChange={onChangeImmediateResponse4} checked={selectImmediateResponse4} color='secondary' />
                                </li>
                                {selectImmediateResponse4 ? (
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        label="Please Specify"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("other_immediate_response_text")}
                                    />
                                ) : null}
                            </ul>
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Adverse Reaction:
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                        value={questions.aw_q2}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                            {questions.aw_q2 === "Yes" ? (
                                <material.TextField
                                    margin="dense"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    label="Please Specify"
                                    size="small"
                                    multiline
                                    fullWidth
                                    InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                    {...register("adverseReactionText")}
                                />
                            ) : null}
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Post treatment care provided:
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q3", event.target.value)}
                                        value={questions.aw_q3}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                            {questions.aw_q3 === "No" ? (
                                <material.TextField
                                    margin="dense"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    label="Please Specify"
                                    size="small"
                                    multiline
                                    fullWidth
                                    InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                    {...register("postTreatmentCareProvidedText")}
                                />
                            ) : null}
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Patient’s Immediate post-treatment comment:</span>
                            <ul>
                                <li>Happy with the treatment.
                                    <material.Checkbox onChange={onChangePostTreatmentComment1} checked={selectPostTreatmentComment1} color='secondary' />
                                </li>
                                <li>Can feel the difference.
                                    <material.Checkbox onChange={onChangePostTreatmentComment2} checked={selectPostTreatmentComment2} color='secondary' />
                                </li>
                                <li>Not satisfied (what explanation provided to patient)
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("not_satisfied")}
                                    />
                                </li>
                                <li>Other
                                    <material.Checkbox onChange={onChangePostTreatmentCommentOther} checked={selectPostTreatmentCommentOther} color='secondary' />
                                </li>
                                {selectPostTreatmentCommentOther ? (
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        label="Please Specify"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("other_immediate_post_treatment_text")}
                                    />
                                ) : null}
                            </ul>
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Questioned / Concerns addressed:
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q4", event.target.value)}
                                        value={questions.aw_q4}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                            {questions.aw_q4 === "No" ? (
                                <material.TextField
                                    margin="dense"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    label="Please Specify"
                                    size="small"
                                    multiline
                                    fullWidth
                                    InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                    {...register("questionedConcernsAddressed")}
                                />
                            ) : null}
                        </div>
                        <div className='ps-4'>
                            <span className='fw-bold'>Therapist's Remarks and Plan for Next Session:
                                <p>
                                    Follow the skin treatment plan as determined earlier
                                    Changes required
                                    <material.TextField
                                        margin="dense"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        label="Please Specify"
                                        size="small"
                                        multiline
                                        fullWidth
                                        InputProps={{ readOnly: addPostSessionNots.note !== null ? true : false }}
                                        {...register("therapist_remark_text")}
                                    />
                                </p>
                            </span>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end m-2'>
                        <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={cancel} startIcon={<material.CloseIcon />}>
                            Cancel
                        </material.Button>
                        {addPostSessionNots.note !== null ? null : (
                            <>
                                <material.Button variant="outlined" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(save)}>
                                    {nextStep ? "Update" : "Save"}
                                </material.Button>
                                <material.Button sx={{ textTransform: "none", ml: 1 }} hidden={!nextStep} size='medium' variant="outlined" onClick={nextFlow}>Next</material.Button>
                            </>
                        )}
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <PreSessionWarning
                openPreSessionWarningDialog={openPreSessionWarningDialog}
                setOpenPreSessionWarningDialog={setOpenPreSessionWarningDialog}
                setOpenConsentForm={setOpenConsentForm}
                setAddSkinTreatmentNots={setAddSkinTreatmentNots}
                setAddPostSessionNots={setAddPostSessionNots}
                callFrom="Post Session Note"
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(PostSessionNote);