import React, { useRef, useState, useEffect } from 'react';
import { material } from '../../library/material';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getMachineList, getMachineScheduleClinic } from '../../services/ConfigureService';
import { getHighestPriorityRole } from '../../highest-priority-role/HighestPriorityRole';
import { Scheduler } from "@aldabil/react-scheduler";


var allMachineList = [];
var selectMachineData;

function ViewMachineSchedule(props) {

    const { openBookedMachineSchedule, setOpenBookedMachineSchedule, clinicData, userData } = props;
    const scheduleObj = useRef(null);
    let clinicDetails = clinicData.clinicReducer.data;
    let userDetails = userData.authReducer.data;
    let role = getHighestPriorityRole(userDetails.role)
    const [machineList, setMachineList] = useState([]);
    const [machineName, setMachineName] = useState([]);
    const [machineScheduleData, setMachineScheduleData] = useState([]);
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (openBookedMachineSchedule.action) {
            getAllMachineList()
        }
    }, [openBookedMachineSchedule.action === true]);

    useEffect(() => {
        if (openBookedMachineSchedule.action) {
            getMachineScheduleList()
        }
    }, [openBookedMachineSchedule.action === true]);

    const getAllMachineList = async () => {
        await getMachineList(role === "INJECTOR" || role === "ADMIN" ? clinicDetails.clinicId : userDetails.clinicId[0])
            .then((resp) => {
                allMachineList = resp.data;
                let machineName = resp.data.map((ele) => ele.machineName);
                setMachineName(machineName)
                let data = resp.data.map((ele) => {
                    return {
                        ...ele,
                        admin_id: ele.machineId,
                        title:ele.machineName,
                        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
                    }
                })
                setMachineList(data)
                setIsLoading(false)
            })
    };

    const getMachineScheduleList = async () => {
        await getMachineScheduleClinic(role === "INJECTOR" || role === "ADMIN" ? clinicDetails.clinicId : userDetails.clinicId[0])
            .then((resp) => {
                let data = resp.data.map((ele) => {
                    return {
                        ...ele,
                        start: new Date(ele.start),
                        end: new Date(ele.end),
                        admin_id: ele.MachineId,
                    }
                })
                setMachineScheduleData(data)
            })
    };

    const selectMachine = (e, value) => {
        selectMachineData = allMachineList.filter((ele) => ele.machineName === value)
    };

    const saveMachineName = (data) => {
        let value = selectMachineData[0];
        setOpenBookedMachineSchedule({ action: false, data: value })
    };

    return (
        <div>
            <material.Dialog maxWidth="lg" fullWidth hideBackdrop open={openBookedMachineSchedule.action}>
                <material.DialogTitle>
                    <span><material.Typography variant="h5">Select Machine</material.Typography></span>
                    <span>
                        <material.Autocomplete
                            id="machineName"
                            onChange={selectMachine}
                            options={machineName}
                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Select Machine"
                                {...register("machineName", { required: true })}
                                sx={{ width: "30%" }}
                            />}
                        />
                        <p className='form-text text-danger'>{errors.machineName?.type === "required" && 'This field is required'}</p>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    {isLoading ? null : (
                        <div>
                            <Scheduler
                                view="day"
                                selectedDate={new Date()}
                                week={{
                                    startHour: 9,
                                    endHour: 23,
                                    step:30
                                }}
                                day={{
                                    startHour: 9,
                                    endHour: 23,
                                    step: 30
                                }}
                                resourceFields={{
                                    idField: "admin_id",
                                    textField: "title",
                                    avatarField: "title",
                                }}
                                events={machineScheduleData}
                                resources={machineList}
                                viewerExtraComponent={(fields, event) => {
                                    return (
                                        <div>
                                            {fields.map((field, i) => {
                                                if (field.name === "admin_id") {
                                                    const admin = field.options.find(
                                                        (fe) => fe.id === event.admin_id
                                                    );
                                                    return (
                                                        <material.Typography
                                                            key={i}
                                                            style={{ display: "flex", alignItems: "center", textTransform: "capitalize" }}
                                                            color="textSecondary"
                                                            variant="caption"
                                                            noWrap
                                                        >
                                                            <material.PersonIcon /> {admin.text}
                                                        </material.Typography>
                                                    );
                                                } else {
                                                    return "";
                                                }
                                            })}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    )}
                </material.DialogContent>
                <material.DialogActions sx={{ marginRight: 7, paddingBottom: 3 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' size="medium" onClick={() => setOpenBookedMachineSchedule({ action: false })}>
                        Cancel
                    </material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(saveMachineName)}>
                        Save
                    </material.Button>
                </material.DialogActions>
            </material.Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ViewMachineSchedule);